"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.alertFragmentDependencies, fragments_1.alertFragment];
const QUERY = `
query getAlerts {
  alert {
    ...alertFragment
  }
}
`.trim();
const getAlertsImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'alert');
exports.default = getAlertsImpl;
