"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.smsTargetFragmentDependencies, fragments_1.smsTargetFragment];
const QUERY = `
query getSmsTargets {
  smsTarget {
    ...smsTargetFragment
  }
}
`.trim();
const getSmsTargetsImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'smsTarget');
exports.default = getSmsTargetsImpl;
