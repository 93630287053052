import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { CheckCircleIcon, ExternalLinkIcon, CashIcon, } from '@heroicons/react/solid'
import { PaperAirplaneIcon, ArrowLeftIcon } from '@heroicons/react/outline' 
import { ethers } from 'ethers'

import services from 'services'
import components from 'components'

import AddBid from './AddBid'
import SetEVMReverseRecord from './SetEVMReverseRecord'
import SetValidatorNodeIDReverseRecord from './SetValidatorNodeIDReverseRecord'
import ClearReverse from './ClearReverse'
import SetRecord from './SetRecord'
import SetResolver from './SetResolver'
import TransferDomain from './TransferDomain'
import actions from './actions'
import constants from './constants'
import reducer from './reducer'
import selectors from './selectors'


class Domain extends React.PureComponent {
  constructor(props) {
    super(props)
    const params = services.linking.getParams('Domain')
    const domain = params.domain ? params.domain.toLowerCase() : null
    this.state = {
      domain: domain,
      connected: services.provider.isConnected(),
      setRecordReset: 0, // increment this to reset the form
      defaultResolver: undefined,
      dataExplorer: null,
      editRecordKey: null,
      deleteRecordKey: null,
      transferEVMAddress: null,
    }
    this.searchPlaceholder = 'Search for another name'
    this.loadDomain(domain)
    this.getAvvy()
  }

  async getAvvy() {
    const api = await services.provider.buildAPI()
    this.avvy = api.avvy
  }

  async setDefaultResolver() { 
    const api = await services.provider.buildAPI()
    this.setState({
      defaultResolver: await api.getDefaultResolverAddress()
    })
  }

  updateParams = () => {
    const params = services.linking.getParams('Domain')
    const domain = params.domain ? params.domain.toLowerCase() : null
    this.setState({
      domain: domain
    }, () => {
      this.loadDomain(domain)
    })
  }

  loadDomain() {
    if (!this.props.isLoading) {
      const params = services.linking.getParams('Domain')
      const domain = params.domain ? params.domain.toLowerCase() : null
      this.props.loadDomain(domain)
      this.props.loadRegistrationPremium()
      this.props.discoverSubdomains(domain)
    }
  }

  onConnect() {
    this.setState({
      connected: true
    })
    this.loadDomain()
  }

  onDisconnect() {
    this.setState({
      connected: false
    })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.setRecordComplete && this.props.setRecordComplete) {
      this.setRecordModal.toggle()
      this.props.resetSetRecord()
    }
  }

  setApi = async () => {
    this.api = await services.provider.buildAPI()
  }

  componentDidMount() {
    services.linking.addEventListener('Domain', this.updateParams)
    services.linking.addEventListener(services.linking.EVENTS.ROUTE_CHANGED, this.updateParams)
    services.provider.addEventListener(services.provider.EVENTS.CONNECTED, this.onConnect.bind(this))
    services.provider.addEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnect.bind(this))
    this.setDefaultResolver()
    this.setApi()
  }

  componentWillUnmount() {
    services.linking.removeEventListener('Domain', this.updateParams)
    services.linking.removeEventListener(services.linking.EVENTS.ROUTE_CHANGED, this.updateParams)
    services.provider.removeEventListener(services.provider.EVENTS.CONNECTED, this.onConnect.bind(this))
    services.provider.removeEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnect.bind(this))
  }

  addToCart(navigator) {
    const isSubdomain = this.props.domain && this.props.domain.subdomain
    const domain = isSubdomain ? this.props.domain.domain : this.state.domain
    this.props.addToCart(domain)
    services.linking.navigate(navigator, 'Register', {})
  }

  setResolver = () => {
    this.props.resetSetResolver()
    this.setResolverModal.toggle()
  }

  handleAddBid(navigate, value) {
    this.props.addBid(this.state.domain, value)
    services.linking.navigate(navigate, 'SunriseAuctionMyBids')
  }

  _handleSetRecord = (type, value) => {
    this.props.setStandardRecord(this.state.domain, type, value)
  }

  showSetRecord = (key, value) => {
    this.setState(currState => ({
      editRecordKey: key,
      deleteRecordKey: null,
    }))
    if (!value) value = ''
    this.setRecord.setValue(value)
    this.setRecordModal.toggle()
  }

  showDeleteRecord = (key, value) => {
    this.setState(currState => ({
      editRecordKey: null,
      deleteRecordKey: key,
    }))
    if (!value) value = ''
    this.setRecord.setValue(value)
    this.setRecordModal.toggle()
  }

  renderAvailableBody() {
    return (
      <div className='max-w-md m-auto'>
        <div className='max-w-sm m-auto mt-4 flex items-center justify-center'>
          <CheckCircleIcon className='w-6 text-alert-blue mr-2' />
          <div className='text-alert-blue'>{'Available for registration'}</div>
        </div>
        {this.props.registrationPremium.gt(ethers.BigNumber.from('0')) ? (
          <div className='mt-4 border-2 rounded-lg border-gray-100 dark:border-gray-700 p-4'>
            <div className='font-bold'>Registration Premium</div>
            <div>
              {'The .avax namespace is currently launching. Names can be acquired, but a one-time Registration Premium must be paid in AVAX. This Registration Premium decreases as time passes, eventually reaching 0.'}
            </div>
            <div className='mt-4 underline'>
              <a href="https://avvy.domains/auction-guide/" target="_blank" rel="noreferrer">Read more about Registration Premiums</a>
            </div>
            <div className='mt-4'>Current Premium: <span className='font-bold'>{services.money.renderAVAX(this.props.registrationPremium)}</span></div>
          </div>
        ) : (
          <div className='p-4'></div>
        )}
        {services.environment.REGISTRATIONS_ENABLED ? (
          <div className='mt-4'>
            <components.buttons.Button 
              text={'Register this name'} 
              onClick={(navigator) => this.addToCart(navigator)} 
            />
          </div>
        ) : null}
        <div className='mt-4'>
          <components.DomainSearch placeholder={this.searchPlaceholder} />
        </div>
      </div>
    )
  }

  renderAuctionAvailableBody() {
    const hasBid = this.props.bids && this.props.bids[this.state.domain]
    return (
      <div className='max-w-md m-auto'>
        {hasBid ? (
          <>
            <components.labels.Success text='You have placed a bid on this name' />
            <div className='mt-8'>
              <components.buttons.Button text={'View my bids'} onClick={(navigator) => services.linking.navigate(navigator, 'SunriseAuctionMyBids')} />
            </div>
          </>
        ) : (
          <>
            <components.labels.Success text='Available for auction' />
            <div className='mt-8'>
              <components.buttons.Button text={'Bid on this name'} onClick={this.bidOnName.bind(this)} />
            </div>
          </>
        )}
        <div className='mt-4'>
          <components.DomainSearch placeholder={this.searchPlaceholder} />
        </div>
      </div>
    )
  }

  renderAuctionBiddingClosedBody() {
    return (
      <div className='max-w-md m-auto'>
        <div className='max-w-sm m-auto mt-4'>
          <components.labels.Information text={'This name is up for auction'} />
        </div>
        <div className='mt-4 bg-gray-100 rounded-xl w-full relative p-8 dark:bg-gray-700'>
          <div className='font-bold'>{'Bidding period is over'}</div>
          <div>
            {'This name is undergoing the Sunrise Auction process, however bidding is closed. If there are no winning bids, this name will be available for registration after the auction completes.'}
          </div>
        </div>
        <div className='mt-4'>
          <components.DomainSearch placeholder={this.searchPlaceholder} />
        </div>
      </div>
    )
  }

  renderUnsupported() {
    return (
      <div className='max-w-md m-auto'>
        <div className='max-w-sm m-auto mt-4 flex items-center justify-center'>
          <components.labels.Error text={'This name cannot be registered'} />
        </div>
        <div className='mt-4'>
          <components.DomainSearch placeholder={this.searchPlaceholder} />
        </div>
      </div>
    )
  }

  renderListings() {
    if (!this.props.listings || this.props.listings.length === 0) return null
    const text = {
      OPENSEA: 'For sale on OpenSea',
      JOEPEGS: 'For sale on Joepegs',
    }
    const link = {
      OPENSEA: `https://opensea.io/assets/avalanche/0x797ac669a1908ca68cd9854994345f570495541a/${this.props.domain.hash}`,
      JOEPEGS: `https://joepegs.com/item/avalanche/0x797ac669a1908ca68cd9854994345f570495541a/${this.props.domain.hash}`,
    }
    return (
      <div className='max-w-screen-md m-auto text-center'>
        {this.props.listings.map((listing) => (
          <>
            <div className='inline-block mr-2'>
              <a href={link[listing.marketplace]} target="_blank" className='mb-4 mr-2 border-gray-200 shadow dark:border-gray-600 border px-2 py-1 inline-block w-full rounded-lg text-center text-sm flex items-center justify-center'>
                <CashIcon className="w-4 mr-2" /><span>{text[listing.marketplace]}</span><ExternalLinkIcon className='w-4 ml-2' />
              </a>
            </div>
          </>
        ))}
      </div>
    )
  }

  renderRegistered() {
    let account = services.provider.getAccount()
    const isOwned = account ? account.toLowerCase() === this.props.domain.owner.toLowerCase() : false
    const isExpired = Date.now() >= this.props.domain.expiresAt * 1000 
    const isGracePeriod = isExpired && Date.now() <= (this.props.domain.expiresAt + 2592000) * 1000
    const isRecyclePeriod = isExpired 
      && Date.now() > ((this.props.domain.expiresAt + 2592000) * 1000) 
      && Date.now() <= ((this.props.domain.expiresAt + 2592000 * 2) * 1000)
    const hash = this.props.domain?.subdomain ? this.props.domain.subdomain.hash : this.props.domain.hash
    const hasLoadedPrivacy = !!this.props.isRevealed && (this.props.isRevealed[hash] !== undefined)
    if (!this.avvy) return

    let subdomains = []
    const subdomainMatcher = `.${this.state.domain}`
    if (this.props.reverseLookups) {
      subdomains = subdomains.concat(Object.keys(this.props.reverseLookups).reduce((output, hash) => {
        const name = this.props.reverseLookups[hash]
        if (name.indexOf(subdomainMatcher) > 0) {
          output.push(name)
        }
        return output
      }, []))
    }
    subdomains.sort()
    
    return (
      <div className='max-w-screen-md m-auto flex w-full md:flex-row md:items-start'>
        <components.Modal ref={(ref) => this.dataExplorerModal = ref}>
          <components.DataExplorer data={this.state.dataExplorer} />
        </components.Modal>
        <components.Modal title={'Set C-Chain / EVM Reverse Record'} ref={(ref) => this.setEVMReverseRecordModal = ref}>
          <SetEVMReverseRecord domain={this.state.domain} onComplete={() => {
            this.setEVMReverseRecordModal.toggle()
          }} reloadRecords={() => this.props.loadReverseRecords(this.state.domain)} />
        </components.Modal>
        <components.Modal title={'Set Validator Node ID Reverse Record'} ref={(ref) => this.setValidatorNodeIDReverseRecordModal = ref}>
          <SetValidatorNodeIDReverseRecord ref={(ref) => this.setValidatorNodeIDReverseRecord = ref} domain={this.state.domain} onComplete={() => {
            this.setValidatorNodeIDReverseRecordModal.toggle()
          }} reloadRecords={() => this.props.loadReverseRecords(this.state.domain)} />
        </components.Modal>
        <components.Modal title={'Delete Reverse Record'} ref={(ref) => this.clearReverseRecordModal = ref}>
          <ClearReverse ref={(ref) => this.clearReverseRecord = ref} domain={this.state.domain} onComplete={() => {
            this.clearReverseRecordModal.toggle()
          }} reloadRecords={() => this.props.loadReverseRecords(this.state.domain)} />
        </components.Modal>
        <components.Modal title={'Transfer Domain'} ref={(ref) => this.transferDomainModal = ref}>
          <TransferDomain onComplete={() => {
            this.loadDomain()
            this.transferDomainModal.toggle()
          }} domain={this.state.domain} />
        </components.Modal>
        <components.Modal title={this.state.deleteRecordKey ? 'Delete Record' : this.state.editRecordKey ? 'Edit Record' :  'Add Record'} ref={(ref) => this.setRecordModal = ref}>
          <SetRecord deleteRecord={this.state.deleteRecordKey} editRecord={this.state.editRecordKey} handleSubmit={this._handleSetRecord} loading={this.props.isSettingRecord} api={this.api} ref={(ref) => this.setRecord = ref} />
        </components.Modal>
        <components.Modal title={'Set Resolver'} ref={(ref) =>  this.setResolverModal = ref}>
          <SetResolver onComplete={() => this.setResolverModal.toggle()} domain={this.state.domain} resolver={this.props.resolver} />
        </components.Modal>
        <components.Modal title={this.state.connected ? 'Send Transfer to ' + this.state.domain : 'Connect your wallet to send a transfer to ' + this.state.domain} ref={(ref) => this.sendTransferModal = ref}>
          {this.state.connected ? (
            <components.Transfer domain={this.state.domain} address={this.state.transferEVMAddress} onClose={() => this.sendTransferModal.toggle()} />
          ) : (
            <div className='m-auto max-w-sm'>
              <components.ConnectWalletButton />
            </div>
          )}
        </components.Modal>
        <components.Modal title={'Switch to Standard Privacy'} ref={(ref) => this.revealDomainModal = ref}>
          {this.props.isRevealComplete ? (
            <div className='max-w-md m-auto'>
              <div className='my-8'>
                <components.labels.Success text={'Your domain has been switched to Standard Privacy'} />
              </div>
              <div className=''>
                <components.buttons.Button text={'Close'} onClick={() => this.revealDomainModal.toggle()} />
              </div>
            </div>
          ) : (
            <div className='max-w-md m-auto text-gray-700'>
              <components.labels.Warning text={'Switching to Standard Privacy reveals your domain name on-chain. This action cannot be reversed.'} />
              <a className='flex items-center justify-center my-4 bg-gray-100 dark:bg-gray-800 dark:text-white p-4 rounded-lg text-center' href="https://avvy.domains/docs/privacy-features-registrations/" target="_blank" rel="noreferrer">Read about privacy features <ExternalLinkIcon className='ml-4 text-gray-400 dark:text-gray-100 w-6' /></a>
              <components.buttons.Button text="Switch to Standard Privacy" onClick={() => this.props.revealDomain(this.state.domain)} loading={this.props.isRevealingDomain} />
            </div>
          )}
        </components.Modal>
        <div className='w-full'>
          {this.renderListings()}
          {this.props.domain.subdomain ? (
            <div className='bg-gray-100 rounded-xl w-full relative p-4 md:p-8 dark:bg-gray-800 w-full'>
              <div className='flex justify-between items-center'>
                <div className='font-bold'>{'Basic Information'}</div>
                {!this.state.connected ? (
                  <components.ConnectWalletButton sm={true} text='Connect' />
                ) : null} 
              </div>
              <div className='w-full bg-gray-300 dark:bg-gray-700 mt-4' style={{height: '1px'}}></div>
              <div className='mt-4 text-sm'>
                <div className='font-bold'>{'Parent Domain'}</div>
                <div className='truncate flex items-center flex-wrap'>
                  {this.props.domain.domain}
                  <components.buttons.Transparent onClick={(navigator) => {
                    services.linking.navigate(navigator, 'Domain', { domain: this.props.domain.domain })
                  }}><div className='sm:ml-2 inline-block cursor-pointer text-alert-blue underline'>View Parent Domain</div></components.buttons.Transparent>
                </div>
              </div>
              <div className='mt-4 text-sm'>
                <div className='font-bold'>{'Parent Domain Hash'}</div>
                <div className='truncate flex items-center flex-wrap'>
                  <div className='text-sm flex items-center cursor-pointer w-full' onClick={() => {
                    this.setState({
                      dataExplorer: {
                        data: this.props.domain.hash.toString(),
                        dataType: 'TOKEN_HASH',
                      }
                    })
                    this.dataExplorerModal.toggle()
                  }}>
                    <div className='truncate'>{this.props.domain.hash.toString()}</div>
                    <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                  </div>
                </div>
              </div>
              <div className='mt-4 text-sm'>
                <div className='font-bold'>{'Hash'}</div>
                <div className='truncate flex items-center flex-wrap'>
                  <div className='text-sm flex items-center cursor-pointer w-full' onClick={() => {
                    this.setState({
                      dataExplorer: {
                        data: this.props.domain.subdomain.hash.toString(),
                        dataType: 'HASH',
                      }
                    })
                    this.dataExplorerModal.toggle()
                  }}>
                    <div className='truncate'>{this.props.domain.subdomain.hash.toString()}</div>
                    <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                  </div>
                </div>
              </div>
              {hasLoadedPrivacy ? (
                <div className='mt-4 text-sm'>
                  <div className='font-bold'>{'Privacy'}</div>
                  <div className='truncate flex items-center'>
                    {this.props.isRevealed[this.props.domain.subdomain.hash] ? (
                      <div>Standard Privacy</div>
                    ) : (
                      <div>Enhanced Privacy</div>
                    )}
                    {this.state.connected && isOwned && !isExpired && !this.props.isRevealed[this.props.domain.subdomain.hash] ? (
                      <components.buttons.Transparent onClick={() => {
                        this.props.resetRevealDomain()
                        this.revealDomainModal.toggle()
                      }}><div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>Switch to Standard Privacy</div></components.buttons.Transparent>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className='mt-4 text-sm'>
                <div className='font-bold'>{'Resolver'}</div>
                <div className='truncate flex items-center'>
                  {this.props.resolver ? (
                    <div>{this.props.resolver.resolver === this.state.defaultResolver ? 'Default Resolver' : (
                      <div className='text-sm flex items-center cursor-pointer w-full' onClick={() => {
                        this.setState({
                          dataExplorer: {
                            data: this.props.resolver.resolver.toString(),
                            dataType: this.avvy.RECORDS.EVM,
                          }
                        })
                        this.dataExplorerModal.toggle()
                      }}>
                        <div className='truncate'>Custom Resolver</div>
                        <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                      </div>
                    )}</div>
                  ) : (
                    <div>Not set</div>
                  )}
                  {this.state.connected && isOwned && !isExpired ? (
                    <components.buttons.Transparent onClick={this.setResolver}><div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>Set Resolver</div></components.buttons.Transparent>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className='bg-gray-100 rounded-xl w-full relative p-4 md:p-8 dark:bg-gray-800 w-full'>
              <div className='flex justify-between items-center'>
                <div className='font-bold'>{'Basic Information'}</div>
                {!this.state.connected ? (
                  <components.ConnectWalletButton sm={true} text='Connect' />
                ) : null} 
              </div>
              <div className='w-full bg-gray-300 dark:bg-gray-700 mt-4' style={{height: '1px'}}></div>
              <div className='mt-4 text-sm'>
                <div className='font-bold'>{'Registrant'}</div>
                <div className='truncate flex items-center flex-wrap'>
                  <div className='flex items-center cursor-pointer w-full sm:w-auto' onClick={() => {
                    this.setState({
                      dataExplorer: {
                        title: 'View on Block Explorer',
                        data: this.props.domain.owner,
                        dataType: this.avvy.RECORDS.EVM,
                      }
                    })
                    this.dataExplorerModal.toggle()
                  }}>
                    <components.EVMAddress address={this.props.domain.owner} />
                    <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                  </div>
                  {this.state.connected && isOwned && !isExpired ? (
                    <components.buttons.Transparent onClick={() => {
                      this.props.resetTransferDomain()
                      this.transferDomainModal.toggle()
                    }}><div className='sm:ml-2 inline-block cursor-pointer text-alert-blue underline'>Transfer</div></components.buttons.Transparent>
                  ) : null}
                </div>
              </div>
              {/* Hide domain from non-registrant if silenced */}
              {services.environment.SILENCE_DOMAINS.indexOf(this.props.domain.domain) > -1 && (!this.state.connected || !isOwned) ? null : (
                <div className='mt-4 text-sm flex items-center justify-between'>
                  <div>
                    <div className='font-bold'>{'Expiry'}</div>
                    <div className='flex items-center'>
                      {isExpired && isGracePeriod ? (
                        <div>
                          Expired (Grace Period)
                        </div>
                      ) : isExpired && isRecyclePeriod ? (
                        <div className='flex items-center'>
                          Expired (Recycle Period)
                          <components.buttons.Transparent onClick={(navigator) => {
                            services.linking.navigate(navigator, 'ExpiredSearch', { search: this.state.domain })
                          }}><div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>{'Register at auction'}</div></components.buttons.Transparent>
                        </div>
                      ) : isExpired ? (
                        <div className='flex items-center'>
                          Expired
                          {this.state.connected ? (
                            <components.buttons.Transparent onClick={(navigator) => {
                              this.props.renewDomain(this.props.domain.domain)
                              services.linking.navigate(navigator, 'Register')
                            }}><div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>{'Register'}</div></components.buttons.Transparent>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          {new Intl.DateTimeFormat(
                            navigator.language,
                            { month: 'short', day: 'numeric', year: 'numeric' }
                          ).format(this.props.domain.expiresAt * 1000)}
                          {' at '}
                          {new Intl.DateTimeFormat(
                            navigator.langauge,
                            { hour: 'numeric', minute: 'numeric' }
                          ).format(this.props.domain.expiresAt * 1000)}
                        </div>
                      )}
                      {this.state.connected && isOwned && this.props.domain.canRenew && services.environment.REGISTRATIONS_ENABLED ? (
                        <components.buttons.Transparent onClick={(navigator) => {
                          this.props.renewDomain(this.props.domain.domain)
                          services.linking.navigate(navigator, 'Register')
                        }}><div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>Renew</div></components.buttons.Transparent>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              <div className='mt-4 text-sm'>
                <div className='font-bold'>{'Token ID / Hash'}</div>
                <div className='truncate flex items-center flex-wrap'>
                  <div className='text-sm flex items-center cursor-pointer w-full' onClick={() => {
                    this.setState({
                      dataExplorer: {
                        data: this.props.domain.hash.toString(),
                        dataType: 'TOKEN_HASH',
                      }
                    })
                    this.dataExplorerModal.toggle()
                  }}>
                    <div className='truncate'>{this.props.domain.hash.toString()}</div>
                    <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                  </div>
                </div>
              </div>
              {hasLoadedPrivacy ? (
                <div className='mt-4 text-sm'>
                  <div className='font-bold'>{'Privacy'}</div>
                  <div className='truncate flex items-center'>
                    {this.props.isRevealed[this.props.domain.hash] ? (
                      <div>Standard Privacy</div>
                    ) : (
                      <div>Enhanced Privacy</div>
                    )}
                    {this.state.connected && isOwned && !isExpired && !this.props.isRevealed[this.props.domain.hash] ? (
                      <components.buttons.Transparent onClick={() => {
                        this.props.resetRevealDomain()
                        this.revealDomainModal.toggle()
                      }}><div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>Switch to Standard Privacy</div></components.buttons.Transparent>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className='mt-4 text-sm'>
                <div className='font-bold'>{'Resolver'}</div>
                <div className='truncate flex items-center'>
                  {this.props.resolver ? (
                    <div>{this.props.resolver.resolver === this.state.defaultResolver ? 'Default Resolver' : (
                      <div className='text-sm flex items-center cursor-pointer w-full' onClick={() => {
                        this.setState({
                          dataExplorer: {
                            data: this.props.resolver.resolver.toString(),
                            dataType: this.avvy.RECORDS.EVM,
                          }
                        })
                        this.dataExplorerModal.toggle()
                      }}>
                        <div className='truncate'>Custom Resolver</div>
                        <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                      </div>
                    )}</div>
                  ) : (
                    <div>Not set</div>
                  )}
                  {this.state.connected && isOwned && !isExpired ? (
                    <components.buttons.Transparent onClick={this.setResolver}><div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>Set Resolver</div></components.buttons.Transparent>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          <div className='mt-4 bg-gray-100 rounded-xl w-full relative p-4 md:p-8 dark:bg-gray-800 w-full'>
            <div className='flex justify-between items-center'>
              <div className='font-bold'>{'Records'}</div>
              {!this.state.connected ? (
                <components.ConnectWalletButton sm={true} text='Connect' />
              ) : null}
            </div>
            <div className='w-full bg-gray-300 dark:bg-gray-700 mt-4' style={{height: '1px'}}></div>
            {this.props.isLoadingRecords ? (
              <div className='mt-4 w-full text-center'>
                <components.Spinner />
              </div>
            ) : isExpired ? (
              <div className='mt-4 text-sm'>
                {'Records are disabled for expired domains.'}
              </div>
            ) : this.props.records.length === 0 ? (
              <div className='mt-4 text-sm flex items-center'>
                <div>{'No records have been set.'}</div>
                {this.state.connected && isOwned && !isExpired ? (this.props.resolver ? (
                  <div onClick={() => this.showSetRecord()} className='ml-2 text-alert-blue underline cursor-pointer'>{'Add a record'}</div>
                ) : (
                  <div className='flex items-center'>
                    <div onClick={this.setResolver} className='ml-2 text-alert-blue underline cursor-pointer'>{'Set a resolver'}</div>
                    <div>&nbsp;{'to set records.'}</div>
                  </div>
                )) : null}
              </div>
            ) : (
              <div>
                {this.props.records.map((record, index) => (
                  <div className='mt-4' key={index}>
                    <div className='text-sm font-bold'>
                      {record.label}
                    </div>
                    <div className='text-sm flex items-center cursor-pointer w-full' onClick={() => {
                      this.setState({
                        dataExplorer: {
                          data: record.value,
                          dataType: record.key,
                        }
                      })
                      this.dataExplorerModal.toggle()
                    }}>
                      <div className='truncate'>{record.value}</div>
                      <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                      {this.state.connected && isOwned && !isExpired ? (
                        <div className='flex items-center'>
                          <div onClick={(e) => {
                            e.stopPropagation()
                            this.showSetRecord(record.key, record.value)
                          }} className='ml-2 text-alert-blue underline cursor-pointer'>{'Edit'}</div>
                          <div onClick={(e) => {
                            e.stopPropagation()
                            this.showDeleteRecord(record.key, record.value)
                          }} className='ml-2 text-alert-blue underline cursor-pointer'>{'Delete'}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
                {this.state.connected && this.props.resolver && isOwned && !isExpired ? (
                  <div className='mt-4 flex'>
                    <components.buttons.Button sm={true} onClick={() => this.showSetRecord()} text='Add a record' />
                  </div>
                ) : null}
              </div>
            )}
          </div>
          <div className='mt-4 bg-gray-100 rounded-xl w-full relative p-4 md:p-8 dark:bg-gray-800 w-full'>
            <div className='flex justify-between items-center'>
              <div className='font-bold'>{'Reverse Records'}</div>
              {!this.state.connected ? (
                <components.ConnectWalletButton sm={true} text='Connect' />
              ) : null}
            </div>

            <div className='w-full bg-gray-300 dark:bg-gray-700 mt-4' style={{height: '1px'}}></div>

            {this.props.isLoadingReverseRecords ? (
              <div className='mt-4 w-full text-center'>
                <components.Spinner />
              </div>
            ) : isExpired ? (
              <div className='mt-4 text-sm'>
                {'Reverse Records are disabled for expired domains.'}
              </div>
            ) : (
              <div className='mt-4 text-sm'>
                <div className='font-bold'>{'C-Chain / EVM Address'}</div>
                <div className='truncate flex items-center flex-wrap'>
                  {this.props.reverseRecords[this.avvy.RECORDS.EVM] ? (
                    <div className='flex items-center cursor-pointer w-full sm:w-auto' onClick={() => {
                      this.setState({
                        dataExplorer: {
                          title: 'View on Block Explorer',
                          data: this.props.domain.owner,
                          dataType: this.avvy.RECORDS.EVM,
                        }
                      })
                      this.dataExplorerModal.toggle()
                    }}>
                      {this.props.reverseRecords[this.avvy.RECORDS.EVM]}
                      <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                    </div>
                  ) : (
                    <div>
                      Not set
                    </div>
                  )}
                  {this.state.connected && isOwned && !isExpired ? (
                    <components.buttons.Transparent onClick={() => {
                      this.setEVMReverseRecordModal.toggle()
                    }}>
                      <div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>
                        {this.props.reverseRecords[this.avvy.RECORDS.EVM] ? 'Change' : 'Set Reverse'}
                      </div>
                    </components.buttons.Transparent>
                  ) : null}
                  {this.props.reverseRecords[this.avvy.RECORDS.EVM] && this.state.connected && isOwned ? (
                    <components.buttons.Transparent onClick={() => {
                      this.clearReverseRecord.reset(this.avvy.RECORDS.EVM)
                      this.clearReverseRecordModal.toggle()
                    }}>
                      <div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>
                        {'Delete'}
                      </div>
                    </components.buttons.Transparent>
                  ) : null}
                </div>
                <div className='font-bold mt-4'>{'Validator Node ID'}</div>
                <div className='truncate flex items-center flex-wrap'>
                  {this.props.reverseRecords[this.avvy.RECORDS.VALIDATOR] ? (
                    <div className='flex items-center cursor-pointer w-full sm:w-auto' onClick={() => {
                      this.setState({
                        dataExplorer: {
                          title: 'View on Block Explorer',
                          data: this.props.reverseRecords[this.avvy.RECORDS.VALIDATOR],
                          dataType: this.avvy.RECORDS.VALIDATOR,
                        }
                      })
                      this.dataExplorerModal.toggle()
                    }}>
                      {this.props.reverseRecords[this.avvy.RECORDS.VALIDATOR]}
                      <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                    </div>
                  ) : (
                    <div>
                      Not set
                    </div>
                  )}
                  {this.state.connected && isOwned && !isExpired ? (
                    <components.buttons.Transparent onClick={() => {
                      this.setValidatorNodeIDReverseRecord.reset()
                      this.setValidatorNodeIDReverseRecordModal.toggle()
                    }}>
                      <div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>
                        {this.props.reverseRecords[this.avvy.RECORDS.VALIDATOR] ? 'Change' : 'Set Reverse'}
                      </div>
                    </components.buttons.Transparent>
                  ) : null}
                  {this.props.reverseRecords[this.avvy.RECORDS.VALIDATOR] ? (
                    <components.buttons.Transparent onClick={() => {
                      this.clearReverseRecord.reset(this.avvy.RECORDS.VALIDATOR)
                      this.clearReverseRecordModal.toggle()
                    }}>
                      <div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>
                        {'Delete'}
                      </div>
                    </components.buttons.Transparent>
                  ) : null}
                </div>
              </div>
            )}
          </div>
          {this.props.domain && this.props.domain.subdomain ? null : ( 
            <div className='mt-4 bg-gray-100 rounded-xl w-full relative p-4 md:p-8 dark:bg-gray-800 w-full'>
              <div className='flex justify-between items-center'>
                <div className='font-bold'>{'Subdomains'}</div>
                {!this.state.connected ? (
                  <components.ConnectWalletButton sm={true} text='Connect' />
                ) : null}
              </div>
              <div className='w-full bg-gray-300 dark:bg-gray-700 mt-4' style={{height: '1px'}}></div>
              <div className='mt-4 text-sm flex items-center'>
                {subdomains.length > 0 ? (
                  <div>
                    {subdomains.map(s => (
                      <div className='mb-2'>
                        <components.buttons.Transparent onClick={(navigator) => {
                          services.linking.navigate(navigator, 'Domain', { domain: s })
                        }}><div className='inline-block cursor-pointer text-alert-blue underline'>{s}</div></components.buttons.Transparent>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>{'No subdomains have been found.'}</div>
                )}
              </div>
              {this.state.connected && isOwned && !isExpired ? (
                <div className='mt-4 flex'>
                  <components.buttons.Button sm={true} onClick={() => this.configureSubdomainModal.toggle()} text='Configure a subdomain' />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderLoader() {
    return (
      <div className='m-auto max-w-sm text-center'>
        <components.Spinner className='w-6' size='md' dark={true} />
      </div>
    )
  }

  renderBody() {
    if (this.props.isLoading) return this.renderLoader()
    if (!this.props.domain) return null
    if (!this.props.domain.supported) return this.renderUnsupported()

    const statuses = this.props.domain.constants.DOMAIN_STATUSES

    switch (this.props.domain.status) {
      case statuses.AVAILABLE:
        return this.renderAvailableBody()

      case statuses.AUCTION_AVAILABLE:
        return this.renderAuctionAvailableBody()

      case statuses.AUCTION_BIDDING_CLOSED:
        return this.renderAuctionBiddingClosedBody()

      case statuses.REGISTERED_OTHER:
        return this.renderRegistered()

      case statuses.REGISTERED_SELF:
        return this.renderRegistered()
      
      default:
        return null
    }
  }

  getEVMRecord = () => {
    if (this.props.isLoadingRecords) return false
    return this.props.records.reduce((sum, curr) => {
      if (curr.key === this.avvy?.RECORDS?.EVM) return curr.value
      return sum
    }, null)
  }

  render() {
    let account = services.provider.getAccount()
    const isOwned = account && this.props.domain?.owner ? account.toLowerCase() === this.props.domain.owner.toLowerCase() : false
    const hasOwner = !!this.props.domain?.owner
    const evmRecord = this.getEVMRecord()
    const isSubdomain = this.props.domain && this.props.domain.subdomain
    const showBackButton = !this.props.isLoading && (isOwned || (isSubdomain && hasOwner))
    return (
      <div>
        <components.Modal ref={(ref) => this.configureSubdomainModal = ref} title={'Configure subdomain'}> 
          <div className='flex flex-col items-center justify-center'>
            <div className='mb-4'>{'Which subdomain do you want to configure?'}</div>
            <div className='max-w-sm flex items-center mb-4'>
              <components.Input placeholder='subdomain' onChange={(e) => this.setState({ configureSubdomain: `${e.target.value}.${this.state.domain}` })} />
              <div className='ml-1'>.{this.state.domain}</div>
            </div>
            <components.buttons.Button sm className='w-full max-w-sm' text="Continue" 
              onClick={(navigator) => {
                this.configureSubdomainModal.hide()
                setTimeout(() => { 
                  services.linking.navigate(navigator, 'Domain', { domain: this.state.configureSubdomain })
                }, 100)
              }}>
              Continue
            </components.buttons.Button>
          </div>
        </components.Modal>
        <div className='flex justify-between max-w-screen-md m-auto items-center mt-2 md:mt-8 mb-4'>
          <Link to={isSubdomain ? services.linking.path('Domain', { domain: this.props.domain.domain }) : services.linking.path('MyDomains')} className={showBackButton ? '' : 'pointer-events-none'}>
            <ArrowLeftIcon className={`w-6 ${showBackButton ? 'cursor-pointer' : 'invisible'}`} />
          </Link>
          <div className='text-lg text-center font-bold'>{decodeURIComponent(this.state.domain)}</div>
          <div>
            <PaperAirplaneIcon className={`w-6 ${hasOwner && evmRecord && !this.props.isLoading ? 'cursor-pointer' : 'invisible'}`} style={{ transform: 'rotate(45deg)' }} onClick={() => {
              if (evmRecord) {
                this.setState({
                  transferEVMAddress: evmRecord
                }, () => {
                  this.sendTransferModal.toggle()
                })
              }
            }}/>
          </div>
        </div>
        {this.renderBody()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: selectors.isLoading(state),
  domain: selectors.domain(state),
  bids: services.sunrise.selectors.bids(state),
  isSettingRecord: selectors.isSettingRecord(state),
  isLoadingRecords: selectors.isLoadingRecords(state),
  records: selectors.records(state),
  setRecordComplete: selectors.setRecordComplete(state),
  avatarRecord: selectors.avatarRecord(state),

  isLoadingReverseRecords: selectors.isLoadingReverseRecords(state),
  reverseRecords: selectors.reverseRecords(state),

  resolver: selectors.resolver(state),
  hasSeenBidDisclaimer: services.sunrise.selectors.hasSeenBidDisclaimer(state),
  registrationPremium: selectors.registrationPremium(state),
  isRevealed: services.names.selectors.isRevealed(state),
  reverseLookups: services.names.selectors.reverseLookups(state),
  isRevealingDomain: selectors.isRevealingDomain(state),
  isRevealComplete: selectors.isRevealComplete(state),

  // marketplace listings
  listings: selectors.listings(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadRegistrationPremium: () => dispatch(actions.loadRegistrationPremium()),
  loadDomain: (domain) => dispatch(actions.loadDomain(domain)),
  loadReverseRecords: (domain) => dispatch(actions.loadReverseRecords(domain)),
  addToCart: (domain) => dispatch(services.cart.actions.addToCart(domain)),
  addBid: (domain, amount) => dispatch(services.sunrise.actions.addBid(domain, amount)),
  discoverSubdomains: (domain) => dispatch(services.names.actions.discoverSubdomains(domain)),
  setStandardRecord: (domain, type, value) => dispatch(actions.setStandardRecord(domain, type, value)),
  resetSetRecord: () => dispatch(actions.setRecordComplete(false)),
  renewDomain: (domain) => dispatch(services.cart.actions.addToCart(domain)),
  resetSetResolver: () => dispatch(actions.setResolverComplete(false)),
  setHasSeenBidDisclaimer: (value) => dispatch(services.sunrise.actions.setHasSeenBidDisclaimer(value)),
  revealDomain: (domain) => dispatch(actions.revealDomain(domain)),
  resetRevealDomain: () => dispatch(actions.resetRevealDomain()),
  resetTransferDomain: () => dispatch(actions.resetTransferDomain()),
})

const component = connect(mapStateToProps, mapDispatchToProps)(Domain)

component.redux = {
  actions,
  constants,
  reducer,
  selectors,
}

export default component
