import React from 'react'
import components from 'components'
import services from 'services'

import { ethers } from 'ethers'


class Onramp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      loading: false,
      complete: false,
    }
  }

  async init() {
    this.setState({ loading: true, complete: false }, async () => {

      const amount = ethers.utils.formatEther(this.props.getTotal().avax)
      const api = await services.provider.buildAPI()
      const account = services.provider.getAccount()
      const onrampData = await api.getStripeOnrampToken(account, amount)

      this.setState({ link: onrampData.redirectUrl, loading: false })
      return 

      /*
	    const clientSecret = onrampData.secret
      const pk = services.environment.STRIPE_PUBLISHABLE_KEY
      const stripeOnramp = StripeOnramp(pk)
      const appearance = {
        theme: this.props.isDarkmode ? 'dark' : undefined
      }
      const session = stripeOnramp.createSession({ clientSecret, appearance })
      session.addEventListener('onramp_session_updated', (e) => {
        switch (e.payload?.session?.status) {
          case "initialized":
            this.setState({
              loading: false
            })
            break

          case "fulfillment_complete":
            this.setState({
              complete: true
            })
            break
        }
      })
      this.stripeContainer.innerHTML = ''
      session.mount(this.stripeContainer)
      */
    })
  }

  render() {
    return (
      <div className='text-center'>
        {this.state.loading ? (
          <components.Spinner.Connected />
        ) : null}
        <div className={`flex items-center justify-center ${this.state.loading ? 'hidden' : ''}`} ref={(ref) => this.stripeContainer = ref} />
        {this.state.link ? (
          <div className='text-center mt-4 max-w-md m-auto'>
            <div className='mb-4'>{'Proceed to Stripe\'s onramp services to easily acquire AVAX. When you are finished, return to the Avvy application to continue your registration.'}</div>
            <components.buttons.Button text={'Continue to Onramp'} onClick={() => window.open(this.state.link, '_blank')} />
          </div>
        ) : this.state.complete ? (
          <div className='text-center mt-4 max-w-md m-auto'>
            <div className='mb-4'>{'Reload the page to refresh your wallet balance and continue your registration'}</div>
            <components.buttons.Button text={'Continue Registration'} onClick={this.props.onComplete} />
          </div>
        ) : null}
      </div>
    )
  }
}

export default Onramp

