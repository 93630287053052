"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationHistoryEntryFragmentDependencies = exports.notificationHistoryEntryFragment = exports.NotificationTypeName = void 0;
var NotificationTypeName;
(function (NotificationTypeName) {
    NotificationTypeName["ACCOUNT_BALANCE_CHANGED"] = "AccountBalanceChangedEventDetails";
    NotificationTypeName["BROADCAST_MESSAGE"] = "BroadcastMessageEventDetails";
    NotificationTypeName["DIRECT_TENANT_MESSAGE"] = "DirectTenantMessageEventDetails";
    NotificationTypeName["NFT_COLLECTION_REPORT"] = "NftCollectionsReportEventDetails";
    NotificationTypeName["CHAT_MESSAGE_RECEIVED"] = "ChatMessageReceivedEventDetails";
    NotificationTypeName["DAO_PROPOSAL_CHANGED"] = "DaoProposalChangedEventDetails";
    NotificationTypeName["NFT_AUCTION_CHANGED"] = "NftAuctionChangedEventDetails";
    NotificationTypeName["WALLETS_ACTIVITY_CHANGED"] = "WalletsActivityChangedEventDetails";
    NotificationTypeName["HEALTH_VALUE_OVER_THRESHOLD"] = "HealthValueOverThresholdEventDetails";
    NotificationTypeName["GENERIC_EVENT"] = "GenericEventDetails";
})(NotificationTypeName = exports.NotificationTypeName || (exports.NotificationTypeName = {}));
exports.notificationHistoryEntryFragment = `
fragment NotificationHistoryEntry on NotificationHistoryEntry {
  __typename
  id
  createdDate
  eventId
  read
  sourceAddress
  category
  transactionSignature
  targets {
    type
    name
  }
  detail {
    __typename
    ... on AccountBalanceChangedEventDetails {
      walletBlockchain
      direction
      newValue
      previousValue
      tokenSymbol
      isWhaleWatch
    }
    ... on BroadcastMessageEventDetails {
      messageType: type
      subject
      message
      messageHtml
    }
    ... on DirectTenantMessageEventDetails {
      tenantName
    }
    ... on NftCollectionsReportEventDetails {
      type
      providerName
      sourceLink
      collections {
        collectionId
        name
        imgUrl
        volume1Day
        volume1DayChange
      }
    }
    ... on ChatMessageReceivedEventDetails {
      senderName
      conversationId
      messageId
      senderId
      senderBlockchain
      senderName
      messageBody
    }
    ... on DAOProposalChangedEventDetails {
      tenantName
      proposalTitle: title
      description
      state
      daoUrl
      proposalUrl
    }
    ... on NftAuctionChangedEventDetails {
      auctionTitle: title
      auctionUrl
      walletBlockchain
      highBidAmount
      highBidSymbol
      imageUrl
    }
    ... on WalletsActivityReportEventDetails {
      providerName
      sourceLink
      walletActivityType: type
      wallets {
        address
        volume1Day
        maxPurchase1Day
        maxPurchaseName
        maxPurchaseImgUrl
        maxPurchaseTokenAddress
      }
    }
    ... on HealthValueOverThresholdEventDetails {
      name
      value
      threshold
      url
    }
    ... on GenericEventDetails {
      sourceName
      notificationTypeName
      genericMessage: message
      genericMessageHtml: messageHtml
      eventDetailsJson
      action {
        name
        url
      }
      icon
    }
  }
}`.trim();
exports.notificationHistoryEntryFragmentDependencies = [];
