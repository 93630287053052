"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authorizationFragmentDependencies = exports.authorizationFragment = void 0;
exports.authorizationFragment = `
fragment authorizationFragment on Authorization {
  token
  expiry
}
`.trim();
exports.authorizationFragmentDependencies = [];
