import React, { useState, useEffect, useRef } from 'react'
import { ethers } from 'ethers'
import { CheckIcon } from '@heroicons/react/solid'

import components from 'components'
import services from 'services'


function Expired(props) {
  const searchParams = new URLSearchParams(window.location.hash.substr(1))
  const searchRef = useRef(null)
  const [ domains, setDomains ] = useState([])
  const [ gracePeriodLength, setGracePeriodLength ] = useState(0)
  const [ recyclePeriodLength, setRecyclePeriodLength ] = useState(0)
  const [ recyclingPremiumPricePoints, setRecyclingPremiumPricePoints ] = useState([])
  const [ avaxPrice, setAvaxPrice ] = useState(1)
  const [ currency, setCurrency ] = useState('usd') // or avax
  const [ locale, setLocale ] = useState('en-CA')
  const [ sort, setSort ] = useState('expiry_asc')
  const [ search, setSearch ] = useState(searchParams.get('search') || '')

  // domain querying parameters
  const [ isLoading, setIsLoading ] = useState(true)
  const [ isLoadingDomains, setIsLoadingDomains ] = useState(false)
  const [ canLoadMore, setCanLoadMore ] = useState(false)
  const [ domainLoadingOffset, setDomainLoadingOffset ] = useState(0)

  // domain buying parameters
  const [ balance, setBalance ] = useState(0)
  const [ isBuyingIndex, setIsBuyingIndex ] = useState(true)
  const [ isBoughtIndexes, setIsBoughtIndexes ] = useState({})
  const [ isConnected, setIsConnected ] = useState(services.provider.isConnected())

  const updateBalance = async () => {
    const api = await services.provider.buildAPI()
    const balance = await api.getBalance()
    setBalance(balance)
  }

  services.provider.addEventListener(services.provider.EVENTS.CONNECTED, () => {
    setIsConnected(true)
    updateBalance()
  })

  services.provider.addEventListener(services.provider.EVENTS.DISCONNECTED, () => {
    setIsConnected(false)
  })

  const loadDomains = async (restartOffset, params) => {
    setIsLoadingDomains(true)
    let _gracePeriodLength = params?.gracePeriodLength || gracePeriodLength
    let _recyclePeriodLength = params?.recyclePeriodLength || recyclePeriodLength
    let offset = restartOffset ? 0 : domainLoadingOffset
    const api = services.provider.buildAPI()
    const greaterThanMS = Date.now() - _gracePeriodLength - _recyclePeriodLength
    const lessThanMS = Date.now() - _gracePeriodLength 
    let order
    if (sort === 'alphabetical_asc') {
      order = 'name'
    } else if (sort === 'alphabetical_desc') {
      order = '-name'
    } else if (sort === 'expiry_asc') {
      order = 'expiry'
    } else if (sort === 'expiry_desc') {
      order = '-expiry'
    }
    const options = {
      search,
      order,
      offset
    }
    const _domains = await api.getDomainsByExpiry(
      greaterThanMS,
      lessThanMS,
      options
    )

    // because we're pulling data from the indexer, which lags behind the chain, 
    // we need to check the chain for real expiries & remove domains that have been
    // registered at auction.
    const expiries = await api.getDomainExpiries(_domains.domains.map((d) => d.name))
    for (let i = 0; i < expiries.length; i += 1) {
      let expiry = parseInt(expiries[i]) * 1000
      if (expiry < greaterThanMS || expiry > lessThanMS) {
        _domains.domains.splice(i, 1)
      }
    }

    // hide silenced domains
    for (let i = 0; i < _domains.domains.length; i += 1) {
      if (services.environment.SILENCE_DOMAINS.indexOf(_domains.domains[i].name) > -1) {
        _domains.domains.splice(i, 1)
      }
    }

    const domainCount = _domains.domains.length
    setCanLoadMore(domainCount === 200)
    setDomainLoadingOffset(offset + domainCount)
    setDomains(restartOffset ? _domains.domains : domains.concat(_domains.domains))
    setIsLoadingDomains(false)
    return _domains.domains
  }

  useEffect(() => {
    let loading = false
    const run = async () => {
      if (loading) return
      setIsLoading(true)
      searchRef.current.value = search
      const api = services.provider.buildAPI()
      const promises = [
        api.getAVAXConversionRate(),
        api.getGracePeriodLengthMS(),
        api.getRecyclePeriodLengthMS(),
        api.getRecycleAuctionPricePoints(),
      ]
      const isConnected = services.provider.isConnected()
      if (isConnected) promises.push(api.getBalance())
      const outputs = await Promise.all(promises)
      const [
        conversionRate, 
        gracePeriodLength,
        recyclePeriodLength,
        pricePoints,
      ] = outputs
      if (isConnected) {
        setBalance(outputs[4])
      }
      const usdPerAvax = ethers.BigNumber.from('1000000000000000000').div(conversionRate)
      setGracePeriodLength(gracePeriodLength)
      setRecyclePeriodLength(recyclePeriodLength)
      setRecyclingPremiumPricePoints(pricePoints)
      setAvaxPrice(parseFloat(usdPerAvax.toString()) / 100)
      await loadDomains(true, {
        gracePeriodLength,
        recyclePeriodLength,
      })
      setIsLoading(false)
    }
    run()

    return () => {
      loading = true
    }
  }, [ sort, search ])

  let searchChangeTimeout
  const handleSearchChange = (val) => {
    if (searchChangeTimeout) clearTimeout(searchChangeTimeout)
    searchChangeTimeout = setTimeout(() => {
      setSearch(val)
      searchParams.set('search', val)
      window.history.pushState({}, '', '#' + searchParams.toString())
    }, 300)
  }

  const auctionStartDate = (domain) => {
    return new Date((new Date(domain.expiry).getTime()) + gracePeriodLength).toISOString()
  }

  const auctionEndDate = (domain) => {
    return new Date((new Date(domain.expiry).getTime()) + gracePeriodLength + recyclePeriodLength).toISOString()
  }

  const formatDate = (date) => {
    return new Intl.DateTimeFormat(locale).format(Date.parse(date))
  }

  const getAVAXValue = (priceUsd) => {
    return priceUsd / avaxPrice
  }

  const formatPrice = (priceUsd, _currency) => {
    if (!_currency) _currency = currency
    if (_currency === 'usd') {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'USD'
      }).format(priceUsd)
    } else if (_currency === 'avax') {
      let priceAvax = priceUsd / avaxPrice
      return new Intl.NumberFormat(locale).format(priceAvax) + ' AVAX'
    }
  }

  const registrationFee = (domain) => {
    const sld = domain.name.split('.')[0]
    if (sld.length === 3) return 640
    if (sld.length === 4) return 160
    return 5
  }

  const auctionPremiumFee = (domain) => {
    const getAuctionCurvePrice = () => {
      const t = parseInt(Date.now() / 1000)
      const startDate = parseInt(Date.parse(auctionStartDate(domain)) / 1000)
      const endDate = parseInt(Date.parse(auctionEndDate(domain)) / 1000)
      const pricePoints = recyclingPremiumPricePoints.map(p => ethers.BigNumber.from(p))
      if (t < startDate) return pricePoints[0]
      if (t > endDate) return 0
      const diff = endDate - startDate
      const sliceSize = diff / pricePoints.length
      let endTime = startDate
      let base
      let x
      let curvePrice
      for (let i = 0; i < pricePoints.length; i += 1) {
        endTime += sliceSize
        if (t < endTime) {
          if (i === pricePoints.length - 1) {
            base = ethers.BigNumber.from('0')
          } else {
            base = ethers.BigNumber.from(pricePoints[i + 1])
          }
          x = ethers.BigNumber.from((endTime - t).toString())
          curvePrice = base.add(pricePoints[i].sub(base).mul(x).div(ethers.BigNumber.from(sliceSize)))
          break
        }
      }
      return curvePrice
    }
    return parseFloat(ethers.utils.formatEther(getAuctionCurvePrice()).toString()) * avaxPrice
  }

  const canBuy = (domain) => {
    const buyNow = ethers.utils.parseEther(getAVAXValue(buyNowFee(domain), 'avax').toString())
    return buyNow.lt(ethers.BigNumber.from(balance.toString()))
  }

  const buyNowFee = (domain) => {
    return parseFloat(registrationFee(domain)) + parseFloat(auctionPremiumFee(domain))
  }

  const purchaseDomain = async (index) => {
    setIsBuyingIndex(index)
    const api = await services.provider.buildAPI()
    const domain = domains[index]
    const refCode = services.referrals.getCode()
    try {
      await api.purchaseRecycleDomain(
        domain.name,
        domain.hash,
        parseInt(Date.parse(domain.expiry) / 1000),
        gracePeriodLength / 1000,
        recyclePeriodLength / 1000,
        refCode,
      )
      setIsBoughtIndexes(Object.assign({}, isBoughtIndexes, { [index]: true }))
    } catch (err) {
      alert('Failed to purchase domain')
    }
    setIsBuyingIndex(null)
    updateBalance()
  }

  return (
    <div className='m-auto w-full px-2'>
      <div className='px-2'>
        <div className='mb-4 flex items-center justify-between'>

          {/* Heading */}
          <div className='max-w-md'>
            <div className='text-2xl'>
            {"Expired Domain Auction"}
            </div>
            <div>{'Domains listed here have expired and are available for immediate registration, with an Auction Premium'}</div>
          </div>

          <div className='flex flex-col justify-end items-end text-sm'>

            {/* Pricing */}
            <div className='text-sm mb-2'>
              <span className='mr-2'>
                {'Show prices in: '}
              </span>
              <select className='dark:bg-gray-700 rounded px-2 py-1' value={currency} onChange={(e) => setCurrency(e.target.value)}>
                <option value="usd">USD</option>
                <option value="avax">AVAX</option>
              </select>
            </div>

            {/* Sort */}
            <div className='text-sm'>
              <span className='mr-2'>
                Sort: 
              </span>
              <select className='dark:bg-gray-700 rounded px-2 py-1' value={sort} onChange={(e) => setSort(e.target.value)}>
                <option value="expiry_asc">Auction Ending Soon</option>
                <option value="expiry_desc">Auction Just Started</option>
                <option value="alphabetical_asc">Alphabetical A-Z</option>
                <option value="alphabetical_desc">Alphabetical Z-A</option>
              </select>
            </div>

            {/* Filter */}
            <div className='flex flex-row text-sm mt-2'>
              <div className={`cursor-pointer`}>
              {'Search'}
                <input ref={searchRef} className='bg-gray-100 dark:bg-gray-700 dark:border-none w-40 ml-2 px-2 py-1 rounded' type="text" onChange={(e) => handleSearchChange(e.target.value)} />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className='bg-gray-100 dark:bg-gray-800 p-4 rounded mx-2'>
        {isLoading ? (
          <div className='flex items-center justify-center'>
            <components.Spinner.Connected />
          </div>
        ) : domains.length === 0 ? (
          <div className='text-center'>
            {'No results were found matching your search'}
          </div>
        ) : (
          <>
            <table className='w-full text-sm border-spacing-2 border-separate'>
              <thead>
                <tr className='font-bold'>
                  <td className='p-2'>Domain</td>
                  <td className='p-2'>Auction Start Date</td>
                  <td className='p-2'>Auction End Date</td>
                  <td className='p-2'>Yearly Registration Fee</td>
                  <td className='p-2'>Current Auction Premium</td>
                  <td className='p-2'>Total Buy Now</td>
                  <td className='p-2'>Register (1 year)</td>
                </tr>
              </thead>
              <tbody>
              {domains.filter(domain => !!domain.name).map((domain, index) => {
                return (
                  <tr key={index}>
                    <td className='p-2'>{domain.name}</td>
                    <td className='p-2'>{formatDate(auctionStartDate(domain))}</td>
                    <td className='p-2'>{formatDate(auctionEndDate(domain))}</td>
                    <td className='p-2'>{currency === 'usd' ? formatPrice(registrationFee(domain)) : `
                      ${formatPrice(registrationFee(domain), 'usd')} (${formatPrice(registrationFee(domain))})
                    `}</td>
                    <td className='p-2'>{formatPrice(auctionPremiumFee(domain))}</td>
                    <td className='p-2'>{formatPrice(buyNowFee(domain))}</td>
                    <td className='p-2'>
                      {isBoughtIndexes[index] ? (
                        <div className='w-6'><CheckIcon /></div>
                      ) : isBuyingIndex === index ? (
                        <components.Spinner.Connected />
                      ) : (
                        <a className='underline cursor-pointer' onClick={() => {
                          if (!services.provider.isConnected()) {
                            return alert('Connect your wallet to register a domain')
                          } else if (canBuy(domain)) {
                            purchaseDomain(index)
                          } else {
                            alert('You do not have enough AVAX in your wallet to register this domain.')
                          }
                        }}>
                        Register
                        </a>
                      )}
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table>
            {canLoadMore ? (
              <div className='max-w-sm m-auto mt-8'>
                <components.buttons.Button sm={true} text='Load More' loading={isLoadingDomains} onClick={() => loadDomains()} />
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}

export default Expired
