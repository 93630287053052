const blocklist = [
  'test',
  'localhost',
  'invalid',
  'example',
  'www',
  'whois',
  'rdds',
  'nic',
  'afghanistan',
  'albania',
  'algeria',
  'american-samoa',
  'americansamoa',
  'andorra',
  'angola',
  'anguilla',
  'antarctica',
  'antigua-and-barbuda',
  'antiguaandbarbuda',
  'argentina',
  'armenia',
  'aruba',
  'ascension-and-tristan-da-cunha-saint-helena',
  'ascensionandtristandacunhasainthelena',
  'australia',
  'austria',
  'azerbaijan',
  'bahamas',
  'bahrain',
  'bangladesh',
  'barbados',
  'belarus',
  'belgium',
  'belize',
  'benin',
  'bermuda',
  'bhutan',
  'bolivarian-republic-of-venezuela',
  'bolivarianrepublicofvenezuela',
  'bolivia',
  'bonaire',
  'bonaire-sint-eustatius-and-saba',
  'bonairesinteustatiusandsaba',
  'bosnia-and-herzegovina',
  'bosniaandherzegovina',
  'botswana',
  'bouvet-island',
  'bouvetisland',
  'brazil',
  'british-indian-ocean-territory',
  'british-virgin-islands',
  'britishindianoceanterritory',
  'britishvirginislands',
  'brunei-darussalam',
  'bruneidarussalam',
  'bulgaria',
  'burkina-faso',
  'burkinafaso',
  'burundi',
  'cabo-verde',
  'caboverde',
  'cambodia',
  'cameroon',
  'canada',
  'cape-verde',
  'capeverde',
  'cayman-islands',
  'caymanislands',
  'central-african-republic',
  'centralafricanrepublic',
  'chad',
  'chile',
  'china',
  'christmas-island',
  'christmasisland',
  'cocos-islands',
  'cocos-keeling-islands',
  'cocosislands',
  'cocoskeelingislands',
  'colombia',
  'comoros',
  'congo',
  'cook-islands',
  'cookislands',
  'costa-rica',
  'costarica',
  'croatia',
  'cuba',
  'xn--curaao-zua',
  'cyprus',
  'czechia',
  'czech-republic',
  'czechrepublic',
  'xn--cte-d-ivoire-eib',
  'xn--cte-divoire-2eb',
  'xn--ctedivoire-rbb',
  'democratic-people-s-republic-of-korea',
  'democratic-peoples-republic-of-korea',
  'democratic-republic-of-the-congo',
  'democraticpeoplesrepublicofkorea',
  'democraticrepublicofthecongo',
  'denmark',
  'djibouti',
  'dominica',
  'dominican-republic',
  'dominicanrepublic',
  'ecuador',
  'egypt',
  'eire',
  'el-salvador',
  'elsalvador',
  'equatorial-guinea',
  'equatorialguinea',
  'eritrea',
  'estonia',
  'ethiopia',
  'european-union',
  'europeanunion',
  'falkland-islands',
  'falkland-islands-malvinas',
  'falklandislands',
  'falklandislandsmalvinas',
  'faroe-islands',
  'faroeislands',
  'federated-states-of-micronesia',
  'federatedstatesofmicronesia',
  'fiji',
  'finland',
  'former-yugoslav-republic-of-macedonia',
  'formeryugoslavrepublicofmacedonia',
  'france',
  'french-guiana',
  'french-polynesia',
  'french-southern-territories',
  'frenchguiana',
  'frenchpolynesia',
  'frenchsouthernterritories',
  'gabon',
  'gambia',
  'georgia',
  'germany',
  'ghana',
  'gibraltar',
  'greece',
  'greenland',
  'grenada',
  'guadeloupe',
  'guam',
  'guatemala',
  'guernsey',
  'guinea',
  'guinea-bissau',
  'guineabissau',
  'guyana',
  'haiti',
  'heard-island-and-mcdonald-islands',
  'heardislandandmcdonaldislands',
  'holy-see',
  'holy-see-vatican-city-state',
  'holysee',
  'holyseevaticancitystate',
  'honduras',
  'hong-kong',
  'hongkong',
  'hungary',
  'iceland',
  'india',
  'indonesia',
  'iran',
  'iraq',
  'ireland',
  'islamic-republic-of-iran',
  'islamicrepublicofiran',
  'isle-of-man',
  'isleofman',
  'israel',
  'italy',
  'jamaica',
  'japan',
  'jersey',
  'jordan',
  'kazakhstan',
  'keeling-islands',
  'keelingislands',
  'kenya',
  'kiribati',
  'korea',
  'kuwait',
  'kyrgyzstan',
  'lao-people-s-democratic-republic',
  'lao-peoples-democratic-republic',
  'laopeoplesdemocraticrepublic',
  'laos',
  'latvia',
  'lebanon',
  'lesotho',
  'liberia',
  'libya',
  'liechtenstein',
  'lithuania',
  'luxembourg',
  'macao',
  'macedonia',
  'madagascar',
  'malawi',
  'malaysia',
  'maldives',
  'mali',
  'malta',
  'malvinas',
  'marshall-islands',
  'marshallislands',
  'martinique',
  'mauritania',
  'mauritius',
  'mayotte',
  'mexico',
  'xn--mxico-bsa',
  'micronesia',
  'moldova',
  'monaco',
  'mongolia',
  'montenegro',
  'montserrat',
  'morocco',
  'mozambique',
  'myanmar',
  'namibia',
  'nauru',
  'nepal',
  'netherlands',
  'new-caledonia',
  'new-zealand',
  'newcaledonia',
  'newzealand',
  'nicaragua',
  'niger',
  'nigeria',
  'niue',
  'norfolk-island',
  'norfolkisland',
  'northern-mariana-islands',
  'northernmarianaislands',
  'norway',
  'oman',
  'pakistan',
  'palau',
  'palestine',
  'panama',
  'papua-new-guinea',
  'papuanewguinea',
  'paraguay',
  'peru',
  'philippines',
  'pitcairn',
  'plurinational-state-of-bolivia',
  'plurinationalstateofbolivia',
  'poland',
  'portugal',
  'province-of-china-taiwan',
  'provinceofchinataiwan',
  'puerto-rico',
  'puertorico',
  'qatar',
  'republic-of-korea',
  'republic-of-moldova',
  'republicofkorea',
  'republicofmoldova',
  'romania',
  'russian-federation',
  'russianfederation',
  'russia',
  'rwanda',
  'xn--runion-bva',
  'xn--saint-barthlemy-lnb',
  'saint-helena',
  'saint-helena-ascension-and-tristan-da-cunha',
  'saint-kitts-and-nevis',
  'saint-lucia',
  'saint-martin',
  'saint-pierre-and-miquelon',
  'saint-vincent-and-the-grenadines',
  'xn--saintbarthlemy-kkb',
  'sainthelena',
  'sainthelenaascensionandtristandacunha',
  'saintkittsandnevis',
  'saintlucia',
  'saintmartin',
  'saintpierreandmiquelon',
  'saintvincentandthegrenadines',
  'samoa',
  'san-marino',
  'sanmarino',
  'sao-tome-and-principe',
  'saotomeandprincipe',
  'sark',
  'saudi-arabia',
  'saudiarabia',
  'senegal',
  'serbia',
  'seychelles',
  'sierra-leone',
  'sierraleone',
  'singapore',
  'sint-eustatius-and-saba-bonaire',
  'sint-maarten',
  'sinteustatiusandsababonaire',
  'sintmaarten',
  'slovakia',
  'slovenia',
  'solomon-islands',
  'solomonislands',
  'somalia',
  'south-africa',
  'south-georgia-and-the-south-sandwich-islands',
  'south-sudan',
  'southafrica',
  'southgeorgiaandthesouthsandwichislands',
  'southsudan',
  'spain',
  'sri-lanka',
  'srilanka',
  'state-of-palestine',
  'stateofpalestine',
  'sudan',
  'suriname',
  'svalbard-and-jan-mayen',
  'svalbardandjanmayen',
  'swaziland',
  'sweden',
  'switzerland',
  'syrian-arab-republic',
  'syrianarabrepublic',
  'taiwan',
  'taiwan-province-of-china',
  'taiwanprovinceofchina',
  'tajikistan',
  'tanzania',
  'thailand',
  'the-democratic-republic-of-the-congo',
  'the-former-yugoslav-republic-of-macedonia',
  'thedemocraticrepublicofthecongo',
  'theformeryugoslavrepublicofmacedonia',
  'timor-leste',
  'timorleste',
  'togo',
  'tokelau',
  'tonga',
  'trinidad-and-tobago',
  'trinidadandtobago',
  'tunisia',
  'turkey',
  'turkmenistan',
  'turks-and-caicos-islands',
  'turksandcaicosislands',
  'tuvalu',
  'u-s-virgin-islands',
  'uganda',
  'ukraine',
  'united-arab-emirates',
  'united-kingdom',
  'united-kingdom-of-great-britain-and-northern-ireland',
  'unitedkingdomofgreatbritainandnorthernireland',
  'united-republic-of-tanzania',
  'united-states',
  'united-states-of-america',
  'unitedstatesofamerica',
  'united-states-minor-outlying-islands',
  'unitedarabemirates',
  'unitedkingdom',
  'unitedrepublicoftanzania',
  'unitedstates',
  'unitedstatesminoroutlyingislands',
  'uruguay',
  'us-virgin-islands',
  'usvirginislands',
  'uzbekistan',
  'vanuatu',
  'vatican',
  'vatican-city-state',
  'vaticancitystate',
  'venezuela',
  'viet-nam',
  'vietnam',
  'virgin-islands',
  'virginislands',
  'wallis-and-futuna',
  'wallisandfutuna',
  'western-sahara',
  'westernsahara',
  'yemen',
  'yugoslav',
  'zambia',
  'zimbabwe',
  'xn--land-islands-scb',
  'xn--landislands-w8a',
  'xn--afganistn-71a',
  'afrique-du-sud',
  'afriquedusud',
  'albanie',
  'alemania',
  'xn--algrie-dva',
  'allemagne',
  'andorre',
  'antigua-et-barbuda',
  'antiguaetbarbuda',
  'antigua-y-barbuda',
  'antiguaybarbuda',
  'arabia-saudita',
  'arabiasaudita',
  'arabie-saoudite',
  'arabiesaoudite',
  'argelia',
  'argentine',
  'xn--armnie-dva',
  'australie',
  'autriche',
  'xn--azerbadjan-c9a',
  'xn--azerbaiyn-71a',
  'bahrein',
  'xn--bahren-mwa',
  'barbade',
  'xn--belars-tya',
  'belgique',
  'belice',
  'bhoutan',
  'xn--bhutn-0qa',
  'xn--blarus-bva',
  'xn--blgica-bva',
  'xn--bnin-bpa',
  'bolivie',
  'bosnia-y-herzegovina',
  'bosniayherzegovina',
  'xn--bosnie-herzgovine-ltb',
  'xn--bosnieherzgovine-kqb',
  'brasil',
  'xn--brsil-csa',
  'xn--bruni-darussalam-eqb',
  'xn--brunidarussalam-enb',
  'bulgarie',
  'burkina',
  'cambodge',
  'camboya',
  'xn--camern-tya',
  'camerooun',
  'xn--canad-1qa',
  'cap-vert',
  'capvert',
  'chili',
  'chine',
  'chipre',
  'chypre',
  'colombie',
  'comoras',
  'comores',
  'croacia',
  'croatie',
  'danemark',
  'dinamarca',
  'dominicana',
  'dominique',
  'egipto',
  'xn--el-reino-unido-de-gran-bretaa-e-irlanda-del-norte-iwe',
  'xn--elreinounidodegranbretaaeirlandadelnorte-b2d',
  'xn--emiratos-rabes-unidos-i0b',
  'xn--emiratosrabesunidos-xub',
  'eslovaquia',
  'eslovenia',
  'xn--espaa-rta',
  'espagne',
  'xn--estados-unidos-de-amrica-ufc',
  'xn--estadosunidosdeamrica-r5b',
  'estonie',
  'xn--etiopa-7va',
  'xn--ex-rpublique-yougoslave-de-macdoine-edd0a',
  'xn--ex-rpubliqueyougoslavedemacdoine-e3cx',
  'xn--exrpubliqueyougoslavedemacdoine-d0cx',
  'xn--fdration-de-russie-bwbb',
  'xn--fdrationderussie-bqbb',
  'xn--federacin-de-rusia-x1b',
  'xn--federacinderusia-dvb',
  'fidji',
  'filipinas',
  'finlande',
  'finlandia',
  'francia',
  'xn--gabn-sqa',
  'gambie',
  'xn--gorgie-bva',
  'granada',
  'xn--grce-6oa',
  'grecia',
  'grenade',
  'guinea-ecuatorial',
  'guineaecuatorial',
  'xn--guine-bissau-eeb',
  'xn--guinebissau-ebb',
  'xn--guine-quatoriale-eqbc',
  'xn--guinequatoriale-enbb',
  'xn--guine-esa',
  'xn--gypte-9ra',
  'xn--hait-ypa',
  'xn--hati-6pa',
  'hongrie',
  'xn--hungra-7va',
  'inde',
  'xn--indonsie-f1a',
  'irlanda',
  'irlande',
  'xn--irn-fla',
  'islande',
  'islandia',
  'islas-marshall',
  'islasmarshall',
  'xn--islas-salomn-bib',
  'xn--islassalomn-zeb',
  'xn--isral-qsa',
  'italia',
  'italie',
  'xn--jamaque-rza',
  'xn--japn-sqa',
  'japon',
  'jordania',
  'jordanie',
  'xn--kazajstn-fza',
  'kirghizistan',
  'xn--kirguistn-71a',
  'xn--kowet-fta',
  'xn--la-ex-repblica-yugoslava-de-macedonia-z1d',
  'xn--laexrepblicayugoslavademacedonia-ikd',
  'xn--lbano-zsa',
  'xn--les-marshall-4fb',
  'xn--lesmarshall-zcb',
  'xn--les-salomon-zcb',
  'xn--lessalomon-u8a',
  'letonia',
  'lettonie',
  'liban',
  'libia',
  'xn--libria-dva',
  'libye',
  'lituania',
  'lituanie',
  'luxemburgo',
  'malaisie',
  'malasia',
  'maldivas',
  'malte',
  'xn--mal-tma',
  'maroc',
  'marruecos',
  'maurice',
  'mauricio',
  'mauritanie',
  'mexique',
  'xn--micronsie-g4a',
  'xn--mirats-arabes-unis-9vb',
  'xn--miratsarabesunis-9pb',
  'xn--mnaco-0ta',
  'mongolie',
  'xn--montngro-e1ab',
  'namibie',
  'xn--nger-vpa',
  'xn--nigria-dva',
  'noruega',
  'xn--norvge-6ua',
  'xn--nouvelle-zlande-knb',
  'xn--nouvellezlande-jkb',
  'xn--npal-bpa',
  'nueva-zelandia',
  'nuevazelandia',
  'xn--omn-fla',
  'ouganda',
  'xn--ouzbkistan-e7a',
  'xn--pakistn-mwa',
  'palaos',
  'xn--panam-1qa',
  'xn--papouasie-nouvelle-guine-xfc',
  'xn--papouasienouvelleguine-v8b',
  'papua-nueva-guinea',
  'papuanuevaguinea',
  'xn--pases-bajos-pcb',
  'xn--pasesbajos-l8a',
  'pays-bas',
  'paysbas',
  'xn--per-boa',
  'pologne',
  'polonia',
  'xn--prou-bpa',
  'xn--quateur-9xa',
  'xn--repblica-centroafricana-0nc',
  'xn--repblicacentroafricana-jkc',
  'xn--repblica-checa-nrb',
  'xn--repblicacheca-5nb',
  'xn--repblica-de-corea-31b',
  'xn--repblicadecorea-4ub',
  'xn--repblica-democrtica-del-congo-unc67e',
  'xn--repblicademocrticadelcongo-ifc20e',
  'xn--repblica-democrtica-popular-lao-etc62f',
  'xn--repblicademocrticapopularlao-1kc25e',
  'xn--repblica-de-moldova-28b',
  'xn--repblicademoldova-31b',
  'xn--repblica-popular-democrtica-de-corea-h7c34g',
  'xn--repblicapopulardemocrticadecorea-cwc54f',
  'xn--repblica-rabe-siria-yub23c',
  'xn--repblicarabesiria-epb38b',
  'xn--repblica-unida-de-tanzana-xlc34a',
  'xn--repblicaunidadetanzana-icc70a',
  'roumanie',
  'royaume-uni-de-grande-bretagne-et-d-irlande-du-nord',
  'royaume-unidegrande-bretagneetdirlandedunord',
  'royaumeunidegrandebretagneetdirlandedunord',
  'xn--rpublique-arabe-syrienne-bfc',
  'xn--rpubliquearabesyrienne-b8b',
  'xn--rpublique-centrafricaine-bfc',
  'xn--rpubliquecentrafricaine-bcc',
  'xn--rpublique-de-core-btbp',
  'xn--rpubliquedecore-bnbn',
  'xn--rpublique-de-moldova-b2b',
  'xn--rpubliquedemoldova-bwb',
  'xn--rpublique-dmocratique-du-congo-bxck',
  'xn--rpubliquedmocratiqueducongo-bocj',
  'xn--rpublique-dmocratique-populaire-lao-bddk',
  'xn--rpubliquedmocratiquepopulairelao-b3cj',
  'xn--rpublique-dominicaine-b5b',
  'xn--rpubliquedominicaine-b2b',
  'xn--rpublique-populaire-dmocratique-de-core-bpdur',
  'xn--rpubliquepopulairedmocratiquedecore-bddsp',
  'xn--rpublique-tchque-5pbf',
  'xn--rpubliquetchque-5mbf',
  'xn--rpublique-unie-de-tanzanie-blc',
  'xn--rpublique-uniedetanzanie-bfc',
  'xn--rpubliqueuniedetanzanie-bcc',
  'rumania',
  'xn--rythre-9uaf',
  'sainte-lucie',
  'saintelucie',
  'saint-kitts-et-nevis',
  'saintkittsetnevis',
  'saint-kitts-y-nevis',
  'saintkittsynevis',
  'saint-marin',
  'saintmarin',
  'xn--saint-sige-66a',
  'xn--saintsige-63a',
  'saint-vincent-et-les-grenadines',
  'saint-vincent-et-lesgrenadines',
  'saintvincentetlesgrenadines',
  'xn--santa-luca-s8a',
  'xn--santaluca-n5a',
  'santa-sede',
  'santasede',
  'xn--santo-tom-y-prncipe-jzb3g',
  'xn--santotomyprncipe-iqb9e',
  'san-vicente-y-las-granadinas',
  'sanvicenteylasgranadinas',
  'xn--sao-tom-et-principe-hzb',
  'xn--saotom-et-principe-gwb',
  'xn--saotometprincipe-gqb',
  'serbie',
  'sierra-leona',
  'sierraleona',
  'singapour',
  'singapur',
  'slovaquie',
  'xn--slovnie-eya',
  'xn--sngal-bsab',
  'somalie',
  'soudan-du-sud',
  'soudandusud',
  'soudan',
  'xn--sude-6oa',
  'xn--sudfrica-bza',
  'xn--sudn-del-sur-ebb',
  'xn--sudndelsur-u4a',
  'xn--sudn-7na',
  'suecia',
  'suisse',
  'suiza',
  'swazilandia',
  'tadjikistan',
  'tailandia',
  'xn--tats-unis-d-amrique-9ybo',
  'xn--tats-unisdamrique-9sbm',
  'xn--tatsunisdamrique-9pbl',
  'xn--tayikistn-71a',
  'tchad',
  'xn--thalande-w2a',
  'xn--thiopie-9xa',
  'xn--tnez-qra',
  'trinidad-y-tabago',
  'trinidadytabago',
  'xn--trinit-et-tobago-gqb',
  'xn--trinitettobago-gkb',
  'tunisie',
  'xn--turkmenistn-t7a',
  'xn--turkmnistan-fbb',
  'xn--turqua-7va',
  'turquie',
  'ucrania',
  'xn-----0sdnaabbqdec3b2a8a9xdafgoi6cwcqb',
  'xn------ozebbafefdz2csci9b9c4a9pj0agir8a1ae8cfhff',
  'xn------ozebabibfdz8bwg6c7b1b5l9adigj4b0af5cjfh',
  'xn------ozebabajbfe1a8b1a2be4jzfmo7acfhd3av3agfb6cheh',
  'xn------nzedobr3arbjgd5iuetddbic8a4cd',
  'xn------nzedf1f5b7f1anbf',
  'xn------5cdbabbbffdlwke1cf1afdjh2a3ahsq1axdf1e8h5d6e9alu',
  'xn------5cdbbabizbggbc8bfdrchdfwpucthce4bihkgnuf5a5c6jnnxa',
  'xn------5cdabbabj2abnbc8bfvhdfcsotcufce3bokglauf4a4c2j3mxa',
  'xn------5cd0atbaa7ajhgtdmgni',
  'xn------8cdhiibcd2acf2ddbfd5chgrh1u',
  'xn------hddldsbdn5ag7bfhed',
  'xn-----7kcbqfbaexhazzibal6ce5ab9kxcg',
  'xn-----6kccenbb7aae8cudq5ak',
  'xn-----4sdhbabbhkcfg6b4ave7lrihgio9i',
  'xn-----btdbbeedcw9cde1a8fsifgh4bvn7ahh',
  'xn-----btdbbeedc3dr2a6eg3lpaaegha5fvbi',
  'xn-----btdabbbbo3a7aa1l2f0a2a1bbg',
  'xn-----ctdbab4codw0qbod3a7a',
  'xn-----ktdc6au5ie1asgc',
  'xn-----zsdqbhu4bdx8bxhoa5ae6arlvgfb',
  'xn-----ctdbahfr6aog7e7a1ikpeiloowgc5bnf',
  'xn-----ctdabhfdemtyqr8ygklcmv9bh',
  'xn-----btdmb4ceb9jqa4agzyf',
  'xn-----btdgaj5ckg0pigvc2aecb',
  'xn-----6kcacafbmq0bd4d8bvsi',
  'xn-----8kcbhfapazbg9ckdj5ak7h7h',
  'xn----8sbaabtf6awlc9agoaxh',
  'xn--jlq652e65w',
  'xn-----6kcabfqbjbc6afudfcnj9afpcnrgxfu4b4h3l',
  'xn-----8kchizk6aynq',
  'xn-----6kcbbabcyjbc4afff2aq6abakcc0by2a9aw0r7fta',
  'xn--mgbyzcbcg1b',
  'xn-----7kcabbduibae6agc2a1bhban9cir7a9p8aq6c',
  'xn-----6kcablcht3a3cggjb8dxlg',
  'xn-----6kcbaabfc3bzaajepffq8ehgfiehf9cwuia',
  'xn--ihq75fko65atzaykt0pp4ez5ho55d3dde46ce96d',
  'xn----ymcaabpvruu2ph7c3a',
  'xn----ymcbcf4cke8lcfurhe',
  'xn----rmckbdxz5a1mcc4c',
  'xn----ymcbgcfk4b5a4mdfi2ap7agb',
  'xn----ymcbgcoym0odfhgohqg4ahbb',
  'xn----qmcbnc7bqfn8azlf',
  'xn----ymcb2bcjd6jifc1cuh',
  'xn----qmcmbeak8bq0g9f0acdv',
  'xn----ymcbb9bp0hn1arir',
  'xn----ymcbdhc6abp1hyf2abdsb',
  'xn----4mcb0aq9geyqfc',
  'xn----zmcaadv9ah9nmau',
  'xn----zmcnucc9ipak',
  'xn----zmcnuei3jik1b',
  'xn----zmcarzcdu9lf',
  'xn----zmcelzc9jmskeuf',
  'xn----zmcelrj9iwafdsmecx',
  'xn----rmcmbt7a8hj4au1ab',
  'xn----zmcabtv1a9koahof',
  'xn----ymcbk7bd2jnvv',
  'xn----ymcb4bg4ifess',
  'xn----zmca1bj3hfr6a',
  'xn----xmcbbaapf4f1c2fua7atbk',
  'xn----ymcbd9cwb6evalbd',
  'xn----zmch5gxaeza',
  'xn----zmchvl8gk4bes',
  'xn----7sbabbjer0atv2aa1ajfr',
  'xn----7sbbb9alwllqos',
  'xn----8sbccenud5b5bxa2l',
  'xn----7sbabeokuafqbfmfrdl4asrf1c9t',
  'xn----7sbdfkfb7a1bx',
  'xn----8sbfkolaprbeaufjfr',
  'xn----7sbabaagubffbb3bedpcgdeuoscsgce1bigkfnte4a2c8jwmwa',
  'xn----7sbb5ahdysgk',
  'xn----8sbfhuj2avmo',
  'xn----7sbaabaahxblbb3betgdecqnrctece0bnkflate3a1c4j0mwa',
  'xn----7sbbgbjiwbthk9aa3akfs3p',
  'xn----7sbabib4dafycashoq7gtc',
  'xn----7sbbfnenlyohe7ti',
  'xn----7sbabjbgr0a9bfgib4dwlf',
  'xn----7sbcpdpoce9adkke5a6r',
  'xn----7sbbbhldzsiel0aceos1b',
  'xn----7sbbpebqgck5bxjfac8c0b5lj',
  'xn----7sbb6aseeipe',
  'xn----7sbqmaaa1ahfgpdkgli',
  'xn----7sbabaiek6bo6b3aee0a2rg',
  'xn----ctbkomodgjehfh7o',
  'xn----8sbfkaaugkj1bazeefr2hsd',
  'xn----8sbfegbcbybe4cdbdd9bhgph1s',
  'xn----dtbhboaclze1bffed',
  'xn----itbkliwef4im',
  'xn----8sbeb6bghfbbbca1aekr2n',
  'xn----7sboca8aphnai6k',
  'xn----jtbhcneoqoh9d',
  'xn--d1aeec0d',
  'xn----7sbaaabg0af0afsbfejwcep4aifhvfj1cp4cym4d',
  'xn----7sbbdrcwlfi6bkjf5a8cs3k',
  'xn----8sba3amept5e',
  'xn----7sbaahegrvfnq0aff6a6a9pj6cg',
  'xn----7sbba0apot4a3b6gpa',
  'xn----8sblgpxe3as0f4a',
  'xn--yfrp4m0wcr42c',
  'xn--7zty5l',
  'xn--3oqr8eczbt4ehn350r',
  'xn--7zty9r4v2b',
  'xn--kgbecl9llc',
  'xn--igbicqsg4m3a',
  'xn--igbi3azffxb',
  'xn--kgbei1ab2mc',
  'xn--igbiac8cug2a',
  'xn--igbiak9ak6jzb',
  'xn--kgbei9ayglp',
  'xn--kgbcc3bj9htb',
  'xn--igbial8b0b9b2bd',
  'xn--kgbeyi1g4ac',
  'xn--mgbaaansprs6ng3c1a',
  'xn--igbhkhy6irab9a',
  'xn--igbhzh7gpa',
  'xn--kgbdbaaagibfe0b0atd1k0hgfim0i',
  'xn--igbiac3loa2a',
  'xn--mgbcpq6gpa1a',
  'xn--mgbac0bg8he1c',
  'xn--mgbaci8a5c8cf',
  'xn--mgbace5bjd2kcetphd',
  'xn--igbhadtv1a5kcb0c',
  'xn--lgbbat1ad8j',
  'xn--mgbafbej9a1a9kdei0ao5afb',
  'xn--mgbafbmul1mdehfnhpf3agbb',
  'xn--mgbaaddcbs9bcdx7exhefh0btl5agg',
  'xn--mgbaxj4gcjh',
  'xn--igbakb0boem4a7ke',
  'xn--mgbayih8ftab3b',
  'xn--mgba9a2a0cdl',
  'xn--mgbaxp8fpl',
  'xn--mgbql1evam',
  'xn--mgba5b5cceu9behv',
  'xn--mgba5b5cceu',
  'xn--mgb2a6bjt',
  'xn--mgba3a5azci',
  'xn--mgbc8elbn1a',
  'xn--mgba4a8deiipq',
  'xn--mgba3abic1ihec7btg',
  'xn--mgb2b0adllc',
  'xn--mgbg8edvm',
  'xn--igbia8ifh2a',
  'xn--mgbc0a9azcg',
  'xn--mgbaaddcb1cox5df5jnadfha7etbh',
  'xn--hgbjaaaabaadjbie9b6ag5ae7dwczaw1uyaadegedceafoixj0fwb6adjl',
  'xn--mgbko9ekno',
  'xn--mgba9a2dff',
  'xn--mgbkp8eky',
  'xn--mgbq6die',
  'xn--igbhaaaanceb7ay3a6wdaefnh2c6bob',
  'xn--mgbaad2lpay',
  'xn--mgb2ddes',
  'xn--mgba0gibjn',
  'xn--igbiadaj2bo2fxfyabdu',
  'xn--igbixi7hn',
  'xn--kgbexq7gbnrb',
  'xn--igbi0dxbin',
  'xn--igbi3azb3dbbr',
  'xn--igbici2bh5i1al',
  'xn--igbix7b6ck',
  'xn--igbia2byfuaku',
  'xn--kgbe3a4fra',
  'xn--hgbkxh9gmz',
  'xn--hgbkxp9fmz',
  'xn--kgbea6d4c7ad',
  'xn--mgbaaabbkx1aa0j8exaz6abf',
  'xn--mgbaab0c1c8era',
  'xn--mgbabj8bzfya',
  'xn--mgbab7iva',
  'xn--mgbba3ahp0m',
  'xn--mgbaab9alcr6mbmdz3a',
  'xn--mgbbm6gd1b',
  'xn--mgbab1b4b5c3b',
  'xn--ngbu7cya',
  'xn--mgbbwu1a4dm0b',
  'xn--mgbb1gd',
  'xn--ngb4da',
  'xn--mgbbg7ih',
  'xn--mgbabi9b5gkc',
  'xn--mgbab0bm6fmyphq',
  'xn--ngbof8fibq',
  'xn--mgbbv7fkk',
  'xn--mgbb9eltnb',
  'xn--ngbs3eg',
  'xn--mgbb2ai6f0ak',
  'xn--mgbfq7fkw',
  'xn--mgbahb7an4iqakd',
  'xn--mgbfv6e9a',
  'xn--mgbacgb2abn1gsfzabdrb',
  'xn--mgbfqs',
  'xn--pgb6a2bb',
  'xn--mgbf3enrd',
  'xn--pgbs0dh',
  'xn--mgbf2c5bj',
  'xn--pgbaum3fevoec',
  'xn--mgbaar1jk0b',
  'xn--mgbaacs4ag0mls',
  'xn--mgbjpbc2hnj',
  'xn--mgbjpdg6hhj7a',
  'xn--mgbanubdr3ke',
  'xn--igbiafo4acs0b9fm0ae2apjtgeb',
  'xn--mgbaafdnyme5d2a2hjndijnnufc2blf',
  'xn--mgbaafdcdkpuoo1vgikclt5bg',
  'xn--mgbdiub1ilqjdte',
  'xn--mgbaaadddct0bpbg1b1cza1niwfio5ayd3cegef',
  'xn--mgbaaafbdct7ase6b0b7a1k4achgh0bxe0chfg',
  'xn--mgbaaaagbddv8aw4ad4h8ekm2acehc0at0afeb2cgdh',
  'xn--mgbdini2htafcrldcw',
  'xn--igbibp1a9gi1asyb',
  'xn--mgbabprx2jngne',
  'xn--mgbjat8ioa',
  'xn--ngbee7iid',
  'xn--mgbp4dhfkpb',
  'xn--mgbaxg0ik',
  'xn--mgba2azfgjr',
  'xn--mgbac2b5f5a',
  'xn--mgbbb1b4fvao',
  'xn--mgba8a8dp',
  'xn--mgbaiaktmahfc2g2d4ccbgc3a9bd',
  'xn--mgbga7ada1hnzevve',
  'xn--mgbai8ac5hmst',
  'xn--mgbcag0bie5lhesbzdcb',
  'xn--mgba4af5gfdqq',
  'xn--mgba2ah6fep3a',
  'xn--mgba8a4cld2at',
  'xn--mgbx6bllkpb',
  'xn--mgbexg9a5b6at',
  'xn--mgbqic1gmks',
  'xn--mgbtf8edkp',
  'xn--mgbtfa8hm',
  'xn--mgbtf4eklkd',
  'xn--ygbc2cwab',
  'xn--zgb6arb',
  'xn--mgbb0ap1i',
  'xn--mgbaih9av0g1a5a',
  'xn--mgb9awbf',
  'xn--mgbc6c7bj',
  'xn--mgbac4esc5a',
  'xn--mgba9c6b',
  'xn--mgbaxg8b5d1a',
  'xn--mgbaak2fzcfvu',
  'xn--mgba9c6bs',
  'xn--mgb1bwbpb',
  'xn--lgbbaaane5excwfta4asbj',
  'xn--mgbac8b6ayesakbc',
  'xn--mgbaj3g0amc',
  'xn--mgbtg8c0a',
  'xn--mgbv8bnino',
  'xn--mgbp7cmjb',
  'xn--rgb5b1ab',
  'xn--mgbf2esadwa',
  'xn--ngbsm3c',
  'xn--wgbl6a',
  'xn--mgbfuef4b2cxb3ac',
  'xn--mgbaaltwj7j8a',
  'xn--mgbai0a3g5aq',
  'xn--mgbbv2fktp',
  'xn--mgbp3dm',
  'xn--mgbb4fua',
  'xn--mgbfpj2fj9adq',
  'xn--mgbah3ah9ge9aw',
  'xn--mgbb4fefqbs',
  'xn--mgbb0ak0fxbb',
  'xn--mgb9cjpb',
  'xn--mgbai6gm3b',
  'xn--mgbb7fjb',
  'xn--ngbse4a4bfj',
  'xn--mgbb1a4e4ac',
  'xn--mgbb7fyab',
  'xn--mgbah7hmkme',
  'xn--mgbfao5a1gqad3ag',
  'xn--pgbr4crbi',
  'xn--mgbe5bxcd',
  'xn--mgb2dbw',
  'xn--mgba6a9dd6ab',
  'xn--ugbeix5bo',
  'xn--wgbh1c',
  'xn--mgb1dcok',
  'xn--ugb0bhd1a',
  'xn--mgb1b4acfnp',
  'xn--mgbah1a3hjkrd',
  'xn--wgbec0epcmb',
  'xn--mgbc2a7dlcsu',
  'xn--mgb0deejd',
  'xn--mgba4a3ebf0a',
  'xn--mgbtd0ejinneb',
  'xn--mgbac9id0ab',
  'xn--mgbu5djb',
  'xn--mgbb9ffu',
  'xn--mgbjp6fuabb',
  'xn--mgbaa2b7bwcuvm',
  'xn--mgbpi6eiclpc',
  'xn--mgbg9fnb',
  'xn--mgbpgj5gfj',
  'xn--mgba3a8a9cfz',
  'xn--mgbp6didg',
  'xn--80aafzo2agi5j',
  'xn--80aes5aeg7g',
  'xn--80aaacqdkdv7b0a',
  'xn--80aac3amo1k',
  'xn--80amgk1a',
  'xn--80aai6aih',
  'xn--80aal9agma',
  'xn--80aaaadbkm4ac5c2btqg',
  'xn--80aaiip3abtv',
  'xn--80akjogp3i',
  'xn--80aaak0a3ac4ajo',
  'xn--80aaabhepxrtzazjeq',
  'xn--80aabjgg3bo8h',
  'xn--80aabbq4dkm',
  'xn--80abnqup8a',
  'xn--80abmy0agn7e',
  'xn--90aigdo',
  'xn--90aefnn6hxa',
  'xn--90aijtb',
  'xn--80abgvmrr5j',
  'xn--90acrbmr7j',
  'xn--80abddalavaf1cidh1ak5gyh',
  'xn--80aabe0ddvh',
  'xn--80abtear5a0j',
  'xn--80aaabpf2atkc6afnawg',
  'xn--80aab2ajtjkonq',
  'xn--90agmsorb',
  'xn--80ab1bse',
  'xn--80aaaaabdecirid3bexecigy0agqoyvde5d2hzdwe7aks',
  'xn--80aae9b0aec',
  'xn--b1acfnxt0i',
  'xn--80adja4ak3aq4g',
  'xn--80adj3ad3a2e',
  'xn--80ace6be',
  'xn--80afqa3c',
  'xn--80aaaj5aza',
  'xn--80acews0j',
  'xn--80aah5b',
  'xn--80aaafeo7bg6c',
  'xn--b1abhlz3i',
  'xn--80abcdlrc1b1bv1k',
  'xn--80afgnshs8j',
  'xn--80afd9aeoin',
  'xn--80aahfd2ct',
  'xn--c1aej4a5a7c',
  'xn--c1aid4ap8e',
  'xn--80ahnt5g',
  'xn--80aaadlbhbb1aerdebmi5afncmqfwet1b4h8k',
  'xn--90afgib3dg',
  'xn--80ahmakjif',
  'xn--80aaadmisaepbelfqdk2arre9byt',
  'xn--c1adbl9at',
  'xn--80acqgs0j',
  'xn--80abbephh6a',
  'xn--80anccp1a1g',
  'xn--d1ahbs6g',
  'xn--d1acgbd0abh1n',
  'xn--80ahlcydo8j',
  'xn--80aphw',
  'xn--80apqk',
  'xn--80aikcmns9j',
  'xn--80aikcmnz1j',
  'xn--80apbtil6h',
  'xn--80apbl7a7e',
  'xn--e1aakng',
  'xn--80acdieb3a8au',
  'xn--80aaa0anw0aiz',
  'xn--80aacmkupr',
  'xn--80aknjgrv',
  'xn--80aaap1at',
  'xn--80aa2a0ai',
  'xn--e1agen5g',
  'xn--h1adqe',
  'xn--80abuach7bt',
  'xn--80apeb5b',
  'xn--90aoeefk0b1g',
  'xn--80adiljanpbdatejeq',
  'xn--c1anjdc',
  'xn--80aaaaafsafebb0bedocfdetnscrgcd0bigjfnse3a1c4j1mva',
  'xn--80aaxgcvpgj',
  'xn--80adfqiyslm',
  'xn--80ab0azb',
  'xn--b1agmb7bc',
  'xn--80afmksoji0fc',
  'xn--80aaaaaagvakbb0besfdecpmrcsecd9anjflase2a0c0jtmva',
  'xn--80aesj0b6e',
  'xn--e1alkanh',
  'xn--90aihcl7a3h',
  'xn--80adrln',
  'xn--b1albj7h',
  'xn--80adrl0b',
  'xn--e1aahggoc9ac0a0b',
  'xn--90aeetei3aev8h',
  'xn--80aesafek8a',
  'xn--80aafybtlvt7k',
  'xn--80aaaane9bk7bh',
  'xn--80aagyme',
  'xn--80aavedkf2n',
  'xn--80aqic',
  'xn--80aafaihtbrgj6aa1aker4o',
  'xn--80aa6ac6a9c',
  'xn--80auaflck',
  'xn--80aaaha6caewbargnp2gsc',
  'xn--80ajjhbi1b',
  'xn--e1aebchkjhr9m',
  'xn--80acqgkhcn',
  'xn--80aueeed',
  'xn--c1akicffc2l',
  'xn--80axad8esa',
  'xn--80acubre5k',
  'xn--80azklb',
  'xn--80ajtfl',
  'xn--c1aehts',
  'xn--c1aehcvv0i',
  'xn--80ahbcnukfy8i',
  'xn--80aaalwmt1a3a',
  'xn--80aaekdlkvnge0sh',
  'xn--b1acfnvgo8j',
  'xn--80aaaabbthbbzfeezo3abaicc7aw0a6av7p2fra',
  'xn--80aaabcpgbad1afbz6ahbal4chq4a5o6ao2c',
  'xn--80axeb',
  'xn--80aaxhrhri',
  'xn--80aa6aox',
  'xn--80aaa1cdk',
  'xn--80aaahbfpx4bffib0d9kf',
  'xn--80aaahe2bxbj',
  'xn--e1atem',
  'xn--80avid1cxa',
  'xn--80afqlmfiqj8j',
  'xn--80ablcnmbe5adjje3a3q',
  'xn--80aabfjdwqhdkycenr9a',
  'xn--80aaldbofcj1bvifac4czb9ki',
  'xn--80aal9ans',
  'xn--h1ahdkp3c4a',
  'xn--80aafj6avob5h',
  'xn--80aa9aim',
  'xn--80aayoedhne',
  'xn--80akjaaaxhefodjgkh',
  'xn--80aaaahdj1bn2b0aeey0qf',
  'xn--80adhdjgvr3a',
  'xn--b1agljmcfiegfg5n',
  'xn--80adiaarfji7aaxeeeq7g8c',
  'xn--80affa6ai0a',
  'xn--80addfbbbvbe0ccbdd6bhfpg2r',
  'xn--b1afalacjxd8aefec',
  'xn--e1agihsdf1hl',
  'xn--90ahk4ad2g',
  'xn--80afoumgbv',
  'xn--80aaaaaeb5avaheneeo0ehfeiege5c8th',
  'xn--80adthcst2j',
  'xn--b1agjjmdt2j',
  'xn--80aakdbactfavvibaj0ce2ab7jucf',
  'xn--80ada8afgebbbbazejq4m',
  'xn--80aqicjr',
  'xn--80ah2ank',
  'xn--80apncnfo',
  'xn--80ajba2angmah5j',
  'xn--80aalikan0a8adh',
  'xn--80aamopn4a',
  'xn--e1afbkemomg3d',
  'xn--c1avbn',
  'xn--80af3adt',
  'xn--80aacjbbzad9bqdo1ai',
  'xn--80ad2azafd',
  'xn--h1ajnbf',
  'xn--80ajjgkhcrodhe',
  'xn--h1apfeq9c',
  'xn--80aahf8b7a',
  'xn--80abmghlx4ajd',
  'xn--80aaxgrpt',
  'xn--80adc0a5arb',
  'xn--h1aaakmka9a8d',
  'xn--d1ahbkjb9b9ed',
  'xn--80aqoktp8d',
  'xn--80adtvkoq0g',
  'xn--80aaaaafxeyerbeeivceo2aifgvej9bp2c4m4d',
  'xn--80ai9c',
  'xn--c1adksfbke0dye',
  'xn--80aacobtkeh2bjje3a5cr6j',
  'xn--h1aai0c',
  'xn--80adjnd7b1bm4e',
  'xn--b1agj9ch0c',
  'xn--80aawjdnr7d',
  'xn--80adhvxm9f',
  'xn--80aaafdfptempyef4a4a9oi4cf',
  'xn--e1afxbj7dta',
  'xn--h1ajclf8dta',
  'xn--h1aarew7ct',
  'xn--80aaavmmr0a9a3go',
  'xn--80ahfmtdzq3e1a',
  'xn--80aa0aeh7i',
  'xn--h1ajcd3fe',
  'xn--ihqzd',
  'xn--xhqz30b3yk',
  'xn--fiqs8s',
  'xn--fiq82ff8cp7cmr7j',
  'xn--riqs47q',
  'xn--bjqw6egd',
  'xn--bjqw6e6dm2a46r56w',
  'xn--bjqp70bzi1b',
  'xn--bjq59sz3j',
  'xn--cjq643b',
  'xn--ujqx75o',
  'xn--jlqa385hmn8a',
  'xn--onqt1fby5d',
  'xn--qoqu8c261a',
  'xn--qoq399c',
  'xn--spqr9ccp',
  'xn--1qqr90bx15a',
  'xn--1tq374c79r72a495c',
  'xn--jlqp4ao4cun',
  'xn--jlq78dmyhzw2c',
  'xn--jlq34eb78a',
  'xn--37qz9x',
  'xn--jlqx4e1h',
  'xn--jlqx4e1ho03g42t',
  'xn--1br59neqlkg049e',
  'xn--4br373c',
  'xn--tiq11fpok2fk6dl39ah4g',
  'xn--jlqp0gix4a',
  'xn--jlqp0gix4a4w8a',
  'xn--b6qj85di6ah6bfxgrxez8y14fh90c9d4e82e',
  'xn--yfru5m85i',
  'xn--yfrq93h',
  'xn--yfr838i',
  'xn--kcrzut56e',
  'xn--riqp8jv72c',
  'xn--6kr069m',
  'xn--9krq49ecxgmse',
  'xn--hlr08iktd',
  'xn--ilrq64bqq4a',
  'xn--ilr63iw2r',
  'xn--wlru4x',
  'xn--jlqs1jb7ivqcb25f',
  'xn--xlr00grun069c',
  'xn--hmrw3klvc8v1b',
  'xn--jlqq3jkt7aomhl33a',
  'xn--7or07u',
  'xn--9pra79w4qglpoa',
  'xn--9pr05ukxe',
  'xn--74qv8fqvc6vwn39a',
  'xn--jlq1m60m9w1a',
  'xn--yfrr6co40asv3b4zp',
  'xn--z0r262mjdf',
  'xn--ilrv7fi09a',
  'xn--uds6fy5s9zhtub',
  'xn--g6qu1lnv4b',
  'xn--jlq69imwdoy3e',
  'xn--0tr13dpsf6ku7n720befg',
  'xn--0tr13de2b83yplb95l5v2b2vq',
  'xn--5gq52n27bb4x1fak2i86aw3bh40cn41a',
  'xn--tfr58hrt1ducm',
  'xn--jlqv2s593c',
  'xn--jlq02e2ycepv1h4ra04y78yzm3c',
  'xn--hor55g',
  'xn--jlq21a99pzrap29e',
  'xn--3jsy2lwphuq3c',
  'xn--74qr0e32de1ar20c',
  'xn--v6qu0at7kwme',
  'xn--jlqw1vp3cm71c',
  'xn--kcr14mvvjlkd',
  'xn--ymsu32aj3f5p0b',
  'xn--yms49fex9b',
  'xn--kwr70gy12d',
  'xn--kwrt1h',
  'xn--74q23q0ucr42c',
  'xn--vcs33cbz4azl5b',
  'xn--kcr65jo1b',
  'xn--v6q65ruvhn2s',
  'xn--yfr84heue7sj',
  'xn--kwr77mevg',
  'xn--0tr95nd3bzb194bet1arx5b',
  'xn--49somo15k',
  'xn--jlq78d78nsnap36l0zm',
  'xn--yfr96t1nf22u',
  'xn--jlqp0gbxlw4j',
  'xn--3et9c166a',
  'xn--3et9c481d',
  'xn--qvr56s504c',
  'xn--vfs5m36mh0i',
  'xn--csst6ia130f',
  'xn--jlqa45tnki92cucq10e',
  'xn--8dsp1nboe',
  'xn--tst35iht1d',
  'xn--tst97s',
  'xn--tst428g',
  'xn--3js53liro60pi0b',
  'xn--8stu42i9ee',
  'xn--itt979e',
  'xn--igrt8x',
  'xn--vcsx6r',
  'xn--kcr43oq3g',
  'xn--yit34klw2a2ia336g',
  'xn--jlqw50cdsuq7b',
  'xn--xysz6s',
  'xn--74q8c76ip7cgtx',
  'xn--b6qp9eutc01c3sfw9pj81a',
  'xn--kwrx47a2qg',
  'xn--kwrx47a4zt',
  'xn--95q28k86nsm3a9qa996co7u',
  'xn--hev55o',
  'xn--95q25p2xay55a',
  'xn--woq37cn56af5g',
  'xn--jlq254a59gtez39a',
  'xn--95q20cv35awt5b',
  'xn--yfro4i67o',
  'xn--95q960c09z',
  'xn--wgv71a',
  'xn--kcr180c',
  'xn--tiqa6b62a85ik5f92emx4bcljea5087k',
  'xn--0isu8gv9m',
  'xn--btv4ns07cwsp',
  'xn--jlq45khxujk3c',
  'xn--kcrq69byie',
  'xn--jlqx0vz7c8pvux8b',
  'xn--dfv50l3ij59j',
  'xn--yfr921d',
  'xn--spqs19bk9g55fk83c',
  'xn--vcs312c',
  'xn--95qx28d',
  'xn--jlqu8lwhr6jm5fx4qu1lth9adj7abw3a',
  'xn--vcsr42c',
  'xn--tst4ay60g6u5b',
  'xn--5su97dduje83b',
  'xn--ces513c',
  'xn--jlqp0go1hw9y',
  'xn--95q32xh0r',
  'xn--jlq254abxmwif',
  'xn--ckqx5hb59a',
  'xn--0tryhk9ivzd6uco75c9fm4q3b',
  'xn--jlqp0gv83bnnj',
  'xn--mhyq07f',
  'xn--i6qr46f',
  'xn--zqsr25d',
  'xn--8frq4hg37ays3a',
  'xn--1tq374cy1l9ki',
  'xn--xys628cdgf',
  'xn--14u898bi5d',
  'xn--w2xs5c4xgtz1a',
  'xn--tmzv32e',
  'xn--8ftu3t2or',
  'xn--nbtr93ee5s',
  'xn--vb0a463bijg',
  'xn--xgvv46c',
  'xn--jlq652eyml28b',
  'xn--znyz2s',
  'xn--jlq254ako1amww',
  'xn--tiqwl15e4sek0d85zpkoa6407b',
  'xn--jlq254a1p2a',
  'xn--95q231j',
  'xn--riqw59j',
  'xn--6krp81i5nj',
  'xn--95q923j',
  'xn--74q147ccpcpr8a',
  'xn--xpu490d5te',
  'xn--nct51g0y7b',
  'xn--css12ew66am4n',
  'xn--jlq944cuow',
  'xn--z1xv09a7ha',
  'xn--7or764i',
  'xn--z1xp7ak74b',
  'xn--w9s776i',
  'xn--jlqx4e1ho51skcd',
  'xn--6kr576k',
  'xn--yms07vt5ok14a',
  'xn--1ctw89aru5a',
  'xn--jlqp0gz9ar8qj19e',
  'xn--jlq614apd56ex01k',
  'xn--jlqynz1d8uay5d14a9zjdx3au19h',
  'xn--spqz1j5yccwrq49atjvhrgzqb',
  'xn--vovy56h',
  'xn--mnq93w2lc8x4h',
  'xn--5suy04dy0s',
  'xn--jlq471dk9xffo',
  'xn--3et2lk98fvyam39g',
  'xn--8mq561jnpq',
  'xn--yfra057lqv3btfl',
  'xn--0m4av3o',
  'xn--k5sr4e941g',
  'xn--rhtp85m',
  'xn--uzbekistn-71a',
  'xn--ymen-bpa',
  'zambie',
  'xn--ggblaae5co1a8jnbxe',
  'xn--ngb4dap',
  'xn--mgbaaab7bmdt3obnd1a6a',
  'xn--pgbu2cxab',
  'xn--mgbaal9aq2ic',
  'xn--mgbaaacgca0a1awdb5a5cza1nzaefwg0cyad8aefge',
  'xn--mgbaxj4gcih',
  'xn--mgbaelrj3kgfflcdof1agb',
  'xn--mgbaa6ak9hf8ac',
  'xn--mgbai9ayglp',
  'xn--mgba2c2bhgh',
  'xn--mgbad4e4bpn',
  'xn--mgb5dbfj',
  'xn--igbixp9fmz',
  'xn--igbixq7gbnrb',
  'xn--mgba3a4fra',
  'xn--igbixh9gmz',
  'xn--mgbbhz3gwbbd',
  'xn--mgbfuef4b2cxb5a',
  'xn--mgba9c3am9a',
  'xn--mgbu1ctdi',
  'xn--mgbb1a4e4abb',
  'xn--ngbse4a4bfjve',
  'xn--mgbanohf3ivabi2d',
  'xn--mgba1cvcd',
  'xn--ngbr0a6b2a',
  'xn--ngbt3cjbqr',
  'xn--mgbab1jd',
  'xn--mgbf5ge',
  'xn--mgbga7ada1hnzevveb',
  'xn--mgbcag0bie5llpbbzdcb',
  'xn--mgbaceb9akt3cf8if8cwah',
  'xn--mgbab9byeqa',
  'xn--kgbeac8cug2a',
  'xn--mgbpl2fh',
  'xn--mgbaykd8hpalu',
  'xn--mgbahw7hnz',
  'xn--mgbeb2abr3hng4aui',
  'xn--igbhaaaabaadjbie9b6ag5ae7dwczaw1uyaadegedceafoixj0fwb6adjl',
  'xn--hhbckc',
  'xn--mgbbb1b4fvajf',
  'xn--larepblicacentroafricana-krc',
  'costademarfil',
  'suscribir',
  'xn--repblicadominicana-l5b',
  'regiones',
  'guayana',
  'irak',
  'kenia',
  'jelgava',
  'hanover',
  'lesoto',
  'xn--papanuevaguinea-4ub',
  'tawi',
  'katar',
  'xn--federacinrusa-ilb',
  'ruanda',
  'xn--saotomyprncipe-gkb1e',
  'xn--bansk-1qa',
  'xn--elsurdesudn-t7a',
  'trinidadytobago',
  'pavo',
  'xn--reinounidodegranbretaaeirlandadelnorte-svd',
  'xn--repblicaunidadetanzania-0nc',
  'zimbabue',
  'cameroun',
  'xn--dmocratiquedespeuplesrpubliquedecore-bgdvn',
  'equateur',
  'salvador',
  'xn--erythre-gya',
  'ethiopie',
  'guyane',
  'xn--laorpubliquepopulairedmocratique-e3cs',
  'ilemaurice',
  'sud-soudan',
  'xn--lex-rpubliqueyougoslavedemacdoine-f6cx',
  'trinidadettobago',
  'xn----7sbbaaag0af1aetbeejwcfo4aiggwej1cq3cxn5d',
  'xn----7sbabaagubffbbc3beeqcgdebuqucugcen0algkfjhte8a4c1k4nwao',
  'xn--c1aibbbh7b7h',
  'xn--80aefqne4ie',
  'xn--80adf1aemc',
  'xn--80ahcbmecg7cgeayt9a9k',
  'xn--80a6a1b',
  'xn--7dv848d',
  'xn--tiqxlp3e4sek0ds18av3hca3592i',
  'xn--b6qr2az8bp0dgudrxgnr2e',
  'xn--hev',
  'xn--wlrq8r',
  'xn--vcst42m',
  'xn--riqq8jt72cs1l',
  'xn--0tryhk9ivzd6uco75cw26bgpd',
  'xn--vnx288g',
  'xn--ihq75fko65atzal4l2oelrhx41d788az3zc',
  'xn--vcsx75g',
  'xn--nlqu54ayjbu74bye5b',
  'xn--nlq29fz9a760eyv1b',
  'xn--49s614ccjv',
  'xn--0tr95nd3bzb194bet1aru6b',
  'xn--nlqa175hmn8a',
  'xn--nlq29fo1hw9y',
  'xn--kcr65ju1b',
  'xn--qvr56spk4c',
  'xn--yfrs6h68d7sj',
  'xn--1tq374cy1ljqi',
  'xn--kcrv30cg6d',
  'xn--spq75e899d',
  'xn--tdt964i',
  'xn--nlq29fv83b9ki',
  'xn--nlq77lwhr6jm5fx4qu1l2w6agx9abw3a',
  'xn--9krq49e5sfuqg',
  'xn--7dv229d',
  'xn--nlq23ao4cun',
  'xn--3js53lirorypbd',
  'xn--z0r262mgdf',
  'xn--fiq82ff8cb9cm86j',
  'xn--fiqz9s',
  'xn--nlqq1by7h902a',
  'xn--14u898bg9d',
  'xn--zdrz13c',
  'xn--yfrr6co40axh4bpep',
  'xn--nlqz8dmyh812c',
  'xn--74q8c76ib9cgrx',
  'xn--tiqxlp3e4sek2de76av3hca1180i',
  'xn--tiq11f9tag8dkte677ah4g',
  'xn--riqq47q',
  'xn--b6qr2az8bb2dgsdrxgnr2e',
  'xn--hmrw3kxxysmc',
  'xn--cssx89ck1bmw0a',
  'xn--nlqs1eh6rdw9bkcd',
  'xn--nlq32jkt7aomhl33a',
  'xn--nlqu54a4wdioo',
  'xn--nlqu1a99pzrap29e',
  'xn--eh1at5e',
  'xn--9csx02c',
  'xn--nlq745aszl',
  'xn--nlqw5khxu7j2c',
  'xn--9csr5r',
  'xn--yfr400h',
  'xn--ittu01f',
  'xn--btv4nx92c9tr',
  'xn--xlr00grun2k9c',
  'xn--nlqs1eh6r',
  'xn--nlqs1eh6ri4mfqu',
  'xn--nlq81s593c',
  'xn--37qs8y',
  'xn--vgu849ao5o',
  'xn--yfr115eu8r',
  'xn--xgvn82c',
  'xn--74qv8fqvc6vwnw1b',
  'xn--nlqu54a1p2a',
  'xn--9pra79w6rua104q',
  'xn--tiqwl15e4sek2dc81abxla6407b',
  'xn--nlq940ccutfgd',
  'xn--xpu490ds1e',
  'xn--nlq29fix4a4w8a',
  'xn--nlq29fix4a',
  'xn--7ksv40buqk',
  'xn--yfra057lvh4by0j',
  'xn--5suo21djzt',
  'xn--nlqwvl50lhsn',
  'xn--mnq93wf4uu79a',
  'xn--0m4a18m',
  'xn--8mq561j67p',
  'xn--klt180c64h1mc6u3c',
  'xn--nlqp0vz7c8pvux8b',
  'xn--nlqz8d78nsna207l4rm',
  'xn--kwrx47ayss',
  'xn--znyp9p',
  'xn--nlqy52eymlura',
  'xn--mhyu74f',
  'xn--8ft289ax6d',
  'xn--6m1an9c',
  'xn--efv263e3ic',
  'xn--8fts8t7kk',
  'xn--nlq29fbxlw4j',
  'xn--qhrv4x',
  'xn--tst35i0x0d',
  'xn--nlqa25sfztfcr2bx40b',
  'xn--tmzx10e',
  'xn--g4t432f48f',
  'xn--0wwo35c',
  'xn--hlr08iww0a',
  'xn--9cs902m',
  'xn--b6qp9e1ucf0ctwxbstbie',
  'xn--nlqu54ais1af4v',
  'xn--1tq374c5is10a960d',
  'xn--ihvr09a79s',
  'xn--0trr3fqzeq8loh4awldp3f',
  'xn--nlqy59g8igbqj',
  'xn--5gq52nu1pweu7gj2at9ao48bpyvdqh',
  'xn--nlq144cuqx',
  'xn--tfr177h4xgpjp',
  'xn--0trv9hr6n77a98kvp8a7ebf33b',
  'xn--05qr3a79jdn5a',
  'xn--nlq90v1vvsok',
  'xn--yms051d1fl',
  'xn--nlqu54a59gtez39a',
  'xn--kltx6jj73atdat97i',
  'xn--vb0a463b1wf',
  'xn--riqq8jgu6cmdj',
  'xn--hlrv93b8wx8yj',
  'xn--riq637k',
  'xn--6kr929ijnh',
  'xn--zqs1yj4zyw6a',
  'xn--nlqqnz1d8uam1gt3eb30a0ved17k',
  'xn--9cs722c',
  'xn--b6qj85di6ah6btzgyuez8y14fh90ccf3eg9d',
  'xn--ztto1sg6d',
  'xn--yfrz06d',
  'xn--0tryhk9ivzd6uco75cwx7b5pc',
  'xn--vnx433f',
  'xn--uds6fs8sjxhtub',
  'xn--ldss70e8lb',
  'xn--fwtr22bx5w',
  'xn--74q218e5uo',
  'xn--spqz1jd0cqurl89a6evvpgtsb',
  'xn--ihq51h7vapue0kuxdl3s0m7aco5a20gt57c',
  'xn--nlqs2e20cep13hqpa04y78yxt3c',
  'xn--9csr65g',
  'xn--8dsz3yykl',
  'xn--74qpue0k67q18m65z',
  'xn--8fru6he85ays3a',
  'xn--05q38ruvhn2s',
  'xn--ujqz74o',
  'xn--nlqy52ezyw',
  'xn--tst4ay60g3r5b',
  'olympic',
  'olympiad',
  'olympique',
  'olympiade',
  'olympisch',
  'xn--olmpico-8ya',
  'xn--olimpada-g2a',
  'xn--igbk7fennc',
  'xn--igbic3azhgtrc',
  'xn--74qv0co1jlpq',
  'xn--jlq85io9flpq',
  'xn--74qv0cu1jdpq',
  'xn--nlq05iu9fdpq',
  'xn--kxadxfcgtff2c',
  'xn--hxakisjgtk7b',
  'xn--4y2bl1uwwl',
  'xn--hy1bl6gb7lq0a546c',
  'xn--h1aaadbedjpny',
  'xn--80aaloaqinm',
  'africandevelopmentbank',
  'african-development-bank',
  'africanunion',
  'african-union',
  'africancaribbeanandpacificgroupofstates',
  'african-caribbean-and-pacific-group-of-states',
  'andeancommunity',
  'andean-community',
  'andeandevelopmentcorporation',
  'andean-development-corporation',
  'asiandevelopmentbank',
  'asian-development-bank',
  'xn--asociacinlatinoamericanadeintegracin-2qd1a',
  'xn--asociacin-latinoamericana-de-integracin-x0d4a',
  'associationofcaribbeanstates',
  'association-of-caribbean-states',
  'associationofsoutheastasiannations',
  'association-of-southeast-asian-nations',
  'caribbeancommunity',
  'caribbean-community',
  'centralamericanintegrationsystem',
  'central-american-integration-system',
  'collectivesecuritytreatyorganization',
  'collective-security-treaty-organization',
  'xn--commissiondelocanindien-pcc',
  'xn--commission-de-l-ocan-indien-soc',
  'commonfundforcommodities',
  'common-fund-for-commodities',
  'commonwealthofindependentstates',
  'commonwealth-of-independent-states',
  'communityofsahel-saharanstates',
  'community-of-sahel-saharan-states',
  'xn--comunidadedospasesdelnguaportuguesa-thdg',
  'xn--comunidade-dos-pases-de-lngua-portuguesa-gxdi',
  'cooperationcouncilforthearabstatesofthegulf',
  'cooperation-council-for-the-arab-states-of-the-gulf',
  'councilofeurope',
  'council-of-europe',
  'eastafricancommunity',
  'east-african-community',
  'economiccommunityofcentralafricanstates',
  'economic-community-of-central-african-states',
  'economiccommunityofwestafricanstates',
  'economic-community-of-west-african-states',
  'economiccooperationorganization',
  'economic-cooperation-organization',
  'energycharterconference',
  'energy-charter-conference',
  'eurasiandevelopmentbank',
  'eurasian-development-bank',
  'eurasianeconomiccommunity',
  'eurasian-economic-community',
  'europeanorganizationfornuclearresearch',
  'european-organization-for-nuclear-research',
  'hagueconferenceonprivateinternationallaw',
  'hague-conference-on-private-international-law',
  'inter-americandevelopmentbank',
  'inter-american-development-bank',
  'intergovernmentalauthorityondevelopment',
  'intergovernmental-authority-on-development',
  'internationalcentreformigrationpolicydevelopment',
  'international-centre-for-migration-policy-development',
  'internationalcriminalcourt',
  'international-criminal-court',
  'internationaldevelopmentlaworganization',
  'international-development-law-organization',
  'internationalhumanitarianfact-findingcommission',
  'international-humanitarian-fact-finding-commission',
  'internationalhydrographicorganization',
  'international-hydrographic-organization',
  'internationalinstitutefordemocracyandelectoralassistance',
  'international-institute-for-democracy-and-electoral-assistance',
  'internationalorganizationformigration',
  'international-organization-for-migration',
  'internationalrenewableenergyagency',
  'international-renewable-energy-agency',
  'internationalseabedauthority',
  'international-seabed-authority',
  'internationaltribunalforthelawofthesea',
  'international-tribunal-for-the-law-of-the-sea',
  'islamicdevelopmentbankgroup',
  'islamic-development-bank-group',
  'italian-latinamericaninstitute',
  'italian-latin-american-institute',
  'latinamericanandcaribbeaneconomicsystem',
  'latin-american-and-caribbean-economic-system',
  'latinamericanparliament',
  'latin-american-parliament',
  'leagueofarabstates',
  'league-of-arab-states',
  'organisationforeconomicco-operationanddevelopment',
  'organisation-for-economic-co-operation-and-development',
  'organisationinternationaledelafrancophonie',
  'organisation-internationale-de-la-francophonie',
  'organisationofeasterncaribbeanstates',
  'organisation-of-eastern-caribbean-states',
  'organisationofislamiccooperation',
  'organisation-of-islamic-cooperation',
  'organizationfordemocracyandeconomicdevelopment',
  'organization-for-democracy-and-economic-development',
  'organizationofamericanstates',
  'organization-of-american-states',
  'organizationoftheblackseaeconomiccooperation',
  'organization-of-the-black-sea-economic-cooperation',
  'pacificislandsforum',
  'pacific-islands-forum',
  'permanentcourtofarbitration',
  'permanent-court-of-arbitration',
  'shanghaicooperationorganisation',
  'shanghai-cooperation-organisation',
  'southasianassociationforregionalcooperation',
  'south-asian-association-for-regional-cooperation',
  'southcentre',
  'south-centre',
  'southernafricandevelopmentcommunity',
  'southern-african-development-community',
  'theopecfundforinternationaldevelopment',
  'the-opec-fund-for-international-development',
  'xn--unioneconomiqueetmontaireouestafricaine-upd',
  'xn--union-economique-et-montaire-ouest-africaine-x4d',
  'unionofsouthamericannations',
  'union-of-south-american-nations',
  'worldcustomsorganization',
  'world-customs-organization',
  'africanintellectualpropertyorganization',
  'african-intellectual-property-organization',
  'africanpetroleumproducersassociation',
  'african-petroleum-producers-association',
  'africanregionalintellectualpropertyorganization',
  'african-regional-intellectual-property-organization',
  'amazoncooperationtreatyorganization',
  'amazon-cooperation-treaty-organization',
  'aseanpromotioncentreontradeinvestmentandtourism',
  'asean-promotion-centre-on-trade-investment-and-tourism',
  'asianproductivityorganization',
  'asian-productivity-organization',
  'bankforinternationalsettlements',
  'bank-for-international-settlements',
  'beneluxorganizationforintellectualproperty',
  'benelux-organization-for-intellectual-property',
  'blackseatradeanddevelopmentbank',
  'black-sea-trade-and-development-bank',
  'bureauinternationaldespoidsetmesures',
  'bureau-international-des-poids-et-mesures',
  'carribeantelecommunicationsunion',
  'carribean-telecommunications-union',
  'centralbankofwestafricanstates',
  'central-bank-of-west-african-states',
  'councilofeuropedevelopmentbank',
  'council-of-europe-development-bank',
  'eftacourt',
  'efta-court',
  'eftasurveillanceauthority',
  'efta-surveillance-authority',
  'eucliduniversity',
  'euclid-university',
  'eurasianpatentorganization',
  'eurasian-patent-organization',
  'europeanatomicenergycommunity',
  'european-atomic-energy-community',
  'europeanbankforreconstructionanddevelopment',
  'european-bank-for-reconstruction-and-development',
  'europeancentralbank',
  'european-central-bank',
  'europeancentreformedium-rangeweatherforecasts',
  'european-centre-for-medium-range-weather-forecasts',
  'europeancommission',
  'european-commission',
  'europeanconferenceofministersoftransport',
  'european-conference-of-ministers-of-transport',
  'europeancouncil',
  'european-council',
  'europeanexternalactionservice',
  'european-external-action-service',
  'europeanforestinstitute',
  'european-forest-institute',
  'europeanfreetradeassociation',
  'european-free-trade-association',
  'europeaninvestmentbank',
  'european-investment-bank',
  'europeanmolecularbiologylaboratory',
  'european-molecular-biology-laboratory',
  'europeanorganisationforthesafetyofairnavigation',
  'european-organisation-for-the-safety-of-air-navigation',
  'europeanparliament',
  'european-parliament',
  'europeanpatentoffice',
  'european-patent-office',
  'europeanpatentorganisation',
  'european-patent-organisation',
  'europeanpoliceoffice',
  'european-police-office',
  'europeanspaceagency',
  'european-space-agency',
  'foodandagricultureorganizationoftheunitednations',
  'food-and-agriculture-organization-of-the-united-nations',
  'inter-americaninvestmentcorporation',
  'inter-american-investment-corporation',
  'internationalanti-corruptionacademy',
  'international-anti-corruption-academy',
  'internationalatomicenergyagency',
  'international-atomic-energy-agency',
  'internationalcentreforadvancedmediterraneanagronomicstudies',
  'internationalcentreforsettlementofinvestmentdisputes',
  'international-centre-for-settlement-of-investment-disputes',
  'internationalcivilaviationorganization',
  'international-civil-aviation-organization',
  'internationalcocoaorganization',
  'international-cocoa-organization',
  'internationalcoffeeorganization',
  'international-coffee-organization',
  'internationalcourtofjustice',
  'international-court-of-justice',
  'internationaldevelopmentassociation',
  'international-development-association',
  'internationalenergyagency',
  'international-energy-agency',
  'internationalexhibitionsbureau',
  'international-exhibitions-bureau',
  'internationalfinancecorporation',
  'international-finance-corporation',
  'internationalfundforagriculturaldevelopment',
  'international-fund-for-agricultural-development',
  'internationalinstitutefortheunificationofprivatelaw',
  'international-institute-for-the-unification-of-private-law',
  'internationaljointcommission',
  'international-joint-commission',
  'internationallabourorganization',
  'international-labour-organization',
  'internationalmaritimeorganization',
  'international-maritime-organization',
  'internationalmobilesatelliteorganization',
  'international-mobile-satellite-organization',
  'internationalmonetaryfund',
  'international-monetary-fund',
  'internationaloliveoilcouncil',
  'international-olive-oil-council',
  'internationalscienceandtechnologycenter',
  'international-science-and-technology-center',
  'internationalsugarorganization',
  'international-sugar-organization',
  'internationaltelecommunicationunion',
  'international-telecommunication-union',
  'internationaltelecommunicationssatelliteorganization',
  'international-telecommunications-satellite-organization',
  'internationaltransportforum',
  'international-transport-forum',
  'internationaltropicaltimberorganization',
  'international-tropical-timber-organization',
  'internationalunionfortheprotectionofnewvarietiesofplants',
  'internationalwhalingcommission',
  'international-whaling-commission',
  'iterinternationalfusionenergyorganization',
  'iter-international-fusion-energy-organization',
  'mekongrivercommission',
  'mekong-river-commission',
  'xn--mercadocomndesur-tyb',
  'xn--mercado-comn-de-sur-b9b',
  'multilateralinvestmentguaranteeagency',
  'multilateral-investment-guarantee-agency',
  'nordicinvestmentbank',
  'nordic-investment-bank',
  'nordicpatentinstitute',
  'nordic-patent-institute',
  'northatlanticsalmonconservationorganization',
  'north-atlantic-salmon-conservation-organization',
  'northatlantictreatyorganization',
  'north-atlantic-treaty-organization',
  'northpacificmarinescienceorganization',
  'north-pacific-marine-science-organization',
  'organisationforjointarmamentco-operation',
  'organisation-for-joint-armament-co-operation',
  'organisationfortheharmonisationofbusinesslawinafrica',
  'organisation-for-the-harmonisation-of-business-law-in-africa',
  'organisationfortheprohibitionofchemicalweapons',
  'organisation-for-the-prohibition-of-chemical-weapons',
  'organisationinternationaledelavigneetduvin',
  'organisation-internationale-de-la-vigne-et-du-vin',
  'organizationofthepetroleumexportingcountries',
  'organization-of-the-petroleum-exporting-countries',
  'pacificcommunity',
  'pacific-community',
  'panamericanhealthorganization',
  'pan-american-health-organization',
  'postalunionoftheamericasspainandportugal',
  'postal-union-of-the-americas-spain-and-portugal',
  'thecommonmarketforeasternandsouthernafrica',
  'the-common-market-for-eastern-and-southern-africa',
  'unionbenelux',
  'union-benelux',
  'unitednations',
  'united-nations',
  'unitednationseducationalscientificandculturalorganization',
  'unitednationsframeworkconventiononclimatechange',
  'united-nations-framework-convention-on-climate-change',
  'unitednationsindustrialdevelopmentorganization',
  'united-nations-industrial-development-organization',
  'universalpostalunion',
  'universal-postal-union',
  'worldbank',
  'world-bank',
  'worldhealthorganization',
  'world-health-organization',
  'worldintellectualpropertyorganization',
  'world-intellectual-property-organization',
  'worldmeteorologicalorganization',
  'world-meteorological-organization',
  'worldorganisationforanimalhealth',
  'world-organisation-for-animal-health',
  'worldtourismorganization',
  'world-tourism-organization',
  'worldtradeorganization',
  'world-trade-organization',
  'asian-africanlegalconsultativeorganization',
  'asian-african-legal-consultative-organization',
  'conferenceoninteractionandconfidencebuildingmeasuresinasia',
  'internationalconferenceonthegreatlakesregionofafrica',
  'international-conference-on-the-great-lakes-region-of-africa',
  'internationalcriminalpoliceorganization-interpol',
  'international-criminal-police-organization-interpol',
  'internationalfundforsavingthearalsea',
  'international-fund-for-saving-the-aral-sea',
  'internationalunionforconservationofnatureandnaturalresources',
  'organizationforsecurityandco-operationineurope',
  'organization-for-security-and-co-operation-in-europe',
  'parliamentaryassemblyofthemediterranean',
  'parliamentary-assembly-of-the-mediterranean',
  'partnersinpopulationanddevelopment',
  'partners-in-population-and-development',
  'thecommonwealth',
  'the-commonwealth',
  'universityforpeace',
  'university-for-peace',
  'internationaltradecentre',
  'international-trade-centre',
  'jointunitednationsprogrammeonhivaids',
  'joint-united-nations-programme-on-hiv-aids',
  'unitednationschildrensfund',
  'united-nations-children-s-fund',
  'unitednationsconferenceontradeanddevelopment',
  'united-nations-conference-on-trade-and-development',
  'unitednationsdevelopmentprogramme',
  'united-nations-development-programme',
  'unitednationsentityforgenderequalityandtheempowermentofwomen',
  'unitednationsenvironmentprogramme',
  'united-nations-environment-programme',
  'unitednationshighcommissionerforrefugeesunrefugeeagency',
  'unitednationshumansettlementsprogramme',
  'united-nations-human-settlements-programme',
  'unitednationsinternationalcomputingcentre',
  'united-nations-international-computing-centre',
  'unitednationsofficeforprojectservices',
  'united-nations-office-for-project-services',
  'unitednationspopulationfund',
  'united-nations-population-fund',
  'unitednationsuniversity',
  'united-nations-university',
  'worldfoodprogramme',
  'world-food-programme',
  'aalco',
  'acp',
  'acs',
  'acto',
  'adb',
  'afdb',
  'aipo',
  'aladi',
  'apo',
  'appa',
  'aripo',
  'asean',
  'au',
  'bceao',
  'benelux',
  'bie',
  'bipm',
  'bis',
  'boip',
  'bsec',
  'bstdp',
  'caf',
  'can',
  'caricom',
  'ceb',
  'cen-sad',
  'cept',
  'cern',
  'cfc',
  'cica',
  'ciheam',
  'cis',
  'coe',
  'coi',
  'comesa',
  'commonwealth',
  'cplp',
  'csto',
  'ctbto',
  'ctu',
  'eabr',
  'eac',
  'eapo',
  'ebrd',
  'ec',
  'ecb',
  'eccas',
  'ecmt',
  'ecmwf',
  'eco',
  'ecowas',
  'eeas',
  'efi',
  'efta',
  'eftasurv',
  'eib',
  'embl',
  'encharter',
  'ep',
  'epo',
  'esa',
  'eso',
  'eu',
  'euclid',
  'euco',
  'eurasec',
  'euratom',
  'eurocontrol',
  'europol',
  'fao',
  'forumsec',
  'gcc',
  'hcch',
  'iaca',
  'iadb',
  'iaea',
  'ibrd',
  'icao',
  'icc-cpi',
  'icco',
  'icglr',
  'icj-cij',
  'icmpd',
  'ico',
  'icsid',
  'ida',
  'idb',
  'idea',
  'idlo',
  'iea',
  'ifad',
  'ifas',
  'ifc',
  'igad',
  'ihffc',
  'iho',
  'iic',
  'iila',
  'ijc',
  'ilo',
  'imf',
  'imo',
  'imso',
  'interelectro',
  'interpol',
  'intracen',
  'iom',
  'iooc',
  'irena',
  'isa',
  'iso',
  'istc',
  'iter',
  'itf',
  'itlos',
  'itso',
  'itto',
  'itu',
  'iucn',
  'iwc',
  'las',
  'mercosur',
  'miga',
  'mrc',
  'nasco',
  'nato',
  'nib',
  'npi',
  'oas',
  'occar',
  'oecd',
  'oecs',
  'ofid',
  'ohada',
  'oic',
  'oie',
  'oif',
  'oiv',
  'opanal',
  'opcw',
  'opec',
  'osce',
  'paho',
  'pam',
  'parlatino',
  'pca',
  'pices',
  'ppd',
  'puasp',
  'recsa',
  'saarc',
  'sadc',
  'sco',
  'sela',
  'sica',
  'spc',
  'uemoa',
  'un',
  'un-habitat',
  'unaids',
  'unasur',
  'unccd',
  'unctad',
  'undp',
  'unep',
  'unesco',
  'unfccc',
  'unfpa',
  'unhcr',
  'unicc',
  'unicef',
  'unido',
  'unidroit',
  'unops',
  'unrwa',
  'unu',
  'unwomen',
  'unwto',
  'upeace',
  'upov',
  'upu',
  'wco',
  'wfp',
  'who',
  'wipo',
  'wmo',
  'wto',
  'redcross',
  'red-cross',
  'redcrescent',
  'red-crescent',
  'redcrystal',
  'red-crystal',
  'redlionandsun',
  'red-lion-and-sun',
  'croixrouge',
  'croix-rouge',
  'croissantrouge',
  'croissant-rouge',
  'cristalrouge',
  'cristal-rouge',
  'lionetsoleilrouge',
  'lion-et-soleil-rouge',
  'cruzroja',
  'cruz-roja',
  'medialunaroja',
  'media-luna-roja',
  'cristalrojo',
  'cristal-rojo',
  'xn--lenysolrojos-1hb',
  'xn--len-y-sol-rojos-wrb',
  'xn--80aklccqteffl6h',
  'xn----7sbqoddswffgm4i',
  'xn--80aklchgejigmhx9b1dvd',
  'xn----7sbqodjheljgniz2c2d0d',
  'xn--80aaxddcjam2aeifo3k',
  'xn----7sbb5adedkan5afigp1l',
  'xn--80aeibodfkdogkspg7fsd',
  'xn------5cdlrdweiofrjnvsj2h1d',
  'xn--igbhad1ayx3jbcn8c',
  'xn----rmckbd7a2a0a8kbdo2d',
  'xn--igbhaa5ay2kabbnq',
  'xn----rmckab1b2a0mabcor',
  'xn--ggblaaaafgcf3b2bfdu8wqafcbdx7dwb',
  'xn-----usdvabbaghdg1c7bgew00asafdbez2eyb',
  'xn--igbhbaaa4bnvpcl7rednevzl',
  'xn------6yetdbaa2dq4aseo3wgepfy3an',
  'xn--kkr01qny4a',
  'xn--efvv3a313c',
  'xn--1kvs1kn4r',
  'xn--jhqs4ylzunwk7v2a',
  'icrc',
  'cicr',
  'ifrc',
  'ficr',
  'mkkk',
  'xn--j1aae6a',
  'spr',
  'frk',
  'cvtl',
  'xn--j1afdfp',
  'xn--tgmj-6ra',
  'xn--t-cubfh',
  'xn--j1aheq',
  'acruzvermelha',
  'a-cruz-vermelha',
  'acruzvermelhabrasileira',
  'a-cruz-vermelha-brasileira',
  'acruzvermelhadecaboverde',
  'a-cruz-vermelha-de-cabo-verde',
  'xn--acruzvermelhadesotomeprncipe-rmc8p1e',
  'xn--a-cruz-vermelha-de-so-tom-e-prncipe-o6c1uwf',
  'acruzvermelhaportuguesa',
  'a-cruz-vermelha-portuguesa',
  'xn--acruzvermelhasociedadenacionaldesotomeprncipe-c0d00a1g',
  'xn--amagyarvrskereszt-6zbb',
  'xn--a-magyar-vrskereszt-z6bb',
  'xn--amagyarvrskereszttrsasg-b6be44cb',
  'xn--a-magyar-vrskereszt-trsasg-pfce60db',
  'xn--asociedadecruzvermelhadesotomeprncipe-hdd2v2f',
  'xn--a-sociedade-cruz-vermelha-de-so-tom-e-prncipe-8zd10a3g',
  'asociedadedacruzvermelhabrasileira',
  'a-sociedade-da-cruz-vermelha-brasileira',
  'asociedadedacruzvermelhadeportugal',
  'a-sociedade-da-cruz-vermelha-de-portugal',
  'xn--asociedadedacruzvermelhadesotomeprncipe-6id4wjg',
  'xn--a-sociedade-da-cruz-vermelha-de-so-tom-e-prncipe-t8d91anh',
  'asociedadedacruzvermelhaportuguesa',
  'a-sociedade-da-cruz-vermelha-portuguesa',
  'xn--asociedadenacionaldacruzvermelhadesotomeprncipe-15d21a9g',
  'xn--asociedadenacionalsotomeprncipedacruzvermelha-xzd00a1g',
  'xn--asociedadesotomeprncipecruzvermelha-f6c0uuf',
  'xn--a-sociedade-so-tom-e-prncipe-cruz-vermelha-9qd3yrg',
  'xn--avrskeresztmagyarorszgon-58b04cb',
  'xn--a-vrskereszt-magyarorszgon-rfc18cb',
  'xn--avrskereszttrsasgmagyarorszgon-jqcel00eb',
  'xn--a-vrskereszt-trsasg-magyarorszgon-wycem16eb',
  'afghanredcrescent',
  'afghan-red-crescent',
  'afghanredcrescentsociety',
  'afghan-red-crescent-society',
  'afghanistanredcrescent',
  'afghanistan-red-crescent',
  'afghanistanredcrescentsociety',
  'afghanistan-red-crescent-society',
  'agbelebupupatiomonaijiria',
  'agbelebu-pupa-ti-omo-naijiria',
  'xn--aggurazeggaazzayri-srh',
  'xn--aggur-azegga-azzayri-fki',
  'albaniaredcross',
  'albania-red-cross',
  'albaniaredcrosssociety',
  'albania-red-cross-society',
  'albanianredcross',
  'albanian-red-cross',
  'albanianredcrosssociety',
  'albanian-red-cross-society',
  'algeriaredcrescent',
  'algeria-red-crescent',
  'algeriaredcrescentsociety',
  'algeria-red-crescent-society',
  'algerianredcrescent',
  'algerian-red-crescent',
  'algerianredcrescentsociety',
  'algerian-red-crescent-society',
  'americannationalredcross',
  'american-national-red-cross',
  'americannationalredcrosssociety',
  'american-national-red-cross-society',
  'americanredcross',
  'american-red-cross',
  'americanredcrosssociety',
  'american-red-cross-society',
  'andorraredcross',
  'andorra-red-cross',
  'andorraredcrosssociety',
  'andorra-red-cross-society',
  'andorranredcross',
  'andorran-red-cross',
  'andorranredcrosssociety',
  'andorran-red-cross-society',
  'angolaredcross',
  'angola-red-cross',
  'angolaredcrosssociety',
  'angola-red-cross-society',
  'angolanredcross',
  'angolan-red-cross',
  'angolanredcrosssociety',
  'angolan-red-cross-society',
  'antiguaandbarbudaredcross',
  'antigua-and-barbuda-red-cross',
  'antiguaandbarbudaredcrosssociety',
  'antigua-and-barbuda-red-cross-society',
  'argentinaredcross',
  'argentina-red-cross',
  'argentinaredcrosssociety',
  'argentina-red-cross-society',
  'argentinenationalredcrosssociety',
  'argentine-national-red-cross-society',
  'argentineredcross',
  'argentine-red-cross',
  'argentineredcrosssociety',
  'argentine-red-cross-society',
  'argentinianredcross',
  'argentinian-red-cross',
  'argentinianredcrosssociety',
  'argentinian-red-cross-society',
  'armeniaredcross',
  'armenia-red-cross',
  'armeniaredcrosssociety',
  'armenia-red-cross-society',
  'armenianredcross',
  'armenian-red-cross',
  'armenianredcrosssociety',
  'armenian-red-cross-society',
  'xn--associaodacruzvermelhabrasileira-txc9k',
  'xn--associao-da-cruz-vermelha-brasileira-48c5m',
  'associationdelacroixrougedebelgique',
  'association-de-la-croix-rouge-de-belgique',
  'associationofthebelgianredcross',
  'association-of-the-belgian-red-cross',
  'associazionedellacrocerossaitaliana',
  'associazione-della-croce-rossa-italiana',
  'australiaredcross',
  'australia-red-cross',
  'australiaredcrosssociety',
  'australia-red-cross-society',
  'australianredcross',
  'australian-red-cross',
  'australianredcrosssociety',
  'australian-red-cross-society',
  'austriaredcross',
  'austria-red-cross',
  'austriaredcrosssociety',
  'austria-red-cross-society',
  'austrianredcross',
  'austrian-red-cross',
  'austrianredcrosssociety',
  'austrian-red-cross-society',
  'azerbaijanredcrescent',
  'azerbaijan-red-crescent',
  'azerbaijanredcrescentsociety',
  'azerbaijan-red-crescent-society',
  'azeriredcrescent',
  'azeri-red-crescent',
  'azeriredcrescentsociety',
  'azeri-red-crescent-society',
  'xn--azrbaycanqzlayparacmiyyti-10db193ftae',
  'xn--azrbaycan-qzl-aypara-cmiyyti-8geb872gwae',
  'xn--azrbaycanqzlayparas-p5cbi209d',
  'xn--azrbaycan-qzl-ayparas-ugdbj294e',
  'xn--azrbaycannqzlayparas-qbdcbi294e',
  'xn--azrbaycann-qzl-ayparas-ulddbj280f',
  'bahamasredcross',
  'bahamas-red-cross',
  'bahamasredcrosssociety',
  'bahamas-red-cross-society',
  'bahrainredcrescent',
  'bahrain-red-crescent',
  'bahrainredcrescentsociety',
  'bahrain-red-crescent-society',
  'bahrainiredcrescent',
  'bahraini-red-crescent',
  'bahrainiredcrescentsociety',
  'bahraini-red-crescent-society',
  'bangladeshnationalredcrescent',
  'bangladesh-national-red-crescent',
  'bangladeshredcrescent',
  'bangladesh-red-crescent',
  'bangladeshredcrescentsociety',
  'bangladesh-red-crescent-society',
  'baphalalieswatiniredcross',
  'baphalali-eswatini-red-cross',
  'baphalalieswatiniredcrosssociety',
  'baphalali-eswatini-red-cross-society',
  'baphalaliswazilandredcross',
  'baphalali-swaziland-red-cross',
  'baphalaliswazilandredcrosssociety',
  'baphalali-swaziland-red-cross-society',
  'barbadosredcross',
  'barbados-red-cross',
  'barbadosredcrosssociety',
  'barbados-red-cross-society',
  'belarusredcross',
  'belarus-red-cross',
  'belarusredcrosssociety',
  'belarus-red-cross-society',
  'belarusianredcross',
  'belarusian-red-cross',
  'belarusianredcrosssociety',
  'belarusian-red-cross-society',
  'belgianredcross',
  'belgian-red-cross',
  'belgianredcrossflanders',
  'belgian-red-cross-flanders',
  'belgianredcrossfrenchspeakingcommunity',
  'belgian-red-cross-french-speaking-community',
  'belgianredcrossgermanspeakingcommunity',
  'belgian-red-cross-german-speaking-community',
  'belgianredcrosssociety',
  'belgian-red-cross-society',
  'belgischenationalerotkreuzgesellschaft',
  'belgische-nationale-rotkreuzgesellschaft',
  'belgischerodekruis',
  'belgische-rode-kruis',
  'belgischerodekruisduitsegemeenschap',
  'belgische-rode-kruis-duitse-gemeenschap',
  'belgischerodekruisfranstaligegemeenschap',
  'belgische-rode-kruis-franstalige-gemeenschap',
  'belgischesroteskreuz',
  'belgisches-rotes-kreuz',
  'belgischesroteskreuzdeutschsprachigegemeinschaft',
  'belgisches-rotes-kreuz-deutschsprachige-gemeinschaft',
  'belgischesroteskreuzflandern',
  'belgisches-rotes-kreuz-flandern',
  'xn--belgischesroteskreuzfranzsischsprachigegemeinschaft-bbf',
  'belizeredcross',
  'belize-red-cross',
  'belizeredcrosssociety',
  'belize-red-cross-society',
  'belizeanredcross',
  'belizean-red-cross',
  'belizeanredcrosssociety',
  'belizean-red-cross-society',
  'beninredcross',
  'benin-red-cross',
  'beninredcrosssociety',
  'benin-red-cross-society',
  'benineseredcross',
  'beninese-red-cross',
  'benineseredcrosssociety',
  'beninese-red-cross-society',
  'biedribalatvijassarkanaiskrusts',
  'biedriba-latvijas-sarkanais-krusts',
  'bishacas',
  'bisha-cas',
  'bishacaseesomaliland',
  'bisha-cas-ee-somaliland',
  'bishacaseesoomaliya',
  'bisha-cas-ee-soomaliya',
  'bishacaseesoomaliyeed',
  'bisha-cas-ee-soomaliyeed',
  'bishacastaeesomaliland',
  'bisha-cas-ta-ee-somaliland',
  'bishacastaeesoomaaliya',
  'bisha-cas-ta-ee-soomaaliya',
  'bishacastaeesoomaliyeed',
  'bisha-cas-ta-ee-soomaliyeed',
  'boliviaredcross',
  'bolivia-red-cross',
  'boliviaredcrosssociety',
  'bolivia-red-cross-society',
  'bolivianredcross',
  'bolivian-red-cross',
  'bolivianredcrossnationalsociety',
  'bolivian-red-cross-national-society',
  'bolivianredcrosssociety',
  'bolivian-red-cross-society',
  'boliviansocietyoftheredcross',
  'bolivian-society-of-the-red-cross',
  'xn--bosanskohercegovakicrvenikri-lmd515a',
  'xn--bosanskohercegovaki-crveni-kri-nud347a',
  'xn--bosanskohercegovakicrvenikrst-mqd',
  'xn--bosanskohercegovaki-crveni-krst-oyd',
  'xn--bosanskohercegovakicrvenikrstkri-p2d179a',
  'xn--bosanskohercegovaki-crveni-krst-kri-sfe313b',
  'xn--bosanskohercegovakodrutvocrvenogkria-tje113ay6a',
  'xn--bosanskohercegovako-drutvo-crvenog-kria-wve465ai9a',
  'xn--bosanskohercegovakodrutvocrvenogkrsta-une593a',
  'xn--bosanskohercegovako-drutvo-crvenog-krsta-xze846a',
  'xn--bosanskohercegovakodrutvocrvenogkrstakria-y3e137a8qb',
  'xn--bosanskohercegovako-drutvo-crvenog-krsta-kria-2kf860bntb',
  'bosniaandherzegovinaredcross',
  'bosnia-and-herzegovina-red-cross',
  'bosniaandherzegovinaredcrosssociety',
  'bosnia-and-herzegovina-red-cross-society',
  'botswanaredcross',
  'botswana-red-cross',
  'botswanaredcrosssociety',
  'botswana-red-cross-society',
  'botswananredcross',
  'botswanan-red-cross',
  'botswananredcrosssociety',
  'botswanan-red-cross-society',
  'brazilredcross',
  'brazil-red-cross',
  'brazilredcrosssociety',
  'brazil-red-cross-society',
  'brazilianredcross',
  'brazilian-red-cross',
  'brazilianredcrossassociation',
  'brazilian-red-cross-association',
  'brazilianredcrosssociety',
  'brazilian-red-cross-society',
  'britishredcross',
  'british-red-cross',
  'britishredcrosssociety',
  'british-red-cross-society',
  'bruneidarussalamredcrescent',
  'brunei-darussalam-red-crescent',
  'bruneidarussalamredcrescentsociety',
  'brunei-darussalam-red-crescent-society',
  'bruneiredcrescent',
  'brunei-red-crescent',
  'bruneiredcrescentsociety',
  'brunei-red-crescent-society',
  'brydeinigygroesgoch',
  'brydeinig-y-groes-goch',
  'bulansabitmerah',
  'bulan-sabit-merah',
  'bulansabitmerahbrunei',
  'bulan-sabit-merah-brunei',
  'bulansabitmerahbruneidarussalam',
  'bulan-sabit-merah-brunei-darussalam',
  'bulansabitmerahmalaysia',
  'bulan-sabit-merah-malaysia',
  'bulgariaredcross',
  'bulgaria-red-cross',
  'bulgariaredcrosssociety',
  'bulgaria-red-cross-society',
  'bulgarianredcross',
  'bulgarian-red-cross',
  'bulgarianredcrosssociety',
  'bulgarian-red-cross-society',
  'burkinafasoredcross',
  'burkina-faso-red-cross',
  'burkinafasoredcrosssociety',
  'burkina-faso-red-cross-society',
  'burkinaredcross',
  'burkina-red-cross',
  'burkinaredcrosssociety',
  'burkina-red-cross-society',
  'burkinaberedcross',
  'burkinabe-red-cross',
  'burkinaberedcrosssociety',
  'burkinabe-red-cross-society',
  'burundiredcross',
  'burundi-red-cross',
  'burundiredcrosssociety',
  'burundi-red-cross-society',
  'cambodiaredcross',
  'cambodia-red-cross',
  'cambodiaredcrosssociety',
  'cambodia-red-cross-society',
  'cambodianredcross',
  'cambodian-red-cross',
  'cambodianredcrosssociety',
  'cambodian-red-cross-society',
  'cameroonredcross',
  'cameroon-red-cross',
  'cameroonredcrosssociety',
  'cameroon-red-cross-society',
  'cameroonianredcross',
  'cameroonian-red-cross',
  'cameroonianredcrosssociety',
  'cameroonian-red-cross-society',
  'canadaredcross',
  'canada-red-cross',
  'canadaredcrosssociety',
  'canada-red-cross-society',
  'canadianredcross',
  'canadian-red-cross',
  'canadianredcrosssociety',
  'canadian-red-cross-society',
  'capeverderedcross',
  'cape-verde-red-cross',
  'capeverderedcrosssociety',
  'cape-verde-red-cross-society',
  'centralafricanredcross',
  'central-african-red-cross',
  'centralafricanredcrossnationalsociety',
  'central-african-red-cross-national-society',
  'centralafricanredcrosssociety',
  'central-african-red-cross-society',
  'centralafricanrepublicredcrossnationalsociety',
  'central-african-republic-red-cross-national-society',
  'xn--ervenk-8va0kxfx5amm',
  'xn--erven-k-eza5l8f28azm',
  'xn--ervenkeskrepubliky-jwb0f2r1lia63pq0a',
  'xn--erven-k-esk-republiky-l5b1gut8mka36rw3a',
  'xn--eskervenk-o5a3of2re16i7q',
  'xn--esk-erven-k-ycb3rg3uf41kct',
  'chadredcross',
  'chad-red-cross',
  'chadredcrosssociety',
  'chad-red-cross-society',
  'chadianredcross',
  'chadian-red-cross',
  'chadianredcrosssociety',
  'chadian-red-cross-society',
  'chamachamsalabamwekundu',
  'chama-cha-msalaba-mwekundu',
  'chamachamsalabamwekunduyajamuhuriyakidemocrasiayakongo',
  'chilecruzroja',
  'chile-cruz-roja',
  'chileredcross',
  'chile-red-cross',
  'chileredcrosssociety',
  'chile-red-cross-society',
  'chileanredcross',
  'chilean-red-cross',
  'chileanredcrosssociety',
  'chilean-red-cross-society',
  'chinaredcross',
  'china-red-cross',
  'chinaredcrosssociety',
  'china-red-cross-society',
  'chinaredcrosssocietyassociation',
  'china-red-cross-society-association',
  'chineseredcross',
  'chinese-red-cross',
  'chineseredcrosssociety',
  'chinese-red-cross-society',
  'colombiaredcross',
  'colombia-red-cross',
  'colombiaredcrosssociety',
  'colombia-red-cross-society',
  'colombianredcross',
  'colombian-red-cross',
  'colombianredcrosssociety',
  'colombian-red-cross-society',
  'xn--comitinternacionaldelacruzroja-fxc',
  'xn--comit-internacional-de-la-cruz-roja-fdd',
  'xn--comitinternationaldelacroixrouge-f3c',
  'xn--comit-international-de-la-croix-rouge-fjd',
  'comorosredcrescent',
  'comoros-red-crescent',
  'comorosredcrescentsociety',
  'comoros-red-crescent-society',
  'comorosianredcrescent',
  'comorosian-red-crescent',
  'comorosianredcrescentsociety',
  'comorosian-red-crescent-society',
  'congoredcross',
  'congo-red-cross',
  'congoredcrosssociety',
  'congo-red-cross-society',
  'congoleseredcross',
  'congolese-red-cross',
  'congoleseredcrosssociety',
  'congolese-red-cross-society',
  'cookislandsredcross',
  'cook-islands-red-cross',
  'cookislandsredcrosssociety',
  'cook-islands-red-cross-society',
  'costaricaredcross',
  'costa-rica-red-cross',
  'costaricaredcrosssociety',
  'costa-rica-red-cross-society',
  'costaricanredcross',
  'costa-rican-red-cross',
  'costaricanredcrosssociety',
  'costa-rican-red-cross-society',
  'xn--ctedivoireredcrosssociety-hqc',
  'xn--cte-d-ivoire-red-cross-society-16c',
  'xn--ctedivoireredcross-91b',
  'xn--cte-d-ivoire-red-cross-jgc',
  'creurojaandorrana',
  'creu-roja-andorrana',
  'crnogorskicrvenikrst',
  'crnogorski-crveni-krst',
  'xn--crnogorskodrutvocrvenogkrsta-2lf',
  'xn--crnogorsko-drutvo-crvenog-krsta-e5f',
  'croatiaredcross',
  'croatia-red-cross',
  'croatiaredcrosssociety',
  'croatia-red-cross-society',
  'croatianredcross',
  'croatian-red-cross',
  'croatianredcrosssociety',
  'croatian-red-cross-society',
  'crocerossaitaliana',
  'croce-rossa-italiana',
  'crocerossarepubblicadisanmarino',
  'croce-rossa-repubblica-di-san-marino',
  'crocerossasvizzera',
  'croce-rossa-svizzera',
  'xn--croisdheargnahireann-o2b',
  'xn--crois-dhearg-na-hireann-rcc',
  'croissantrougecomores',
  'croissant-rouge-comores',
  'croissantrougecomorien',
  'croissant-rouge-comorien',
  'croissantrougededjibouti',
  'croissant-rouge-de-djibouti',
  'croissantrougedemauritanie',
  'croissant-rouge-de-mauritanie',
  'croissantrougedetunisie',
  'croissant-rouge-de-tunisie',
  'croissantrougedescomores',
  'croissant-rouge-des-comores',
  'croissantrougedjibouti',
  'croissant-rouge-djibouti',
  'croissantrougedjiboutien',
  'croissant-rouge-djiboutien',
  'croissantrougedudjibouti',
  'croissant-rouge-du-djibouti',
  'croissantrougedumaroc',
  'croissant-rouge-du-maroc',
  'croissantrougemaroc',
  'croissant-rouge-maroc',
  'croissantrougemarocain',
  'croissant-rouge-marocain',
  'croissantrougemauritanie',
  'croissant-rouge-mauritanie',
  'croissantrougemauritanien',
  'croissant-rouge-mauritanien',
  'croissantrougetunisie',
  'croissant-rouge-tunisie',
  'croisssantrougetunisien',
  'croisssant-rouge-tunisien',
  'croixrougebelge',
  'croix-rouge-belge',
  'xn--croixrougebnin-lkb',
  'xn--croix-rouge-bnin-nqb',
  'xn--croixrougebninoise-lwb',
  'xn--croix-rouge-bninoise-n2b',
  'croixrougeburkinafaso',
  'croix-rouge-burkina-faso',
  'xn--croixrougeburkinab-swb',
  'xn--croix-rouge-burkinab-u2b',
  'croixrougeburundi',
  'croix-rouge-burundi',
  'croixrougecamerounaise',
  'croix-rouge-camerounaise',
  'croixrougecanada',
  'croix-rouge-canada',
  'croixrougecanadienne',
  'croix-rouge-canadienne',
  'croixrougecentrafricaine',
  'croix-rouge-centrafricaine',
  'croixrougecongolaise',
  'croix-rouge-congolaise',
  'xn--croixrougectedivoire-68b',
  'xn--croix-rouge-cte-d-ivoire-inc',
  'croixrougedebelgique',
  'croix-rouge-de-belgique',
  'xn--croixrougedebelgiquecommunautflamande-3jd',
  'xn--croix-rouge-de-belgique-communaut-flamande-7yd',
  'xn--croixrougedebelgiquecommunautfrancophone-3sd',
  'xn--croix-rouge-de-belgique-communaut-francophone-77d',
  'xn--croixrougedebelgiquecommunautgermanophone-3vd',
  'xn--croix-rouge-de-belgique-communaut-germanophone-7be',
  'croixrougedeburkinafaso',
  'croix-rouge-de-burkina-faso',
  'xn--croixrougedectedivoire-vgc',
  'xn--croix-rouge-de-cte-d-ivoire-jxc',
  'croixrougedefrance',
  'croix-rouge-de-france',
  'xn--croixrougedeguine-qtb',
  'xn--croix-rouge-de-guine-t2b',
  'xn--croixrougedeguineequatoriale-qrc',
  'xn--croix-rouge-de-guine-equatoriale-t3c',
  'xn--croixrougedellemaurice-xcc',
  'xn--croix-rouge-de-l-le-maurice-rsc',
  'xn--croixrougedelarpubliquecentrafricaine-pjd',
  'xn--croix-rouge-de-la-rpublique-centrafricaine-tyd',
  'xn--croixrougedelarpubliquedmocratiqueducongo-pvdj',
  'xn--croix-rouge-de-la-rpublique-dmocratique-du-congo-thek',
  'croixrougedemadagascar',
  'croix-rouge-de-madagascar',
  'croixrougedemali',
  'croix-rouge-de-mali',
  'croixrougedemaurice',
  'croix-rouge-de-maurice',
  'croixrougedemonaco',
  'croix-rouge-de-monaco',
  'xn--croixrougedesaotometprincipe-src',
  'xn--croix-rouge-de-sao-tom-et-principe-w9c',
  'croixrougedesuisse',
  'croix-rouge-de-suisse',
  'croixrougedesseychelles',
  'croix-rouge-des-seychelles',
  'xn--croixrougedubnin-nqb',
  'xn--croix-rouge-du-bnin-qzb',
  'croixrougeduburkinafaso',
  'croix-rouge-du-burkina-faso',
  'croixrougeduburundi',
  'croix-rouge-du-burundi',
  'croixrougeducameroun',
  'croix-rouge-du-cameroun',
  'croixrougeducanada',
  'croix-rouge-du-canada',
  'croixrougeducongo',
  'croix-rouge-du-congo',
  'croixrougedugabon',
  'croix-rouge-du-gabon',
  'croixrougeduliban',
  'croix-rouge-du-liban',
  'croixrougeduluxembourg',
  'croix-rouge-du-luxembourg',
  'croixrougedumadagascar',
  'croix-rouge-du-madagascar',
  'croixrougedumali',
  'croix-rouge-du-mali',
  'croixrougeduniger',
  'croix-rouge-du-niger',
  'croixrougedurwanda',
  'croix-rouge-du-rwanda',
  'xn--croixrougedusngal-ntbb',
  'xn--croix-rouge-du-sngal-q2bb',
  'croixrougedutchad',
  'croix-rouge-du-tchad',
  'croixrougedutogo',
  'croix-rouge-du-togo',
  'xn--croixrougequatoguinenne-kccj',
  'xn--croix-rouge-quato-guinenne-mlck',
  'xn--croixrougefranaise-lvb',
  'xn--croix-rouge-franaise-j1b',
  'croixrougefrance',
  'croix-rouge-france',
  'croixrougegabonaise',
  'croix-rouge-gabonaise',
  'xn--croixrougeguine-onb',
  'xn--croix-rouge-guine-qtb',
  'xn--croixrougeguinenne-owb',
  'xn--croix-rouge-guinenne-q2b',
  'croixrougeivoirienne',
  'croix-rouge-ivoirienne',
  'croixrougelao',
  'croix-rouge-lao',
  'croixrougeliban',
  'croix-rouge-liban',
  'croixrougelibanaise',
  'croix-rouge-libanaise',
  'croixrougeluxembourg',
  'croix-rouge-luxembourg',
  'croixrougeluxembourgeoise',
  'croix-rouge-luxembourgeoise',
  'croixrougemadagascar',
  'croix-rouge-madagascar',
  'croixrougemalagasy',
  'croix-rouge-malagasy',
  'croixrougemali',
  'croix-rouge-mali',
  'croixrougemalienne',
  'croix-rouge-malienne',
  'croixrougemauricienne',
  'croix-rouge-mauricienne',
  'croixrougemonaco',
  'croix-rouge-monaco',
  'xn--croixrougemongasque-nzb',
  'xn--croix-rouge-mongasque-p5b',
  'croixrougeniger',
  'croix-rouge-niger',
  'xn--croixrougenigrienne-nzb',
  'xn--croix-rouge-nigrienne-p5b',
  'xn--croixrougerougehatienne-vgc',
  'xn--croix-rouge-rouge-hatienne-hqc',
  'croixrougerwanda',
  'croix-rouge-rwanda',
  'croixrougerwandaise',
  'croix-rouge-rwandaise',
  'xn--croixrougesngal-lnbb',
  'xn--croix-rouge-sngal-ntbb',
  'xn--croixrougesngalaise-lzbb',
  'xn--croix-rouge-sngalaise-n5bb',
  'croixrougeseychelles',
  'croix-rouge-seychelles',
  'croixrougesuisse',
  'croix-rouge-suisse',
  'croixrougetchadienne',
  'croix-rouge-tchadienne',
  'croixrougetogo',
  'croix-rouge-togo',
  'croixrougetogolaise',
  'croix-rouge-togolaise',
  'croixrougeyurwanda',
  'croix-rouge-y-u-rwanda',
  'croixrougeyuburundi',
  'croix-rouge-y-uburundi',
  'xn--crucearoiedinmoldova-w9g',
  'xn--crucea-roie-din-moldova-z9h',
  'xn--crucearoiedinromnia-qvb03x',
  'xn--crucea-roie-din-romnia-73b301a',
  'xn--crucearoiemoldova-u9f',
  'xn--crucea-roie-moldova-8xg',
  'xn--crucearoieromn-nhb34cg4c',
  'xn--crucea-roie-romn-bnb90dl8c',
  'crusvermelhaangola',
  'crus-vermelha-angola',
  'cruschcotschnadalasvizra',
  'crusch-cotschna-da-la-svizra',
  'cruschcotschnasvizra',
  'crusch-cotschna-svizra',
  'cruzrojaamericana',
  'cruz-roja-americana',
  'cruzrojaargentina',
  'cruz-roja-argentina',
  'cruzrojaboliviana',
  'cruz-roja-boliviana',
  'cruzrojachilena',
  'cruz-roja-chilena',
  'cruzrojacolombiana',
  'cruz-roja-colombiana',
  'cruzrojacostarricense',
  'cruz-roja-costarricense',
  'cruzrojacubana',
  'cruz-roja-cubana',
  'xn--cruzrojadeamrica-mqb',
  'xn--cruz-roja-de-amrica-pzb',
  'cruzrojadeargentina',
  'cruz-roja-de-argentina',
  'cruzrojadechile',
  'cruz-roja-de-chile',
  'cruzrojadecolombia',
  'cruz-roja-de-colombia',
  'cruzrojadecuba',
  'cruz-roja-de-cuba',
  'cruzrojadeguineaecuatorial',
  'cruz-roja-de-guinea-ecuatorial',
  'xn--cruzrojadehait-emb',
  'xn--cruz-roja-de-hait-tvb',
  'cruzrojadeparaguay',
  'cruz-roja-de-paraguay',
  'cruzrojadeuruguay',
  'cruz-roja-de-uruguay',
  'cruzrojadevenezuela',
  'cruz-roja-de-venezuela',
  'cruzrojadelparaguay',
  'cruz-roja-del-paraguay',
  'cruzrojadominicana',
  'cruz-roja-dominicana',
  'cruzrojaecuatoriana',
  'cruz-roja-ecuatoriana',
  'xn--cruzrojaespaola-9qb',
  'xn--cruz-roja-espaola-sxb',
  'cruzrojaguatemalteca',
  'cruz-roja-guatemalteca',
  'cruzrojahaitiana',
  'cruz-roja-haitiana',
  'xn--cruzrojahondurea-lub',
  'xn--cruz-roja-hondurea-30b',
  'cruzrojamexicana',
  'cruz-roja-mexicana',
  'cruzrojanacionalamericana',
  'cruz-roja-nacional-americana',
  'xn--cruzrojanicaragense-kbc',
  'xn--cruz-roja-nicaragense-pic',
  'xn--cruzrojapanamea-crb',
  'xn--cruz-roja-panamea-uxb',
  'cruzrojaparaguay',
  'cruz-roja-paraguay',
  'cruzrojaparaguaya',
  'cruz-roja-paraguaya',
  'cruzrojaperuana',
  'cruz-roja-peruana',
  'xn--cruzrojasalvadorea-30b',
  'xn--cruz-roja-salvadorea-m7b',
  'cruzrojasociedaddechile',
  'cruz-roja-sociedad-de-chile',
  'cruzrojauruguaya',
  'cruz-roja-uruguaya',
  'cruzrojavenezolana',
  'cruz-roja-venezolana',
  'cruzvermelha',
  'cruz-vermelha',
  'cruzvermelhabrasileira',
  'cruz-vermelha-brasileira',
  'xn--cruzvermelhadaguinbissau-sfc',
  'xn--cruz-vermelha-da-guin-bissau-vrc',
  'cruzvermelhadeangola',
  'cruz-vermelha-de-angola',
  'cruzvermelhadecaboverde',
  'cruz-vermelha-de-cabo-verde',
  'cruzvermelhademocambique',
  'cruz-vermelha-de-mocambique',
  'cruzvermelhadeportugal',
  'cruz-vermelha-de-portugal',
  'xn--cruzvermelhadesotomeprncipe-wjc2pxe',
  'xn--cruz-vermelha-de-so-tom-e-prncipe-y0c9stf',
  'cruzvermelhadetimorleste',
  'cruz-vermelha-de-timor-leste',
  'cruzvermelhamocambicana',
  'cruz-vermelha-mocambicana',
  'cruzvermelhamozambique',
  'cruz-vermelha-mozambique',
  'cruzvermelhaportuguesa',
  'cruz-vermelha-portuguesa',
  'xn--crvenikri-3cc',
  'xn--crveni-kri-ekc',
  'xn--crvenikribosneihercegovine-hvf',
  'xn--crveni-kri-bosne-i-hercegovine-jpg',
  'xn--crvenikrihrvatske-5zd',
  'xn--crveni-kri-hrvatske-pfe',
  'xn--crvenikrirepublikehrvatske-hvf',
  'xn--crveni-kri-republike-hrvatske-9hg',
  'crvenikrst',
  'crveni-krst',
  'crvenikrstbosneihercegovine',
  'crveni-krst-bosne-i-hercegovine',
  'crvenikrstcrnegore',
  'crveni-krst-crne-gore',
  'crvenikrstrepublikesrbije',
  'crveni-krst-republike-srbije',
  'crvenikrstsrbije',
  'crveni-krst-srbije',
  'xn--crvenikrstkri-85c',
  'xn--crveni-krst-kri-tld',
  'xn--crvenikrstkribosneihercegovine-mpg',
  'xn--crveni-krst-kri-bosne-i-hercegovine-yqh',
  'cubaredcross',
  'cuba-red-cross',
  'cubaredcrosssociety',
  'cuba-red-cross-society',
  'cubanredcross',
  'cuban-red-cross',
  'cubanredcrosssociety',
  'cuban-red-cross-society',
  'cymdeithasbrydeinigygroesgoch',
  'cymdeithas-brydeinig-y-groes-goch',
  'cypriotredcross',
  'cypriot-red-cross',
  'cypriotredcrosssociety',
  'cypriot-red-cross-society',
  'cyprusredcross',
  'cyprus-red-cross',
  'cyprusredcrosssociety',
  'cyprus-red-cross-society',
  'czechredcross',
  'czech-red-cross',
  'czechredcrosssociety',
  'czech-red-cross-society',
  'czechrepublicredcross',
  'czech-republic-red-cross',
  'czechrepublicredcrosssociety',
  'czech-republic-red-cross-society',
  'xn--czerwonykrzywpolsce-mee',
  'xn--czerwony-krzy-w-polsce-8ze',
  'danishredcross',
  'danish-red-cross',
  'danishredcrosssociety',
  'danish-red-cross-society',
  'xn--danskrdekors-0jb',
  'xn--dansk-rde-kors-wqb',
  'dasbelgischerotekreuz',
  'das-belgische-rote-kreuz',
  'dasdeutscherotekreuz',
  'das-deutsche-rote-kreuz',
  'dasoesterreichischerotekreuz',
  'das-oesterreichische-rote-kreuz',
  'xn--dassterreichischerotekreuz-evc',
  'xn--das-sterreichische-rote-kreuz-j5c',
  'dasrotekreuzbelgien',
  'das-rote-kreuz-belgien',
  'dasrotekreuzderschweiz',
  'das-rote-kreuz-der-schweiz',
  'dasrotekreuzdeutschland',
  'das-rote-kreuz-deutschland',
  'dasrotekreuzindeutschland',
  'das-rote-kreuz-in-deutschland',
  'xn--dasrotekreuzinsterreich-llc',
  'xn--das-rote-kreuz-in-sterreich-6yc',
  'dasschweizerischerotekreuz',
  'das-schweizerische-rote-kreuz',
  'debelgischerodekruisvereniging',
  'de-belgische-rode-kruis-vereniging',
  'xn--denationalerodekruisverenigingvanbelgi-lpd',
  'xn--de-nationale-rode-kruisvereniging-van-belgi-04d',
  'xn--deverenigingvanhetrodekruisvanbelgi-cgd',
  'xn--de-vereniging-van-het-rode-kruis-van-belgi-x1d',
  'xn--demsterreichischenrotenkreuz-41c',
  'xn--dem-sterreichischen-roten-kreuz-9cd',
  'democraticpeoplesrepublicofkorearedcross',
  'democratic-people-s-republic-of-korea-red-cross',
  'democraticpeoplesrepublicofkorearedcrosssociety',
  'democratic-people-s-republic-of-korea-red-cross-society',
  'democraticrepublicofthecongoredcross',
  'democratic-republic-of-the-congo-red-cross',
  'democraticrepublicofthecongoredcrosssociety',
  'democratic-republic-of-the-congo-red-cross-society',
  'denmarkredcross',
  'denmark-red-cross',
  'denmarkredcrosssociety',
  'denmark-red-cross-society',
  'xn--dessterreichischenrotenkreuzes-v8c',
  'xn--des-sterreichischen-roten-kreuzes-0jd',
  'deutscherotkreuzgesellschaft',
  'deutsche-rotkreuz-gesellschaft',
  'deutschesroteskreuz',
  'deutsches-rotes-kreuz',
  'diebelgischenationalerotkreuzgesellschaft',
  'die-belgische-nationale-rotkreuzgesellschaft',
  'diedeutscherotkreuzgesellschaft',
  'die-deutsche-rotkreuz-gesellschaft',
  'diegesellschaftvomrotenkreuzinoesterreich',
  'die-gesellschaft-vom-roten-kreuz-in-oesterreich',
  'xn--diegesellschaftvomrotenkreuzinsterreich-z4d',
  'xn--die-gesellschaft-vom-roten-kreuz-in-sterreich-eqe',
  'dienationalerotkreuzgesellschaftderschweiz',
  'die-nationale-rotkreuzgesellschaft-der-schweiz',
  'dienationalerotkreuzgesellschaftvonbelgien',
  'die-nationale-rotkreuzgesellschaft-von-belgien',
  'xn--diesterreichischerotkreuzgesellschaft-hxd',
  'xn--die-sterreichische-rotkreuz-gesellschaft-m7d',
  'dierotkreuzgesellschaftindeutschland',
  'die-rotkreuz-gesellschaft-in-deutschland',
  'xn--dierotkreuzgesellschaftinsterreich-znd',
  'xn--die-rotkreuz-gesellschaft-in-sterreich-l1d',
  'dieschweizerischenationalerotkreuzgesellschaft',
  'die-schweizerische-nationale-rotkreuzgesellschaft',
  'dievereinigungvomrotenkreuzvonbelgien',
  'die-vereinigung-vom-roten-kreuz-von-belgien',
  'djiboutiredcrescent',
  'djibouti-red-crescent',
  'djiboutiredcrescentsociety',
  'djibouti-red-crescent-society',
  'djiboutianredcrescent',
  'djiboutian-red-crescent',
  'djiboutianredcrescentsociety',
  'djiboutian-red-crescent-society',
  'dominicaredcross',
  'dominica-red-cross',
  'dominicaredcrosssociety',
  'dominica-red-cross-society',
  'dominicanredcross',
  'dominican-red-cross',
  'dominicanredcrosssociety',
  'dominican-red-cross-society',
  'dominicanrepublicredcross',
  'dominican-republic-red-cross',
  'dominicanrepublicredcrosssociety',
  'dominican-republic-red-cross-society',
  'dominiquaisredcross',
  'dominiquais-red-cross',
  'dominiquaisredcrosssociety',
  'dominiquais-red-cross-society',
  'xn--drutvocrvenogkria-2kd87c',
  'xn--drutvo-crvenog-kria-xxd83d',
  'xn--drutvocrvenogkriabosneihercegovine-dpg17h',
  'xn--drutvo-crvenog-kria-bosne-i-hercegovine-imh81j',
  'xn--drutvocrvenogkriahrvatske-i1e01f',
  'xn--drutvo-crvenog-kria-hrvatske-slf99f',
  'xn--drutvocrvenogkriarepublikehrvatske-dpg17h',
  'xn--drutvo-crvenog-kria-republike-hrvatske-2fh98i',
  'xn--drutvocrvenogkrsta-ird',
  'xn--drutvo-crvenog-krsta-d4d',
  'xn--drutvocrvenogkrstabosneihercegovine-svg',
  'xn--drutvo-crvenog-krsta-bosne-i-hercegovine-xsh',
  'xn--drutvocrvenogkrstacrnegore-x7e',
  'xn--drutvo-crvenog-krsta-crne-gore-nyf',
  'xn--drutvocrvenogkrstasrbije-2ue',
  'xn--drutvo-crvenog-krsta-srbije-dff',
  'xn--drutvocrvenogkrstakria-7he82e',
  'xn--drutvo-crvenog-krsta-kria-i1e81f',
  'xn--drutvocrvenogkrstakriabosneihercegovine-imh12j',
  'xn--drutvo-crvenog-krsta-kria-bosne-i-hercegovine-2pi89k',
  'xn--drutvohrvatskogcrvenogkria-x7e84f',
  'xn--drutvo-hrvatskog-crvenog-kria-7rf83g',
  'xn--drutvordeegakriaslovenije-98c57tuxa',
  'xn--drutvo-rdeega-kria-slovenije-emd62we0a',
  'xn--drutvoslovenskegardeegakria-nid23vtza',
  'xn--drutvo-slovenskega-rdeega-kria-sud28xe2a',
  'xn--drutvosrbijanskogcrvenogkrsta-7rf',
  'xn--drutvo-srbijanskog-crvenog-krsta-icg',
  'xn--drutvosrpskogcrvenogkrsta-i1e',
  'xn--drutvo-srpskog-crvenog-krsta-slf',
  'dutchredcross',
  'dutch-red-cross',
  'dutchredcrosssociety',
  'dutch-red-cross-society',
  'ecuadorredcross',
  'ecuador-red-cross',
  'ecuadorredcrosssociety',
  'ecuador-red-cross-society',
  'ecuadorianredcross',
  'ecuadorian-red-cross',
  'ecuadorianredcrosssociety',
  'ecuadorian-red-cross-society',
  'ecuadoriansocietyoftheredcross',
  'ecuadorian-society-of-the-red-cross',
  'eestipunanerist',
  'eesti-punane-rist',
  'eestipunaseristiselts',
  'eesti-punase-risti-selts',
  'egbeagbelegbupupailunaijiria',
  'egbe-agbelegbu-pupa-ilu-naijiria',
  'egyptredcrescent',
  'egypt-red-crescent',
  'egyptredcrescentsociety',
  'egypt-red-crescent-society',
  'egyptianredcrescent',
  'egyptian-red-crescent',
  'egyptianredcrescentsociety',
  'egyptian-red-crescent-society',
  'elsalvadorredcross',
  'el-salvador-red-cross',
  'elsalvadorredcrosssociety',
  'el-salvador-red-cross-society',
  'emiratesredcrescentauthority',
  'emirates-red-crescent-authority',
  'emiratiredcrescent',
  'emirati-red-crescent',
  'emiratiredcrescentauthority',
  'emirati-red-crescent-authority',
  'emiratiredcrescentsociety',
  'emirati-red-crescent-society',
  'equatorialguinearedcross',
  'equatorial-guinea-red-cross',
  'equatorialguinearedcrosssociety',
  'equatorial-guinea-red-cross-society',
  'equatorialguineanredcross',
  'equatorial-guinean-red-cross',
  'equatorialguineanredcrosssociety',
  'equatorial-guinean-red-cross-society',
  'estoniaredcross',
  'estonia-red-cross',
  'estoniaredcrosssociety',
  'estonia-red-cross-society',
  'estonianredcross',
  'estonian-red-cross',
  'estonianredcrosssociety',
  'estonian-red-cross-society',
  'eswatiniredcross',
  'eswatini-red-cross',
  'eswatiniredcrosssociety',
  'eswatini-red-cross-society',
  'ethiopiaredcross',
  'ethiopia-red-cross',
  'ethiopiaredcrosssociety',
  'ethiopia-red-cross-society',
  'ethiopiannationalredcross',
  'ethiopian-national-red-cross',
  'ethiopiannationalredcrosssociety',
  'ethiopian-national-red-cross-society',
  'ethiopianredcross',
  'ethiopian-red-cross',
  'ethiopianredcrosssociety',
  'ethiopian-red-cross-society',
  'fapotopotogasautaulokulaotuvalu',
  'fapotopotoga-sautaulo-kula-o-tuvalu',
  'fijiredcross',
  'fiji-red-cross',
  'fijiredcrosssociety',
  'fiji-red-cross-society',
  'fijianredcross',
  'fijian-red-cross',
  'fijianredcrosssociety',
  'fijian-red-cross-society',
  'filipinoredcross',
  'filipino-red-cross',
  'filipinoredcrosssociety',
  'filipino-red-cross-society',
  'finlandredcross',
  'finland-red-cross',
  'finlandredcrosssociety',
  'finland-red-cross-society',
  'xn--finlandsrdakors-htb',
  'xn--finlands-rda-kors-8zb',
  'xn--finlandsrdakorset-7zb',
  'xn--finlands-rda-korset-z6b',
  'finnishredcross',
  'finnish-red-cross',
  'finnishredcrosssociety',
  'finnish-red-cross-society',
  'xn--finskardakors-omb',
  'xn--finska-rda-kors-gtb',
  'xn--finskardakorset-ftb',
  'xn--finska-rda-korset-6zb',
  'xn--freningensvenskardakors-7kcq',
  'xn--freningen-svenska-rda-kors-cvcs',
  'xn--freningensvenskardakorset-yrcq',
  'xn--freningen-svenska-rda-korset-21cs',
  'xn--freningensverigardakors-7kcq',
  'xn--freningen-sveriga-rda-kors-cvcs',
  'xn--freningensverigardakorset-yrcq',
  'xn--freningen-sveriga-rda-korset-21cs',
  'franceredcross',
  'france-red-cross',
  'franceredcrosssociety',
  'france-red-cross-society',
  'frenchredcross',
  'french-red-cross',
  'frenchredcrosssociety',
  'french-red-cross-society',
  'gabonredcross',
  'gabon-red-cross',
  'gabonredcrosssociety',
  'gabon-red-cross-society',
  'gaboneseredcross',
  'gabonese-red-cross',
  'gaboneseredcrosssociety',
  'gabonese-red-cross-society',
  'gambiaredcross',
  'gambia-red-cross',
  'gambiaredcrosssociety',
  'gambia-red-cross-society',
  'gambianredcross',
  'gambian-red-cross',
  'gambianredcrosssociety',
  'gambian-red-cross-society',
  'georgiaredcross',
  'georgia-red-cross',
  'georgiaredcrosssociety',
  'georgia-red-cross-society',
  'georgianredcross',
  'georgian-red-cross',
  'georgianredcrosssociety',
  'georgian-red-cross-society',
  'germanredcross',
  'german-red-cross',
  'germanredcrosssociety',
  'german-red-cross-society',
  'germanyredcross',
  'germany-red-cross',
  'germanyredcrosssociety',
  'germany-red-cross-society',
  'ghanaredcross',
  'ghana-red-cross',
  'ghanaredcrosssociety',
  'ghana-red-cross-society',
  'ghanaianredcross',
  'ghanaian-red-cross',
  'ghanaianredcrosssociety',
  'ghanaian-red-cross-society',
  'greeceredcross',
  'greece-red-cross',
  'greeceredcrosssociety',
  'greece-red-cross-society',
  'greekredcross',
  'greek-red-cross',
  'greekredcrosssociety',
  'greek-red-cross-society',
  'grenadaredcross',
  'grenada-red-cross',
  'grenadaredcrosssociety',
  'grenada-red-cross-society',
  'grenadanredcross',
  'grenadan-red-cross',
  'grenadanredcrosssociety',
  'grenadan-red-cross-society',
  'grenadianredcross',
  'grenadian-red-cross',
  'grenadianredcrosssociety',
  'grenadian-red-cross-society',
  'guatemalaredcross',
  'guatemala-red-cross',
  'guatemalaredcrosssociety',
  'guatemala-red-cross-society',
  'guatemalanredcross',
  'guatemalan-red-cross',
  'guatemalanredcrosssociety',
  'guatemalan-red-cross-society',
  'guineabissauredcross',
  'guinea-bissau-red-cross',
  'guineabissauredcrosssociety',
  'guinea-bissau-red-cross-society',
  'guinearedcross',
  'guinea-red-cross',
  'guinearedcrosssociety',
  'guinea-red-cross-society',
  'guineanredcross',
  'guinean-red-cross',
  'guineanredcrosssociety',
  'guinean-red-cross-society',
  'guyanaredcross',
  'guyana-red-cross',
  'guyanaredcrosssociety',
  'guyana-red-cross-society',
  'guyaneseredcross',
  'guyanese-red-cross',
  'guyaneseredcrosssociety',
  'guyanese-red-cross-society',
  'haitiredcross',
  'haiti-red-cross',
  'haitiredcrosssociety',
  'haiti-red-cross-society',
  'haitianredcross',
  'haitian-red-cross',
  'haitianredcrosssociety',
  'haitian-red-cross-society',
  'hellenicredcross',
  'hellenic-red-cross',
  'hellenicredcrosssociety',
  'hellenic-red-cross-society',
  'hetbelgischerodekruis',
  'het-belgische-rode-kruis',
  'hetnederlandserodekruis',
  'het-nederlandse-rode-kruis',
  'hetrodekruis',
  'het-rode-kruis',
  'xn--hetrodekruisvanbelgi-13b',
  'xn--het-rode-kruis-van-belgi-ehc',
  'hetsurinaamserodekruis',
  'het-surinaamse-rode-kruis',
  'xn--hichthpvitnam-hxb2184i9lane7gvq',
  'xn--hi-ch-thp--vit-nam-9ic1172m1parfsimu',
  'honduranredcross',
  'honduran-red-cross',
  'honduranredcrosssociety',
  'honduran-red-cross-society',
  'hondurasredcross',
  'honduras-red-cross',
  'hondurasredcrosssociety',
  'honduras-red-cross-society',
  'xn--hrvatskicrvenikri-e0d',
  'xn--hrvatski-crveni-kri-yfe',
  'hungarianredcross',
  'hungarian-red-cross',
  'hungarianredcrosssociety',
  'hungarian-red-cross-society',
  'hungaryredcross',
  'hungary-red-cross',
  'hungaryredcrosssociety',
  'hungary-red-cross-society',
  'icelandredcross',
  'iceland-red-cross',
  'icelandredcrosssociety',
  'iceland-red-cross-society',
  'icelandicredcross',
  'icelandic-red-cross',
  'icelandicredcrosssociety',
  'icelandic-red-cross-society',
  'ilunaijirapupaagbelebu',
  'ilu-naijira-pupa-agbelebu',
  'ilunaijirapupaagbelebuegbe',
  'ilu-naijira-pupa-agbelebu-egbe',
  'indiaredcross',
  'india-red-cross',
  'indiaredcrosssociety',
  'india-red-cross-society',
  'indianredcross',
  'indian-red-cross',
  'indianredcrosssociety',
  'indian-red-cross-society',
  'indonesiaredcross',
  'indonesia-red-cross',
  'indonesiaredcrosssociety',
  'indonesia-red-cross-society',
  'indonesianredcross',
  'indonesian-red-cross',
  'indonesianredcrosssociety',
  'indonesian-red-cross-society',
  'internationalcommitteeoftheredcross',
  'international-committee-of-the-red-cross',
  'internationalfederationofredcrossandredcrescentsocieties',
  'internationalmovementoftheredcrossandredcrescent',
  'international-movement-of-the-red-cross-and-red-crescent',
  'iranredcrescent',
  'iran-red-crescent',
  'iranredcrescentsociety',
  'iran-red-crescent-society',
  'iranianredcrescent',
  'iranian-red-crescent',
  'iranianredcrescentsociety',
  'iranian-red-crescent-society',
  'iraqredcrescent',
  'iraq-red-crescent',
  'iraqredcrescentsociety',
  'iraq-red-crescent-society',
  'iraqiredcrescent',
  'iraqi-red-crescent',
  'iraqiredcrescentsociety',
  'iraqi-red-crescent-society',
  'irelandredcross',
  'ireland-red-cross',
  'irelandredcrosssociety',
  'ireland-red-cross-society',
  'irishredcross',
  'irish-red-cross',
  'irishredcrosssociety',
  'irish-red-cross-society',
  'islamicrepublicofiranredcrescent',
  'islamic-republic-of-iran-red-crescent',
  'islamicrepublicofiranredcrescentsociety',
  'islamic-republic-of-iran-red-crescent-society',
  'italianredcross',
  'italian-red-cross',
  'italianredcrosssociety',
  'italian-red-cross-society',
  'italyredcross',
  'italy-red-cross',
  'italyredcrosssociety',
  'italy-red-cross-society',
  'ivorianredcross',
  'ivorian-red-cross',
  'ivorianredcrosssociety',
  'ivorian-red-cross-society',
  'ivorycoastredcross',
  'ivory-coast-red-cross',
  'ivorycoastredcrosssociety',
  'ivory-coast-red-cross-society',
  'jamaicaredcross',
  'jamaica-red-cross',
  'jamaicaredcrosssociety',
  'jamaica-red-cross-society',
  'jamaicanredcross',
  'jamaican-red-cross',
  'jamaicanredcrosssociety',
  'jamaican-red-cross-society',
  'japanredcross',
  'japan-red-cross',
  'japanredcrosssociety',
  'japan-red-cross-society',
  'japaneseredcross',
  'japanese-red-cross',
  'japaneseredcrosssociety',
  'japanese-red-cross-society',
  'jordannationalredcrescentsociety',
  'jordan-national-red-crescent-society',
  'jordanredcrescent',
  'jordan-red-crescent',
  'jordanredcrescentsociety',
  'jordan-red-crescent-society',
  'jordanianredcrescent',
  'jordanian-red-crescent',
  'jordanianredcrescentsociety',
  'jordanian-red-crescent-society',
  'kauvelataidamudamuiviti',
  'kauvelatai-damudamu-i-viti',
  'kazakhredcrescent',
  'kazakh-red-crescent',
  'kazakhredcrescentsociety',
  'kazakh-red-crescent-society',
  'kazakhstanredcrescent',
  'kazakhstan-red-crescent',
  'kazakhstanredcrescentsociety',
  'kazakhstan-red-crescent-society',
  'kenyaredcross',
  'kenya-red-cross',
  'kenyaredcrosssociety',
  'kenya-red-cross-society',
  'kenyanredcross',
  'kenyan-red-cross',
  'kenyanredcrosssociety',
  'kenyan-red-cross-society',
  'kimvukayakulunsiyambwakiyakidemokratiayakongo',
  'kimvuka-ya-kulunsi-ya-mbwaki-ya-kidemokratia-ya-kongo',
  'kiribatikaibangakiuraura',
  'kiribati-kaibangaki-uraura',
  'kiribatiredcross',
  'kiribati-red-cross',
  'kiribatiredcrosssociety',
  'kiribati-red-cross-society',
  'kiribatianredcross',
  'kiribatian-red-cross',
  'kiribatianredcrosssociety',
  'kiribatian-red-cross-society',
  'xn--kzlay-n4ab',
  'kolosikulatonga',
  'kolosi-kula-tonga',
  'kolusemumuisamoa',
  'koluse-mumu-i-samoa',
  'koreanredcross',
  'korean-red-cross',
  'koreanredcrosssociety',
  'korean-red-cross-society',
  'kouloussoutigbengbatibeafrica',
  'kouloussou-ti-gbengba-ti-be-africa',
  'kouloussoutigbengbatikodorosessetibeafrica',
  'kouloussou-ti-gbengba-ti-kodoro-sesse-ti-be-africa',
  'xn--kruqiikuqirepublikssmaqedonissveriut-widcjb',
  'xn--kruqi-i-kuq-i-republiks-s-maqedonis-s-veriut-h7ddkc',
  'krusnapulaorpulangkrus',
  'krus-na-pula-or-pulang-krus',
  'xn--kryqiikuqiishrepubliksjugosllavetmaqedonis-c1dmj',
  'xn--kryqi-i-kuq-i-ish-republiks-jugosllave-t-maqedonis-xqeok',
  'xn--kryqiikuqimaqedonis-y0b',
  'xn--kryqi-i-kuq-i-maqedonis-bec',
  'xn--kryqiikuqirepublikssmaqedonis-iwccj',
  'xn--kryqi-i-kuq-i-republiks-s-maqedonis-yfddk',
  'xn--kryqiikuqirepublikssshqipris-gtccfd',
  'xn--kryqi-i-kuq-i-republiks-s-shqipris-wcddgd',
  'xn--kryqiikuqishqipris-sxbd',
  'xn--kryqi-i-kuq-i-shqipris-49bd',
  'kryqiikuqmaqedonas',
  'kryqi-i-kuq-maqedonas',
  'kryqiikuqshqiptar',
  'kryqi-i-kuq-shqiptar',
  'kulunsiyambwakiyakidemokratiayakongo',
  'kulunsi-ya-mbwaki-ya-kidemokratia-ya-kongo',
  'kuluzemotaneyambokakongodemocratiki',
  'kuluze-motane-ya-mboka-kongo-democratiki',
  'kungiyarredkurosdinnijeriya',
  'kungiyar-red-kuros-din-nijeriya',
  'kungiyarredkurostanijeriya',
  'kungiyar-red-kuros-ta-nijeriya',
  'kurusupyta',
  'kurusu-pyta',
  'kuruzupyta',
  'kuruzu-pyta',
  'kuwaitredcrescent',
  'kuwait-red-crescent',
  'kuwaitredcrescentsociety',
  'kuwait-red-crescent-society',
  'kuwaitiredcrescent',
  'kuwaiti-red-crescent',
  'kuwaitiredcrescentsociety',
  'kuwaiti-red-crescent-society',
  'kwawoujayisyen',
  'kwawouj-ayisyen',
  'kyrgyzredcrescent',
  'kyrgyz-red-crescent',
  'kyrgyzredcrescentsociety',
  'kyrgyz-red-crescent-society',
  'kyrgyzstanredcrescent',
  'kyrgyzstan-red-crescent',
  'kyrgyzstanredcrescentsociety',
  'kyrgyzstan-red-crescent-society',
  'lassociationdelacroixrougedebelgique',
  'l-association-de-la-croix-rouge-de-belgique',
  'lacrocerossasvizzera',
  'la-croce-rossa-svizzera',
  'lacroixrougebelge',
  'la-croix-rouge-belge',
  'xn--lacroixrougebnin-nqb',
  'xn--la-croix-rouge-bnin-qzb',
  'xn--lacroixrougebninoise-n2b',
  'xn--la-croix-rouge-bninoise-qcc',
  'lacroixrougeburkinafaso',
  'la-croix-rouge-burkina-faso',
  'xn--lacroixrougeburkinab-u2b',
  'xn--la-croix-rouge-burkinab-xcc',
  'lacroixrougeburundi',
  'la-croix-rouge-burundi',
  'lacroixrougecamerounaise',
  'la-croix-rouge-camerounaise',
  'lacroixrougecanada',
  'la-croix-rouge-canada',
  'lacroixrougecanadienne',
  'la-croix-rouge-canadienne',
  'lacroixrougecentrafricaine',
  'la-croix-rouge-centrafricaine',
  'lacroixrougecongolaise',
  'la-croix-rouge-congolaise',
  'xn--lacroixrougectedivoire-vgc',
  'xn--la-croix-rouge-cte-d-ivoire-jxc',
  'lacroixrougedebelgique',
  'la-croix-rouge-de-belgique',
  'lacroixrougedeburkinafaso',
  'la-croix-rouge-de-burkina-faso',
  'xn--lacroixrougedectedivoire-knc',
  'xn--la-croix-rouge-de-cte-d-ivoire-k7c',
  'lacroixrougedefrance',
  'la-croix-rouge-de-france',
  'xn--lacroixrougedeguine-szb',
  'xn--la-croix-rouge-de-guine-wcc',
  'xn--lacroixrougedeguineequatoriale-sxc',
  'xn--la-croix-rouge-de-guine-equatoriale-wdd',
  'xn--lacroixrougedellemaurice-9ic',
  'xn--la-croix-rouge-de-l-le-maurice-91c',
  'xn--lacroixrougedelarpubliquecentrafricaine-rpd',
  'xn--la-croix-rouge-de-la-rpublique-centrafricaine-w7d',
  'xn--lacroixrougedelarpubliquedmocratiqueducongo-r1dj',
  'xn--la-croix-rouge-de-la-rpublique-dmocratique-du-congo-wqek',
  'lacroixrougedemadagascar',
  'la-croix-rouge-de-madagascar',
  'lacroixrougedemali',
  'la-croix-rouge-de-mali',
  'lacroixrougedemaurice',
  'la-croix-rouge-de-maurice',
  'lacroixrougedemonaco',
  'la-croix-rouge-de-monaco',
  'xn--lacroixrougedesaotometprincipe-uxc',
  'xn--la-croix-rouge-de-sao-tom-et-principe-zjd',
  'xn--lacroixrougedesotometprncipe-qmc8p2e',
  'xn--la-croix-rouge-de-so-tom-et-prncipe-n6c1uxf',
  'lacroixrougedesuisse',
  'la-croix-rouge-de-suisse',
  'lacroixrougedesseychelles',
  'la-croix-rouge-des-seychelles',
  'xn--lacroixrougedubnin-pwb',
  'xn--la-croix-rouge-du-bnin-t8b',
  'lacroixrougeduburkinafaso',
  'la-croix-rouge-du-burkina-faso',
  'lacroixrougeduburundi',
  'la-croix-rouge-du-burundi',
  'lacroixrougeducameroun',
  'la-croix-rouge-du-cameroun',
  'lacroixrougeducanada',
  'la-croix-rouge-du-canada',
  'lacroixrougeducongo',
  'la-croix-rouge-du-congo',
  'lacroixrougedugabon',
  'la-croix-rouge-du-gabon',
  'lacroixrougeduliban',
  'la-croix-rouge-du-liban',
  'lacroixrougeduluxembourg',
  'la-croix-rouge-du-luxembourg',
  'lacroixrougedumadagascar',
  'la-croix-rouge-du-madagascar',
  'lacroixrougedumali',
  'la-croix-rouge-du-mali',
  'lacroixrougeduniger',
  'la-croix-rouge-du-niger',
  'lacroixrougedurwanda',
  'la-croix-rouge-du-rwanda',
  'xn--lacroixrougedusngal-pzbb',
  'xn--la-croix-rouge-du-sngal-tccb',
  'lacroixrougedutchad',
  'la-croix-rouge-du-tchad',
  'lacroixrougedutogo',
  'la-croix-rouge-du-togo',
  'xn--lacroixrougequatoguinenne-micj',
  'xn--la-croix-rouge-quato-guinenne-puck',
  'xn--lacroixrougefranaise-j1b',
  'xn--la-croix-rouge-franaise-gbc',
  'lacroixrougefrance',
  'la-croix-rouge-france',
  'lacroixrougegabonaise',
  'la-croix-rouge-gabonaise',
  'xn--lacroixrougeguine-qtb',
  'xn--la-croix-rouge-guine-t2b',
  'xn--lacroixrougeguinenne-q2b',
  'xn--la-croix-rouge-guinenne-tcc',
  'xn--lacroixrougehatienne-95b',
  'xn--la-croix-rouge-hatienne-vgc',
  'lacroixrougeivoirienne',
  'la-croix-rouge-ivoirienne',
  'lacroixrougeliban',
  'la-croix-rouge-liban',
  'lacroixrougelibanaise',
  'la-croix-rouge-libanaise',
  'lacroixrougeluxembourg',
  'la-croix-rouge-luxembourg',
  'lacroixrougeluxembourgeoise',
  'la-croix-rouge-luxembourgeoise',
  'lacroixrougemadagascar',
  'la-croix-rouge-madagascar',
  'lacroixrougemalagasy',
  'la-croix-rouge-malagasy',
  'lacroixrougemali',
  'la-croix-rouge-mali',
  'lacroixrougemalienne',
  'la-croix-rouge-malienne',
  'lacroixrougemauricienne',
  'la-croix-rouge-mauricienne',
  'lacroixrougemonaco',
  'la-croix-rouge-monaco',
  'xn--lacroixrougemongasque-p5b',
  'xn--la-croix-rouge-mongasque-sfc',
  'lacroixrougeniger',
  'la-croix-rouge-niger',
  'xn--lacroixrougenigrienne-p5b',
  'xn--la-croix-rouge-nigrienne-sfc',
  'lacroixrougerwanda',
  'la-croix-rouge-rwanda',
  'lacroixrougerwandaise',
  'la-croix-rouge-rwandaise',
  'xn--lacroixrougesngal-ntbb',
  'xn--la-croix-rouge-sngal-q2bb',
  'xn--lacroixrougesngalaise-n5bb',
  'xn--la-croix-rouge-sngalaise-qfcb',
  'lacroixrougeseychelles',
  'la-croix-rouge-seychelles',
  'lacroixrougesuisse',
  'la-croix-rouge-suisse',
  'lacroixrougetchadienne',
  'la-croix-rouge-tchadienne',
  'lacroixrougetogo',
  'la-croix-rouge-togo',
  'lacroixrougetogolaise',
  'la-croix-rouge-togolaise',
  'lacruschcotschnadalasvizra',
  'la-crusch-cotschna-da-la-svizra',
  'lacruschcotschnasvizra',
  'la-crusch-cotschna-svizra',
  'lacruzrojaamericana',
  'la-cruz-roja-americana',
  'lacruzrojaargentina',
  'la-cruz-roja-argentina',
  'lacruzrojaboliviana',
  'la-cruz-roja-boliviana',
  'lacruzrojachile',
  'la-cruz-roja-chile',
  'lacruzrojachilena',
  'la-cruz-roja-chilena',
  'lacruzrojacolombiana',
  'la-cruz-roja-colombiana',
  'lacruzrojacostarricense',
  'la-cruz-roja-costarricense',
  'lacruzrojacubana',
  'la-cruz-roja-cubana',
  'xn--lacruzrojadeamrica-owb',
  'xn--la-cruz-roja-de-amrica-s8b',
  'lacruzrojadeargentina',
  'la-cruz-roja-de-argentina',
  'lacruzrojadecolombia',
  'la-cruz-roja-de-colombia',
  'lacruzrojadeguineaecuatorial',
  'la-cruz-roja-de-guinea-ecuatorial',
  'lacruzrojadeparaguay',
  'la-cruz-roja-de-paraguay',
  'lacruzrojadevenezuela',
  'la-cruz-roja-de-venezuela',
  'lacruzrojadelparaguay',
  'la-cruz-roja-del-paraguay',
  'lacruzrojadominicana',
  'la-cruz-roja-dominicana',
  'lacruzrojaecuatoriana',
  'la-cruz-roja-ecuatoriana',
  'xn--lacruzrojaespaola-sxb',
  'xn--la-cruz-roja-espaola-k7b',
  'lacruzrojanacionalamericana',
  'la-cruz-roja-nacional-americana',
  'xn--lacruzrojanicaragense-pic',
  'xn--la-cruz-roja-nicaragense-ftc',
  'lacruzrojaparaguaya',
  'la-cruz-roja-paraguaya',
  'lacruzrojaperuana',
  'la-cruz-roja-peruana',
  'xn--lacruzrojasalvadorea-m7b',
  'xn--la-cruz-roja-salvadorea-eic',
  'lacruzrojauruguaya',
  'la-cruz-roja-uruguaya',
  'lacruzrojavenezolana',
  'la-cruz-roja-venezolana',
  'lasociedadargentinadelacruzroja',
  'la-sociedad-argentina-de-la-cruz-roja',
  'lasociedaddelacruzrojaamericana',
  'la-sociedad-de-la-cruz-roja-americana',
  'xn--lasociedaddelacruzrojadeamrica-0xc',
  'xn--la-sociedad-de-la-cruz-roja-de-amrica-7jd',
  'lasociedaddelacruzrojadeargentina',
  'la-sociedad-de-la-cruz-roja-de-argentina',
  'lasociedaddelacruzrojadecolombia',
  'la-sociedad-de-la-cruz-roja-de-colombia',
  'lasociedaddelacruzrojadeguineaecuatorial',
  'la-sociedad-de-la-cruz-roja-de-guinea-ecuatorial',
  'lasociedaddelacruzrojadevenezuela',
  'la-sociedad-de-la-cruz-roja-de-venezuela',
  'lasociedaddelacruzrojaecuatorialdeguinea',
  'la-sociedad-de-la-cruz-roja-ecuatorial-de-guinea',
  'lasociedaddelacruzrojaecuatoriana',
  'la-sociedad-de-la-cruz-roja-ecuatoriana',
  'lasociedaddelacruzrojanacionalamericana',
  'la-sociedad-de-la-cruz-roja-nacional-americana',
  'lasociedadnacionalargentinadelacruzroja',
  'la-sociedad-nacional-argentina-de-la-cruz-roja',
  'lasociedadnacionaldelacruzrojachilena',
  'la-sociedad-nacional-de-la-cruz-roja-chilena',
  'lasociedadnacionaldelacruzrojadeargentina',
  'la-sociedad-nacional-de-la-cruz-roja-de-argentina',
  'lasociedadnacionaldelacruzrojadeguineaecuatorial',
  'la-sociedad-nacional-de-la-cruz-roja-de-guinea-ecuatorial',
  'lasociedadnacionaldelacruzrojadominicana',
  'la-sociedad-nacional-de-la-cruz-roja-dominicana',
  'lasociedadnacionaldelacruzrojaguatemalteca',
  'la-sociedad-nacional-de-la-cruz-roja-guatemalteca',
  'xn--lasociedadnacionaldelacruzrojapanamea-zsd',
  'xn--la-sociedad-nacional-de-la-cruz-roja-panamea-sge',
  'xn--lasocietnazionaledellacrocerossainsvizzera-gnd',
  'xn--la-societ-nazionale-della-croce-rossa-in-svizzera-o6d',
  'xn--lasocietnazionaledellacrocerossasvizzera-yhd',
  'xn--la-societ-nazionale-della-croce-rossa-svizzera-gyd',
  'lasocietadnaziunaladalacruschcotschnadalasvizra',
  'la-societad-naziunala-da-la-crusch-cotschna-da-la-svizra',
  'lasocietadnaziunaladalacruschcotschnasvizra',
  'la-societad-naziunala-da-la-crusch-cotschna-svizra',
  'xn--lasocitcroixrougedeguineequatoriale-gddbq',
  'xn--la-socit-croix-rouge-de-guine-equatoriale-hvdbu',
  'xn--lasocitcroixrougedesaotometprincipe-gddbs',
  'xn--la-socit-croix-rouge-de-sao-tom-et-principe-h1dbx',
  'xn--lasocitdelacroixrougedeguineequatoriale-gpdbu',
  'xn--la-socit-de-la-croix-rouge-de-guine-equatoriale-heeb0a',
  'xn--lasocitdelacroixrougedesaotometprincipe-gpdbw',
  'xn--la-socit-de-la-croix-rouge-de-sao-tom-et-principe-hkeb3a',
  'xn--lasocitdelacroixrougequatoguinenne-g9cboj',
  'xn--la-socit-de-la-croix-rouge-quato-guinenne-hvdbtk',
  'xn--lasocitnationalecroixrougedesaotometprincipe-g4db1a',
  'xn--lasocitnationaledecroixrougedectedivoire-gsdb11c',
  'xn--la-socit-nationale-de-croix-rouge-de-cte-d-ivoire-hkeb61d',
  'xn--lasocitnationaledecroixrougedutogo-g9cb',
  'xn--la-socit-nationale-de-croix-rouge-du-togo-hvdb',
  'xn--lasocitnationaledelacroixrougebelge-gddb',
  'xn--la-socit-nationale-de-la-croix-rouge-belge-hydb',
  'xn--lasocitnationaledelacroixrougebnin-g9cby',
  'xn--la-socit-nationale-de-la-croix-rouge-bnin-hvdb4a',
  'xn--lasocitnationaledelacroixrougebninoise-gmdby',
  'xn--la-socit-nationale-de-la-croix-rouge-bninoise-h7db4a',
  'xn--lasocitnationaledelacroixrougeburkinafaso-gvdb',
  'xn--la-socit-nationale-de-la-croix-rouge-burkina-faso-hkeb',
  'xn--lasocitnationaledelacroixrougeburkinab-gmdb5a',
  'xn--la-socit-nationale-de-la-croix-rouge-burkinab-h7db1b',
  'xn--lasocitnationaledelacroixrougecamerounaise-gydb',
  'xn--la-socit-nationale-de-la-croix-rouge-camerounaise-hkeb',
  'xn--lasocitnationaledelacroixrougecanada-ggdb',
  'xn--la-socit-nationale-de-la-croix-rouge-canada-h1db',
  'xn--lasocitnationaledelacroixrougecanadienne-gsdb',
  'xn--la-socit-nationale-de-la-croix-rouge-canadienne-heeb',
  'xn--lasocitnationaledelacroixrougecentrafricaine-g4db',
  'xn--la-socit-nationale-de-la-croix-rouge-centrafricaine-hqeb',
  'xn--lasocitnationaledelacroixrougecongolaise-gsdb',
  'xn--la-socit-nationale-de-la-croix-rouge-congolaise-heeb',
  'xn--lasocitnationaledelacroixrougectedivoire-gsdb11c',
  'xn--la-socit-nationale-de-la-croix-rouge-cte-d-ivoire-hkeb61d',
  'xn--lasocitnationaledelacroixrougedebelgique-gsdb',
  'xn--la-socit-nationale-de-la-croix-rouge-de-belgique-hheb',
  'xn--lasocitnationaledelacroixrougedeburkinafaso-g1db',
  'xn--la-socit-nationale-de-la-croix-rouge-de-burkina-faso-hteb',
  'xn--lasocitnationaledelacroixrougedectedivoire-gydb53c',
  'xn--lasocitnationaledelacroixrougedefrance-gmdb',
  'xn--la-socit-nationale-de-la-croix-rouge-de-france-hbeb',
  'xn--lasocitnationaledelacroixrougedeguine-gjdb3a',
  'xn--la-socit-nationale-de-la-croix-rouge-de-guine-h7db0b',
  'xn--lasocitnationaledelacroixrougedeguineequatoriale-gheb3a',
  'xn--lasocitnationaledelacroixrougedellemaurice-gydb5w',
  'xn--lasocitnationaledelacroixrougedelaguineequatoriale-gneb5a',
  'xn--lasocitnationaledelacroixrougedemadagascar-gydb',
  'xn--la-socit-nationale-de-la-croix-rouge-de-madagascar-hneb',
  'xn--lasocitnationaledelacroixrougedemali-ggdb',
  'xn--la-socit-nationale-de-la-croix-rouge-de-mali-h4db',
  'xn--lasocitnationaledelacroixrougedemaurice-gpdb',
  'xn--la-socit-nationale-de-la-croix-rouge-de-maurice-heeb',
  'xn--lasocitnationaledelacroixrougedemonaco-gmdb',
  'xn--la-socit-nationale-de-la-croix-rouge-de-monaco-hbeb',
  'xn--lasocitnationaledelacroixrougedesaotometprincipe-gheb5a',
  'xn--lasocitnationaledelacroixrougedesuisse-gmdb',
  'xn--la-socit-nationale-de-la-croix-rouge-de-suisse-hbeb',
  'xn--lasocitnationaledelacroixrougedesseychelles-g1db',
  'xn--la-socit-nationale-de-la-croix-rouge-des-seychelles-hqeb',
  'xn--lasocitnationaledelacroixrougedubnin-ggdb0a',
  'xn--la-socit-nationale-de-la-croix-rouge-du-bnin-h4db7a',
  'xn--lasocitnationaledelacroixrougeduburundi-gpdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-burundi-heeb',
  'xn--lasocitnationaledelacroixrougeducameroun-gsdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-cameroun-hheb',
  'xn--lasocitnationaledelacroixrougeducanada-gmdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-canada-hbeb',
  'xn--lasocitnationaledelacroixrougeducongo-gjdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-congo-h7db',
  'xn--lasocitnationaledelacroixrougedugabon-gjdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-gabon-h7db',
  'xn--lasocitnationaledelacroixrougeduliban-gjdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-liban-h7db',
  'xn--lasocitnationaledelacroixrougeduluxembourg-gydb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-luxembourg-hneb',
  'xn--lasocitnationaledelacroixrougedumadagascar-gydb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-madagascar-hneb',
  'xn--lasocitnationaledelacroixrougedumali-ggdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-mali-h4db',
  'xn--lasocitnationaledelacroixrougeduniger-gjdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-niger-h7db',
  'xn--lasocitnationaledelacroixrougedurwanda-gmdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-rwanda-hbeb',
  'xn--lasocitnationaledelacroixrougedusngal-gjdb0ab',
  'xn--la-socit-nationale-de-la-croix-rouge-du-sngal-h7db7ab',
  'xn--lasocitnationaledelacroixrougedutchad-gjdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-tchad-h7db',
  'xn--lasocitnationaledelacroixrougedutogo-ggdb',
  'xn--la-socit-nationale-de-la-croix-rouge-du-togo-h4db',
  'xn--lasocitnationaledelacroixrougefranaise-0kd5cb',
  'xn--la-socit-nationale-de-la-croix-rouge-franaise-t5d3db',
  'xn--lasocitnationaledelacroixrougefrance-ggdb',
  'xn--la-socit-nationale-de-la-croix-rouge-france-h1db',
  'xn--lasocitnationaledelacroixrougegabonaise-gpdb',
  'xn--la-socit-nationale-de-la-croix-rouge-gabonaise-hbeb',
  'xn--lasocitnationaledelacroixrougeguine-gddb1a',
  'xn--la-socit-nationale-de-la-croix-rouge-guine-hydb7a',
  'xn--lasocitnationaledelacroixrougeivoirienne-gsdb',
  'xn--la-socit-nationale-de-la-croix-rouge-ivoirienne-heeb',
  'xn--lasocitnationaledelacroixrougelibanaise-gpdb',
  'xn--la-socit-nationale-de-la-croix-rouge-libanaise-hbeb',
  'xn--lasocitnationaledelacroixrougeluxembourg-gsdb',
  'xn--la-socit-nationale-de-la-croix-rouge-luxembourg-heeb',
  'xn--lasocitnationaledelacroixrougeluxembourgeoise-g7db',
  'xn--la-socit-nationale-de-la-croix-rouge-luxembourgeoise-hteb',
  'xn--lasocitnationaledelacroixrougemadagascar-gsdb',
  'xn--la-socit-nationale-de-la-croix-rouge-madagascar-heeb',
  'xn--lasocitnationaledelacroixrougemalagasy-gmdb',
  'xn--la-socit-nationale-de-la-croix-rouge-malagasy-h7db',
  'xn--lasocitnationaledelacroixrougemali-g9cb',
  'xn--la-socit-nationale-de-la-croix-rouge-mali-hvdb',
  'xn--lasocitnationaledelacroixrougemalienne-gmdb',
  'xn--la-socit-nationale-de-la-croix-rouge-malienne-h7db',
  'xn--lasocitnationaledelacroixrougemauricienne-gvdb',
  'xn--la-socit-nationale-de-la-croix-rouge-mauricienne-hheb',
  'xn--lasocitnationaledelacroixrougemonaco-ggdb',
  'xn--la-socit-nationale-de-la-croix-rouge-monaco-h1db',
  'xn--lasocitnationaledelacroixrougemongasque-gpdb0a',
  'xn--la-socit-nationale-de-la-croix-rouge-mongasque-hbeb6a',
  'xn--lasocitnationaledelacroixrougeniger-gddb',
  'xn--la-socit-nationale-de-la-croix-rouge-niger-hydb',
  'xn--lasocitnationaledelacroixrougenigrienne-gpdb0a',
  'xn--la-socit-nationale-de-la-croix-rouge-nigrienne-hbeb6a',
  'xn--lasocitnationaledelacroixrougerwanda-ggdb',
  'xn--la-socit-nationale-de-la-croix-rouge-rwanda-h1db',
  'xn--lasocitnationaledelacroixrougerwandaise-gpdb',
  'xn--la-socit-nationale-de-la-croix-rouge-rwandaise-hbeb',
  'xn--lasocitnationaledelacroixrougesngal-gddbyb',
  'xn--la-socit-nationale-de-la-croix-rouge-sngal-hydb4ab',
  'xn--lasocitnationaledelacroixrougesngalaise-gpdbyb',
  'xn--la-socit-nationale-de-la-croix-rouge-sngalaise-hbeb4ab',
  'xn--lasocitnationaledelacroixrougeseychelles-gsdb',
  'xn--la-socit-nationale-de-la-croix-rouge-seychelles-heeb',
  'xn--lasocitnationaledelacroixrougesuisse-ggdb',
  'xn--la-socit-nationale-de-la-croix-rouge-suisse-h1db',
  'xn--lasocitnationaledelacroixrougetchadienne-gsdb',
  'xn--la-socit-nationale-de-la-croix-rouge-tchadienne-heeb',
  'xn--lasocitnationaledelacroixrougetogo-g9cb',
  'xn--la-socit-nationale-de-la-croix-rouge-togo-hvdb',
  'xn--lasocitnationaledelacroixrougetogolaise-gpdb',
  'xn--la-socit-nationale-de-la-croix-rouge-togolaise-hbeb',
  'xn--lasocitnationaledesotometprncipedelacroixrouge-r2d8ybr2s',
  'xn--lasocitnationaleducroissantrougecomores-gpdb',
  'xn--la-socit-nationale-du-croissant-rouge-comores-h7db',
  'xn--lasocitnationaleducroissantrougecomorien-gsdb',
  'xn--la-socit-nationale-du-croissant-rouge-comorien-hbeb',
  'xn--lasocitnationaleducroissantrougededjibouti-gydb',
  'xn--la-socit-nationale-du-croissant-rouge-de-djibouti-hkeb',
  'xn--lasocitnationaleducroissantrougedemauritanie-g4db',
  'xn--la-socit-nationale-du-croissant-rouge-de-mauritanie-hqeb',
  'xn--lasocitnationaleducroissantrougedetunisie-gvdb',
  'xn--la-socit-nationale-du-croissant-rouge-de-tunisie-hheb',
  'xn--lasocitnationaleducroissantrougedescomores-gydb',
  'xn--la-socit-nationale-du-croissant-rouge-des-comores-hkeb',
  'xn--lasocitnationaleducroissantrougedjibouti-gsdb',
  'xn--la-socit-nationale-du-croissant-rouge-djibouti-hbeb',
  'xn--lasocitnationaleducroissantrougedjiboutien-gydb',
  'xn--la-socit-nationale-du-croissant-rouge-djiboutien-hheb',
  'xn--lasocitnationaleducroissantrougedudjibouti-gydb',
  'xn--la-socit-nationale-du-croissant-rouge-du-djibouti-hkeb',
  'xn--lasocitnationaleducroissantrougedumaroc-gpdb',
  'xn--la-socit-nationale-du-croissant-rouge-du-maroc-hbeb',
  'xn--lasocitnationaleducroissantrougemaroc-gjdb',
  'xn--la-socit-nationale-du-croissant-rouge-maroc-h1db',
  'xn--lasocitnationaleducroissantrougemarocain-gsdb',
  'xn--la-socit-nationale-du-croissant-rouge-marocain-hbeb',
  'xn--lasocitnationaleducroissantrougemauritanie-gydb',
  'xn--la-socit-nationale-du-croissant-rouge-mauritanie-hheb',
  'xn--lasocitnationaleducroissantrougemauritanien-g1db',
  'xn--la-socit-nationale-du-croissant-rouge-mauritanien-hkeb',
  'xn--lasocitnationaleducroissantrougetunisie-gpdb',
  'xn--la-socit-nationale-du-croissant-rouge-tunisie-h7db',
  'xn--lasocitnationaleducroissantrougetunisien-gsdb',
  'xn--la-socit-nationale-du-croissant-rouge-tunisien-hbeb',
  'xn--landsflagrauakrossins-g5b1o',
  'xn--landsflag-raua-krossins-gcc6p',
  'xn--landsflagrauakrossinsslandi-hic0t6end',
  'xn--landsflag-raua-krossins--slandi-ntc9vvftd',
  'laoredcross',
  'lao-red-cross',
  'laoredcrosssociety',
  'lao-red-cross-society',
  'laosredcross',
  'laos-red-cross',
  'laosredcrosssociety',
  'laos-red-cross-society',
  'latviaredcross',
  'latvia-red-cross',
  'latviaredcrosssociety',
  'latvia-red-cross-society',
  'latvianredcross',
  'latvian-red-cross',
  'latvianredcrosssociety',
  'latvian-red-cross-society',
  'latvijassarkanaiskrusts',
  'latvijas-sarkanais-krusts',
  'lecroissantrougecomores',
  'le-croissant-rouge-comores',
  'lecroissantrougecomorien',
  'le-croissant-rouge-comorien',
  'lecroissantrougededjibouti',
  'le-croissant-rouge-de-djibouti',
  'lecroissantrougedemauritanie',
  'le-croissant-rouge-de-mauritanie',
  'lecroissantrougedetunisie',
  'le-croissant-rouge-de-tunisie',
  'lecroissantrougedescomores',
  'le-croissant-rouge-des-comores',
  'lecroissantrougedjibouti',
  'le-croissant-rouge-djibouti',
  'lecroissantrougedjiboutien',
  'le-croissant-rouge-djiboutien',
  'lecroissantrougedudjibouti',
  'le-croissant-rouge-du-djibouti',
  'lecroissantrougedumaroc',
  'le-croissant-rouge-du-maroc',
  'lecroissantrougemaroc',
  'le-croissant-rouge-maroc',
  'lecroissantrougemarocain',
  'le-croissant-rouge-marocain',
  'lecroissantrougemauritanie',
  'le-croissant-rouge-mauritanie',
  'lecroissantrougemauritanien',
  'le-croissant-rouge-mauritanien',
  'lecroissantrougetunisie',
  'le-croissant-rouge-tunisie',
  'lecroisssantrougetunisien',
  'le-croisssant-rouge-tunisien',
  'lebaneseredcross',
  'lebanese-red-cross',
  'lebaneseredcrosssociety',
  'lebanese-red-cross-society',
  'lebanonredcross',
  'lebanon-red-cross',
  'lebanonredcrosssociety',
  'lebanon-red-cross-society',
  'lesothanredcross',
  'lesothan-red-cross',
  'lesothanredcrosssociety',
  'lesothan-red-cross-society',
  'lesothoredcross',
  'lesotho-red-cross',
  'lesothoredcrosssociety',
  'lesotho-red-cross-society',
  'xn--ltzebuergerroutkriz-wwb4k',
  'xn--ltzebuerger-rout-kriz-o2b6l',
  'liberianationalredcrosssociety',
  'liberia-national-red-cross-society',
  'liberiaredcross',
  'liberia-red-cross',
  'liberiaredcrosssociety',
  'liberia-red-cross-society',
  'liberianredcross',
  'liberian-red-cross',
  'liberianredcrosssociety',
  'liberian-red-cross-society',
  'libyaredcrescent',
  'libya-red-crescent',
  'libyaredcrescentsociety',
  'libya-red-crescent-society',
  'libyanredcrescent',
  'libyan-red-crescent',
  'libyanredcrescentsociety',
  'libyan-red-crescent-society',
  'liechtensteinredcross',
  'liechtenstein-red-cross',
  'liechtensteinredcrosssociety',
  'liechtenstein-red-cross-society',
  'liechtensteinischesroteskreuz',
  'liechtensteinisches-rotes-kreuz',
  'xn--lietuvosraudonasiskryius-bhf',
  'xn--lietuvos-raudonasis-kryius-vvf',
  'xn--lietuvosraudonojokryiausdraugija-c4g',
  'xn--lietuvos-raudonojo-kryiaus-draugija-5qh',
  'xn--lietuvosraudonojokryiausorganizacija-dyh',
  'xn--lietuvos-raudonojo-kryiaus-organizacija-6ki',
  'lisangayakuluzemotaneyambokakongodemocratiki',
  'lisanga-ya-kuluze-motane-ya-mboka-kongo-democratiki',
  'lithuaniaredcross',
  'lithuania-red-cross',
  'lithuaniaredcrosssociety',
  'lithuania-red-cross-society',
  'lithuanianredcross',
  'lithuanian-red-cross',
  'lithuanianredcrossorganisation',
  'lithuanian-red-cross-organisation',
  'lithuanianredcrosssociety',
  'lithuanian-red-cross-society',
  'luxembourgredcross',
  'luxembourg-red-cross',
  'luxembourgredcrosssociety',
  'luxembourg-red-cross-society',
  'luxembourgianredcross',
  'luxembourgian-red-cross',
  'luxembourgianredcrosssociety',
  'luxembourgian-red-cross-society',
  'luxemburgerroteskreuz',
  'luxemburger-rotes-kreuz',
  'macedoniaredcross',
  'macedonia-red-cross',
  'macedoniaredcrosssociety',
  'macedonia-red-cross-society',
  'macedonianredcross',
  'macedonian-red-cross',
  'macedonianredcrosssociety',
  'macedonian-red-cross-society',
  'madagascarredcross',
  'madagascar-red-cross',
  'madagascarredcrosssociety',
  'madagascar-red-cross-society',
  'magendavidadom',
  'magen-david-adom',
  'magendavidadominisrael',
  'magen-david-adom-in-israel',
  'magendavidadominisraelsociety',
  'magen-david-adom-in-israel-society',
  'xn--magyarvrskereszt-swbb',
  'xn--magyar-vrskereszt-6zbb',
  'xn--magyarvrskereszttrsasg-i3be32cb',
  'xn--magyar-vrskereszt-trsasg-38be46cb',
  'malagasyredcross',
  'malagasy-red-cross',
  'malagasyredcrosssociety',
  'malagasy-red-cross-society',
  'malawiredcross',
  'malawi-red-cross',
  'malawiredcrosssociety',
  'malawi-red-cross-society',
  'malawianredcross',
  'malawian-red-cross',
  'malawianredcrosssociety',
  'malawian-red-cross-society',
  'malaysiaredcrescent',
  'malaysia-red-crescent',
  'malaysiaredcrescentsociety',
  'malaysia-red-crescent-society',
  'malaysianredcrescent',
  'malaysian-red-crescent',
  'malaysianredcrescentsociety',
  'malaysian-red-crescent-society',
  'maldivesredcrescent',
  'maldives-red-crescent',
  'maldivesredcrescentsociety',
  'maldives-red-crescent-society',
  'maldivianredcrescent',
  'maldivian-red-crescent',
  'maldivianredcrescentsociety',
  'maldivian-red-crescent-society',
  'maliredcross',
  'mali-red-cross',
  'maliredcrosssociety',
  'mali-red-cross-society',
  'malianredcross',
  'malian-red-cross',
  'malianredcrosssociety',
  'malian-red-cross-society',
  'maltaredcross',
  'malta-red-cross',
  'maltaredcrosssociety',
  'malta-red-cross-society',
  'malteseredcross',
  'maltese-red-cross',
  'malteseredcrosssociety',
  'maltese-red-cross-society',
  'marshallislandsburorodrebaldoulul',
  'marshall-islands-buroro-drebal-doulul',
  'marshallislandsredcross',
  'marshall-islands-red-cross',
  'marshallislandsredcrosssociety',
  'marshall-islands-red-cross-society',
  'marshalleseredcross',
  'marshallese-red-cross',
  'marshalleseredcrosssociety',
  'marshallese-red-cross-society',
  'mauritaniaredcrescent',
  'mauritania-red-crescent',
  'mauritaniaredcrescentsociety',
  'mauritania-red-crescent-society',
  'mauritanianredcrescent',
  'mauritanian-red-crescent',
  'mauritanianredcrescentsociety',
  'mauritanian-red-crescent-society',
  'mauritianredcross',
  'mauritian-red-cross',
  'mauritianredcrosssociety',
  'mauritian-red-cross-society',
  'mauritiusredcross',
  'mauritius-red-cross',
  'mauritiusredcrosssociety',
  'mauritius-red-cross-society',
  'mexicanredcross',
  'mexican-red-cross',
  'mexicanredcrosssociety',
  'mexican-red-cross-society',
  'mexicoredcross',
  'mexico-red-cross',
  'mexicoredcrosssociety',
  'mexico-red-cross-society',
  'micronesiaredcross',
  'micronesia-red-cross',
  'micronesiaredcrosssociety',
  'micronesia-red-cross-society',
  'micronesianredcross',
  'micronesian-red-cross',
  'micronesianredcrosssociety',
  'micronesian-red-cross-society',
  'moldovaredcross',
  'moldova-red-cross',
  'moldovaredcrosssociety',
  'moldova-red-cross-society',
  'moldovanredcross',
  'moldovan-red-cross',
  'moldovanredcrosssociety',
  'moldovan-red-cross-society',
  'monacanredcross',
  'monacan-red-cross',
  'monacanredcrosssociety',
  'monacan-red-cross-society',
  'monacoredcross',
  'monaco-red-cross',
  'monacoredcrosssociety',
  'monaco-red-cross-society',
  'mongoliaredcross',
  'mongolia-red-cross',
  'mongoliaredcrosssociety',
  'mongolia-red-cross-society',
  'mongolianredcross',
  'mongolian-red-cross',
  'mongolianredcrosssociety',
  'mongolian-red-cross-society',
  'montenegrinredcross',
  'montenegrin-red-cross',
  'montenegrinredcrosssociety',
  'montenegrin-red-cross-society',
  'montenegroredcross',
  'montenegro-red-cross',
  'montenegroredcrosssociety',
  'montenegro-red-cross-society',
  'moroccanredcrescent',
  'moroccan-red-crescent',
  'moroccanredcrescentsociety',
  'moroccan-red-crescent-society',
  'moroccoredcrescent',
  'morocco-red-crescent',
  'moroccoredcrescentsociety',
  'morocco-red-crescent-society',
  'mouvementinternationaldelacroix-rougeetducroissant-rouge',
  'movimientointernacionaldelacruzrojaydelamedialunaroja',
  'mozambicanredcross',
  'mozambican-red-cross',
  'mozambicanredcrosssociety',
  'mozambican-red-cross-society',
  'mozambiqueredcross',
  'mozambique-red-cross',
  'mozambiqueredcrosssociety',
  'mozambique-red-cross-society',
  'msalabamwekundu',
  'msalaba-mwekundu',
  'msalabamwekundutanzania',
  'msalaba-mwekundu-tanzania',
  'msalabamwekunduyajamuhuriyakidemocrasiayakongo',
  'msalaba-mwekundu-ya-jamuhuri-ya-kidemocrasia-ya-kongo',
  'myanmarredcross',
  'myanmar-red-cross',
  'myanmarredcrosssociety',
  'myanmar-red-cross-society',
  'myanmareseredcross',
  'myanmarese-red-cross',
  'myanmareseredcrosssociety',
  'myanmarese-red-cross-society',
  'naijiriapupaagbelebu',
  'naijiria-pupa-agbelebu',
  'naijiriauhieobe',
  'naijiria-uhie-obe',
  'naijirianuhieobe',
  'naijirian-uhie-obe',
  'namibiaredcross',
  'namibia-red-cross',
  'namibiaredcrosssociety',
  'namibia-red-cross-society',
  'namibianredcross',
  'namibian-red-cross',
  'namibianredcrosssociety',
  'namibian-red-cross-society',
  'xn--nasjonalforeningennorgesrdekors-pfd',
  'xn--nasjonalforeningen-norges-rde-kors-1pd',
  'nationalredcrescentofturkmenistan',
  'national-red-crescent-of-turkmenistan',
  'nationalredcrescentsocietyofkyrgyzstan',
  'national-red-crescent-society-of-kyrgyzstan',
  'nationalredcrescentsocietyofturkmenistan',
  'national-red-crescent-society-of-turkmenistan',
  'nationalredcrosssocietyofargentina',
  'national-red-cross-society-of-argentina',
  'nationalredcrosssocietyofbenin',
  'national-red-cross-society-of-benin',
  'nationalredcrosssocietyofburkinafaso',
  'national-red-cross-society-of-burkina-faso',
  'xn--nationalredcrosssocietyofctedivoire-cpd',
  'xn--national-red-cross-society-of-cte-d-ivoire-ode',
  'nationalredcrosssocietyofivorycoast',
  'national-red-cross-society-of-ivory-coast',
  'nationalredcrosssocietyofliberia',
  'national-red-cross-society-of-liberia',
  'nationalredcrosssocietyoftogo',
  'national-red-cross-society-of-togo',
  'nationalsocietyofthecameroonianredcross',
  'national-society-of-the-cameroonian-red-cross',
  'nationalsocietyofthecongoleseredcross',
  'national-society-of-the-congolese-red-cross',
  'nationalsocietyoftheecuadorianredcross',
  'national-society-of-the-ecuadorian-red-cross',
  'nationalsocietyofthegaboneseredcross',
  'national-society-of-the-gabonese-red-cross',
  'nationalsocietyofthemalianredcross',
  'national-society-of-the-malian-red-cross',
  'nationalsocietyoftheredcrossofcamerooon',
  'national-society-of-the-red-cross-of-camerooon',
  'nationalsocietyoftheredcrossofcongo',
  'national-society-of-the-red-cross-of-congo',
  'nationalsocietyoftheredcrossofequatorialguinea',
  'national-society-of-the-red-cross-of-equatorial-guinea',
  'nationalsocietyoftheredcrossofgabon',
  'national-society-of-the-red-cross-of-gabon',
  'nationalsocietyoftheredcrossofmali',
  'national-society-of-the-red-cross-of-mali',
  'nationalsocietyoftheredcrossofsaotomeandprincipe',
  'national-society-of-the-red-cross-of-sao-tome-and-principe',
  'xn--nationalsocietysotomandprncipeoftheredcross-6td8yvg',
  'xn--nationalerodekruisverenigingvanbelgi-fjd',
  'xn--nationale-rode-kruisvereniging-van-belgi-rvd',
  'nationalerotkreuzgesellschaftderschweiz',
  'nationale-rotkreuzgesellschaft-der-schweiz',
  'nationalerotkreuzgesellschaftvonbelgien',
  'nationale-rotkreuzgesellschaft-von-belgien',
  'nationaleverenigingvanhetbelgischerodekruis',
  'nationale-vereniging-van-het-belgische-rode-kruis',
  'nepalredcross',
  'nepal-red-cross',
  'nepalredcrosssociety',
  'nepal-red-cross-society',
  'nepaleseredcross',
  'nepalese-red-cross',
  'nepaleseredcrosssociety',
  'nepalese-red-cross-society',
  'netherlandsredcross',
  'netherlands-red-cross',
  'netherlandsredcrosssociety',
  'netherlands-red-cross-society',
  'newzealandredcross',
  'new-zealand-red-cross',
  'newzealandredcrosssociety',
  'new-zealand-red-cross-society',
  'nicaraguaredcross',
  'nicaragua-red-cross',
  'nicaraguaredcrosssociety',
  'nicaragua-red-cross-society',
  'nicaraguanredcross',
  'nicaraguan-red-cross',
  'nicaraguanredcrosssociety',
  'nicaraguan-red-cross-society',
  'nigerredcross',
  'niger-red-cross',
  'nigerredcrosssociety',
  'niger-red-cross-society',
  'nigeriaredcross',
  'nigeria-red-cross',
  'nigeriaredcrosssociety',
  'nigeria-red-cross-society',
  'nigerianredcross',
  'nigerian-red-cross',
  'nigerianredcrosssociety',
  'nigerian-red-cross-society',
  'nigerienredcross',
  'nigerien-red-cross',
  'nigerienredcrosssociety',
  'nigerien-red-cross-society',
  'nkenaijiriauhieobe',
  'nke-naijiria-uhie-obe',
  'nkenaijirianuhieobe',
  'nke-naijirian-uhie-obe',
  'nkuluzamukunzemwaditungadiakongowamunganata',
  'nkuluza-mukunze-mwa-ditunga-dia-kongo-wa-munganata',
  'xn--norgesrdekors-hnb',
  'xn--norges-rde-kors-dub',
  'xn--norgesrdekorsforening-wfc',
  'xn--norges-rde-kors-forening-7pc',
  'northkoreanredcross',
  'north-korean-red-cross',
  'northkoreanredcrosssociety',
  'north-korean-red-cross-society',
  'northmacedoniaredcross',
  'north-macedonia-red-cross',
  'northmacedoniaredcrosssociety',
  'north-macedonia-red-cross-society',
  'northmacedonianredcross',
  'north-macedonian-red-cross',
  'northmacedonianredcrosssociety',
  'north-macedonian-red-cross-society',
  'norwayredcross',
  'norway-red-cross',
  'norwayredcrosssociety',
  'norway-red-cross-society',
  'norwegianredcross',
  'norwegian-red-cross',
  'norwegianredcrosssociety',
  'norwegian-red-cross-society',
  'ozbekqizilyarimoy',
  'o-zbek-qizil-yarim-oy',
  'ozbekqizilyarimoyjamiyati',
  'o-zbek-qizil-yarim-oy-jamiyati',
  'ozbekistonrespublikasiqizilyarimoyjamiyati',
  'o-zbekiston-respublikasi-qizil-yarim-oy-jamiyati',
  'ozbekistonqizilyarimoy',
  'o-zbekiston-qizil-yarim-oy',
  'ozbekistonqizilyarimoyjamiyati',
  'o-zbekiston-qizil-yarim-oy-jamiyati',
  'oesterreichischerotkreuzgesellschaft',
  'oesterreichische-rotkreuz-gesellschaft',
  'oesterreichischesroteskreuz',
  'oesterreichisches-rotes-kreuz',
  'omonaijiriapupaagbelebu',
  'omo-naijiria-pupa-agbelebu',
  'omonaijiriapupaagbelebuebge',
  'omo-naijiria-pupa-agbelebu-ebge',
  'omonaijiriapupaagbelebuegbe',
  'omo-naijiria-pupa-agbelebu-egbe',
  'omusalabaomumyuufu',
  'omusalaba-omumyuufu',
  'xn--sterreichischerotkreuzgesellschaft-9md',
  'xn--sterreichische-rotkreuz-gesellschaft-0td',
  'xn--sterreichischenrotenkreuz-xrc',
  'xn--sterreichischen-roten-kreuz-oyc',
  'xn--sterreichischenrotenkreuzes-oyc',
  'xn--sterreichischen-roten-kreuzes-f5c',
  'xn--sterreichischesroteskreuz-xrc',
  'xn--sterreichisches-rotes-kreuz-oyc',
  'otunkenaijirianuhieobe',
  'otu-nke-naijirian-uhie-obe',
  'otuobeuhienaijiria',
  'otu-obe-uhie-naijiria',
  'otuobeuhienaijirian',
  'otu-obe-uhie-naijirian',
  'otuobeuhienkenaijiria',
  'otu-obe-uhie-nke-naijiria',
  'otuuhieobedinanaijiria',
  'otu-uhie-obe-di-na-naijiria',
  'otuuhieobenkedinanaijira',
  'otu-uhie-obe-nke-di-na-naijira',
  'pakistanredcrescent',
  'pakistan-red-crescent',
  'pakistanredcrescentsociety',
  'pakistan-red-crescent-society',
  'pakistaniredcrescent',
  'pakistani-red-crescent',
  'pakistaniredcrescentsociety',
  'pakistani-red-crescent-society',
  'palangmerahindonesia',
  'palang-merah-indonesia',
  'palangmerahsingapura',
  'palang-merah-singapura',
  'palauredcross',
  'palau-red-cross',
  'palauredcrosssociety',
  'palau-red-cross-society',
  'palauanredcross',
  'palauan-red-cross',
  'palauanredcrosssociety',
  'palauan-red-cross-society',
  'palestineredcrescent',
  'palestine-red-crescent',
  'palestineredcrescentsociety',
  'palestine-red-crescent-society',
  'palestinianredcrescent',
  'palestinian-red-crescent',
  'palestinianredcrescentsociety',
  'palestinian-red-crescent-society',
  'panamaredcross',
  'panama-red-cross',
  'panamaredcrosssociety',
  'panama-red-cross-society',
  'panamanianredcross',
  'panamanian-red-cross',
  'panamanianredcrosssociety',
  'panamanian-red-cross-society',
  'papuanewguinearedcross',
  'papua-new-guinea-red-cross',
  'papuanewguinearedcrosssociety',
  'papua-new-guinea-red-cross-society',
  'paraguayredcross',
  'paraguay-red-cross',
  'paraguayredcrosssociety',
  'paraguay-red-cross-society',
  'paraguayanredcross',
  'paraguayan-red-cross',
  'paraguayanredcrossnationalsociety',
  'paraguayan-red-cross-national-society',
  'paraguayanredcrosssociety',
  'paraguayan-red-cross-society',
  'perhimpunanpalangmerahindonesia',
  'perhimpunan-palang-merah-indonesia',
  'persatuanbulansabitmerahnegarabruneidarussalam',
  'persatuan-bulan-sabit-merah-negara-brunei-darussalam',
  'persatuanbulatsabitmerahmalaysia',
  'persatuan-bulat-sabit-merah-malaysia',
  'peruredcross',
  'peru-red-cross',
  'peruredcrosssociety',
  'peru-red-cross-society',
  'peruvianredcross',
  'peruvian-red-cross',
  'peruvianredcrossnationalsociety',
  'peruvian-red-cross-national-society',
  'peruvianredcrosssociety',
  'peruvian-red-cross-society',
  'peruviansocietyoftheredcross',
  'peruvian-society-of-the-red-cross',
  'philippinenationalredcross',
  'philippine-national-red-cross',
  'philippineredcross',
  'philippine-red-cross',
  'philippineredcrosssociety',
  'philippine-red-cross-society',
  'philippinesredcross',
  'philippines-red-cross',
  'philippinesredcrosssociety',
  'philippines-red-cross-society',
  'polandredcross',
  'poland-red-cross',
  'polandredcrosssociety',
  'poland-red-cross-society',
  'polishredcross',
  'polish-red-cross',
  'polishredcrosssociety',
  'polish-red-cross-society',
  'xn--polskiczerwonykrzy-l6d',
  'xn--polski-czerwony-krzy-1le',
  'xn--polskiestowarzyszeniekrajoweczerwonegokrzya-9cj',
  'xn--polskie-stowarzyszenie-krajowe-czerwonego-krzya-65j',
  'portugalredcross',
  'portugal-red-cross',
  'portugalredcrosssociety',
  'portugal-red-cross-society',
  'portugueseredcross',
  'portuguese-red-cross',
  'portugueseredcrosssociety',
  'portuguese-red-cross-society',
  'punainenristi',
  'punainen-risti',
  'pupaagbelebuegbeilunaijiria',
  'pupa-agbelebu-egbe-ilu-naijiria',
  'pupaagbelebuilunaijiria',
  'pupa-agbelebu-ilu-naijiria',
  'qatarredcrescent',
  'qatar-red-crescent',
  'qatarredcrescentsociety',
  'qatar-red-crescent-society',
  'qatariredcrescent',
  'qatari-red-crescent',
  'qatariredcrescentsociety',
  'qatari-red-crescent-society',
  'xn--qzlaypara-vpbb',
  'xn--qzl-aypara-xubb',
  'xn--qzlayparacmiyyti-9pcb647cea',
  'xn--qzl-aypara-cmiyyti-e0cb043dea',
  'raudekrossen',
  'raude-krossen',
  'raudekrosseninoreg',
  'raude-krossen-i-noreg',
  'xn--rauikrossslands-dpb8b',
  'xn--raui-kross-slands-nvb2c',
  'xn--rauikrossinn-ygb',
  'xn--raui-krossinn-5jb',
  'xn--rauikrossinnslandi-9rb4vzb',
  'xn--raui-krossinn--slandi-n0b1z4b',
  'xn--raudonasiskryius-0sd',
  'xn--raudonasis-kryius-b0d',
  'xn--raudonojokryiausdraugija-1gf',
  'xn--raudonojo-kryiaus-draugija-lvf',
  'xn--rdeikrislovenije-u7b25r',
  'xn--rdei-kri-slovenije-wgc97t',
  'redcrescentauthorityoftheunitedarabemirates',
  'red-crescent-authority-of-the-united-arab-emirates',
  'redcrescentbangladesh',
  'red-crescent-bangladesh',
  'redcrescentinpalestine',
  'red-crescent-in-palestine',
  'redcrescentofafghanistan',
  'red-crescent-of-afghanistan',
  'redcrescentofalgeria',
  'red-crescent-of-algeria',
  'redcrescentofazerbaijan',
  'red-crescent-of-azerbaijan',
  'redcrescentofbahrain',
  'red-crescent-of-bahrain',
  'redcrescentofbangladesh',
  'red-crescent-of-bangladesh',
  'redcrescentofbrunei',
  'red-crescent-of-brunei',
  'redcrescentofbruneidarussalam',
  'red-crescent-of-brunei-darussalam',
  'redcrescentofcomoros',
  'red-crescent-of-comoros',
  'redcrescentofdjibouti',
  'red-crescent-of-djibouti',
  'redcrescentofegypt',
  'red-crescent-of-egypt',
  'redcrescentofiran',
  'red-crescent-of-iran',
  'redcrescentofiraq',
  'red-crescent-of-iraq',
  'redcrescentofjordan',
  'red-crescent-of-jordan',
  'redcrescentofkazakhstan',
  'red-crescent-of-kazakhstan',
  'redcrescentofkuwait',
  'red-crescent-of-kuwait',
  'redcrescentofkyrgyzstan',
  'red-crescent-of-kyrgyzstan',
  'redcrescentoflibya',
  'red-crescent-of-libya',
  'redcrescentofmalaysia',
  'red-crescent-of-malaysia',
  'redcrescentofmauritania',
  'red-crescent-of-mauritania',
  'redcrescentofmorocco',
  'red-crescent-of-morocco',
  'redcrescentofpakistan',
  'red-crescent-of-pakistan',
  'redcrescentofpalestine',
  'red-crescent-of-palestine',
  'redcrescentofqatar',
  'red-crescent-of-qatar',
  'redcrescentofsaudiarabia',
  'red-crescent-of-saudi-arabia',
  'redcrescentofsomalia',
  'red-crescent-of-somalia',
  'redcrescentofsomaliland',
  'red-crescent-of-somaliland',
  'redcrescentofsudan',
  'red-crescent-of-sudan',
  'redcrescentofsyria',
  'red-crescent-of-syria',
  'redcrescentoftajikistan',
  'red-crescent-of-tajikistan',
  'redcrescentoftheislamicrepublicofiran',
  'red-crescent-of-the-islamic-republic-of-iran',
  'redcrescentofthemaldives',
  'red-crescent-of-the-maldives',
  'redcrescentoftherepublicofkazakhstan',
  'red-crescent-of-the-republic-of-kazakhstan',
  'redcrescentoftherepublicofthemaldives',
  'red-crescent-of-the-republic-of-the-maldives',
  'redcrescentofthesyrianarabrepublic',
  'red-crescent-of-the-syrian-arab-republic',
  'redcrescentoftheunitedarabemirates',
  'red-crescent-of-the-united-arab-emirates',
  'redcrescentoftunisia',
  'red-crescent-of-tunisia',
  'redcrescentofturkey',
  'red-crescent-of-turkey',
  'redcrescentofturkmenistan',
  'red-crescent-of-turkmenistan',
  'redcrescentofuzbekistan',
  'red-crescent-of-uzbekistan',
  'redcrescentofyemen',
  'red-crescent-of-yemen',
  'redcrescentsocietyofafghanistan',
  'red-crescent-society-of-afghanistan',
  'redcrescentsocietyofalgeria',
  'red-crescent-society-of-algeria',
  'redcrescentsocietyofazerbaijan',
  'red-crescent-society-of-azerbaijan',
  'redcrescentsocietyofbahrain',
  'red-crescent-society-of-bahrain',
  'redcrescentsocietyofbangladesh',
  'red-crescent-society-of-bangladesh',
  'redcrescentsocietyofbrunei',
  'red-crescent-society-of-brunei',
  'redcrescentsocietyofbruneidarussalam',
  'red-crescent-society-of-brunei-darussalam',
  'redcrescentsocietyofcomoros',
  'red-crescent-society-of-comoros',
  'redcrescentsocietyofdjibouti',
  'red-crescent-society-of-djibouti',
  'redcrescentsocietyofegypt',
  'red-crescent-society-of-egypt',
  'redcrescentsocietyofiran',
  'red-crescent-society-of-iran',
  'redcrescentsocietyofiraq',
  'red-crescent-society-of-iraq',
  'redcrescentsocietyofjordan',
  'red-crescent-society-of-jordan',
  'redcrescentsocietyofkazakhstan',
  'red-crescent-society-of-kazakhstan',
  'redcrescentsocietyofkuwait',
  'red-crescent-society-of-kuwait',
  'redcrescentsocietyofkyrgyzstan',
  'red-crescent-society-of-kyrgyzstan',
  'redcrescentsocietyoflibya',
  'red-crescent-society-of-libya',
  'redcrescentsocietyofmalaysia',
  'red-crescent-society-of-malaysia',
  'redcrescentsocietyofmauritania',
  'red-crescent-society-of-mauritania',
  'redcrescentsocietyofmorocco',
  'red-crescent-society-of-morocco',
  'redcrescentsocietyofpakistan',
  'red-crescent-society-of-pakistan',
  'redcrescentsocietyofpalestine',
  'red-crescent-society-of-palestine',
  'redcrescentsocietyofqatar',
  'red-crescent-society-of-qatar',
  'redcrescentsocietyofsaudiarabia',
  'red-crescent-society-of-saudi-arabia',
  'redcrescentsocietyofsomalia',
  'red-crescent-society-of-somalia',
  'redcrescentsocietyofsomaliland',
  'red-crescent-society-of-somaliland',
  'redcrescentsocietyofsudan',
  'red-crescent-society-of-sudan',
  'redcrescentsocietyofsyria',
  'red-crescent-society-of-syria',
  'redcrescentsocietyoftajikistan',
  'red-crescent-society-of-tajikistan',
  'redcrescentsocietyoftheislamicrepublicofiran',
  'red-crescent-society-of-the-islamic-republic-of-iran',
  'redcrescentsocietyofthemaldives',
  'red-crescent-society-of-the-maldives',
  'redcrescentsocietyoftherepublicofkazakhstan',
  'red-crescent-society-of-the-republic-of-kazakhstan',
  'redcrescentsocietyoftherepublicofthemaldives',
  'red-crescent-society-of-the-republic-of-the-maldives',
  'redcrescentsocietyoftherepublicofuzbekistan',
  'red-crescent-society-of-the-republic-of-uzbekistan',
  'redcrescentsocietyofthesyrianarabrepublic',
  'red-crescent-society-of-the-syrian-arab-republic',
  'redcrescentsocietyoftheunitedarabemirates',
  'red-crescent-society-of-the-united-arab-emirates',
  'redcrescentsocietyoftunisia',
  'red-crescent-society-of-tunisia',
  'redcrescentsocietyofturkey',
  'red-crescent-society-of-turkey',
  'redcrescentsocietyofturkmenistan',
  'red-crescent-society-of-turkmenistan',
  'redcrescentsocietyofuzbekistan',
  'red-crescent-society-of-uzbekistan',
  'redcrescentsocietyofyemen',
  'red-crescent-society-of-yemen',
  'redcrosscharity',
  'red-cross-charity',
  'redcrossgermany',
  'red-cross-germany',
  'redcrossinukraine',
  'red-cross-in-ukraine',
  'redcrossofalbania',
  'red-cross-of-albania',
  'redcrossofamerica',
  'red-cross-of-america',
  'redcrossofandorra',
  'red-cross-of-andorra',
  'redcrossofangola',
  'red-cross-of-angola',
  'redcrossofantiguaandbarbuda',
  'red-cross-of-antigua-and-barbuda',
  'redcrossofargentina',
  'red-cross-of-argentina',
  'redcrossofarmenia',
  'red-cross-of-armenia',
  'redcrossofaustralia',
  'red-cross-of-australia',
  'redcrossofaustria',
  'red-cross-of-austria',
  'redcrossofbaphalalieswatini',
  'red-cross-of-baphalali-eswatini',
  'redcrossofbaphalaliswaziland',
  'red-cross-of-baphalali-swaziland',
  'redcrossofbarbados',
  'red-cross-of-barbados',
  'redcrossofbelarus',
  'red-cross-of-belarus',
  'redcrossofbelgium',
  'red-cross-of-belgium',
  'redcrossofbelize',
  'red-cross-of-belize',
  'redcrossofbenin',
  'red-cross-of-benin',
  'redcrossofbolivia',
  'red-cross-of-bolivia',
  'redcrossofbosniaandherzegovina',
  'red-cross-of-bosnia-and-herzegovina',
  'redcrossofbotswana',
  'red-cross-of-botswana',
  'redcrossofbrazil',
  'red-cross-of-brazil',
  'redcrossofbulgaria',
  'red-cross-of-bulgaria',
  'redcrossofburkinafaso',
  'red-cross-of-burkina-faso',
  'redcrossofburundi',
  'red-cross-of-burundi',
  'redcrossofcambodia',
  'red-cross-of-cambodia',
  'redcrossofcameroon',
  'red-cross-of-cameroon',
  'redcrossofcanada',
  'red-cross-of-canada',
  'redcrossofcapeverde',
  'red-cross-of-cape-verde',
  'redcrossofchad',
  'red-cross-of-chad',
  'redcrossofchile',
  'red-cross-of-chile',
  'redcrossofchina',
  'red-cross-of-china',
  'redcrossofcolombia',
  'red-cross-of-colombia',
  'redcrossofcongo',
  'red-cross-of-congo',
  'redcrossofcostarica',
  'red-cross-of-costa-rica',
  'xn--redcrossofctedivoire-68b',
  'xn--red-cross-of-cte-d-ivoire-uqc',
  'redcrossofcroatia',
  'red-cross-of-croatia',
  'redcrossofcuba',
  'red-cross-of-cuba',
  'redcrossofcyprus',
  'red-cross-of-cyprus',
  'redcrossofdenmark',
  'red-cross-of-denmark',
  'redcrossofdominica',
  'red-cross-of-dominica',
  'redcrossofecuador',
  'red-cross-of-ecuador',
  'redcrossofelsalvador',
  'red-cross-of-el-salvador',
  'redcrossofequatorialguinea',
  'red-cross-of-equatorial-guinea',
  'redcrossofestonia',
  'red-cross-of-estonia',
  'redcrossofeswatini',
  'red-cross-of-eswatini',
  'redcrossofethiopia',
  'red-cross-of-ethiopia',
  'redcrossoffiji',
  'red-cross-of-fiji',
  'redcrossoffinland',
  'red-cross-of-finland',
  'redcrossoffrance',
  'red-cross-of-france',
  'redcrossofgabon',
  'red-cross-of-gabon',
  'redcrossofgambia',
  'red-cross-of-gambia',
  'redcrossofgeorgia',
  'red-cross-of-georgia',
  'redcrossofgermany',
  'red-cross-of-germany',
  'redcrossofghana',
  'red-cross-of-ghana',
  'redcrossofgreece',
  'red-cross-of-greece',
  'redcrossofgrenada',
  'red-cross-of-grenada',
  'redcrossofguatemala',
  'red-cross-of-guatemala',
  'redcrossofguinea',
  'red-cross-of-guinea',
  'redcrossofguineabissau',
  'red-cross-of-guinea-bissau',
  'redcrossofguyana',
  'red-cross-of-guyana',
  'redcrossofhaiti',
  'red-cross-of-haiti',
  'redcrossofhonduras',
  'red-cross-of-honduras',
  'redcrossofhungary',
  'red-cross-of-hungary',
  'redcrossoficeland',
  'red-cross-of-iceland',
  'redcrossofindia',
  'red-cross-of-india',
  'redcrossofindonesia',
  'red-cross-of-indonesia',
  'redcrossofireland',
  'red-cross-of-ireland',
  'redcrossofitaly',
  'red-cross-of-italy',
  'redcrossofivorycoast',
  'red-cross-of-ivory-coast',
  'redcrossofjamaica',
  'red-cross-of-jamaica',
  'redcrossofjapan',
  'red-cross-of-japan',
  'redcrossofkenya',
  'red-cross-of-kenya',
  'redcrossofkiribati',
  'red-cross-of-kiribati',
  'redcrossoflaos',
  'red-cross-of-laos',
  'redcrossoflatvia',
  'red-cross-of-latvia',
  'redcrossoflebanon',
  'red-cross-of-lebanon',
  'redcrossoflesotho',
  'red-cross-of-lesotho',
  'redcrossofliberia',
  'red-cross-of-liberia',
  'redcrossofliechtenstein',
  'red-cross-of-liechtenstein',
  'redcrossoflithuania',
  'red-cross-of-lithuania',
  'redcrossofluxembourg',
  'red-cross-of-luxembourg',
  'redcrossofmacedonia',
  'red-cross-of-macedonia',
  'redcrossofmadagascar',
  'red-cross-of-madagascar',
  'redcrossofmalawi',
  'red-cross-of-malawi',
  'redcrossofmali',
  'red-cross-of-mali',
  'redcrossofmalta',
  'red-cross-of-malta',
  'redcrossofmauritius',
  'red-cross-of-mauritius',
  'redcrossofmexico',
  'red-cross-of-mexico',
  'redcrossofmicronesia',
  'red-cross-of-micronesia',
  'redcrossofmoldova',
  'red-cross-of-moldova',
  'redcrossofmonaco',
  'red-cross-of-monaco',
  'redcrossofmongolia',
  'red-cross-of-mongolia',
  'redcrossofmontenegro',
  'red-cross-of-montenegro',
  'redcrossofmozambique',
  'red-cross-of-mozambique',
  'redcrossofmyanmar',
  'red-cross-of-myanmar',
  'redcrossofnamibia',
  'red-cross-of-namibia',
  'redcrossofnepal',
  'red-cross-of-nepal',
  'redcrossofnewzealand',
  'red-cross-of-new-zealand',
  'redcrossofnicaragua',
  'red-cross-of-nicaragua',
  'redcrossofniger',
  'red-cross-of-niger',
  'redcrossofnigeria',
  'red-cross-of-nigeria',
  'redcrossofnorthmacedonia',
  'red-cross-of-north-macedonia',
  'redcrossofnorway',
  'red-cross-of-norway',
  'redcrossofpalau',
  'red-cross-of-palau',
  'redcrossofpanama',
  'red-cross-of-panama',
  'redcrossofpapuanewguinea',
  'red-cross-of-papua-new-guinea',
  'redcrossofparaguay',
  'red-cross-of-paraguay',
  'redcrossofperu',
  'red-cross-of-peru',
  'redcrossofpoland',
  'red-cross-of-poland',
  'redcrossofportugal',
  'red-cross-of-portugal',
  'redcrossofromania',
  'red-cross-of-romania',
  'redcrossofrussia',
  'red-cross-of-russia',
  'redcrossofrwanda',
  'red-cross-of-rwanda',
  'redcrossofsaintkittsandnevisredcross',
  'red-cross-of-saint-kitts-and-nevis-red-cross',
  'redcrossofsaintlucia',
  'red-cross-of-saint-lucia',
  'redcrossofsaintvincentandthegrenadines',
  'red-cross-of-saint-vincent-and-the-grenadines',
  'redcrossofsamoa',
  'red-cross-of-samoa',
  'redcrossofsanmarino',
  'red-cross-of-san-marino',
  'xn--redcrossofsotomandprncipe-4dc0ove',
  'xn--red-cross-of-so-tom-and-prncipe-6uc7rnf',
  'redcrossofsenegal',
  'red-cross-of-senegal',
  'redcrossofserbia',
  'red-cross-of-serbia',
  'redcrossofseychelles',
  'red-cross-of-seychelles',
  'redcrossofsierraleone',
  'red-cross-of-sierra-leone',
  'redcrossofsingapore',
  'red-cross-of-singapore',
  'redcrossofslovakia',
  'red-cross-of-slovakia',
  'redcrossofslovenia',
  'red-cross-of-slovenia',
  'redcrossofsouthafrica',
  'red-cross-of-south-africa',
  'redcrossofsouthsudan',
  'red-cross-of-south-sudan',
  'redcrossofspain',
  'red-cross-of-spain',
  'redcrossofsrilanka',
  'red-cross-of-sri-lanka',
  'redcrossofsuriname',
  'red-cross-of-suriname',
  'redcrossofsweden',
  'red-cross-of-sweden',
  'redcrossofswitzerland',
  'red-cross-of-switzerland',
  'redcrossoftanzania',
  'red-cross-of-tanzania',
  'redcrossofthailand',
  'red-cross-of-thailand',
  'redcrossofthebahamas',
  'red-cross-of-the-bahamas',
  'redcrossofthecentralafricanrepublic',
  'red-cross-of-the-central-african-republic',
  'redcrossofthecookislands',
  'red-cross-of-the-cook-islands',
  'redcrossoftheczechrepublic',
  'red-cross-of-the-czech-republic',
  'redcrossofthedemocraticpeoplesrepublicofkorea',
  'red-cross-of-the-democratic-people-s-republic-of-korea',
  'redcrossofthedemocraticrepublicofthecongo',
  'red-cross-of-the-democratic-republic-of-the-congo',
  'redcrossofthedominicanrepublic',
  'red-cross-of-the-dominican-republic',
  'redcrossoftheformeryugoslavrepublicofmacedonia',
  'red-cross-of-the-former-yugoslav-republic-of-macedonia',
  'redcrossofthemarshallislands',
  'red-cross-of-the-marshall-islands',
  'redcrossofthenetherlands',
  'red-cross-of-the-netherlands',
  'redcrossofthephilippines',
  'red-cross-of-the-philippines',
  'redcrossoftherepublicofalbania',
  'red-cross-of-the-republic-of-albania',
  'redcrossoftherepublicofcroatia',
  'red-cross-of-the-republic-of-croatia',
  'redcrossoftherepublicofkorea',
  'red-cross-of-the-republic-of-korea',
  'redcrossoftherepublicofmacedonia',
  'red-cross-of-the-republic-of-macedonia',
  'redcrossoftherepublicofmoldova',
  'red-cross-of-the-republic-of-moldova',
  'redcrossoftherepublicofnorthmacedonia',
  'red-cross-of-the-republic-of-north-macedonia',
  'redcrossoftherepublicofsanmarino',
  'red-cross-of-the-republic-of-san-marino',
  'redcrossoftherepublicofserbia',
  'red-cross-of-the-republic-of-serbia',
  'redcrossofthesolomonislands',
  'red-cross-of-the-solomon-islands',
  'redcrossoftheunitedkindgom',
  'red-cross-of-the-united-kindgom',
  'redcrossoftimorleste',
  'red-cross-of-timor-leste',
  'redcrossoftogo',
  'red-cross-of-togo',
  'redcrossoftonga',
  'red-cross-of-tonga',
  'redcrossoftrinidadandtobago',
  'red-cross-of-trinidad-and-tobago',
  'redcrossoftuvalu',
  'red-cross-of-tuvalu',
  'redcrossofuganda',
  'red-cross-of-uganda',
  'redcrossofukraine',
  'red-cross-of-ukraine',
  'redcrossofuruguay',
  'red-cross-of-uruguay',
  'redcrossofvanuatu',
  'red-cross-of-vanuatu',
  'redcrossofvenezuela',
  'red-cross-of-venezuela',
  'redcrossofvietnam',
  'red-cross-of-vietnam',
  'redcrossofzambia',
  'red-cross-of-zambia',
  'redcrossofzimbabwe',
  'red-cross-of-zimbabwe',
  'redcrosssociety',
  'red-cross-society',
  'redcrosssocietyassociation',
  'red-cross-society-association',
  'redcrosssocietyheadquarters',
  'red-cross-society-headquarters',
  'redcrosssocietyofalbania',
  'red-cross-society-of-albania',
  'redcrosssocietyofamerica',
  'red-cross-society-of-america',
  'redcrosssocietyofandorra',
  'red-cross-society-of-andorra',
  'redcrosssocietyofangola',
  'red-cross-society-of-angola',
  'redcrosssocietyofantiguaandbarbuda',
  'red-cross-society-of-antigua-and-barbuda',
  'redcrosssocietyofargentina',
  'red-cross-society-of-argentina',
  'redcrosssocietyofarmenia',
  'red-cross-society-of-armenia',
  'redcrosssocietyofaustralia',
  'red-cross-society-of-australia',
  'redcrosssocietyofaustria',
  'red-cross-society-of-austria',
  'redcrosssocietyofbaphalalieswatini',
  'red-cross-society-of-baphalali-eswatini',
  'redcrosssocietyofbaphalaliswaziland',
  'red-cross-society-of-baphalali-swaziland',
  'redcrosssocietyofbarbados',
  'red-cross-society-of-barbados',
  'redcrosssocietyofbelarus',
  'red-cross-society-of-belarus',
  'redcrosssocietyofbelgium',
  'red-cross-society-of-belgium',
  'redcrosssocietyofbelize',
  'red-cross-society-of-belize',
  'redcrosssocietyofbenin',
  'red-cross-society-of-benin',
  'redcrosssocietyofbolivia',
  'red-cross-society-of-bolivia',
  'redcrosssocietyofbosniaandherzegovina',
  'red-cross-society-of-bosnia-and-herzegovina',
  'redcrosssocietyofbotswana',
  'red-cross-society-of-botswana',
  'redcrosssocietyofbrazil',
  'red-cross-society-of-brazil',
  'redcrosssocietyofbulgaria',
  'red-cross-society-of-bulgaria',
  'redcrosssocietyofburkinafaso',
  'red-cross-society-of-burkina-faso',
  'redcrosssocietyofburundi',
  'red-cross-society-of-burundi',
  'redcrosssocietyofcambodia',
  'red-cross-society-of-cambodia',
  'redcrosssocietyofcameroon',
  'red-cross-society-of-cameroon',
  'redcrosssocietyofcanada',
  'red-cross-society-of-canada',
  'redcrosssocietyofcapeverde',
  'red-cross-society-of-cape-verde',
  'redcrosssocietyofchad',
  'red-cross-society-of-chad',
  'redcrosssocietyofchile',
  'red-cross-society-of-chile',
  'redcrosssocietyofchina',
  'red-cross-society-of-china',
  'redcrosssocietyofcolombia',
  'red-cross-society-of-colombia',
  'redcrosssocietyofcongo',
  'red-cross-society-of-congo',
  'redcrosssocietyofcostarica',
  'red-cross-society-of-costa-rica',
  'xn--redcrosssocietyofctedivoire-lxc',
  'xn--red-cross-society-of-cte-d-ivoire-lid',
  'redcrosssocietyofcroatia',
  'red-cross-society-of-croatia',
  'redcrosssocietyofcuba',
  'red-cross-society-of-cuba',
  'redcrosssocietyofcyprus',
  'red-cross-society-of-cyprus',
  'redcrosssocietyofdenmark',
  'red-cross-society-of-denmark',
  'redcrosssocietyofdominica',
  'red-cross-society-of-dominica',
  'redcrosssocietyofecuador',
  'red-cross-society-of-ecuador',
  'redcrosssocietyofelsalvador',
  'red-cross-society-of-el-salvador',
  'redcrosssocietyofequatorialguinea',
  'red-cross-society-of-equatorial-guinea',
  'redcrosssocietyofestonia',
  'red-cross-society-of-estonia',
  'redcrosssocietyofeswatini',
  'red-cross-society-of-eswatini',
  'redcrosssocietyofethiopia',
  'red-cross-society-of-ethiopia',
  'redcrosssocietyoffiji',
  'red-cross-society-of-fiji',
  'redcrosssocietyoffinland',
  'red-cross-society-of-finland',
  'redcrosssocietyoffrance',
  'red-cross-society-of-france',
  'redcrosssocietyofgabon',
  'red-cross-society-of-gabon',
  'redcrosssocietyofgambia',
  'red-cross-society-of-gambia',
  'redcrosssocietyofgeorgia',
  'red-cross-society-of-georgia',
  'redcrosssocietyofgermany',
  'red-cross-society-of-germany',
  'redcrosssocietyofghana',
  'red-cross-society-of-ghana',
  'redcrosssocietyofgreece',
  'red-cross-society-of-greece',
  'redcrosssocietyofgrenada',
  'red-cross-society-of-grenada',
  'redcrosssocietyofguatemala',
  'red-cross-society-of-guatemala',
  'redcrosssocietyofguinea',
  'red-cross-society-of-guinea',
  'redcrosssocietyofguineabissau',
  'red-cross-society-of-guinea-bissau',
  'redcrosssocietyofguyana',
  'red-cross-society-of-guyana',
  'redcrosssocietyofhaiti',
  'red-cross-society-of-haiti',
  'redcrosssocietyofhonduras',
  'red-cross-society-of-honduras',
  'redcrosssocietyofhungary',
  'red-cross-society-of-hungary',
  'redcrosssocietyoficeland',
  'red-cross-society-of-iceland',
  'redcrosssocietyofindia',
  'red-cross-society-of-india',
  'redcrosssocietyofindonesia',
  'red-cross-society-of-indonesia',
  'redcrosssocietyofireland',
  'red-cross-society-of-ireland',
  'redcrosssocietyofitaly',
  'red-cross-society-of-italy',
  'redcrosssocietyofivorycoast',
  'red-cross-society-of-ivory-coast',
  'redcrosssocietyofjamaica',
  'red-cross-society-of-jamaica',
  'redcrosssocietyofjapan',
  'red-cross-society-of-japan',
  'redcrosssocietyofkenya',
  'red-cross-society-of-kenya',
  'redcrosssocietyofkiribati',
  'red-cross-society-of-kiribati',
  'redcrosssocietyoflaos',
  'red-cross-society-of-laos',
  'redcrosssocietyoflatvia',
  'red-cross-society-of-latvia',
  'redcrosssocietyoflebanon',
  'red-cross-society-of-lebanon',
  'redcrosssocietyoflesotho',
  'red-cross-society-of-lesotho',
  'redcrosssocietyofliberia',
  'red-cross-society-of-liberia',
  'redcrosssocietyofliechtenstein',
  'red-cross-society-of-liechtenstein',
  'redcrosssocietyoflithuania',
  'red-cross-society-of-lithuania',
  'redcrosssocietyofluxembourg',
  'red-cross-society-of-luxembourg',
  'redcrosssocietyofmacedonia',
  'red-cross-society-of-macedonia',
  'redcrosssocietyofmadagascar',
  'red-cross-society-of-madagascar',
  'redcrosssocietyofmalawi',
  'red-cross-society-of-malawi',
  'redcrosssocietyofmali',
  'red-cross-society-of-mali',
  'redcrosssocietyofmalta',
  'red-cross-society-of-malta',
  'redcrosssocietyofmauritius',
  'red-cross-society-of-mauritius',
  'redcrosssocietyofmexico',
  'red-cross-society-of-mexico',
  'redcrosssocietyofmicronesia',
  'red-cross-society-of-micronesia',
  'redcrosssocietyofmoldova',
  'red-cross-society-of-moldova',
  'redcrosssocietyofmonaco',
  'red-cross-society-of-monaco',
  'redcrosssocietyofmongolia',
  'red-cross-society-of-mongolia',
  'redcrosssocietyofmontenegro',
  'red-cross-society-of-montenegro',
  'redcrosssocietyofmozambique',
  'red-cross-society-of-mozambique',
  'redcrosssocietyofmyanmar',
  'red-cross-society-of-myanmar',
  'redcrosssocietyofnamibia',
  'red-cross-society-of-namibia',
  'redcrosssocietyofnepal',
  'red-cross-society-of-nepal',
  'redcrosssocietyofnewzealand',
  'red-cross-society-of-new-zealand',
  'redcrosssocietyofnicaragua',
  'red-cross-society-of-nicaragua',
  'redcrosssocietyofniger',
  'red-cross-society-of-niger',
  'redcrosssocietyofnigeria',
  'red-cross-society-of-nigeria',
  'redcrosssocietyofnorthmacedonia',
  'red-cross-society-of-north-macedonia',
  'redcrosssocietyofnorway',
  'red-cross-society-of-norway',
  'redcrosssocietyofpalau',
  'red-cross-society-of-palau',
  'redcrosssocietyofpanama',
  'red-cross-society-of-panama',
  'redcrosssocietyofpapuanewguinea',
  'red-cross-society-of-papua-new-guinea',
  'redcrosssocietyofparaguay',
  'red-cross-society-of-paraguay',
  'redcrosssocietyofperu',
  'red-cross-society-of-peru',
  'redcrosssocietyofpoland',
  'red-cross-society-of-poland',
  'redcrosssocietyofportugal',
  'red-cross-society-of-portugal',
  'redcrosssocietyofromania',
  'red-cross-society-of-romania',
  'redcrosssocietyofrussia',
  'red-cross-society-of-russia',
  'redcrosssocietyofrwanda',
  'red-cross-society-of-rwanda',
  'redcrosssocietyofsaintkittsandnevis',
  'red-cross-society-of-saint-kitts-and-nevis',
  'redcrosssocietyofsaintlucia',
  'red-cross-society-of-saint-lucia',
  'redcrosssocietyofsaintvincentandthegrenadines',
  'red-cross-society-of-saint-vincent-and-the-grenadines',
  'redcrosssocietyofsamoa',
  'red-cross-society-of-samoa',
  'redcrosssocietyofsanmarino',
  'red-cross-society-of-san-marino',
  'xn--redcrosssocietyofsotomandprncipe-4xc2spf',
  'xn--red-cross-society-of-so-tom-and-prncipe-1id5wng',
  'redcrosssocietyofsenegal',
  'red-cross-society-of-senegal',
  'redcrosssocietyofserbia',
  'red-cross-society-of-serbia',
  'redcrosssocietyofseychelles',
  'red-cross-society-of-seychelles',
  'redcrosssocietyofsierraleone',
  'red-cross-society-of-sierra-leone',
  'redcrosssocietyofsingapore',
  'red-cross-society-of-singapore',
  'redcrosssocietyofslovakia',
  'red-cross-society-of-slovakia',
  'redcrosssocietyofslovenia',
  'red-cross-society-of-slovenia',
  'redcrosssocietyofsouthafrica',
  'red-cross-society-of-south-africa',
  'redcrosssocietyofsouthsudan',
  'red-cross-society-of-south-sudan',
  'redcrosssocietyofspain',
  'red-cross-society-of-spain',
  'redcrosssocietyofsrilanka',
  'red-cross-society-of-sri-lanka',
  'redcrosssocietyofsuriname',
  'red-cross-society-of-suriname',
  'redcrosssocietyofsweden',
  'red-cross-society-of-sweden',
  'redcrosssocietyofswitzerland',
  'red-cross-society-of-switzerland',
  'redcrosssocietyoftanzania',
  'red-cross-society-of-tanzania',
  'redcrosssocietyofthailand',
  'red-cross-society-of-thailand',
  'redcrosssocietyofthebahamas',
  'red-cross-society-of-the-bahamas',
  'redcrosssocietyofthecentralafricanrepublic',
  'red-cross-society-of-the-central-african-republic',
  'redcrosssocietyofthecookislands',
  'red-cross-society-of-the-cook-islands',
  'redcrosssocietyoftheczechrepublic',
  'red-cross-society-of-the-czech-republic',
  'redcrosssocietyofthedemocraticpeoplesrepublicofkorea',
  'red-cross-society-of-the-democratic-people-s-republic-of-korea',
  'redcrosssocietyofthedemocraticrepublicofthecongo',
  'red-cross-society-of-the-democratic-republic-of-the-congo',
  'redcrosssocietyofthedominicanrepublic',
  'red-cross-society-of-the-dominican-republic',
  'redcrosssocietyoftheformeryugoslavrepublicofmacedonia',
  'red-cross-society-of-the-former-yugoslav-republic-of-macedonia',
  'redcrosssocietyofthemarshallislands',
  'red-cross-society-of-the-marshall-islands',
  'redcrosssocietyofthenetherlands',
  'red-cross-society-of-the-netherlands',
  'redcrosssocietyofthephilippines',
  'red-cross-society-of-the-philippines',
  'redcrosssocietyoftherepublicofalbania',
  'red-cross-society-of-the-republic-of-albania',
  'redcrosssocietyoftherepublicofkorea',
  'red-cross-society-of-the-republic-of-korea',
  'redcrosssocietyoftherepublicofmacedonia',
  'red-cross-society-of-the-republic-of-macedonia',
  'redcrosssocietyoftherepublicofmoldova',
  'red-cross-society-of-the-republic-of-moldova',
  'redcrosssocietyoftherepublicofnorthmacedonia',
  'red-cross-society-of-the-republic-of-north-macedonia',
  'redcrosssocietyoftherepublicofsanmarino',
  'red-cross-society-of-the-republic-of-san-marino',
  'redcrosssocietyofthesolomonislands',
  'red-cross-society-of-the-solomon-islands',
  'redcrosssocietyoftheunitedkindgom',
  'red-cross-society-of-the-united-kindgom',
  'redcrosssocietyoftimorleste',
  'red-cross-society-of-timor-leste',
  'redcrosssocietyoftogo',
  'red-cross-society-of-togo',
  'redcrosssocietyoftonga',
  'red-cross-society-of-tonga',
  'redcrosssocietyoftrinidadandtobago',
  'red-cross-society-of-trinidad-and-tobago',
  'redcrosssocietyoftuvalu',
  'red-cross-society-of-tuvalu',
  'redcrosssocietyofuganda',
  'red-cross-society-of-uganda',
  'redcrosssocietyofukraine',
  'red-cross-society-of-ukraine',
  'redcrosssocietyofuruguay',
  'red-cross-society-of-uruguay',
  'redcrosssocietyofvanuatu',
  'red-cross-society-of-vanuatu',
  'redcrosssocietyofvenezuela',
  'red-cross-society-of-venezuela',
  'redcrosssocietyofvietnam',
  'red-cross-society-of-vietnam',
  'redcrosssocietyofzambia',
  'red-cross-society-of-zambia',
  'redcrosssocietyofzimbabwe',
  'red-cross-society-of-zimbabwe',
  'redkurosanijeriya',
  'red-kuros-a-nijeriya',
  'redkurosdinnijeriya',
  'red-kuros-din-nijeriya',
  'redkurostanijeriya',
  'red-kuros-ta-nijeriya',
  'redstarofdavid',
  'red-star-of-david',
  'redstarofdavidinisrael',
  'red-star-of-david-in-israel',
  'republicofalbaniaredcross',
  'republic-of-albania-red-cross',
  'republicofalbaniaredcrosssociety',
  'republic-of-albania-red-cross-society',
  'republicofkoreanationalredcross',
  'republic-of-korea-national-red-cross',
  'republicofkorearedcross',
  'republic-of-korea-red-cross',
  'republicofkorearedcrosssociety',
  'republic-of-korea-red-cross-society',
  'republicofmoldovaredcross',
  'republic-of-moldova-red-cross',
  'republicofmoldovaredcrosssociety',
  'republic-of-moldova-red-cross-society',
  'republicofsanmarinoredcross',
  'republic-of-san-marino-red-cross',
  'republicofsanmarinoredcrosssociety',
  'republic-of-san-marino-red-cross-society',
  'republicofserbiaredcross',
  'republic-of-serbia-red-cross',
  'republicofserbiaredcrosssociety',
  'republic-of-serbia-red-cross-society',
  'republicofthemaldivesredcrescent',
  'republic-of-the-maldives-red-crescent',
  'republicofthemaldivesredcrescentsociety',
  'republic-of-the-maldives-red-crescent-society',
  'xn--rdakorssverige-vpb',
  'xn--rda-kors-sverige-mwb',
  'xn--rdakorset-07a',
  'xn--rda-korset-ecb',
  'xn--rdakorsetsverige-mwb',
  'xn--rda-korset-sverige-d3b',
  'xn--rdekors-q1a',
  'xn--rde-kors-54a',
  'xn--rdekorsforeningeninorge-lmc',
  'xn--rde-kors-foreningen-i-norge-b0c',
  'xn--rdekorsidanmark-5tb',
  'xn--rde-kors-i-danmark-g4b',
  'xn--rdekorsinorge-bnb',
  'xn--rde-kors-i-norge-lxb',
  'xn--rdekorsnorge-vjb',
  'xn--rde-kors-norge-qqb',
  'rodekruis',
  'rode-kruis',
  'xn--rodekruisvanbelgi-sub',
  'xn--rode-kruis-van-belgi-13b',
  'rodekruisvansuriname',
  'rode-kruis-van-suriname',
  'rodekruisvlaanderen',
  'rode-kruis-vlaanderen',
  'romaniaredcross',
  'romania-red-cross',
  'romaniaredcrosssociety',
  'romania-red-cross-society',
  'romanianredcross',
  'romanian-red-cross',
  'romanianredcrosssociety',
  'romanian-red-cross-society',
  'roteskreuzbelgien',
  'rotes-kreuz-belgien',
  'roteskreuzderschweiz',
  'rotes-kreuz-der-schweiz',
  'roteskreuzdeutschland',
  'rotes-kreuz-deutschland',
  'roteskreuzindeutschland',
  'rotes-kreuz-in-deutschland',
  'xn--roteskreuzinsterreich-sec',
  'xn--rotes-kreuz-in-sterreich-zoc',
  'roteskreuzliechtenstein',
  'rotes-kreuz-liechtenstein',
  'rotkreuzgesellschaftindeutschland',
  'rotkreuz-gesellschaft-in-deutschland',
  'xn--rotkreuzgesellschaftinsterreich-sdd',
  'xn--rotkreuz-gesellschaft-in-sterreich-znd',
  'russiaredcross',
  'russia-red-cross',
  'russiaredcrosssociety',
  'russia-red-cross-society',
  'russianredcross',
  'russian-red-cross',
  'russianredcrosssociety',
  'russian-red-cross-society',
  'rwandaredcross',
  'rwanda-red-cross',
  'rwandaredcrosssociety',
  'rwanda-red-cross-society',
  'rwandanredcross',
  'rwandan-red-cross',
  'rwandanredcrosssociety',
  'rwandan-red-cross-society',
  'saintkittsandnevisredcross',
  'saint-kitts-and-nevis-red-cross',
  'saintkittsandnevisredcrosssociety',
  'saint-kitts-and-nevis-red-cross-society',
  'saintluciaredcross',
  'saint-lucia-red-cross',
  'saintluciaredcrosssociety',
  'saint-lucia-red-cross-society',
  'saintlucianredcross',
  'saint-lucian-red-cross',
  'saintlucianredcrosssociety',
  'saint-lucian-red-cross-society',
  'saintvincentandthegrenadinesredcross',
  'saint-vincent-and-the-grenadines-red-cross',
  'saintvincentandthegrenadinesredcrosssociety',
  'saint-vincent-and-the-grenadines-red-cross-society',
  'saisaietikolosikulatonga',
  'saisaieti-kolosi-kula-tonga',
  'salvadoreanredcross',
  'salvadorean-red-cross',
  'salvadoreanredcrosssociety',
  'salvadorean-red-cross-society',
  'samoaredcross',
  'samoa-red-cross',
  'samoaredcrosssociety',
  'samoa-red-cross-society',
  'samoanredcross',
  'samoan-red-cross',
  'samoanredcrosssociety',
  'samoan-red-cross-society',
  'sanmarinoredcross',
  'san-marino-red-cross',
  'sanmarinoredcrosssociety',
  'san-marino-red-cross-society',
  'xn--sotomandprinciperedcross-09b4n',
  'xn--so-tom-and-principe-red-cross-5oc5q',
  'xn--sotomandprinciperedcrosssociety-tuc6r',
  'xn--so-tom-and-principe-red-cross-society-scd3v',
  'sataulokulaotuvalu',
  'sataulo-kula-o-tuvalu',
  'saudiarabiaredcrescent',
  'saudi-arabia-red-crescent',
  'saudiarabiaredcrescentsociety',
  'saudi-arabia-red-crescent-society',
  'saudiredcrescent',
  'saudi-red-crescent',
  'saudiredcrescentauthority',
  'saudi-red-crescent-authority',
  'saudiredcrescentsociety',
  'saudi-red-crescent-society',
  'schweizerischenationalerotkreuzgesellschaft',
  'schweizerische-nationale-rotkreuzgesellschaft',
  'schweizerischesroteskreuz',
  'schweizerisches-rotes-kreuz',
  'sedenacionaldelacruzrojamexicana',
  'sede-nacional-de-la-cruz-roja-mexicana',
  'senegalredcross',
  'senegal-red-cross',
  'senegalredcrosssociety',
  'senegal-red-cross-society',
  'senegaleseredcross',
  'senegalese-red-cross',
  'senegaleseredcrosssociety',
  'senegalese-red-cross-society',
  'serbianredcross',
  'serbian-red-cross',
  'serbianredcrosssociety',
  'serbian-red-cross-society',
  'seychellesredcross',
  'seychelles-red-cross',
  'seychellesredcrosssociety',
  'seychelles-red-cross-society',
  'shirikalamsalabamwekundu',
  'shirika-la-msalaba-mwekundu',
  'xn--shoqataekryqittkuqeishrepubliksjugosllavetmaqedonis-otepmj',
  'xn--shoqataekryqittkuqemaqedonis-dtcn',
  'xn--shoqata-e-kryqit-t-kuq-e-maqedonis-scdq',
  'xn--shoqataekryqittkuqerepublikssmaqedonis-xodmcj',
  'xn--shoqata-e-kryqit-t-kuq-e-republiks-s-maqedonis-heepdk',
  'xn--shoqataekryqittkuqshqiptar-8mc',
  'xn--shoqata-e-kryqit-t-kuq-shqiptar-m2c',
  'xn--shoqataekryqittkuqtrepublikssshqipris-vldeicfd',
  'xn--shoqata-e-kryqit-t-kuq-t-republiks-s-shqipris-fbegjdgd',
  'xn--shoqataekryqittkuqtshqipris-bqcefd',
  'xn--shoqata-e-kryqit-t-kuq-t-shqipris-q8cggd',
  'xn--shoqatamaqedonaseekryqittkuq-ntc',
  'xn--shoqata-maqedonase-e-kryqit-t-kuq-18c',
  'sierraleoneredcross',
  'sierra-leone-red-cross',
  'sierraleoneredcrosssociety',
  'sierra-leone-red-cross-society',
  'singaporeredcross',
  'singapore-red-cross',
  'singaporeredcrosssociety',
  'singapore-red-cross-society',
  'singaporeanredcross',
  'singaporean-red-cross',
  'singaporeanredcrosssociety',
  'singaporean-red-cross-society',
  'slovakredcross',
  'slovak-red-cross',
  'slovakredcrosssociety',
  'slovak-red-cross-society',
  'slovakiaredcross',
  'slovakia-red-cross',
  'slovakiaredcrosssociety',
  'slovakia-red-cross-society',
  'sloveniaredcross',
  'slovenia-red-cross',
  'sloveniaredcrosssociety',
  'slovenia-red-cross-society',
  'slovenianredcross',
  'slovenian-red-cross',
  'slovenianredcrosssociety',
  'slovenian-red-cross-society',
  'xn--slovenskirdeikri-37b25r',
  'xn--slovenski-rdei-kri-6gc97t',
  'xn--slovenskervenkr-jpb8xf21a34f',
  'xn--slovensk-erven-kr-tvb80ag44a59f',
  'sociedadargentinadelacruzroja',
  'sociedad-argentina-de-la-cruz-roja',
  'sociedadbolivianadelacruzroja',
  'sociedad-boliviana-de-la-cruz-roja',
  'sociedadcruzrojachilena',
  'sociedad-cruz-roja-chilena',
  'sociedadcruzrojacolombia',
  'sociedad-cruz-roja-colombia',
  'sociedadcruzrojadeguineaecuatorial',
  'sociedad-cruz-roja-de-guinea-ecuatorial',
  'sociedadcruzrojavenezuela',
  'sociedad-cruz-roja-venezuela',
  'sociedaddelacruzrojaamericana',
  'sociedad-de-la-cruz-roja-americana',
  'sociedaddelacruzrojaboliviana',
  'sociedad-de-la-cruz-roja-boliviana',
  'sociedaddelacruzrojachilena',
  'sociedad-de-la-cruz-roja-chilena',
  'sociedaddelacruzrojacolombia',
  'sociedad-de-la-cruz-roja-colombia',
  'xn--sociedaddelacruzrojadeamrica-yrc',
  'xn--sociedad-de-la-cruz-roja-de-amrica-49c',
  'sociedaddelacruzrojadeargentina',
  'sociedad-de-la-cruz-roja-de-argentina',
  'sociedaddelacruzrojadecolombia',
  'sociedad-de-la-cruz-roja-de-colombia',
  'sociedaddelacruzrojadeguineaecuatorial',
  'sociedad-de-la-cruz-roja-de-guinea-ecuatorial',
  'sociedaddelacruzrojadevenezuela',
  'sociedad-de-la-cruz-roja-de-venezuela',
  'sociedaddelacruzrojaecuatorialdeguinea',
  'sociedad-de-la-cruz-roja-ecuatorial-de-guinea',
  'sociedaddelacruzrojaecuatoriana',
  'sociedad-de-la-cruz-roja-ecuatoriana',
  'sociedaddelacruzrojanacionalamericana',
  'sociedad-de-la-cruz-roja-nacional-americana',
  'sociedaddelacruzrojaparaguaya',
  'sociedad-de-la-cruz-roja-paraguaya',
  'sociedaddelacruzrojauruguaya',
  'sociedad-de-la-cruz-roja-uruguaya',
  'sociedaddelacruzrojavenezuela',
  'sociedad-de-la-cruz-roja-venezuela',
  'sociedadecuatorianadelacruzroja',
  'sociedad-ecuatoriana-de-la-cruz-roja',
  'sociedadnacionalargentinadelacruzroja',
  'sociedad-nacional-argentina-de-la-cruz-roja',
  'sociedadnacionalcubanadelacruzroja',
  'sociedad-nacional-cubana-de-la-cruz-roja',
  'sociedadnacionaldelacruzrojaboliviana',
  'sociedad-nacional-de-la-cruz-roja-boliviana',
  'sociedadnacionaldelacruzrojacolombiana',
  'sociedad-nacional-de-la-cruz-roja-colombiana',
  'sociedadnacionaldelacruzrojadeargentina',
  'sociedad-nacional-de-la-cruz-roja-de-argentina',
  'sociedadnacionaldelacruzrojadeguineaecuatorial',
  'sociedad-nacional-de-la-cruz-roja-de-guinea-ecuatorial',
  'sociedadnacionaldelacruzrojadominicana',
  'sociedad-nacional-de-la-cruz-roja-dominicana',
  'sociedadnacionaldelacruzrojaecuatoriana',
  'sociedad-nacional-de-la-cruz-roja-ecuatoriana',
  'sociedadnacionaldelacruzrojahaitiana',
  'sociedad-nacional-de-la-cruz-roja-haitiana',
  'sociedadnacionaldelacruzrojamexicana',
  'sociedad-nacional-de-la-cruz-roja-mexicana',
  'sociedadnacionaldelacruzrojaparaguaya',
  'sociedad-nacional-de-la-cruz-roja-paraguaya',
  'sociedadnacionaldelacruzrojaperuana',
  'sociedad-nacional-de-la-cruz-roja-peruana',
  'xn--sociedadnacionaldelacruzrojasalvadorea-8vd',
  'xn--sociedad-nacional-de-la-cruz-roja-salvadorea-sge',
  'sociedadnacionaldelacruzrojauruguaya',
  'sociedad-nacional-de-la-cruz-roja-uruguaya',
  'sociedadnacionaldelacruzrojavenezolana',
  'sociedad-nacional-de-la-cruz-roja-venezolana',
  'sociedadparaguayadelacruzroja',
  'sociedad-paraguaya-de-la-cruz-roja',
  'sociedadperuanadelacruzroja',
  'sociedad-peruana-de-la-cruz-roja',
  'sociedadecruzvermelhabrasileira',
  'sociedade-cruz-vermelha-brasileira',
  'xn--sociedadecruzvermelhadesotomeprncipe-m9c6uyf',
  'xn--sociedade-cruz-vermelha-de-so-tom-e-prncipe-jud9yvg',
  'sociedadedacruzvermelhabrasileira',
  'sociedade-da-cruz-vermelha-brasileira',
  'sociedadedacruzvermelhadeportugal',
  'sociedade-da-cruz-vermelha-de-portugal',
  'xn--sociedadedacruzvermelhadesotomeprncipe-cgd8v6f',
  'xn--sociedade-da-cruz-vermelha-de-so-tom-e-prncipe-32d70a7g',
  'sociedadedacruzvermelhaportuguesa',
  'sociedade-da-cruz-vermelha-portuguesa',
  'xn--sociedadedacruzvermelhasotomeprncipe-m9c6uyf',
  'xn--sociedade-da-cruz-vermelha-so-tom-e-prncipe-jud9yvg',
  'xn--sociedadenacionaldacruzvermelhadesotomeprncipe-62d60a5g',
  'xn--sociedadenacionalsotomeprncipedacruzvermelha-2wd4zxg',
  'xn--sociedadesotomeprncipedacruzvermelha-78c6uyf',
  'xn--sociedade-so-tom-e-prncipe-da-cruz-vermelha-1td9yvg',
  'xn--societnazionaledellacrocerossainsvizzera-whd',
  'xn--societ-nazionale-della-croce-rossa-in-svizzera-dyd',
  'xn--societnazionaledellacrocerossasvizzera-fcd',
  'xn--societ-nazionale-della-croce-rossa-svizzera-4pd',
  'societadnaziunaladalacruschcotschnadalasvizra',
  'societad-naziunala-da-la-crusch-cotschna-da-la-svizra',
  'societadnaziunaladalacruschcotschnasvizra',
  'societad-naziunala-da-la-crusch-cotschna-svizra',
  'xn--societateadecruceroiedinmoldova-ryk',
  'xn--societatea-de-cruce-roie-din-moldova-9mm',
  'xn--societateadecruceroiedinromnia-urc501b',
  'xn--societatea-de-cruce-roie-din-romnia-z5c827b',
  'xn--societateanaionaldecruceroiedinromnia-ncd38jo2h14j',
  'xn--societatea-naional-de-cruce-roie-din-romnia-mtd67ljzi65k',
  'xn--socitcroixrougedeguineequatoriale-e6cbq',
  'xn--socit-croix-rouge-de-guine-equatoriale-emdbu',
  'xn--socitcroixrougedesotometprncipe-bvc7pbs2m',
  'xn--socit-croix-rouge-de-so-tom-et-prncipe-7fd5tbx2p',
  'xn--socitdelacroixrougedeguineequatoriale-ejdbu',
  'xn--socit-de-la-croix-rouge-de-guine-equatoriale-e4db0a',
  'xn--socitdelacroixrougedelaguineequatoriale-epdbw',
  'xn--socit-de-la-croix-rouge-de-la-guine-equatoriale-eeeb3a',
  'xn--socitdelacroixrougedesaotometprincipe-ejdbw',
  'xn--socit-de-la-croix-rouge-de-sao-tom-et-principe-ebeb3a',
  'xn--socitdelacroixrougequatoguinenne-e3cboj',
  'xn--socit-de-la-croix-rouge-quato-guinenne-emdbtk',
  'xn--socitnationaledecroixrougedectedivoire-emdb98b',
  'xn--socit-nationale-de-croix-rouge-de-cte-d-ivoire-ebeb38c',
  'xn--socitnationaledecroixrougedutogo-e3cb',
  'xn--socit-nationale-de-croix-rouge-du-togo-emdb',
  'xn--socitnationaledelacroixrougebelge-e6cb',
  'xn--socit-nationale-de-la-croix-rouge-belge-epdb',
  'xn--socitnationaledelacroixrougebnin-e3cby',
  'xn--socit-nationale-de-la-croix-rouge-bnin-emdb4a',
  'xn--socitnationaledelacroixrougebninoise-egdby',
  'xn--socit-nationale-de-la-croix-rouge-bninoise-eydb4a',
  'xn--socitnationaledelacroixrougeburkinafaso-epdb',
  'xn--socit-nationale-de-la-croix-rouge-burkina-faso-ebeb',
  'xn--socitnationaledelacroixrougeburkinab-egdb5a',
  'xn--socit-nationale-de-la-croix-rouge-burkinab-eydb1b',
  'xn--socitnationaledelacroixrougecamerounaise-esdb',
  'xn--socit-nationale-de-la-croix-rouge-camerounaise-ebeb',
  'xn--socitnationaledelacroixrougecanada-e9cb',
  'xn--socit-nationale-de-la-croix-rouge-canada-esdb',
  'xn--socitnationaledelacroixrougecanadienne-emdb',
  'xn--socit-nationale-de-la-croix-rouge-canadienne-e4db',
  'xn--socitnationaledelacroixrougecentrafricaine-eydb',
  'xn--socit-nationale-de-la-croix-rouge-centrafricaine-eheb',
  'xn--socitnationaledelacroixrougeconglaise-ejdb',
  'xn--socit-nationale-de-la-croix-rouge-conglaise-e1db',
  'xn--socitnationaledelacroixrougecongolaise-emdb',
  'xn--socit-nationale-de-la-croix-rouge-congolaise-e4db',
  'xn--socitnationaledelacroixrougectedivoire-emdb98b',
  'xn--socit-nationale-de-la-croix-rouge-cte-d-ivoire-ebeb38c',
  'xn--socitnationaledelacroixrougedebelgique-emdb',
  'xn--socit-nationale-de-la-croix-rouge-de-belgique-e7db',
  'xn--socitnationaledelacroixrougedeburkinafaso-evdb',
  'xn--socit-nationale-de-la-croix-rouge-de-burkina-faso-ekeb',
  'xn--socitnationaledelacroixrougedectedivoire-esdb31c',
  'xn--socit-nationale-de-la-croix-rouge-de-cte-d-ivoire-ekeb91d',
  'xn--socitnationaledelacroixrougedefrance-egdb',
  'xn--socit-nationale-de-la-croix-rouge-de-france-e1db',
  'xn--socitnationaledelacroixrougedeguine-eddb3a',
  'xn--socit-nationale-de-la-croix-rouge-de-guine-eydb0b',
  'xn--socitnationaledelacroixrougedeguineequatoriale-ebeb3a',
  'xn--socitnationaledelacroixrougedellemaurice-esdb5v',
  'xn--socit-nationale-de-la-croix-rouge-de-l-le-maurice-ekeb80a',
  'xn--socitnationaledelacroixrougedelaguineequatoriale-eheb5a',
  'xn--socitnationaledelacroixrougedemadagascar-esdb',
  'xn--socit-nationale-de-la-croix-rouge-de-madagascar-eeeb',
  'xn--socitnationaledelacroixrougedemali-e9cb',
  'xn--socit-nationale-de-la-croix-rouge-de-mali-evdb',
  'xn--socitnationaledelacroixrougedemaurice-ejdb',
  'xn--socit-nationale-de-la-croix-rouge-de-maurice-e4db',
  'xn--socitnationaledelacroixrougedemonaco-egdb',
  'xn--socit-nationale-de-la-croix-rouge-de-monaco-e1db',
  'xn--socitnationaledelacroixrougedesaotometprincipe-ebeb5a',
  'xn--socitnationaledelacroixrougedesuisse-egdb',
  'xn--socit-nationale-de-la-croix-rouge-de-suisse-e1db',
  'xn--socitnationaledelacroixrougedesseychelles-evdb',
  'xn--socit-nationale-de-la-croix-rouge-des-seychelles-eheb',
  'xn--socitnationaledelacroixrougedubnin-e9cb0a',
  'xn--socit-nationale-de-la-croix-rouge-du-bnin-evdb7a',
  'xn--socitnationaledelacroixrougeduburkinafaso-evdb',
  'xn--socit-nationale-de-la-croix-rouge-du-burkina-faso-ekeb',
  'xn--socitnationaledelacroixrougeduburundi-ejdb',
  'xn--socit-nationale-de-la-croix-rouge-du-burundi-e4db',
  'xn--socitnationaledelacroixrougeducameroun-emdb',
  'xn--socit-nationale-de-la-croix-rouge-du-cameroun-e7db',
  'xn--socitnationaledelacroixrougeducanada-egdb',
  'xn--socit-nationale-de-la-croix-rouge-du-canada-e1db',
  'xn--socitnationaledelacroixrougeducongo-eddb',
  'xn--socit-nationale-de-la-croix-rouge-du-congo-eydb',
  'xn--socitnationaledelacroixrougedugabon-eddb',
  'xn--socit-nationale-de-la-croix-rouge-du-gabon-eydb',
  'xn--socitnationaledelacroixrougeduliban-eddb',
  'xn--socit-nationale-de-la-croix-rouge-du-liban-eydb',
  'xn--socitnationaledelacroixrougeduluxembourg-esdb',
  'xn--socit-nationale-de-la-croix-rouge-du-luxembourg-eeeb',
  'xn--socitnationaledelacroixrougedumadagascar-esdb',
  'xn--socit-nationale-de-la-croix-rouge-du-madagascar-eeeb',
  'xn--socitnationaledelacroixrougedumali-e9cb',
  'xn--socit-nationale-de-la-croix-rouge-du-mali-evdb',
  'xn--socitnationaledelacroixrougeduniger-eddb',
  'xn--socit-nationale-de-la-croix-rouge-du-niger-eydb',
  'xn--socitnationaledelacroixrougedurwanda-egdb',
  'xn--socit-nationale-de-la-croix-rouge-du-rwanda-e1db',
  'xn--socitnationaledelacroixrougedusngal-eddb0ab',
  'xn--socit-nationale-de-la-croix-rouge-du-sngal-eydb7ab',
  'xn--socitnationaledelacroixrougedutchad-eddb',
  'xn--socit-nationale-de-la-croix-rouge-du-tchad-eydb',
  'xn--socitnationaledelacroixrougedutogo-e9cb',
  'xn--socit-nationale-de-la-croix-rouge-du-togo-evdb',
  'xn--socitnationaledelacroixrougefranaise-2ed1cb',
  'xn--socit-nationale-de-la-croix-rouge-franaise-wwd7cb',
  'xn--socitnationaledelacroixrougefrance-e9cb',
  'xn--socit-nationale-de-la-croix-rouge-france-esdb',
  'xn--socitnationaledelacroixrougegabonaise-ejdb',
  'xn--socit-nationale-de-la-croix-rouge-gabonaise-e1db',
  'xn--socitnationaledelacroixrougeguine-e6cb1a',
  'xn--socit-nationale-de-la-croix-rouge-guine-epdb7a',
  'xn--socitnationaledelacroixrougehatienne-egdb3x',
  'xn--socit-nationale-de-la-croix-rouge-hatienne-eydb51a',
  'xn--socitnationaledelacroixrougeivoirienne-emdb',
  'xn--socit-nationale-de-la-croix-rouge-ivoirienne-e4db',
  'xn--socitnationaledelacroixrougelibanaise-ejdb',
  'xn--socit-nationale-de-la-croix-rouge-libanaise-e1db',
  'xn--socitnationaledelacroixrougeluxembourg-emdb',
  'xn--socit-nationale-de-la-croix-rouge-luxembourg-e4db',
  'xn--socitnationaledelacroixrougeluxembourgeoise-e1db',
  'xn--socit-nationale-de-la-croix-rouge-luxembourgeoise-ekeb',
  'xn--socitnationaledelacroixrougemadagascar-emdb',
  'xn--socit-nationale-de-la-croix-rouge-madagascar-e4db',
  'xn--socitnationaledelacroixrougemalagasy-egdb',
  'xn--socit-nationale-de-la-croix-rouge-malagasy-eydb',
  'xn--socitnationaledelacroixrougemali-e3cb',
  'xn--socit-nationale-de-la-croix-rouge-mali-emdb',
  'xn--socitnationaledelacroixrougemalienne-egdb',
  'xn--socit-nationale-de-la-croix-rouge-malienne-eydb',
  'xn--socitnationaledelacroixrougemauricienne-epdb',
  'xn--socit-nationale-de-la-croix-rouge-mauricienne-e7db',
  'xn--socitnationaledelacroixrougemonaco-e9cb',
  'xn--socit-nationale-de-la-croix-rouge-monaco-esdb',
  'xn--socitnationaledelacroixrougemongasque-ejdb0a',
  'xn--socit-nationale-de-la-croix-rouge-mongasque-e1db6a',
  'xn--socitnationaledelacroixrougeniger-e6cb',
  'xn--socit-nationale-de-la-croix-rouge-niger-epdb',
  'xn--socitnationaledelacroixrougenigrienne-ejdb0a',
  'xn--socit-nationale-de-la-croix-rouge-nigrienne-e1db6a',
  'xn--socitnationaledelacroixrougerwanda-e9cb',
  'xn--socit-nationale-de-la-croix-rouge-rwanda-esdb',
  'xn--socitnationaledelacroixrougerwandaise-ejdb',
  'xn--socit-nationale-de-la-croix-rouge-rwandaise-e1db',
  'xn--socitnationaledelacroixrougesngal-e6cbyb',
  'xn--socit-nationale-de-la-croix-rouge-sngal-epdb4ab',
  'xn--socitnationaledelacroixrougesngalaise-ejdbyb',
  'xn--socit-nationale-de-la-croix-rouge-sngalaise-e1db4ab',
  'xn--socitnationaledelacroixrougeseychelles-emdb',
  'xn--socit-nationale-de-la-croix-rouge-seychelles-e4db',
  'xn--socitnationaledelacroixrougesuisse-e9cb',
  'xn--socit-nationale-de-la-croix-rouge-suisse-esdb',
  'xn--socitnationaledelacroixrougetchadienne-emdb',
  'xn--socit-nationale-de-la-croix-rouge-tchadienne-e4db',
  'xn--socitnationaledelacroixrougetogo-e3cb',
  'xn--socit-nationale-de-la-croix-rouge-togo-emdb',
  'xn--socitnationaledelacroixrougetogolaise-ejdb',
  'xn--socit-nationale-de-la-croix-rouge-togolaise-e1db',
  'xn--socitnationaleducroissantrougecomores-ejdb',
  'xn--socit-nationale-du-croissant-rouge-comores-eydb',
  'xn--socitnationaleducroissantrougecomorien-emdb',
  'xn--socit-nationale-du-croissant-rouge-comorien-e1db',
  'xn--socitnationaleducroissantrougededjibouti-esdb',
  'xn--socit-nationale-du-croissant-rouge-de-djibouti-ebeb',
  'xn--socitnationaleducroissantrougedemauritanie-eydb',
  'xn--socit-nationale-du-croissant-rouge-de-mauritanie-eheb',
  'xn--socitnationaleducroissantrougedetunisie-epdb',
  'xn--socit-nationale-du-croissant-rouge-de-tunisie-e7db',
  'xn--socitnationaleducroissantrougedescomores-esdb',
  'xn--socit-nationale-du-croissant-rouge-des-comores-ebeb',
  'xn--socitnationaleducroissantrougedjibouti-emdb',
  'xn--socit-nationale-du-croissant-rouge-djibouti-e1db',
  'xn--socitnationaleducroissantrougedjiboutien-esdb',
  'xn--socit-nationale-du-croissant-rouge-djiboutien-e7db',
  'xn--socitnationaleducroissantrougedudjibouti-esdb',
  'xn--socit-nationale-du-croissant-rouge-du-djibouti-ebeb',
  'xn--socitnationaleducroissantrougedumaroc-ejdb',
  'xn--socit-nationale-du-croissant-rouge-du-maroc-e1db',
  'xn--socitnationaleducroissantrougemaroc-eddb',
  'xn--socit-nationale-du-croissant-rouge-maroc-esdb',
  'xn--socitnationaleducroissantrougemarocain-emdb',
  'xn--socit-nationale-du-croissant-rouge-marocain-e1db',
  'xn--socitnationaleducroissantrougemauritanie-esdb',
  'xn--socit-nationale-du-croissant-rouge-mauritanie-e7db',
  'xn--socitnationaleducroissantrougemauritanien-evdb',
  'xn--socit-nationale-du-croissant-rouge-mauritanien-ebeb',
  'xn--socitnationaleducroissantrougetunisie-ejdb',
  'xn--socit-nationale-du-croissant-rouge-tunisie-eydb',
  'xn--socitnationaleducroissantrougetunisien-emdb',
  'xn--socit-nationale-du-croissant-rouge-tunisien-e1db',
  'xn--socitnationalesotometprncipedelacroixrouge-crd6wbp6q',
  'xn--socitsotometprncipedelacroixrouge-m0c1sbg0n',
  'xn--socit-so-tom-et-prncipe-de-la-croix-rouge-9nd8wbi4q',
  'societyofsomaliredcrescent',
  'society-of-somali-red-crescent',
  'societyofsomaliaredcrescent',
  'society-of-somalia-red-crescent',
  'societyofsomalilandredcrescent',
  'society-of-somaliland-red-crescent',
  'societyoftheredcrossofequatorialguinea',
  'society-of-the-red-cross-of-equatorial-guinea',
  'societyoftheredcrossofsaotomeandprincipe',
  'society-of-the-red-cross-of-sao-tome-and-principe',
  'xn--societysotomandprncipeoftheredcross-c6c0uwf',
  'xn--society-so-tom-and-prncipe-of-the-red-cross-ztd9yxg',
  'solomonislandsredcross',
  'solomon-islands-red-cross',
  'solomonislandsredcrosssociety',
  'solomon-islands-red-cross-society',
  'somaliredcrescent',
  'somali-red-crescent',
  'somaliredcrescentsociety',
  'somali-red-crescent-society',
  'somaliaredcrescent',
  'somalia-red-crescent',
  'somaliaredcrescentsociety',
  'somalia-red-crescent-society',
  'somalilandredcrescent',
  'somaliland-red-crescent',
  'somalilandredcrescentsociety',
  'somaliland-red-crescent-society',
  'soqosoqonikauvelataidamudamuiviti',
  'soqosoqo-ni-kauvelatai-damudamu-i-viti',
  'sosaietikolosikulatonga',
  'sosaieti-kolosi-kula-tonga',
  'southafricaredcross',
  'south-africa-red-cross',
  'southafricaredcrosssociety',
  'south-africa-red-cross-society',
  'southafricanredcross',
  'south-african-red-cross',
  'southafricanredcrosssociety',
  'south-african-red-cross-society',
  'southsudanredcross',
  'south-sudan-red-cross',
  'southsudanredcrosssociety',
  'south-sudan-red-cross-society',
  'southsudaneseredcross',
  'south-sudanese-red-cross',
  'southsudaneseredcrosssociety',
  'south-sudanese-red-cross-society',
  'spainredcross',
  'spain-red-cross',
  'spainredcrosssociety',
  'spain-red-cross-society',
  'spanishredcross',
  'spanish-red-cross',
  'spanishredcrosssociety',
  'spanish-red-cross-society',
  'xn--spolenosteskhoervenhoke-mcch1iz3beag30u26a',
  'xn--spolenost-eskho-ervenho-ke-nlci4j95bfah23w89a',
  'xn--spolenosteskervenk-yyb72af25aee12qs1a',
  'xn--spolenost-esk-erven-k-e8b37ag89aff05sy4a',
  'srbijanskicrvenikrst',
  'srbijanski-crveni-krst',
  'srilankaredcross',
  'sri-lanka-red-cross',
  'srilankaredcrosssociety',
  'sri-lanka-red-cross-society',
  'srilankanredcross',
  'sri-lankan-red-cross',
  'srilankanredcrosssociety',
  'sri-lankan-red-cross-society',
  'srpskicrvenikrst',
  'srpski-crveni-krst',
  'xn--stowarzyszeniepolskiegoczerwonegokrzya-4bi',
  'xn--stowarzyszenie-polskiego-czerwonego-krzya-txi',
  'sudanredcrescent',
  'sudan-red-crescent',
  'sudanredcrescentsociety',
  'sudan-red-crescent-society',
  'sudaneseredcrescent',
  'sudanese-red-crescent',
  'sudaneseredcrescentsociety',
  'sudanese-red-crescent-society',
  'suomenpunainenristi',
  'suomen-punainen-risti',
  'surinameredcross',
  'suriname-red-cross',
  'surinameredcrosssociety',
  'suriname-red-cross-society',
  'surinameseredcross',
  'surinamese-red-cross',
  'surinameseredcrosssociety',
  'surinamese-red-cross-society',
  'xn--svenskardakors-2pb',
  'xn--svenska-rda-kors-uwb',
  'xn--svenskardakorset-twb',
  'xn--svenska-rda-korset-l3b',
  'xn--sverigesrdakors-htb',
  'xn--sveriges-rda-kors-8zb',
  'xn--sverigesrdakorset-7zb',
  'xn--sveriges-rda-korset-z6b',
  'xn--sverigesrdakorsfrening-2hch',
  'xn--sveriges-rdakorsfrening-hlch',
  'swedenredcross',
  'sweden-red-cross',
  'swedenredcrosssociety',
  'sweden-red-cross-society',
  'swedishredcross',
  'swedish-red-cross',
  'swedishredcrosssociety',
  'swedish-red-cross-society',
  'swissredcross',
  'swiss-red-cross',
  'swissredcrosssociety',
  'swiss-red-cross-society',
  'switzerlandredcross',
  'switzerland-red-cross',
  'switzerlandredcrosssociety',
  'switzerland-red-cross-society',
  'syriaredcrescent',
  'syria-red-crescent',
  'syriaredcrescentsociety',
  'syria-red-crescent-society',
  'syrianarabredcrescent',
  'syrian-arab-red-crescent',
  'syrianarabredcrescentsociety',
  'syrian-arab-red-crescent-society',
  'syrianarabrepublicredcrescent',
  'syrian-arab-republic-red-crescent',
  'syrianarabrepublicredcrescentsociety',
  'syrian-arab-republic-red-crescent-society',
  'syrianredcrescent',
  'syrian-red-crescent',
  'syrianredcrescentsociety',
  'syrian-red-crescent-society',
  'tajikredcrescent',
  'tajik-red-crescent',
  'tajikredcrescentsociety',
  'tajik-red-crescent-society',
  'tajikistanredcrescent',
  'tajikistan-red-crescent',
  'tajikistanredcrescentsociety',
  'tajikistan-red-crescent-society',
  'tanganyikaredcrosssociety',
  'tanganyika-red-cross-society',
  'tanzaniaredcross',
  'tanzania-red-cross',
  'tanzaniaredcrossnationalsociety',
  'tanzania-red-cross-national-society',
  'tanzaniaredcrosssociety',
  'tanzania-red-cross-society',
  'tanzanianredcross',
  'tanzanian-red-cross',
  'tanzanianredcrosssociety',
  'tanzanian-red-cross-society',
  'tekuraoraotekukiairani',
  'te-kura-ora-o-te-kuki-airani',
  'thairedcross',
  'thai-red-cross',
  'thairedcrosssociety',
  'thai-red-cross-society',
  'thailandredcross',
  'thailand-red-cross',
  'thailandredcrosssociety',
  'thailand-red-cross-society',
  'theafghanredcrescent',
  'the-afghan-red-crescent',
  'theafghanredcrescentsociety',
  'the-afghan-red-crescent-society',
  'theafghanistanredcrescent',
  'the-afghanistan-red-crescent',
  'theafghanistanredcrescentsociety',
  'the-afghanistan-red-crescent-society',
  'thealbaniaredcross',
  'the-albania-red-cross',
  'thealbaniaredcrosssociety',
  'the-albania-red-cross-society',
  'thealbanianredcross',
  'the-albanian-red-cross',
  'thealbanianredcrosssociety',
  'the-albanian-red-cross-society',
  'thealgeriaredcrescent',
  'the-algeria-red-crescent',
  'thealgeriaredcrescentsociety',
  'the-algeria-red-crescent-society',
  'thealgerianredcrescent',
  'the-algerian-red-crescent',
  'thealgerianredcrescentsociety',
  'the-algerian-red-crescent-society',
  'theamericannationalredcross',
  'the-american-national-red-cross',
  'theamericannationalredcrosssociety',
  'the-american-national-red-cross-society',
  'theamericanredcross',
  'the-american-red-cross',
  'theamericanredcrosssociety',
  'the-american-red-cross-society',
  'theandorraredcross',
  'the-andorra-red-cross',
  'theandorraredcrosssociety',
  'the-andorra-red-cross-society',
  'theandorranredcross',
  'the-andorran-red-cross',
  'theandorranredcrosssociety',
  'the-andorran-red-cross-society',
  'theangolaredcross',
  'the-angola-red-cross',
  'theangolaredcrosssociety',
  'the-angola-red-cross-society',
  'theangolanredcross',
  'the-angolan-red-cross',
  'theangolanredcrosssociety',
  'the-angolan-red-cross-society',
  'theantiguaandbarbudaredcross',
  'the-antigua-and-barbuda-red-cross',
  'theantiguaandbarbudaredcrosssociety',
  'the-antigua-and-barbuda-red-cross-society',
  'theargentinaredcross',
  'the-argentina-red-cross',
  'theargentinaredcrosssociety',
  'the-argentina-red-cross-society',
  'theargentinenationalredcrosssociety',
  'the-argentine-national-red-cross-society',
  'theargentineredcross',
  'the-argentine-red-cross',
  'theargentineredcrosssociety',
  'the-argentine-red-cross-society',
  'theargentinianredcross',
  'the-argentinian-red-cross',
  'theargentinianredcrosssociety',
  'the-argentinian-red-cross-society',
  'thearmeniaredcross',
  'the-armenia-red-cross',
  'thearmeniaredcrosssociety',
  'the-armenia-red-cross-society',
  'thearmenianredcross',
  'the-armenian-red-cross',
  'thearmenianredcrosssociety',
  'the-armenian-red-cross-society',
  'theassociationofthebelgianredcross',
  'the-association-of-the-belgian-red-cross',
  'theaustraliaredcross',
  'the-australia-red-cross',
  'theaustraliaredcrosssociety',
  'the-australia-red-cross-society',
  'theaustralianredcross',
  'the-australian-red-cross',
  'theaustralianredcrosssociety',
  'the-australian-red-cross-society',
  'theaustriaredcross',
  'the-austria-red-cross',
  'theaustriaredcrosssociety',
  'the-austria-red-cross-society',
  'theaustrianredcross',
  'the-austrian-red-cross',
  'theaustrianredcrosssociety',
  'the-austrian-red-cross-society',
  'theazerbaijanredcrescent',
  'the-azerbaijan-red-crescent',
  'theazerbaijanredcrescentsociety',
  'the-azerbaijan-red-crescent-society',
  'theazeriredcrescent',
  'the-azeri-red-crescent',
  'theazeriredcrescentsociety',
  'the-azeri-red-crescent-society',
  'thebahamasredcross',
  'the-bahamas-red-cross',
  'thebahamasredcrosssociety',
  'the-bahamas-red-cross-society',
  'thebahrainredcrescent',
  'the-bahrain-red-crescent',
  'thebahrainredcrescentsociety',
  'the-bahrain-red-crescent-society',
  'thebahrainiredcrescent',
  'the-bahraini-red-crescent',
  'thebahrainiredcrescentsociety',
  'the-bahraini-red-crescent-society',
  'thebangladeshnationalredcrescent',
  'the-bangladesh-national-red-crescent',
  'thebangladeshredcrescent',
  'the-bangladesh-red-crescent',
  'thebangladeshredcrescentsociety',
  'the-bangladesh-red-crescent-society',
  'thebaphalalieswatiniredcross',
  'the-baphalali-eswatini-red-cross',
  'thebaphalalieswatiniredcrosssociety',
  'the-baphalali-eswatini-red-cross-society',
  'thebaphalaliswazilandredcross',
  'the-baphalali-swaziland-red-cross',
  'thebaphalaliswazilandredcrosssociety',
  'the-baphalali-swaziland-red-cross-society',
  'thebarbadosredcross',
  'the-barbados-red-cross',
  'thebarbadosredcrosssociety',
  'the-barbados-red-cross-society',
  'thebelarusredcross',
  'the-belarus-red-cross',
  'thebelarusredcrosssociety',
  'the-belarus-red-cross-society',
  'thebelarusianredcross',
  'the-belarusian-red-cross',
  'thebelarusianredcrosssociety',
  'the-belarusian-red-cross-society',
  'thebelgianredcross',
  'the-belgian-red-cross',
  'thebelgianredcrosssociety',
  'the-belgian-red-cross-society',
  'thebelizeredcross',
  'the-belize-red-cross',
  'thebelizeredcrosssociety',
  'the-belize-red-cross-society',
  'thebelizeanredcross',
  'the-belizean-red-cross',
  'thebelizeanredcrosssociety',
  'the-belizean-red-cross-society',
  'thebeninredcross',
  'the-benin-red-cross',
  'thebeninredcrosssociety',
  'the-benin-red-cross-society',
  'thebenineseredcross',
  'the-beninese-red-cross',
  'thebenineseredcrosssociety',
  'the-beninese-red-cross-society',
  'theboliviaredcross',
  'the-bolivia-red-cross',
  'theboliviaredcrosssociety',
  'the-bolivia-red-cross-society',
  'thebolivianredcross',
  'the-bolivian-red-cross',
  'thebolivianredcrosssociety',
  'the-bolivian-red-cross-society',
  'thebosniaandherzegovinaredcross',
  'the-bosnia-and-herzegovina-red-cross',
  'thebosniaandherzegovinaredcrosssociety',
  'the-bosnia-and-herzegovina-red-cross-society',
  'thebotswanaredcross',
  'the-botswana-red-cross',
  'thebotswanaredcrosssociety',
  'the-botswana-red-cross-society',
  'thebotswananredcross',
  'the-botswanan-red-cross',
  'thebotswananredcrosssociety',
  'the-botswanan-red-cross-society',
  'thebrazilredcross',
  'the-brazil-red-cross',
  'thebrazilredcrosssociety',
  'the-brazil-red-cross-society',
  'thebrazilianredcross',
  'the-brazilian-red-cross',
  'thebrazilianredcrosssociety',
  'the-brazilian-red-cross-society',
  'thebritishredcross',
  'the-british-red-cross',
  'thebritishredcrosssociety',
  'the-british-red-cross-society',
  'thebruneidarussalamredcrescent',
  'the-brunei-darussalam-red-crescent',
  'thebruneidarussalamredcrescentsociety',
  'the-brunei-darussalam-red-crescent-society',
  'thebruneiredcrescent',
  'the-brunei-red-crescent',
  'thebruneiredcrescentsociety',
  'the-brunei-red-crescent-society',
  'thebulgariaredcross',
  'the-bulgaria-red-cross',
  'thebulgariaredcrosssociety',
  'the-bulgaria-red-cross-society',
  'thebulgarianredcross',
  'the-bulgarian-red-cross',
  'thebulgarianredcrosssociety',
  'the-bulgarian-red-cross-society',
  'theburkinafasoredcross',
  'the-burkina-faso-red-cross',
  'theburkinafasoredcrosssociety',
  'the-burkina-faso-red-cross-society',
  'theburkinaredcross',
  'the-burkina-red-cross',
  'theburkinaredcrosssociety',
  'the-burkina-red-cross-society',
  'theburkinaberedcross',
  'the-burkinabe-red-cross',
  'theburkinaberedcrosssociety',
  'the-burkinabe-red-cross-society',
  'theburundiredcross',
  'the-burundi-red-cross',
  'theburundiredcrosssociety',
  'the-burundi-red-cross-society',
  'thecambodiaredcross',
  'the-cambodia-red-cross',
  'thecambodiaredcrosssociety',
  'the-cambodia-red-cross-society',
  'thecambodianredcross',
  'the-cambodian-red-cross',
  'thecambodianredcrosssociety',
  'the-cambodian-red-cross-society',
  'thecameroonredcross',
  'the-cameroon-red-cross',
  'thecameroonredcrosssociety',
  'the-cameroon-red-cross-society',
  'thecameroonianredcross',
  'the-cameroonian-red-cross',
  'thecameroonianredcrosssociety',
  'the-cameroonian-red-cross-society',
  'thecanadaredcross',
  'the-canada-red-cross',
  'thecanadaredcrosssociety',
  'the-canada-red-cross-society',
  'thecanadianredcross',
  'the-canadian-red-cross',
  'thecanadianredcrosssociety',
  'the-canadian-red-cross-society',
  'thecapeverderedcross',
  'the-cape-verde-red-cross',
  'thecapeverderedcrosssociety',
  'the-cape-verde-red-cross-society',
  'thecentralafricanredcross',
  'the-central-african-red-cross',
  'thecentralafricanredcrosssociety',
  'the-central-african-red-cross-society',
  'thechadredcross',
  'the-chad-red-cross',
  'thechadredcrosssociety',
  'the-chad-red-cross-society',
  'thechadianredcross',
  'the-chadian-red-cross',
  'thechadianredcrosssociety',
  'the-chadian-red-cross-society',
  'thechileredcross',
  'the-chile-red-cross',
  'thechileredcrosssociety',
  'the-chile-red-cross-society',
  'thechileanredcross',
  'the-chilean-red-cross',
  'thechileanredcrossnationalsociety',
  'the-chilean-red-cross-national-society',
  'thechileanredcrosssociety',
  'the-chilean-red-cross-society',
  'thechinaredcross',
  'the-china-red-cross',
  'thechinaredcrosssociety',
  'the-china-red-cross-society',
  'thechineseredcross',
  'the-chinese-red-cross',
  'thechineseredcrosssociety',
  'the-chinese-red-cross-society',
  'thecolombiaredcross',
  'the-colombia-red-cross',
  'thecolombiaredcrosssociety',
  'the-colombia-red-cross-society',
  'thecolombianredcross',
  'the-colombian-red-cross',
  'thecolombianredcrosssociety',
  'the-colombian-red-cross-society',
  'thecomorosredcrescent',
  'the-comoros-red-crescent',
  'thecomorosredcrescentsociety',
  'the-comoros-red-crescent-society',
  'thecomorosianredcrescent',
  'the-comorosian-red-crescent',
  'thecomorosianredcrescentsociety',
  'the-comorosian-red-crescent-society',
  'thecongoredcross',
  'the-congo-red-cross',
  'thecongoredcrosssociety',
  'the-congo-red-cross-society',
  'thecongoleseredcross',
  'the-congolese-red-cross',
  'thecongoleseredcrosssociety',
  'the-congolese-red-cross-society',
  'thecookislandsredcross',
  'the-cook-islands-red-cross',
  'thecookislandsredcrosssociety',
  'the-cook-islands-red-cross-society',
  'thecostaricaredcross',
  'the-costa-rica-red-cross',
  'thecostaricaredcrosssociety',
  'the-costa-rica-red-cross-society',
  'thecostaricanredcross',
  'the-costa-rican-red-cross',
  'thecostaricanredcrosssociety',
  'the-costa-rican-red-cross-society',
  'xn--thectedivoireredcross-bdc',
  'xn--the-cte-d-ivoire-red-cross-wtc',
  'xn--thectedivoireredcrosssociety-i0c',
  'xn--the-cte-d-ivoire-red-cross-society-fld',
  'thecroatiaredcross',
  'the-croatia-red-cross',
  'thecroatiaredcrosssociety',
  'the-croatia-red-cross-society',
  'thecroatianredcross',
  'the-croatian-red-cross',
  'thecroatianredcrosssociety',
  'the-croatian-red-cross-society',
  'thecubaredcross',
  'the-cuba-red-cross',
  'thecubaredcrosssociety',
  'the-cuba-red-cross-society',
  'thecubanredcross',
  'the-cuban-red-cross',
  'thecubanredcrosssociety',
  'the-cuban-red-cross-society',
  'thecypriotredcross',
  'the-cypriot-red-cross',
  'thecypriotredcrosssociety',
  'the-cypriot-red-cross-society',
  'thecyprusredcross',
  'the-cyprus-red-cross',
  'thecyprusredcrosssociety',
  'the-cyprus-red-cross-society',
  'theczechredcross',
  'the-czech-red-cross',
  'theczechredcrosssociety',
  'the-czech-red-cross-society',
  'theczechrepublicredcross',
  'the-czech-republic-red-cross',
  'theczechrepublicredcrosssociety',
  'the-czech-republic-red-cross-society',
  'thedanishredcross',
  'the-danish-red-cross',
  'thedanishredcrosssociety',
  'the-danish-red-cross-society',
  'thedemocraticpeoplesrepublicofkorearedcross',
  'the-democratic-people-s-republic-of-korea-red-cross',
  'thedemocraticpeoplesrepublicofkorearedcrosssociety',
  'the-democratic-people-s-republic-of-korea-red-cross-society',
  'thedemocraticrepublicofthecongoredcross',
  'the-democratic-republic-of-the-congo-red-cross',
  'thedemocraticrepublicofthecongoredcrosssociety',
  'the-democratic-republic-of-the-congo-red-cross-society',
  'thedenmarkredcross',
  'the-denmark-red-cross',
  'thedenmarkredcrosssociety',
  'the-denmark-red-cross-society',
  'thedjiboutiredcrescent',
  'the-djibouti-red-crescent',
  'thedjiboutiredcrescentsociety',
  'the-djibouti-red-crescent-society',
  'thedjiboutianredcrescent',
  'the-djiboutian-red-crescent',
  'thedjiboutianredcrescentsociety',
  'the-djiboutian-red-crescent-society',
  'thedominicaredcross',
  'the-dominica-red-cross',
  'thedominicaredcrosssociety',
  'the-dominica-red-cross-society',
  'thedominicanredcross',
  'the-dominican-red-cross',
  'thedominicanredcrosssociety',
  'the-dominican-red-cross-society',
  'thedominicanrepublicredcross',
  'the-dominican-republic-red-cross',
  'thedominicanrepublicredcrosssociety',
  'the-dominican-republic-red-cross-society',
  'thedominiquaisredcross',
  'the-dominiquais-red-cross',
  'thedominiquaisredcrosssociety',
  'the-dominiquais-red-cross-society',
  'thedutchredcross',
  'the-dutch-red-cross',
  'thedutchredcrosssociety',
  'the-dutch-red-cross-society',
  'theecuadorredcross',
  'the-ecuador-red-cross',
  'theecuadorredcrosssociety',
  'the-ecuador-red-cross-society',
  'theecuadorianredcross',
  'the-ecuadorian-red-cross',
  'theecuadorianredcrosssociety',
  'the-ecuadorian-red-cross-society',
  'theegyptredcrescent',
  'the-egypt-red-crescent',
  'theegyptredcrescentsociety',
  'the-egypt-red-crescent-society',
  'theegyptianredcrescent',
  'the-egyptian-red-crescent',
  'theegyptianredcrescentsociety',
  'the-egyptian-red-crescent-society',
  'theelsalvadorredcross',
  'the-el-salvador-red-cross',
  'theelsalvadorredcrosssociety',
  'the-el-salvador-red-cross-society',
  'theemiratesredcrescentauthority',
  'the-emirates-red-crescent-authority',
  'theemiratiredcrescent',
  'the-emirati-red-crescent',
  'theemiratiredcrescentauthority',
  'the-emirati-red-crescent-authority',
  'theemiratiredcrescentsociety',
  'the-emirati-red-crescent-society',
  'theequatorialguinearedcross',
  'the-equatorial-guinea-red-cross',
  'theequatorialguinearedcrosssociety',
  'the-equatorial-guinea-red-cross-society',
  'theequatorialguineanredcross',
  'the-equatorial-guinean-red-cross',
  'theequatorialguineanredcrosssociety',
  'the-equatorial-guinean-red-cross-society',
  'theestoniaredcross',
  'the-estonia-red-cross',
  'theestoniaredcrosssociety',
  'the-estonia-red-cross-society',
  'theestonianredcross',
  'the-estonian-red-cross',
  'theestonianredcrosssociety',
  'the-estonian-red-cross-society',
  'theeswatiniredcross',
  'the-eswatini-red-cross',
  'theeswatiniredcrosssociety',
  'the-eswatini-red-cross-society',
  'theethiopiaredcross',
  'the-ethiopia-red-cross',
  'theethiopiaredcrosssociety',
  'the-ethiopia-red-cross-society',
  'theethiopiannationalredcross',
  'the-ethiopian-national-red-cross',
  'theethiopiannationalredcrosssoceity',
  'the-ethiopian-national-red-cross-soceity',
  'theethiopianredcross',
  'the-ethiopian-red-cross',
  'theethiopianredcrosssociety',
  'the-ethiopian-red-cross-society',
  'thefijiredcross',
  'the-fiji-red-cross',
  'thefijiredcrosssociety',
  'the-fiji-red-cross-society',
  'thefijianredcross',
  'the-fijian-red-cross',
  'thefijianredcrosssociety',
  'the-fijian-red-cross-society',
  'thefilipinoredcross',
  'the-filipino-red-cross',
  'thefilipinoredcrosssociety',
  'the-filipino-red-cross-society',
  'thefinlandredcross',
  'the-finland-red-cross',
  'thefinlandredcrosssociety',
  'the-finland-red-cross-society',
  'thefinnishredcross',
  'the-finnish-red-cross',
  'thefinnishredcrosssociety',
  'the-finnish-red-cross-society',
  'thefranceredcross',
  'the-france-red-cross',
  'thefranceredcrosssociety',
  'the-france-red-cross-society',
  'thefrenchredcross',
  'the-french-red-cross',
  'thefrenchredcrosssociety',
  'the-french-red-cross-society',
  'thegabonredcross',
  'the-gabon-red-cross',
  'thegabonredcrosssociety',
  'the-gabon-red-cross-society',
  'thegaboneseredcross',
  'the-gabonese-red-cross',
  'thegaboneseredcrosssociety',
  'the-gabonese-red-cross-society',
  'thegambiaredcross',
  'the-gambia-red-cross',
  'thegambiaredcrosssociety',
  'the-gambia-red-cross-society',
  'thegambianredcross',
  'the-gambian-red-cross',
  'thegambianredcrosssociety',
  'the-gambian-red-cross-society',
  'thegeorgiaredcross',
  'the-georgia-red-cross',
  'thegeorgiaredcrosssociety',
  'the-georgia-red-cross-society',
  'thegeorgianredcross',
  'the-georgian-red-cross',
  'thegeorgianredcrosssociety',
  'the-georgian-red-cross-society',
  'thegermanredcross',
  'the-german-red-cross',
  'thegermanredcrosssociety',
  'the-german-red-cross-society',
  'thegermanyredcross',
  'the-germany-red-cross',
  'thegermanyredcrosssociety',
  'the-germany-red-cross-society',
  'theghanaredcross',
  'the-ghana-red-cross',
  'theghanaredcrosssociety',
  'the-ghana-red-cross-society',
  'theghanaianredcross',
  'the-ghanaian-red-cross',
  'theghanaianredcrosssociety',
  'the-ghanaian-red-cross-society',
  'thegreeceredcross',
  'the-greece-red-cross',
  'thegreeceredcrosssociety',
  'the-greece-red-cross-society',
  'thegreekredcross',
  'the-greek-red-cross',
  'thegreekredcrosssociety',
  'the-greek-red-cross-society',
  'thegrenadaredcross',
  'the-grenada-red-cross',
  'thegrenadaredcrosssociety',
  'the-grenada-red-cross-society',
  'thegrenadanredcross',
  'the-grenadan-red-cross',
  'thegrenadanredcrosssociety',
  'the-grenadan-red-cross-society',
  'thegrenadianredcross',
  'the-grenadian-red-cross',
  'thegrenadianredcrosssociety',
  'the-grenadian-red-cross-society',
  'theguatemalaredcross',
  'the-guatemala-red-cross',
  'theguatemalaredcrosssociety',
  'the-guatemala-red-cross-society',
  'theguatemalanredcross',
  'the-guatemalan-red-cross',
  'theguatemalanredcrosssociety',
  'the-guatemalan-red-cross-society',
  'theguineabissauredcross',
  'the-guinea-bissau-red-cross',
  'theguineabissauredcrosssociety',
  'the-guinea-bissau-red-cross-society',
  'theguinearedcross',
  'the-guinea-red-cross',
  'theguinearedcrosssociety',
  'the-guinea-red-cross-society',
  'theguineanredcross',
  'the-guinean-red-cross',
  'theguineanredcrosssociety',
  'the-guinean-red-cross-society',
  'theguyanaredcross',
  'the-guyana-red-cross',
  'theguyanaredcrosssociety',
  'the-guyana-red-cross-society',
  'theguyaneseredcross',
  'the-guyanese-red-cross',
  'theguyaneseredcrosssociety',
  'the-guyanese-red-cross-society',
  'thehaitiredcross',
  'the-haiti-red-cross',
  'thehaitiredcrosssociety',
  'the-haiti-red-cross-society',
  'thehaitianredcross',
  'the-haitian-red-cross',
  'thehaitianredcrosssociety',
  'the-haitian-red-cross-society',
  'thehellenicredcross',
  'the-hellenic-red-cross',
  'thehellenicredcrosssociety',
  'the-hellenic-red-cross-society',
  'thehonduranredcross',
  'the-honduran-red-cross',
  'thehonduranredcrosssociety',
  'the-honduran-red-cross-society',
  'thehondurasredcross',
  'the-honduras-red-cross',
  'thehondurasredcrosssociety',
  'the-honduras-red-cross-society',
  'thehungarianredcross',
  'the-hungarian-red-cross',
  'thehungarianredcrosssociety',
  'the-hungarian-red-cross-society',
  'thehungaryredcross',
  'the-hungary-red-cross',
  'thehungaryredcrosssociety',
  'the-hungary-red-cross-society',
  'theicelandredcross',
  'the-iceland-red-cross',
  'theicelandredcrosssociety',
  'the-iceland-red-cross-society',
  'theicelandicredcross',
  'the-icelandic-red-cross',
  'theicelandicredcrosssociety',
  'the-icelandic-red-cross-society',
  'theindiaredcross',
  'the-india-red-cross',
  'theindiaredcrosssociety',
  'the-india-red-cross-society',
  'theindianredcross',
  'the-indian-red-cross',
  'theindianredcrosssociety',
  'the-indian-red-cross-society',
  'theindonesiaredcross',
  'the-indonesia-red-cross',
  'theindonesiaredcrosssociety',
  'the-indonesia-red-cross-society',
  'theindonesianredcross',
  'the-indonesian-red-cross',
  'theindonesianredcrosssociety',
  'the-indonesian-red-cross-society',
  'theiranredcrescent',
  'the-iran-red-crescent',
  'theiranredcrescentsociety',
  'the-iran-red-crescent-society',
  'theiranianredcrescent',
  'the-iranian-red-crescent',
  'theiranianredcrescentsociety',
  'the-iranian-red-crescent-society',
  'theiraqredcrescent',
  'the-iraq-red-crescent',
  'theiraqredcrescentsociety',
  'the-iraq-red-crescent-society',
  'theiraqiredcrescent',
  'the-iraqi-red-crescent',
  'theiraqiredcrescentsociety',
  'the-iraqi-red-crescent-society',
  'theirelandredcross',
  'the-ireland-red-cross',
  'theirelandredcrosssociety',
  'the-ireland-red-cross-society',
  'theirishredcross',
  'the-irish-red-cross',
  'theirishredcrosssociety',
  'the-irish-red-cross-society',
  'theislamicrepublicofiranredcrescent',
  'the-islamic-republic-of-iran-red-crescent',
  'theislamicrepublicofiranredcrescentsociety',
  'the-islamic-republic-of-iran-red-crescent-society',
  'theitalianredcross',
  'the-italian-red-cross',
  'theitalianredcrosssociety',
  'the-italian-red-cross-society',
  'theitalyredcross',
  'the-italy-red-cross',
  'theitalyredcrosssociety',
  'the-italy-red-cross-society',
  'theivorianredcross',
  'the-ivorian-red-cross',
  'theivorianredcrosssociety',
  'the-ivorian-red-cross-society',
  'theivorycoastredcross',
  'the-ivory-coast-red-cross',
  'theivorycoastredcrosssociety',
  'the-ivory-coast-red-cross-society',
  'thejamaicaredcross',
  'the-jamaica-red-cross',
  'thejamaicaredcrosssociety',
  'the-jamaica-red-cross-society',
  'thejamaicanredcross',
  'the-jamaican-red-cross',
  'thejamaicanredcrosssociety',
  'the-jamaican-red-cross-society',
  'thejapanredcross',
  'the-japan-red-cross',
  'thejapanredcrosssociety',
  'the-japan-red-cross-society',
  'thejapaneseredcross',
  'the-japanese-red-cross',
  'thejapaneseredcrosssociety',
  'the-japanese-red-cross-society',
  'thejordannationalredcrescentsociety',
  'the-jordan-national-red-crescent-society',
  'thejordanredcrescent',
  'the-jordan-red-crescent',
  'thejordanredcrescentsociety',
  'the-jordan-red-crescent-society',
  'thejordanianredcrescent',
  'the-jordanian-red-crescent',
  'thejordanianredcrescentsociety',
  'the-jordanian-red-crescent-society',
  'thekazakhredcrescent',
  'the-kazakh-red-crescent',
  'thekazakhredcrescentsociety',
  'the-kazakh-red-crescent-society',
  'thekazakhstanredcrescent',
  'the-kazakhstan-red-crescent',
  'thekazakhstanredcrescentsociety',
  'the-kazakhstan-red-crescent-society',
  'thekenyaredcross',
  'the-kenya-red-cross',
  'thekenyaredcrosssociety',
  'the-kenya-red-cross-society',
  'thekenyanredcross',
  'the-kenyan-red-cross',
  'thekenyanredcrosssociety',
  'the-kenyan-red-cross-society',
  'thekiribatiredcross',
  'the-kiribati-red-cross',
  'thekiribatiredcrosssociety',
  'the-kiribati-red-cross-society',
  'thekiribatianredcross',
  'the-kiribatian-red-cross',
  'thekiribatianredcrosssociety',
  'the-kiribatian-red-cross-society',
  'thekoreanredcross',
  'the-korean-red-cross',
  'thekoreanredcrosssociety',
  'the-korean-red-cross-society',
  'thekuwaitredcrescent',
  'the-kuwait-red-crescent',
  'thekuwaitredcrescentsociety',
  'the-kuwait-red-crescent-society',
  'thekuwaitiredcrescent',
  'the-kuwaiti-red-crescent',
  'thekuwaitiredcrescentsociety',
  'the-kuwaiti-red-crescent-society',
  'thekyrgyzredcrescent',
  'the-kyrgyz-red-crescent',
  'thekyrgyzredcrescentsociety',
  'the-kyrgyz-red-crescent-society',
  'thekyrgyzstanredcrescent',
  'the-kyrgyzstan-red-crescent',
  'thekyrgyzstanredcrescentsociety',
  'the-kyrgyzstan-red-crescent-society',
  'thelaoredcross',
  'the-lao-red-cross',
  'thelaoredcrosssociety',
  'the-lao-red-cross-society',
  'thelaosredcross',
  'the-laos-red-cross',
  'thelaosredcrosssociety',
  'the-laos-red-cross-society',
  'thelatviaredcross',
  'the-latvia-red-cross',
  'thelatviaredcrosssociety',
  'the-latvia-red-cross-society',
  'thelatvianredcross',
  'the-latvian-red-cross',
  'thelatvianredcrosssociety',
  'the-latvian-red-cross-society',
  'thelebaneseredcross',
  'the-lebanese-red-cross',
  'thelebaneseredcrosssociety',
  'the-lebanese-red-cross-society',
  'thelebanonredcross',
  'the-lebanon-red-cross',
  'thelebanonredcrosssociety',
  'the-lebanon-red-cross-society',
  'thelesothanredcross',
  'the-lesothan-red-cross',
  'thelesothanredcrosssociety',
  'the-lesothan-red-cross-society',
  'thelesothoredcross',
  'the-lesotho-red-cross',
  'thelesothoredcrosssociety',
  'the-lesotho-red-cross-society',
  'theliberiaredcross',
  'the-liberia-red-cross',
  'theliberiaredcrosssociety',
  'the-liberia-red-cross-society',
  'theliberianredcross',
  'the-liberian-red-cross',
  'theliberianredcrosssociety',
  'the-liberian-red-cross-society',
  'thelibyaredcrescent',
  'the-libya-red-crescent',
  'thelibyaredcrescentsociety',
  'the-libya-red-crescent-society',
  'thelibyanredcrescent',
  'the-libyan-red-crescent',
  'thelibyanredcrescentsociety',
  'the-libyan-red-crescent-society',
  'theliechtensteinredcross',
  'the-liechtenstein-red-cross',
  'theliechtensteinredcrosssociety',
  'the-liechtenstein-red-cross-society',
  'thelithuaniaredcross',
  'the-lithuania-red-cross',
  'thelithuaniaredcrosssociety',
  'the-lithuania-red-cross-society',
  'thelithuanianredcross',
  'the-lithuanian-red-cross',
  'thelithuanianredcrosssociety',
  'the-lithuanian-red-cross-society',
  'theluxembourgredcross',
  'the-luxembourg-red-cross',
  'theluxembourgredcrosssociety',
  'the-luxembourg-red-cross-society',
  'theluxembourgianredcross',
  'the-luxembourgian-red-cross',
  'theluxembourgianredcrosssociety',
  'the-luxembourgian-red-cross-society',
  'themacedoniaredcross',
  'the-macedonia-red-cross',
  'themacedoniaredcrosssociety',
  'the-macedonia-red-cross-society',
  'themacedonianredcross',
  'the-macedonian-red-cross',
  'themacedonianredcrosssociety',
  'the-macedonian-red-cross-society',
  'themadagascarredcross',
  'the-madagascar-red-cross',
  'themadagascarredcrosssociety',
  'the-madagascar-red-cross-society',
  'themagendavidadom',
  'the-magen-david-adom',
  'themagendavidadominisrael',
  'the-magen-david-adom-in-israel',
  'themagendavidadominisraelsociety',
  'the-magen-david-adom-in-israel-society',
  'themalagasyredcross',
  'the-malagasy-red-cross',
  'themalagasyredcrosssociety',
  'the-malagasy-red-cross-society',
  'themalawiredcross',
  'the-malawi-red-cross',
  'themalawiredcrosssociety',
  'the-malawi-red-cross-society',
  'themalawianredcross',
  'the-malawian-red-cross',
  'themalawianredcrosssociety',
  'the-malawian-red-cross-society',
  'themalaysiaredcrescent',
  'the-malaysia-red-crescent',
  'themalaysiaredcrescentsociety',
  'the-malaysia-red-crescent-society',
  'themalaysianredcrescent',
  'the-malaysian-red-crescent',
  'themalaysianredcrescentsociety',
  'the-malaysian-red-crescent-society',
  'themaldivesredcrescent',
  'the-maldives-red-crescent',
  'themaldivesredcrescentsociety',
  'the-maldives-red-crescent-society',
  'themaldivianredcrescent',
  'the-maldivian-red-crescent',
  'themaldivianredcrescentsociety',
  'the-maldivian-red-crescent-society',
  'themaliredcross',
  'the-mali-red-cross',
  'themaliredcrosssociety',
  'the-mali-red-cross-society',
  'themalianredcross',
  'the-malian-red-cross',
  'themalianredcrosssociety',
  'the-malian-red-cross-society',
  'themaltaredcross',
  'the-malta-red-cross',
  'themaltaredcrosssociety',
  'the-malta-red-cross-society',
  'themalteseredcross',
  'the-maltese-red-cross',
  'themalteseredcrosssociety',
  'the-maltese-red-cross-society',
  'themarshallislandsredcross',
  'the-marshall-islands-red-cross',
  'themarshallislandsredcrosssociety',
  'the-marshall-islands-red-cross-society',
  'themarshalleseredcross',
  'the-marshallese-red-cross',
  'themarshalleseredcrosssociety',
  'the-marshallese-red-cross-society',
  'themauritaniaredcrescent',
  'the-mauritania-red-crescent',
  'themauritaniaredcrescentsociety',
  'the-mauritania-red-crescent-society',
  'themauritanianredcrescent',
  'the-mauritanian-red-crescent',
  'themauritanianredcrescentsociety',
  'the-mauritanian-red-crescent-society',
  'themauritianredcross',
  'the-mauritian-red-cross',
  'themauritianredcrosssociety',
  'the-mauritian-red-cross-society',
  'themauritiusredcross',
  'the-mauritius-red-cross',
  'themauritiusredcrosssociety',
  'the-mauritius-red-cross-society',
  'themexicanredcross',
  'the-mexican-red-cross',
  'themexicanredcrosssociety',
  'the-mexican-red-cross-society',
  'themexicoredcross',
  'the-mexico-red-cross',
  'themexicoredcrosssociety',
  'the-mexico-red-cross-society',
  'themicronesiaredcross',
  'the-micronesia-red-cross',
  'themicronesiaredcrosssociety',
  'the-micronesia-red-cross-society',
  'themicronesianredcross',
  'the-micronesian-red-cross',
  'themicronesianredcrosssociety',
  'the-micronesian-red-cross-society',
  'themoldovaredcross',
  'the-moldova-red-cross',
  'themoldovaredcrosssociety',
  'the-moldova-red-cross-society',
  'themoldovanredcross',
  'the-moldovan-red-cross',
  'themoldovanredcrosssociety',
  'the-moldovan-red-cross-society',
  'themonacanredcross',
  'the-monacan-red-cross',
  'themonacanredcrosssociety',
  'the-monacan-red-cross-society',
  'themonacoredcross',
  'the-monaco-red-cross',
  'themonacoredcrosssociety',
  'the-monaco-red-cross-society',
  'themongoliaredcross',
  'the-mongolia-red-cross',
  'themongoliaredcrosssociety',
  'the-mongolia-red-cross-society',
  'themongolianredcross',
  'the-mongolian-red-cross',
  'themongolianredcrosssociety',
  'the-mongolian-red-cross-society',
  'themontenegrinredcross',
  'the-montenegrin-red-cross',
  'themontenegrinredcrosssociety',
  'the-montenegrin-red-cross-society',
  'themontenegroredcross',
  'the-montenegro-red-cross',
  'themontenegroredcrosssociety',
  'the-montenegro-red-cross-society',
  'themoroccanredcrescent',
  'the-moroccan-red-crescent',
  'themoroccanredcrescentsociety',
  'the-moroccan-red-crescent-society',
  'themoroccoredcrescent',
  'the-morocco-red-crescent',
  'themoroccoredcrescentsociety',
  'the-morocco-red-crescent-society',
  'themozambicanredcross',
  'the-mozambican-red-cross',
  'themozambicanredcrosssociety',
  'the-mozambican-red-cross-society',
  'themozambiqueredcross',
  'the-mozambique-red-cross',
  'themozambiqueredcrosssociety',
  'the-mozambique-red-cross-society',
  'themyanmarredcross',
  'the-myanmar-red-cross',
  'themyanmarredcrosssociety',
  'the-myanmar-red-cross-society',
  'themyanmareseredcross',
  'the-myanmarese-red-cross',
  'themyanmareseredcrosssociety',
  'the-myanmarese-red-cross-society',
  'thenamibiaredcross',
  'the-namibia-red-cross',
  'thenamibiaredcrosssociety',
  'the-namibia-red-cross-society',
  'thenamibianredcross',
  'the-namibian-red-cross',
  'thenamibianredcrosssociety',
  'the-namibian-red-cross-society',
  'thenationalredcrescentofturkmenistan',
  'the-national-red-crescent-of-turkmenistan',
  'thenationalredcrescentsocietyofkyrgyzstan',
  'the-national-red-crescent-society-of-kyrgyzstan',
  'thenationalredcrescentsocietyofturkmenistan',
  'the-national-red-crescent-society-of-turkmenistan',
  'thenationalredcrosssocietyofargentina',
  'the-national-red-cross-society-of-argentina',
  'thenationalredcrosssocietyofbenin',
  'the-national-red-cross-society-of-benin',
  'thenationalredcrosssocietyofburkinafaso',
  'the-national-red-cross-society-of-burkina-faso',
  'xn--thenationalredcrosssocietyofctedivoire-dzd',
  'xn--the-national-red-cross-society-of-cte-d-ivoire-1qe',
  'thenationalredcrosssocietyofliberia',
  'the-national-red-cross-society-of-liberia',
  'thenationalredcrosssocietyoftogo',
  'the-national-red-cross-society-of-togo',
  'thenationalsocietyofthecameroonianredcross',
  'the-national-society-of-the-cameroonian-red-cross',
  'thenationalsocietyofthecongoleseredcross',
  'the-national-society-of-the-congolese-red-cross',
  'thenationalsocietyofthegaboneseredcross',
  'the-national-society-of-the-gabonese-red-cross',
  'thenationalsocietyofthemalianredcross',
  'the-national-society-of-the-malian-red-cross',
  'thenationalsocietyofthepanamanianredcross',
  'the-national-society-of-the-panamanian-red-cross',
  'thenationalsocietyoftheredcrossofcamerooon',
  'the-national-society-of-the-red-cross-of-camerooon',
  'thenationalsocietyoftheredcrossofcongo',
  'the-national-society-of-the-red-cross-of-congo',
  'thenationalsocietyoftheredcrossofequatorialguinea',
  'the-national-society-of-the-red-cross-of-equatorial-guinea',
  'thenationalsocietyoftheredcrossofgabon',
  'the-national-society-of-the-red-cross-of-gabon',
  'thenationalsocietyoftheredcrossofmali',
  'the-national-society-of-the-red-cross-of-mali',
  'thenationalsocietyoftheredcrossofsaotomeandprincipe',
  'the-national-society-of-the-red-cross-of-sao-tome-and-principe',
  'thenepalredcross',
  'the-nepal-red-cross',
  'thenepalredcrosssociety',
  'the-nepal-red-cross-society',
  'thenepaleseredcross',
  'the-nepalese-red-cross',
  'thenepaleseredcrosssociety',
  'the-nepalese-red-cross-society',
  'thenetherlandsredcross',
  'the-netherlands-red-cross',
  'thenetherlandsredcrosssociety',
  'the-netherlands-red-cross-society',
  'thenewzealandredcross',
  'the-new-zealand-red-cross',
  'thenewzealandredcrosssociety',
  'the-new-zealand-red-cross-society',
  'thenicaraguaredcross',
  'the-nicaragua-red-cross',
  'thenicaraguaredcrosssociety',
  'the-nicaragua-red-cross-society',
  'thenicaraguanredcross',
  'the-nicaraguan-red-cross',
  'thenicaraguanredcrosssociety',
  'the-nicaraguan-red-cross-society',
  'thenigerredcross',
  'the-niger-red-cross',
  'thenigerredcrosssociety',
  'the-niger-red-cross-society',
  'thenigeriaredcross',
  'the-nigeria-red-cross',
  'thenigeriaredcrosssociety',
  'the-nigeria-red-cross-society',
  'thenigerianredcross',
  'the-nigerian-red-cross',
  'thenigerianredcrosssociety',
  'the-nigerian-red-cross-society',
  'thenigerienredcross',
  'the-nigerien-red-cross',
  'thenigerienredcrosssociety',
  'the-nigerien-red-cross-society',
  'thenorthkoreanredcross',
  'the-north-korean-red-cross',
  'thenorthkoreanredcrosssociety',
  'the-north-korean-red-cross-society',
  'thenorthmacedoniaredcross',
  'the-north-macedonia-red-cross',
  'thenorthmacedoniaredcrosssociety',
  'the-north-macedonia-red-cross-society',
  'thenorthmacedonianredcross',
  'the-north-macedonian-red-cross',
  'thenorthmacedonianredcrosssociety',
  'the-north-macedonian-red-cross-society',
  'thenorwayredcross',
  'the-norway-red-cross',
  'thenorwayredcrosssociety',
  'the-norway-red-cross-society',
  'thenorwegianredcross',
  'the-norwegian-red-cross',
  'thenorwegianredcrosssociety',
  'the-norwegian-red-cross-society',
  'thepakistanredcrescent',
  'the-pakistan-red-crescent',
  'thepakistanredcrescentsociety',
  'the-pakistan-red-crescent-society',
  'thepakistaniredcrescent',
  'the-pakistani-red-crescent',
  'thepakistaniredcrescentsociety',
  'the-pakistani-red-crescent-society',
  'thepalauredcross',
  'the-palau-red-cross',
  'thepalauredcrosssociety',
  'the-palau-red-cross-society',
  'thepalauanredcross',
  'the-palauan-red-cross',
  'thepalauanredcrosssociety',
  'the-palauan-red-cross-society',
  'thepalestineredcrescent',
  'the-palestine-red-crescent',
  'thepalestineredcrescentsociety',
  'the-palestine-red-crescent-society',
  'thepalestinianredcrescent',
  'the-palestinian-red-crescent',
  'thepalestinianredcrescentsociety',
  'the-palestinian-red-crescent-society',
  'thepanamaredcross',
  'the-panama-red-cross',
  'thepanamaredcrosssociety',
  'the-panama-red-cross-society',
  'thepanamanianredcross',
  'the-panamanian-red-cross',
  'thepanamanianredcrosssociety',
  'the-panamanian-red-cross-society',
  'thepapuanewguinearedcross',
  'the-papua-new-guinea-red-cross',
  'thepapuanewguinearedcrosssociety',
  'the-papua-new-guinea-red-cross-society',
  'theparaguayredcross',
  'the-paraguay-red-cross',
  'theparaguayredcrosssociety',
  'the-paraguay-red-cross-society',
  'theparaguayanredcross',
  'the-paraguayan-red-cross',
  'theparaguayanredcrosssociety',
  'the-paraguayan-red-cross-society',
  'theperuredcross',
  'the-peru-red-cross',
  'theperuredcrosssociety',
  'the-peru-red-cross-society',
  'theperuvianredcross',
  'the-peruvian-red-cross',
  'theperuvianredcrosssociety',
  'the-peruvian-red-cross-society',
  'thephilippineredcross',
  'the-philippine-red-cross',
  'thephilippineredcrosssociety',
  'the-philippine-red-cross-society',
  'thephilippinesredcross',
  'the-philippines-red-cross',
  'thephilippinesredcrosssociety',
  'the-philippines-red-cross-society',
  'thepolandredcross',
  'the-poland-red-cross',
  'thepolandredcrosssociety',
  'the-poland-red-cross-society',
  'thepolishredcross',
  'the-polish-red-cross',
  'thepolishredcrosssociety',
  'the-polish-red-cross-society',
  'theportugalredcross',
  'the-portugal-red-cross',
  'theportugalredcrosssociety',
  'the-portugal-red-cross-society',
  'theportugueseredcross',
  'the-portuguese-red-cross',
  'theportugueseredcrosssociety',
  'the-portuguese-red-cross-society',
  'theqatarredcrescent',
  'the-qatar-red-crescent',
  'theqatarredcrescentsociety',
  'the-qatar-red-crescent-society',
  'theqatariredcrescent',
  'the-qatari-red-crescent',
  'theqatariredcrescentsociety',
  'the-qatari-red-crescent-society',
  'theredcrescentauthorityoftheunitedarabemirates',
  'the-red-crescent-authority-of-the-united-arab-emirates',
  'theredcrescentinpalestine',
  'the-red-crescent-in-palestine',
  'theredcrescentnationalsocietyofthekyrgyzrepublic',
  'the-red-crescent-national-society-of-the-kyrgyz-republic',
  'theredcrescentofafghanistan',
  'the-red-crescent-of-afghanistan',
  'theredcrescentofalgeria',
  'the-red-crescent-of-algeria',
  'theredcrescentofazerbaijan',
  'the-red-crescent-of-azerbaijan',
  'theredcrescentofbahrain',
  'the-red-crescent-of-bahrain',
  'theredcrescentofbrunei',
  'the-red-crescent-of-brunei',
  'theredcrescentofbruneidarussalam',
  'the-red-crescent-of-brunei-darussalam',
  'theredcrescentofcomoros',
  'the-red-crescent-of-comoros',
  'theredcrescentofdjibouti',
  'the-red-crescent-of-djibouti',
  'theredcrescentofegypt',
  'the-red-crescent-of-egypt',
  'theredcrescentofiran',
  'the-red-crescent-of-iran',
  'theredcrescentofiraq',
  'the-red-crescent-of-iraq',
  'theredcrescentofjordan',
  'the-red-crescent-of-jordan',
  'theredcrescentofkazakhstan',
  'the-red-crescent-of-kazakhstan',
  'theredcrescentofkuwait',
  'the-red-crescent-of-kuwait',
  'theredcrescentofkyrgyzstan',
  'the-red-crescent-of-kyrgyzstan',
  'theredcrescentoflibya',
  'the-red-crescent-of-libya',
  'theredcrescentofmalaysia',
  'the-red-crescent-of-malaysia',
  'theredcrescentofmauritania',
  'the-red-crescent-of-mauritania',
  'theredcrescentofmorocco',
  'the-red-crescent-of-morocco',
  'theredcrescentofpakistan',
  'the-red-crescent-of-pakistan',
  'theredcrescentofpalestine',
  'the-red-crescent-of-palestine',
  'theredcrescentofqatar',
  'the-red-crescent-of-qatar',
  'theredcrescentofsaudiarabia',
  'the-red-crescent-of-saudi-arabia',
  'theredcrescentofsomalia',
  'the-red-crescent-of-somalia',
  'theredcrescentofsomaliland',
  'the-red-crescent-of-somaliland',
  'theredcrescentofsudan',
  'the-red-crescent-of-sudan',
  'theredcrescentofsyria',
  'the-red-crescent-of-syria',
  'theredcrescentoftajikistan',
  'the-red-crescent-of-tajikistan',
  'theredcrescentoftheislamicrepublicofiran',
  'the-red-crescent-of-the-islamic-republic-of-iran',
  'theredcrescentofthemaldives',
  'the-red-crescent-of-the-maldives',
  'theredcrescentoftherepublicofkazakhstan',
  'the-red-crescent-of-the-republic-of-kazakhstan',
  'theredcrescentoftherepublicofthemaldives',
  'the-red-crescent-of-the-republic-of-the-maldives',
  'theredcrescentofthesyrianarabrepublic',
  'the-red-crescent-of-the-syrian-arab-republic',
  'theredcrescentoftheunitedarabemirates',
  'the-red-crescent-of-the-united-arab-emirates',
  'theredcrescentoftunisia',
  'the-red-crescent-of-tunisia',
  'theredcrescentofturkey',
  'the-red-crescent-of-turkey',
  'theredcrescentofturkmenistan',
  'the-red-crescent-of-turkmenistan',
  'theredcrescentofuzbekistan',
  'the-red-crescent-of-uzbekistan',
  'theredcrescentofyemen',
  'the-red-crescent-of-yemen',
  'theredcrescentsocietyofafghanistan',
  'the-red-crescent-society-of-afghanistan',
  'theredcrescentsocietyofalgeria',
  'the-red-crescent-society-of-algeria',
  'theredcrescentsocietyofazerbaijan',
  'the-red-crescent-society-of-azerbaijan',
  'theredcrescentsocietyofbahrain',
  'the-red-crescent-society-of-bahrain',
  'theredcrescentsocietyofbrunei',
  'the-red-crescent-society-of-brunei',
  'theredcrescentsocietyofbruneidarussalam',
  'the-red-crescent-society-of-brunei-darussalam',
  'theredcrescentsocietyofcomoros',
  'the-red-crescent-society-of-comoros',
  'theredcrescentsocietyofdjibouti',
  'the-red-crescent-society-of-djibouti',
  'theredcrescentsocietyofegypt',
  'the-red-crescent-society-of-egypt',
  'theredcrescentsocietyofiran',
  'the-red-crescent-society-of-iran',
  'theredcrescentsocietyofiraq',
  'the-red-crescent-society-of-iraq',
  'theredcrescentsocietyofjordan',
  'the-red-crescent-society-of-jordan',
  'theredcrescentsocietyofkazakhstan',
  'the-red-crescent-society-of-kazakhstan',
  'theredcrescentsocietyofkuwait',
  'the-red-crescent-society-of-kuwait',
  'theredcrescentsocietyofkyrgyzstan',
  'the-red-crescent-society-of-kyrgyzstan',
  'theredcrescentsocietyoflibya',
  'the-red-crescent-society-of-libya',
  'theredcrescentsocietyofmalaysia',
  'the-red-crescent-society-of-malaysia',
  'theredcrescentsocietyofmauritania',
  'the-red-crescent-society-of-mauritania',
  'theredcrescentsocietyofmorocco',
  'the-red-crescent-society-of-morocco',
  'theredcrescentsocietyofpakistan',
  'the-red-crescent-society-of-pakistan',
  'theredcrescentsocietyofpalestine',
  'the-red-crescent-society-of-palestine',
  'theredcrescentsocietyofqatar',
  'the-red-crescent-society-of-qatar',
  'theredcrescentsocietyofsaudiarabia',
  'the-red-crescent-society-of-saudi-arabia',
  'theredcrescentsocietyofsomalia',
  'the-red-crescent-society-of-somalia',
  'theredcrescentsocietyofsomaliland',
  'the-red-crescent-society-of-somaliland',
  'theredcrescentsocietyofsudan',
  'the-red-crescent-society-of-sudan',
  'theredcrescentsocietyofsyria',
  'the-red-crescent-society-of-syria',
  'theredcrescentsocietyoftajikistan',
  'the-red-crescent-society-of-tajikistan',
  'theredcrescentsocietyoftheislamicrepublicofiran',
  'the-red-crescent-society-of-the-islamic-republic-of-iran',
  'theredcrescentsocietyofthemaldives',
  'the-red-crescent-society-of-the-maldives',
  'theredcrescentsocietyoftherepublicofkazakhstan',
  'the-red-crescent-society-of-the-republic-of-kazakhstan',
  'theredcrescentsocietyoftherepublicofthemaldives',
  'the-red-crescent-society-of-the-republic-of-the-maldives',
  'theredcrescentsocietyoftherepublicofuzbekistan',
  'the-red-crescent-society-of-the-republic-of-uzbekistan',
  'theredcrescentsocietyofthesyrianarabrepublic',
  'the-red-crescent-society-of-the-syrian-arab-republic',
  'theredcrescentsocietyoftheunitedarabemirates',
  'the-red-crescent-society-of-the-united-arab-emirates',
  'theredcrescentsocietyoftunisia',
  'the-red-crescent-society-of-tunisia',
  'theredcrescentsocietyofturkey',
  'the-red-crescent-society-of-turkey',
  'theredcrescentsocietyofturkmenistan',
  'the-red-crescent-society-of-turkmenistan',
  'theredcrescentsocietyofuzbekistan',
  'the-red-crescent-society-of-uzbekistan',
  'theredcrescentsocietyofyemen',
  'the-red-crescent-society-of-yemen',
  'theredcrossgermany',
  'the-red-cross-germany',
  'xn--theredcrossnationalsocietyofsotomandprncipe-kud8yvg',
  'theredcrossofalbania',
  'the-red-cross-of-albania',
  'theredcrossofamerica',
  'the-red-cross-of-america',
  'theredcrossofandorra',
  'the-red-cross-of-andorra',
  'theredcrossofangola',
  'the-red-cross-of-angola',
  'theredcrossofantiguaandbarbuda',
  'the-red-cross-of-antigua-and-barbuda',
  'theredcrossofargentina',
  'the-red-cross-of-argentina',
  'theredcrossofarmenia',
  'the-red-cross-of-armenia',
  'theredcrossofaustralia',
  'the-red-cross-of-australia',
  'theredcrossofaustria',
  'the-red-cross-of-austria',
  'theredcrossofbaphalalieswatini',
  'the-red-cross-of-baphalali-eswatini',
  'theredcrossofbaphalaliswaziland',
  'the-red-cross-of-baphalali-swaziland',
  'theredcrossofbarbados',
  'the-red-cross-of-barbados',
  'theredcrossofbelarus',
  'the-red-cross-of-belarus',
  'theredcrossofbelgium',
  'the-red-cross-of-belgium',
  'theredcrossofbelize',
  'the-red-cross-of-belize',
  'theredcrossofbenin',
  'the-red-cross-of-benin',
  'theredcrossofbolivia',
  'the-red-cross-of-bolivia',
  'theredcrossofbosniaandherzegovina',
  'the-red-cross-of-bosnia-and-herzegovina',
  'theredcrossofbotswana',
  'the-red-cross-of-botswana',
  'theredcrossofbrazil',
  'the-red-cross-of-brazil',
  'theredcrossofbulgaria',
  'the-red-cross-of-bulgaria',
  'theredcrossofburkinafaso',
  'the-red-cross-of-burkina-faso',
  'theredcrossofburundi',
  'the-red-cross-of-burundi',
  'theredcrossofcambodia',
  'the-red-cross-of-cambodia',
  'theredcrossofcameroon',
  'the-red-cross-of-cameroon',
  'theredcrossofcanada',
  'the-red-cross-of-canada',
  'theredcrossofcapeverde',
  'the-red-cross-of-cape-verde',
  'theredcrossofchad',
  'the-red-cross-of-chad',
  'theredcrossofchile',
  'the-red-cross-of-chile',
  'theredcrossofchina',
  'the-red-cross-of-china',
  'theredcrossofcolombia',
  'the-red-cross-of-colombia',
  'theredcrossofcongo',
  'the-red-cross-of-congo',
  'theredcrossofcostarica',
  'the-red-cross-of-costa-rica',
  'xn--theredcrossofctedivoire-7jc',
  'xn--the-red-cross-of-cte-d-ivoire-73c',
  'theredcrossofcroatia',
  'the-red-cross-of-croatia',
  'theredcrossofcuba',
  'the-red-cross-of-cuba',
  'theredcrossofcyprus',
  'the-red-cross-of-cyprus',
  'theredcrossofdenmark',
  'the-red-cross-of-denmark',
  'theredcrossofdominica',
  'the-red-cross-of-dominica',
  'theredcrossofecuador',
  'the-red-cross-of-ecuador',
  'theredcrossofelsalvador',
  'the-red-cross-of-el-salvador',
  'theredcrossofequatorialguinea',
  'the-red-cross-of-equatorial-guinea',
  'theredcrossofestonia',
  'the-red-cross-of-estonia',
  'theredcrossofeswatini',
  'the-red-cross-of-eswatini',
  'theredcrossofethiopia',
  'the-red-cross-of-ethiopia',
  'theredcrossoffiji',
  'the-red-cross-of-fiji',
  'theredcrossoffinland',
  'the-red-cross-of-finland',
  'theredcrossoffrance',
  'the-red-cross-of-france',
  'theredcrossofgabon',
  'the-red-cross-of-gabon',
  'theredcrossofgambia',
  'the-red-cross-of-gambia',
  'theredcrossofgeorgia',
  'the-red-cross-of-georgia',
  'theredcrossofgermany',
  'the-red-cross-of-germany',
  'theredcrossofghana',
  'the-red-cross-of-ghana',
  'theredcrossofgreece',
  'the-red-cross-of-greece',
  'theredcrossofgrenada',
  'the-red-cross-of-grenada',
  'theredcrossofguatemala',
  'the-red-cross-of-guatemala',
  'theredcrossofguinea',
  'the-red-cross-of-guinea',
  'theredcrossofguineabissau',
  'the-red-cross-of-guinea-bissau',
  'theredcrossofguyana',
  'the-red-cross-of-guyana',
  'theredcrossofhaiti',
  'the-red-cross-of-haiti',
  'theredcrossofhonduras',
  'the-red-cross-of-honduras',
  'theredcrossofhungary',
  'the-red-cross-of-hungary',
  'theredcrossoficeland',
  'the-red-cross-of-iceland',
  'theredcrossofindia',
  'the-red-cross-of-india',
  'theredcrossofindonesia',
  'the-red-cross-of-indonesia',
  'theredcrossofireland',
  'the-red-cross-of-ireland',
  'theredcrossofitaly',
  'the-red-cross-of-italy',
  'theredcrossofivorycoast',
  'the-red-cross-of-ivory-coast',
  'theredcrossofjamaica',
  'the-red-cross-of-jamaica',
  'theredcrossofjapan',
  'the-red-cross-of-japan',
  'theredcrossofkenya',
  'the-red-cross-of-kenya',
  'theredcrossofkiribati',
  'the-red-cross-of-kiribati',
  'theredcrossoflaos',
  'the-red-cross-of-laos',
  'theredcrossoflatvia',
  'the-red-cross-of-latvia',
  'theredcrossoflebanon',
  'the-red-cross-of-lebanon',
  'theredcrossofliberia',
  'the-red-cross-of-liberia',
  'theredcrossofliechtenstein',
  'the-red-cross-of-liechtenstein',
  'theredcrossoflithuania',
  'the-red-cross-of-lithuania',
  'theredcrossofluxembourg',
  'the-red-cross-of-luxembourg',
  'theredcrossofmacedonia',
  'the-red-cross-of-macedonia',
  'theredcrossofmadagascar',
  'the-red-cross-of-madagascar',
  'theredcrossofmalawi',
  'the-red-cross-of-malawi',
  'theredcrossofmali',
  'the-red-cross-of-mali',
  'theredcrossofmalta',
  'the-red-cross-of-malta',
  'theredcrossofmauritius',
  'the-red-cross-of-mauritius',
  'theredcrossofmexico',
  'the-red-cross-of-mexico',
  'theredcrossofmicronesia',
  'the-red-cross-of-micronesia',
  'theredcrossofmoldova',
  'the-red-cross-of-moldova',
  'theredcrossofmonaco',
  'the-red-cross-of-monaco',
  'theredcrossofmongolia',
  'the-red-cross-of-mongolia',
  'theredcrossofmontenegro',
  'the-red-cross-of-montenegro',
  'theredcrossofmozambique',
  'the-red-cross-of-mozambique',
  'theredcrossofmyanmar',
  'the-red-cross-of-myanmar',
  'theredcrossofnamibia',
  'the-red-cross-of-namibia',
  'theredcrossofnepal',
  'the-red-cross-of-nepal',
  'theredcrossofnewzealand',
  'the-red-cross-of-new-zealand',
  'theredcrossofnicaragua',
  'the-red-cross-of-nicaragua',
  'theredcrossofniger',
  'the-red-cross-of-niger',
  'theredcrossofnigeria',
  'the-red-cross-of-nigeria',
  'theredcrossofnorthmacedonia',
  'the-red-cross-of-north-macedonia',
  'theredcrossofnorway',
  'the-red-cross-of-norway',
  'theredcrossofpalau',
  'the-red-cross-of-palau',
  'theredcrossofpanama',
  'the-red-cross-of-panama',
  'theredcrossofpapuanewguinea',
  'the-red-cross-of-papua-new-guinea',
  'theredcrossofparaguay',
  'the-red-cross-of-paraguay',
  'theredcrossofperu',
  'the-red-cross-of-peru',
  'theredcrossofpoland',
  'the-red-cross-of-poland',
  'theredcrossofportugal',
  'the-red-cross-of-portugal',
  'theredcrossofromania',
  'the-red-cross-of-romania',
  'theredcrossofrussia',
  'the-red-cross-of-russia',
  'theredcrossofrwanda',
  'the-red-cross-of-rwanda',
  'theredcrossofsaintkittsandnevis',
  'the-red-cross-of-saint-kitts-and-nevis',
  'theredcrossofsaintlucia',
  'the-red-cross-of-saint-lucia',
  'theredcrossofsaintvincentandthegrenadines',
  'the-red-cross-of-saint-vincent-and-the-grenadines',
  'theredcrossofsamoa',
  'the-red-cross-of-samoa',
  'theredcrossofsanmarino',
  'the-red-cross-of-san-marino',
  'xn--theredcrossofsotomandprncipe-pmc8p3e',
  'xn--the-red-cross-of-so-tom-and-prncipe-m6c1uyf',
  'theredcrossofsenegal',
  'the-red-cross-of-senegal',
  'theredcrossofserbia',
  'the-red-cross-of-serbia',
  'theredcrossofseychelles',
  'the-red-cross-of-seychelles',
  'theredcrossofsierraleone',
  'the-red-cross-of-sierra-leone',
  'theredcrossofsingapore',
  'the-red-cross-of-singapore',
  'theredcrossofslovakia',
  'the-red-cross-of-slovakia',
  'theredcrossofslovenia',
  'the-red-cross-of-slovenia',
  'theredcrossofsouthafrica',
  'the-red-cross-of-south-africa',
  'theredcrossofsouthsudan',
  'the-red-cross-of-south-sudan',
  'theredcrossofspain',
  'the-red-cross-of-spain',
  'theredcrossofsrilanka',
  'the-red-cross-of-sri-lanka',
  'theredcrossofsuriname',
  'the-red-cross-of-suriname',
  'theredcrossofsweden',
  'the-red-cross-of-sweden',
  'theredcrossofswitzerland',
  'the-red-cross-of-switzerland',
  'theredcrossoftanzania',
  'the-red-cross-of-tanzania',
  'theredcrossofthailand',
  'the-red-cross-of-thailand',
  'theredcrossofthebahamas',
  'the-red-cross-of-the-bahamas',
  'theredcrossofthecentralafricanrepublic',
  'the-red-cross-of-the-central-african-republic',
  'theredcrossofthecookislands',
  'the-red-cross-of-the-cook-islands',
  'theredcrossoftheczechrepublic',
  'the-red-cross-of-the-czech-republic',
  'theredcrossofthedemocraticpeoplesrepublicofkorea',
  'the-red-cross-of-the-democratic-people-s-republic-of-korea',
  'theredcrossofthedemocraticrepublicofthecongo',
  'the-red-cross-of-the-democratic-republic-of-the-congo',
  'theredcrossofthedominicanrepublic',
  'the-red-cross-of-the-dominican-republic',
  'theredcrossoftheformeryugoslavrepublicofmacedonia',
  'the-red-cross-of-the-former-yugoslav-republic-of-macedonia',
  'theredcrossofthemarshallislands',
  'the-red-cross-of-the-marshall-islands',
  'theredcrossofthenetherlands',
  'the-red-cross-of-the-netherlands',
  'theredcrossofthephilippines',
  'the-red-cross-of-the-philippines',
  'theredcrossoftherepublicofalbania',
  'the-red-cross-of-the-republic-of-albania',
  'theredcrossoftherepublicofcroatia',
  'the-red-cross-of-the-republic-of-croatia',
  'theredcrossoftherepublicofkorea',
  'the-red-cross-of-the-republic-of-korea',
  'theredcrossoftherepublicofmacedonia',
  'the-red-cross-of-the-republic-of-macedonia',
  'theredcrossoftherepublicofmoldova',
  'the-red-cross-of-the-republic-of-moldova',
  'theredcrossoftherepublicofnorthmacedonia',
  'the-red-cross-of-the-republic-of-north-macedonia',
  'theredcrossoftherepublicofsanmarino',
  'the-red-cross-of-the-republic-of-san-marino',
  'theredcrossoftherepublicofserbia',
  'the-red-cross-of-the-republic-of-serbia',
  'theredcrossofthesolomonislands',
  'the-red-cross-of-the-solomon-islands',
  'theredcrossoftheunitedkingdom',
  'the-red-cross-of-the-united-kingdom',
  'theredcrossoftimorleste',
  'the-red-cross-of-timor-leste',
  'theredcrossoftogo',
  'the-red-cross-of-togo',
  'theredcrossoftonga',
  'the-red-cross-of-tonga',
  'theredcrossoftrinidadandtobago',
  'the-red-cross-of-trinidad-and-tobago',
  'theredcrossoftuvalu',
  'the-red-cross-of-tuvalu',
  'theredcrossofuganda',
  'the-red-cross-of-uganda',
  'theredcrossofukraine',
  'the-red-cross-of-ukraine',
  'theredcrossofuruguay',
  'the-red-cross-of-uruguay',
  'theredcrossofvanuatu',
  'the-red-cross-of-vanuatu',
  'theredcrossofvenezuela',
  'the-red-cross-of-venezuela',
  'theredcrossofvietnam',
  'the-red-cross-of-vietnam',
  'theredcrossofzambia',
  'the-red-cross-of-zambia',
  'theredcrossofzimbabwe',
  'the-red-cross-of-zimbabwe',
  'theredcrosssocietyofalbania',
  'the-red-cross-society-of-albania',
  'theredcrosssocietyofamerica',
  'the-red-cross-society-of-america',
  'theredcrosssocietyofandorra',
  'the-red-cross-society-of-andorra',
  'theredcrosssocietyofangola',
  'the-red-cross-society-of-angola',
  'theredcrosssocietyofantiguaandbarbuda',
  'the-red-cross-society-of-antigua-and-barbuda',
  'theredcrosssocietyofargentina',
  'the-red-cross-society-of-argentina',
  'theredcrosssocietyofarmenia',
  'the-red-cross-society-of-armenia',
  'theredcrosssocietyofaustralia',
  'the-red-cross-society-of-australia',
  'theredcrosssocietyofaustria',
  'the-red-cross-society-of-austria',
  'theredcrosssocietyofbaphalalieswatini',
  'the-red-cross-society-of-baphalali-eswatini',
  'theredcrosssocietyofbaphalaliswaziland',
  'the-red-cross-society-of-baphalali-swaziland',
  'theredcrosssocietyofbarbados',
  'the-red-cross-society-of-barbados',
  'theredcrosssocietyofbelarus',
  'the-red-cross-society-of-belarus',
  'theredcrosssocietyofbelgium',
  'the-red-cross-society-of-belgium',
  'theredcrosssocietyofbelize',
  'the-red-cross-society-of-belize',
  'theredcrosssocietyofbenin',
  'the-red-cross-society-of-benin',
  'theredcrosssocietyofbolivia',
  'the-red-cross-society-of-bolivia',
  'theredcrosssocietyofbosniaandherzegovina',
  'the-red-cross-society-of-bosnia-and-herzegovina',
  'theredcrosssocietyofbotswana',
  'the-red-cross-society-of-botswana',
  'theredcrosssocietyofbrazil',
  'the-red-cross-society-of-brazil',
  'theredcrosssocietyofbulgaria',
  'the-red-cross-society-of-bulgaria',
  'theredcrosssocietyofburkinafaso',
  'the-red-cross-society-of-burkina-faso',
  'theredcrosssocietyofburundi',
  'the-red-cross-society-of-burundi',
  'theredcrosssocietyofcambodia',
  'the-red-cross-society-of-cambodia',
  'theredcrosssocietyofcameroon',
  'the-red-cross-society-of-cameroon',
  'theredcrosssocietyofcanada',
  'the-red-cross-society-of-canada',
  'theredcrosssocietyofcapeverde',
  'the-red-cross-society-of-cape-verde',
  'theredcrosssocietyofchad',
  'the-red-cross-society-of-chad',
  'theredcrosssocietyofchile',
  'the-red-cross-society-of-chile',
  'theredcrosssocietyofchina',
  'the-red-cross-society-of-china',
  'theredcrosssocietyofcolombia',
  'the-red-cross-society-of-colombia',
  'theredcrosssocietyofcongo',
  'the-red-cross-society-of-congo',
  'theredcrosssocietyofcostarica',
  'the-red-cross-society-of-costa-rica',
  'xn--theredcrosssocietyofctedivoire-m7c',
  'xn--the-red-cross-society-of-cte-d-ivoire-yvd',
  'theredcrosssocietyofcroatia',
  'the-red-cross-society-of-croatia',
  'theredcrosssocietyofcuba',
  'the-red-cross-society-of-cuba',
  'theredcrosssocietyofcyprus',
  'the-red-cross-society-of-cyprus',
  'theredcrosssocietyofdenmark',
  'the-red-cross-society-of-denmark',
  'theredcrosssocietyofdominica',
  'the-red-cross-society-of-dominica',
  'theredcrosssocietyofecuador',
  'the-red-cross-society-of-ecuador',
  'theredcrosssocietyofelsalvador',
  'the-red-cross-society-of-el-salvador',
  'theredcrosssocietyofequatorialguinea',
  'the-red-cross-society-of-equatorial-guinea',
  'theredcrosssocietyofestonia',
  'the-red-cross-society-of-estonia',
  'theredcrosssocietyofeswatini',
  'the-red-cross-society-of-eswatini',
  'theredcrosssocietyofethiopia',
  'the-red-cross-society-of-ethiopia',
  'theredcrosssocietyoffiji',
  'the-red-cross-society-of-fiji',
  'theredcrosssocietyoffinland',
  'the-red-cross-society-of-finland',
  'theredcrosssocietyoffrance',
  'the-red-cross-society-of-france',
  'theredcrosssocietyofgabon',
  'the-red-cross-society-of-gabon',
  'theredcrosssocietyofgambia',
  'the-red-cross-society-of-gambia',
  'theredcrosssocietyofgeorgia',
  'the-red-cross-society-of-georgia',
  'theredcrosssocietyofgermany',
  'the-red-cross-society-of-germany',
  'theredcrosssocietyofghana',
  'the-red-cross-society-of-ghana',
  'theredcrosssocietyofgreece',
  'the-red-cross-society-of-greece',
  'theredcrosssocietyofgrenada',
  'the-red-cross-society-of-grenada',
  'theredcrosssocietyofguatemala',
  'the-red-cross-society-of-guatemala',
  'theredcrosssocietyofguinea',
  'the-red-cross-society-of-guinea',
  'theredcrosssocietyofguineabissau',
  'the-red-cross-society-of-guinea-bissau',
  'theredcrosssocietyofguyana',
  'the-red-cross-society-of-guyana',
  'theredcrosssocietyofhaiti',
  'the-red-cross-society-of-haiti',
  'theredcrosssocietyofhonduras',
  'the-red-cross-society-of-honduras',
  'theredcrosssocietyofhungary',
  'the-red-cross-society-of-hungary',
  'theredcrosssocietyoficeland',
  'the-red-cross-society-of-iceland',
  'theredcrosssocietyofindia',
  'the-red-cross-society-of-india',
  'theredcrosssocietyofindonesia',
  'the-red-cross-society-of-indonesia',
  'theredcrosssocietyofireland',
  'the-red-cross-society-of-ireland',
  'theredcrosssocietyofitaly',
  'the-red-cross-society-of-italy',
  'theredcrosssocietyofivorycoast',
  'the-red-cross-society-of-ivory-coast',
  'theredcrosssocietyofjamaica',
  'the-red-cross-society-of-jamaica',
  'theredcrosssocietyofjapan',
  'the-red-cross-society-of-japan',
  'theredcrosssocietyofkenya',
  'the-red-cross-society-of-kenya',
  'theredcrosssocietyofkiribati',
  'the-red-cross-society-of-kiribati',
  'theredcrosssocietyoflaos',
  'the-red-cross-society-of-laos',
  'theredcrosssocietyoflatvia',
  'the-red-cross-society-of-latvia',
  'theredcrosssocietyoflebanon',
  'the-red-cross-society-of-lebanon',
  'theredcrosssocietyofliberia',
  'the-red-cross-society-of-liberia',
  'theredcrosssocietyofliechtenstein',
  'the-red-cross-society-of-liechtenstein',
  'theredcrosssocietyoflithuania',
  'the-red-cross-society-of-lithuania',
  'theredcrosssocietyofluxembourg',
  'the-red-cross-society-of-luxembourg',
  'theredcrosssocietyofmacedonia',
  'the-red-cross-society-of-macedonia',
  'theredcrosssocietyofmadagascar',
  'the-red-cross-society-of-madagascar',
  'theredcrosssocietyofmalawi',
  'the-red-cross-society-of-malawi',
  'theredcrosssocietyofmali',
  'the-red-cross-society-of-mali',
  'theredcrosssocietyofmalta',
  'the-red-cross-society-of-malta',
  'theredcrosssocietyofmauritius',
  'the-red-cross-society-of-mauritius',
  'theredcrosssocietyofmexico',
  'the-red-cross-society-of-mexico',
  'theredcrosssocietyofmicronesia',
  'the-red-cross-society-of-micronesia',
  'theredcrosssocietyofmoldova',
  'the-red-cross-society-of-moldova',
  'theredcrosssocietyofmonaco',
  'the-red-cross-society-of-monaco',
  'theredcrosssocietyofmongolia',
  'the-red-cross-society-of-mongolia',
  'theredcrosssocietyofmontenegro',
  'the-red-cross-society-of-montenegro',
  'theredcrosssocietyofmozambique',
  'the-red-cross-society-of-mozambique',
  'theredcrosssocietyofmyanmar',
  'the-red-cross-society-of-myanmar',
  'theredcrosssocietyofnamibia',
  'the-red-cross-society-of-namibia',
  'theredcrosssocietyofnepal',
  'the-red-cross-society-of-nepal',
  'theredcrosssocietyofnewzealand',
  'the-red-cross-society-of-new-zealand',
  'theredcrosssocietyofnicaragua',
  'the-red-cross-society-of-nicaragua',
  'theredcrosssocietyofniger',
  'the-red-cross-society-of-niger',
  'theredcrosssocietyofnigeria',
  'the-red-cross-society-of-nigeria',
  'theredcrosssocietyofnorthmacedonia',
  'the-red-cross-society-of-north-macedonia',
  'theredcrosssocietyofnorway',
  'the-red-cross-society-of-norway',
  'theredcrosssocietyofpalau',
  'the-red-cross-society-of-palau',
  'theredcrosssocietyofpanama',
  'the-red-cross-society-of-panama',
  'theredcrosssocietyofpapuanewguinea',
  'the-red-cross-society-of-papua-new-guinea',
  'theredcrosssocietyofparaguay',
  'the-red-cross-society-of-paraguay',
  'theredcrosssocietyofperu',
  'the-red-cross-society-of-peru',
  'theredcrosssocietyofpoland',
  'the-red-cross-society-of-poland',
  'theredcrosssocietyofportugal',
  'the-red-cross-society-of-portugal',
  'theredcrosssocietyofromania',
  'the-red-cross-society-of-romania',
  'theredcrosssocietyofrussia',
  'the-red-cross-society-of-russia',
  'theredcrosssocietyofrwanda',
  'the-red-cross-society-of-rwanda',
  'theredcrosssocietyofsaintkittsandnevis',
  'the-red-cross-society-of-saint-kitts-and-nevis',
  'theredcrosssocietyofsaintlucia',
  'the-red-cross-society-of-saint-lucia',
  'theredcrosssocietyofsaintvincentandthegrenadines',
  'the-red-cross-society-of-saint-vincent-and-the-grenadines',
  'theredcrosssocietyofsamoa',
  'the-red-cross-society-of-samoa',
  'theredcrosssocietyofsanmarino',
  'the-red-cross-society-of-san-marino',
  'xn--theredcrosssocietyofsotomandprncipe-p6c0uwf',
  'xn--the-red-cross-society-of-so-tom-and-prncipe-hud9yxg',
  'theredcrosssocietyofsenegal',
  'the-red-cross-society-of-senegal',
  'theredcrosssocietyofserbia',
  'the-red-cross-society-of-serbia',
  'theredcrosssocietyofseychelles',
  'the-red-cross-society-of-seychelles',
  'theredcrosssocietyofsierraleone',
  'the-red-cross-society-of-sierra-leone',
  'theredcrosssocietyofsingapore',
  'the-red-cross-society-of-singapore',
  'theredcrosssocietyofslovakia',
  'the-red-cross-society-of-slovakia',
  'theredcrosssocietyofslovenia',
  'the-red-cross-society-of-slovenia',
  'theredcrosssocietyofsouthafrica',
  'the-red-cross-society-of-south-africa',
  'theredcrosssocietyofsouthsudan',
  'the-red-cross-society-of-south-sudan',
  'theredcrosssocietyofspain',
  'the-red-cross-society-of-spain',
  'theredcrosssocietyofsrilanka',
  'the-red-cross-society-of-sri-lanka',
  'theredcrosssocietyofsuriname',
  'the-red-cross-society-of-suriname',
  'theredcrosssocietyofsweden',
  'the-red-cross-society-of-sweden',
  'theredcrosssocietyofswitzerland',
  'the-red-cross-society-of-switzerland',
  'theredcrosssocietyoftanzania',
  'the-red-cross-society-of-tanzania',
  'theredcrosssocietyofthailand',
  'the-red-cross-society-of-thailand',
  'theredcrosssocietyofthebahamas',
  'the-red-cross-society-of-the-bahamas',
  'theredcrosssocietyofthecentralafricanrepublic',
  'the-red-cross-society-of-the-central-african-republic',
  'theredcrosssocietyofthecookislands',
  'the-red-cross-society-of-the-cook-islands',
  'theredcrosssocietyoftheczechrepublic',
  'the-red-cross-society-of-the-czech-republic',
  'theredcrosssocietyofthedemocraticpeoplesrepublicofkorea',
  'theredcrosssocietyofthedemocraticrepublicofthecongo',
  'the-red-cross-society-of-the-democratic-republic-of-the-congo',
  'theredcrosssocietyofthedominicanrepublic',
  'the-red-cross-society-of-the-dominican-republic',
  'theredcrosssocietyoftheformeryugoslavrepublicofmacedonia',
  'theredcrosssocietyofthemarshallislands',
  'the-red-cross-society-of-the-marshall-islands',
  'theredcrosssocietyofthenetherlands',
  'the-red-cross-society-of-the-netherlands',
  'theredcrosssocietyofthephilippines',
  'the-red-cross-society-of-the-philippines',
  'theredcrosssocietyoftherepublicofalbania',
  'the-red-cross-society-of-the-republic-of-albania',
  'theredcrosssocietyoftherepublicofcroatia',
  'the-red-cross-society-of-the-republic-of-croatia',
  'theredcrosssocietyoftherepublicofkorea',
  'the-red-cross-society-of-the-republic-of-korea',
  'theredcrosssocietyoftherepublicofmacedonia',
  'the-red-cross-society-of-the-republic-of-macedonia',
  'theredcrosssocietyoftherepublicofmoldova',
  'the-red-cross-society-of-the-republic-of-moldova',
  'theredcrosssocietyoftherepublicofnorthmacedonia',
  'the-red-cross-society-of-the-republic-of-north-macedonia',
  'theredcrosssocietyoftherepublicofsanmarino',
  'the-red-cross-society-of-the-republic-of-san-marino',
  'theredcrosssocietyofthesolomonislands',
  'the-red-cross-society-of-the-solomon-islands',
  'theredcrosssocietyoftheunitedkindgom',
  'the-red-cross-society-of-the-united-kindgom',
  'theredcrosssocietyoftimorleste',
  'the-red-cross-society-of-timor-leste',
  'theredcrosssocietyoftogo',
  'the-red-cross-society-of-togo',
  'theredcrosssocietyoftonga',
  'the-red-cross-society-of-tonga',
  'theredcrosssocietyoftrinidadandtobago',
  'the-red-cross-society-of-trinidad-and-tobago',
  'theredcrosssocietyoftuvalu',
  'the-red-cross-society-of-tuvalu',
  'theredcrosssocietyofuganda',
  'the-red-cross-society-of-uganda',
  'theredcrosssocietyofukraine',
  'the-red-cross-society-of-ukraine',
  'theredcrosssocietyofuruguay',
  'the-red-cross-society-of-uruguay',
  'theredcrosssocietyofvanuatu',
  'the-red-cross-society-of-vanuatu',
  'theredcrosssocietyofvenezuela',
  'the-red-cross-society-of-venezuela',
  'theredcrosssocietyofvietnam',
  'the-red-cross-society-of-vietnam',
  'theredcrosssocietyofzambia',
  'the-red-cross-society-of-zambia',
  'theredcrosssocietyofzimbabwe',
  'the-red-cross-society-of-zimbabwe',
  'therepublicofalbaniaredcross',
  'the-republic-of-albania-red-cross',
  'therepublicofalbaniaredcrosssociety',
  'the-republic-of-albania-red-cross-society',
  'therepublicofkoreanationalredcross',
  'the-republic-of-korea-national-red-cross',
  'therepublicofkorearedcross',
  'the-republic-of-korea-red-cross',
  'therepublicofkorearedcrosssociety',
  'the-republic-of-korea-red-cross-society',
  'therepublicofmoldovaredcross',
  'the-republic-of-moldova-red-cross',
  'therepublicofmoldovaredcrosssociety',
  'the-republic-of-moldova-red-cross-society',
  'therepublicofsanmarinoredcross',
  'the-republic-of-san-marino-red-cross',
  'therepublicofsanmarinoredcrosssociety',
  'the-republic-of-san-marino-red-cross-society',
  'therepublicofserbiaredcross',
  'the-republic-of-serbia-red-cross',
  'therepublicofserbiaredcrosssociety',
  'the-republic-of-serbia-red-cross-society',
  'therepublicofthemaldivesredcrescent',
  'the-republic-of-the-maldives-red-crescent',
  'therepublicofthemaldivesredcrescentsociety',
  'the-republic-of-the-maldives-red-crescent-society',
  'theromaniaredcross',
  'the-romania-red-cross',
  'theromaniaredcrosssociety',
  'the-romania-red-cross-society',
  'theromanianredcross',
  'the-romanian-red-cross',
  'theromanianredcrosssociety',
  'the-romanian-red-cross-society',
  'therussiaredcross',
  'the-russia-red-cross',
  'therussiaredcrosssociety',
  'the-russia-red-cross-society',
  'therussianredcross',
  'the-russian-red-cross',
  'therussianredcrosssociety',
  'the-russian-red-cross-society',
  'therwandaredcross',
  'the-rwanda-red-cross',
  'therwandaredcrosssociety',
  'the-rwanda-red-cross-society',
  'therwandanredcross',
  'the-rwandan-red-cross',
  'therwandanredcrosssociety',
  'the-rwandan-red-cross-society',
  'thesaintkittsandnevisredcross',
  'the-saint-kitts-and-nevis-red-cross',
  'thesaintkittsandnevisredcrosssociety',
  'the-saint-kitts-and-nevis-red-cross-society',
  'thesaintluciaredcross',
  'the-saint-lucia-red-cross',
  'thesaintluciaredcrosssociety',
  'the-saint-lucia-red-cross-society',
  'thesaintlucianredcross',
  'the-saint-lucian-red-cross',
  'thesaintlucianredcrosssociety',
  'the-saint-lucian-red-cross-society',
  'thesaintvincentandthegrenadinesredcross',
  'the-saint-vincent-and-the-grenadines-red-cross',
  'thesaintvincentandthegrenadinesredcrosssociety',
  'the-saint-vincent-and-the-grenadines-red-cross-society',
  'thesalvadoreanredcross',
  'the-salvadorean-red-cross',
  'thesalvadoreanredcrosssociety',
  'the-salvadorean-red-cross-society',
  'thesamoaredcross',
  'the-samoa-red-cross',
  'thesamoaredcrosssociety',
  'the-samoa-red-cross-society',
  'thesamoanredcross',
  'the-samoan-red-cross',
  'thesamoanredcrosssociety',
  'the-samoan-red-cross-society',
  'thesanmarinoredcross',
  'the-san-marino-red-cross',
  'thesanmarinoredcrosssociety',
  'the-san-marino-red-cross-society',
  'xn--thesotomandprncipenationalsocietyoftheredcross-c2d60a7g',
  'xn--thesotomandprinciperedcross-ljc2p',
  'xn--the-so-tom-and-principe-red-cross-l0c9s',
  'xn--thesotomandprnciperedcrosssociety-k0c8stf',
  'xn--the-so-tom-and-prncipe-red-cross-society-eld1xrg',
  'thesaudiarabiaredcrescent',
  'the-saudi-arabia-red-crescent',
  'thesaudiarabiaredcrescentsociety',
  'the-saudi-arabia-red-crescent-society',
  'thesaudiredcrescent',
  'the-saudi-red-crescent',
  'thesaudiredcrescentauthority',
  'the-saudi-red-crescent-authority',
  'thesaudiredcrescentsociety',
  'the-saudi-red-crescent-society',
  'thesenegalredcross',
  'the-senegal-red-cross',
  'thesenegalredcrosssociety',
  'the-senegal-red-cross-society',
  'thesenegaleseredcross',
  'the-senegalese-red-cross',
  'thesenegaleseredcrosssociety',
  'the-senegalese-red-cross-society',
  'theserbianredcross',
  'the-serbian-red-cross',
  'theserbianredcrosssociety',
  'the-serbian-red-cross-society',
  'theseychellesredcross',
  'the-seychelles-red-cross',
  'theseychellesredcrosssociety',
  'the-seychelles-red-cross-society',
  'thesierraleoneredcross',
  'the-sierra-leone-red-cross',
  'thesierraleoneredcrosssociety',
  'the-sierra-leone-red-cross-society',
  'thesingaporeredcross',
  'the-singapore-red-cross',
  'thesingaporeredcrosssociety',
  'the-singapore-red-cross-society',
  'thesingaporeanredcross',
  'the-singaporean-red-cross',
  'thesingaporeanredcrosssociety',
  'the-singaporean-red-cross-society',
  'theslovakredcross',
  'the-slovak-red-cross',
  'theslovakredcrosssociety',
  'the-slovak-red-cross-society',
  'theslovakiaredcross',
  'the-slovakia-red-cross',
  'theslovakiaredcrosssociety',
  'the-slovakia-red-cross-society',
  'thesloveniaredcross',
  'the-slovenia-red-cross',
  'thesloveniaredcrosssociety',
  'the-slovenia-red-cross-society',
  'theslovenianredcross',
  'the-slovenian-red-cross',
  'theslovenianredcrosssociety',
  'the-slovenian-red-cross-society',
  'thesocietyofsomaliredcrescent',
  'the-society-of-somali-red-crescent',
  'thesocietyofsomaliaredcrescent',
  'the-society-of-somalia-red-crescent',
  'thesocietyofsomalilandredcrescent',
  'the-society-of-somaliland-red-crescent',
  'thesocietyoftheredcrossofsaotomeandprincipe',
  'the-society-of-the-red-cross-of-sao-tome-and-principe',
  'thesolomonislandsredcross',
  'the-solomon-islands-red-cross',
  'thesolomonislandsredcrosssociety',
  'the-solomon-islands-red-cross-society',
  'thesomaliredcrescent',
  'the-somali-red-crescent',
  'thesomaliredcrescentsociety',
  'the-somali-red-crescent-society',
  'thesomaliaredcrescent',
  'the-somalia-red-crescent',
  'thesomaliaredcrescentsociety',
  'the-somalia-red-crescent-society',
  'thesomalilandredcrescent',
  'the-somaliland-red-crescent',
  'thesomalilandredcrescentsociety',
  'the-somaliland-red-crescent-society',
  'thesouthafricaredcross',
  'the-south-africa-red-cross',
  'thesouthafricaredcrosssociety',
  'the-south-africa-red-cross-society',
  'thesouthafricanredcross',
  'the-south-african-red-cross',
  'thesouthafricanredcrosssociety',
  'the-south-african-red-cross-society',
  'thesouthsudanredcross',
  'the-south-sudan-red-cross',
  'thesouthsudanredcrosssociety',
  'the-south-sudan-red-cross-society',
  'thesouthsudaneseredcross',
  'the-south-sudanese-red-cross',
  'thesouthsudaneseredcrosssociety',
  'the-south-sudanese-red-cross-society',
  'thespainredcross',
  'the-spain-red-cross',
  'thespainredcrosssociety',
  'the-spain-red-cross-society',
  'thespanishredcross',
  'the-spanish-red-cross',
  'thespanishredcrosssociety',
  'the-spanish-red-cross-society',
  'thesrilankaredcross',
  'the-sri-lanka-red-cross',
  'thesrilankaredcrosssociety',
  'the-sri-lanka-red-cross-society',
  'thesrilankanredcross',
  'the-sri-lankan-red-cross',
  'thesrilankanredcrosssociety',
  'the-sri-lankan-red-cross-society',
  'thesudanredcrescent',
  'the-sudan-red-crescent',
  'thesudanredcrescentsociety',
  'the-sudan-red-crescent-society',
  'thesudaneseredcrescent',
  'the-sudanese-red-crescent',
  'thesudaneseredcrescentsociety',
  'the-sudanese-red-crescent-society',
  'thesurinameredcross',
  'the-suriname-red-cross',
  'thesurinameredcrosssociety',
  'the-suriname-red-cross-society',
  'thesurinameseredcross',
  'the-surinamese-red-cross',
  'thesurinameseredcrosssociety',
  'the-surinamese-red-cross-society',
  'theswedenredcross',
  'the-sweden-red-cross',
  'theswedenredcrosssociety',
  'the-sweden-red-cross-society',
  'theswedishredcross',
  'the-swedish-red-cross',
  'theswedishredcrosssociety',
  'the-swedish-red-cross-society',
  'theswissredcross',
  'the-swiss-red-cross',
  'theswissredcrosssociety',
  'the-swiss-red-cross-society',
  'theswitzerlandredcross',
  'the-switzerland-red-cross',
  'theswitzerlandredcrosssociety',
  'the-switzerland-red-cross-society',
  'thesyriaredcrescent',
  'the-syria-red-crescent',
  'thesyriaredcrescentsociety',
  'the-syria-red-crescent-society',
  'thesyrianarabredcrescent',
  'the-syrian-arab-red-crescent',
  'thesyrianarabredcrescentorganization',
  'the-syrian-arab-red-crescent-organization',
  'thesyrianarabredcrescentsociety',
  'the-syrian-arab-red-crescent-society',
  'thesyrianarabrepublicredcrescent',
  'the-syrian-arab-republic-red-crescent',
  'thesyrianarabrepublicredcrescentsociety',
  'the-syrian-arab-republic-red-crescent-society',
  'thesyrianredcrescent',
  'the-syrian-red-crescent',
  'thesyrianredcrescentorganization',
  'the-syrian-red-crescent-organization',
  'thesyrianredcrescentsociety',
  'the-syrian-red-crescent-society',
  'thetajikredcrescent',
  'the-tajik-red-crescent',
  'thetajikredcrescentsociety',
  'the-tajik-red-crescent-society',
  'thetajikistanredcrescent',
  'the-tajikistan-red-crescent',
  'thetajikistanredcrescentsociety',
  'the-tajikistan-red-crescent-society',
  'thetanzaniaredcross',
  'the-tanzania-red-cross',
  'thetanzaniaredcrossnationalsociety',
  'the-tanzania-red-cross-national-society',
  'thetanzaniaredcrosssociety',
  'the-tanzania-red-cross-society',
  'thetanzanianredcross',
  'the-tanzanian-red-cross',
  'thetanzanianredcrosssociety',
  'the-tanzanian-red-cross-society',
  'thethairedcross',
  'the-thai-red-cross',
  'thethairedcrosssociety',
  'the-thai-red-cross-society',
  'thethailandredcross',
  'the-thailand-red-cross',
  'thethailandredcrosssociety',
  'the-thailand-red-cross-society',
  'thetimorlesteredcross',
  'the-timor-leste-red-cross',
  'thetimorlesteredcrosssociety',
  'the-timor-leste-red-cross-society',
  'thetogoredcross',
  'the-togo-red-cross',
  'thetogoredcrosssociety',
  'the-togo-red-cross-society',
  'thetogoleseredcross',
  'the-togolese-red-cross',
  'thetogoleseredcrosssociety',
  'the-togolese-red-cross-society',
  'thetongaredcross',
  'the-tonga-red-cross',
  'thetongaredcrosssociety',
  'the-tonga-red-cross-society',
  'thetonganredcross',
  'the-tongan-red-cross',
  'thetonganredcrosssociety',
  'the-tongan-red-cross-society',
  'thetrinidadandtobagoredcross',
  'the-trinidad-and-tobago-red-cross',
  'thetrinidadandtobagoredcrosssociety',
  'the-trinidad-and-tobago-red-cross-society',
  'thetunisiaredcrescent',
  'the-tunisia-red-crescent',
  'thetunisiaredcrescentsociety',
  'the-tunisia-red-crescent-society',
  'thetunisianredcrescent',
  'the-tunisian-red-crescent',
  'thetunisianredcrescentsociety',
  'the-tunisian-red-crescent-society',
  'theturkeyredcrescent',
  'the-turkey-red-crescent',
  'theturkeyredcrescentsociety',
  'the-turkey-red-crescent-society',
  'theturkishredcrescent',
  'the-turkish-red-crescent',
  'theturkishredcrescentsociety',
  'the-turkish-red-crescent-society',
  'theturkmenredcrescent',
  'the-turkmen-red-crescent',
  'theturkmenredcrescentsociety',
  'the-turkmen-red-crescent-society',
  'theturkmenistanredcrescent',
  'the-turkmenistan-red-crescent',
  'theturkmenistanredcrescentsociety',
  'the-turkmenistan-red-crescent-society',
  'thetuvaluredcross',
  'the-tuvalu-red-cross',
  'thetuvaluredcrosssociety',
  'the-tuvalu-red-cross-society',
  'thetuvaluanredcross',
  'the-tuvaluan-red-cross',
  'thetuvaluanredcrosssociety',
  'the-tuvaluan-red-cross-society',
  'theugandaredcross',
  'the-uganda-red-cross',
  'theugandaredcrosssociety',
  'the-uganda-red-cross-society',
  'theugandanredcross',
  'the-ugandan-red-cross',
  'theugandanredcrosssociety',
  'the-ugandan-red-cross-society',
  'theukraineredcross',
  'the-ukraine-red-cross',
  'theukraineredcrosssociety',
  'the-ukraine-red-cross-society',
  'theukrainianredcross',
  'the-ukrainian-red-cross',
  'theukrainianredcrosssociety',
  'the-ukrainian-red-cross-society',
  'theunitedarabemiratesredcrescent',
  'the-united-arab-emirates-red-crescent',
  'theunitedarabemiratesredcrescentauthority',
  'the-united-arab-emirates-red-crescent-authority',
  'theunitedarabemiratesredcrescentsociety',
  'the-united-arab-emirates-red-crescent-society',
  'theuruguayredcross',
  'the-uruguay-red-cross',
  'theuruguayredcrosssociety',
  'the-uruguay-red-cross-society',
  'theuruguayanredcross',
  'the-uruguayan-red-cross',
  'theuruguayanredcrosssociety',
  'the-uruguayan-red-cross-society',
  'theuzbekredcrescent',
  'the-uzbek-red-crescent',
  'theuzbekredcrescentsociety',
  'the-uzbek-red-crescent-society',
  'theuzbekistanredcrescent',
  'the-uzbekistan-red-crescent',
  'theuzbekistanredcrescentsociety',
  'the-uzbekistan-red-crescent-society',
  'thevanuaturedcross',
  'the-vanuatu-red-cross',
  'thevanuaturedcrosssociety',
  'the-vanuatu-red-cross-society',
  'thevanuatuanredcross',
  'the-vanuatuan-red-cross',
  'thevanuatuanredcrosssociety',
  'the-vanuatuan-red-cross-society',
  'thevenezuelaredcross',
  'the-venezuela-red-cross',
  'thevenezuelaredcrosssociety',
  'the-venezuela-red-cross-society',
  'thevenezuelanredcross',
  'the-venezuelan-red-cross',
  'thevenezuelanredcrosssociety',
  'the-venezuelan-red-cross-society',
  'thevietnamredcross',
  'the-vietnam-red-cross',
  'thevietnamredcrosssociety',
  'the-vietnam-red-cross-society',
  'thevietnameseredcross',
  'the-vietnamese-red-cross',
  'thevietnameseredcrosssociety',
  'the-vietnamese-red-cross-society',
  'theyemenredcrescent',
  'the-yemen-red-crescent',
  'theyemenredcrescentsociety',
  'the-yemen-red-crescent-society',
  'theyemeniredcrescent',
  'the-yemeni-red-crescent',
  'theyemeniredcrescentsociety',
  'the-yemeni-red-crescent-society',
  'thezambiaredcross',
  'the-zambia-red-cross',
  'thezambiaredcrosssociety',
  'the-zambia-red-cross-society',
  'thezambianredcross',
  'the-zambian-red-cross',
  'thezambianredcrosssociety',
  'the-zambian-red-cross-society',
  'thezimbabweredcross',
  'the-zimbabwe-red-cross',
  'thezimbabweredcrosssociety',
  'the-zimbabwe-red-cross-society',
  'thezimbabweanredcross',
  'the-zimbabwean-red-cross',
  'thezimbabweanredcrosssociety',
  'the-zimbabwean-red-cross-society',
  'timorlesteredcross',
  'timor-leste-red-cross',
  'timorlesteredcrosssociety',
  'timor-leste-red-cross-society',
  'togoredcross',
  'togo-red-cross',
  'togoredcrosssociety',
  'togo-red-cross-society',
  'togoleseredcross',
  'togolese-red-cross',
  'togoleseredcrosssociety',
  'togolese-red-cross-society',
  'tongaredcross',
  'tonga-red-cross',
  'tongaredcrosssociety',
  'tonga-red-cross-society',
  'tonganredcross',
  'tongan-red-cross',
  'tonganredcrosssociety',
  'tongan-red-cross-society',
  'trinidadandtobagoredcross',
  'trinidad-and-tobago-red-cross',
  'trinidadandtobagoredcrosssociety',
  'trinidad-and-tobago-red-cross-society',
  'tshisumbuthsiankuruzamukenzemuditungadiakongowamungata',
  'tunisiaredcrescent',
  'tunisia-red-crescent',
  'tunisiaredcrescentsociety',
  'tunisia-red-crescent-society',
  'tunisianredcrescent',
  'tunisian-red-crescent',
  'tunisianredcrescentsociety',
  'tunisian-red-crescent-society',
  'xn--trkkzlay-65a74cbd',
  'xn--trk-kzlay-q9a10dbd',
  'turkeyredcrescent',
  'turkey-red-crescent',
  'turkeyredcrescentsociety',
  'turkey-red-crescent-society',
  'turkishredcrescent',
  'turkish-red-crescent',
  'turkishredcrescentsociety',
  'turkish-red-crescent-society',
  'xn--trkiyekzlaydernei-22b49d4kb',
  'xn--trkiye-kzlay-dernei-59b66e7lb',
  'xn--trkmengyzylarymay-22b3af',
  'xn--trkmen-gyzyl-arymay-59b7af',
  'xn--trkmengyzylarymayjemgyeti-fwc1bfg',
  'xn--trkmen-gyzyl-arymay-jemgyeti-16c6bfh',
  'turkmenredcrescent',
  'turkmen-red-crescent',
  'turkmenredcrescentsociety',
  'turkmen-red-crescent-society',
  'turkmenistanredcrescent',
  'turkmenistan-red-crescent',
  'turkmenistanredcrescentsociety',
  'turkmenistan-red-crescent-society',
  'xn--trkmenistanygyzylarymajemgyeti-4ed2cff12x',
  'xn--trkmenistany-gyzyl-aryma-jemgyeti-rpd7cfg34z',
  'xn--trkmenistanygyzylarymamillijemgyeti-uwd7cfk190a',
  'xn--trkmenistany-gyzyl-aryma-milli-jemgyeti-0be3dfm783a',
  'xn--trkmenistanygyzylarymay-cpc5bf72r',
  'xn--trkmenistany-gyzyl-arymay-fwc9bf57s',
  'xn--trkmenistanymilligyzylarymay-16c5cf79u',
  'xn--trkmenistany-milli-gyzyl-arymay-oid1df91x',
  'tuvaluredcross',
  'tuvalu-red-cross',
  'tuvaluredcrosssociety',
  'tuvalu-red-cross-society',
  'tuvaluanredcross',
  'tuvaluan-red-cross',
  'tuvaluanredcrosssociety',
  'tuvaluan-red-cross-society',
  'ugandaredcross',
  'uganda-red-cross',
  'ugandaredcrosssociety',
  'uganda-red-cross-society',
  'ugandanredcross',
  'ugandan-red-cross',
  'ugandanredcrosssociety',
  'ugandan-red-cross-society',
  'uhieobedinanaijiria',
  'uhie-obe-di-na-naijiria',
  'uhieobenkedinanaijiria',
  'uhie-obe-nke-di-na-naijiria',
  'ukraineredcross',
  'ukraine-red-cross',
  'ukraineredcrosssociety',
  'ukraine-red-cross-society',
  'ukrainianredcross',
  'ukrainian-red-cross',
  'ukrainianredcrosssociety',
  'ukrainian-red-cross-society',
  'umuryangocroixrougemuburundi',
  'umuryango-croix-rouge-mu-burundi',
  'umuryangoutabaraimbabarecroixrougeyuburundi',
  'umuryango-utabara-imbabare-croix-rouge-y-uburundi',
  'umuryangoutabaraimbabaremuburundi',
  'umuryango-utabara-imbabare-mu-burundi',
  'umuryangoutabareimbabaremurwanda',
  'umuryango-utabare-imbabare-mu-rwanda',
  'unitedarabemiratesredcrescent',
  'united-arab-emirates-red-crescent',
  'unitedarabemiratesredcrescentauthority',
  'united-arab-emirates-red-crescent-authority',
  'unitedarabemiratesredcrescentsociety',
  'united-arab-emirates-red-crescent-society',
  'uruguayredcross',
  'uruguay-red-cross',
  'uruguayredcrosssociety',
  'uruguay-red-cross-society',
  'uruguayanredcross',
  'uruguayan-red-cross',
  'uruguayanredcrossnationalsociety',
  'uruguayan-red-cross-national-society',
  'uruguayanredcrosssociety',
  'uruguayan-red-cross-society',
  'ururkabishacaseesoomaaliland',
  'ururka-bisha-cas-ee-soomaaliland',
  'ururkabishacaseesoomaliya',
  'ururka-bisha-cas-ee-soomaliya',
  'ururkabishacaseesoomaliyeed',
  'ururka-bisha-cas-ee-soomaliyeed',
  'ururkabishacasta',
  'ururka-bisha-cas-ta',
  'ururkabishacastaeesoomaaliland',
  'ururka-bisha-cas-ta-ee-soomaaliland',
  'ururkabishacastaeesoomaliya',
  'ururka-bisha-cas-ta-ee-soomaliya',
  'ururkasomalilandeebishacas',
  'ururka-somaliland-ee-bisha-cas',
  'ururkasoomalidaeebishacas',
  'ururka-soomalida-ee-bisha-cas',
  'ururkasoomalilandeebishacasta',
  'ururka-soomaliland-ee-bisha-cas-ta',
  'ururkasoomaliyaeebishacas',
  'ururka-soomaliya-ee-bisha-cas',
  'ururkasoomaliyaeebishacasta',
  'ururka-soomaliya-ee-bisha-cas-ta',
  'ururkasoomaliyeedeebisha',
  'ururka-soomaliyeed-ee-bisha',
  'uzbekredcrescent',
  'uzbek-red-crescent',
  'uzbekredcrescentsociety',
  'uzbek-red-crescent-society',
  'uzbekistanredcrescent',
  'uzbekistan-red-crescent',
  'uzbekistanredcrescentsociety',
  'uzbekistan-red-crescent-society',
  'vanuaturedcross',
  'vanuatu-red-cross',
  'vanuaturedcrosssociety',
  'vanuatu-red-cross-society',
  'vanuatuanredcross',
  'vanuatuan-red-cross',
  'vanuatuanredcrosssociety',
  'vanuatuan-red-cross-society',
  'venezuelaredcross',
  'venezuela-red-cross',
  'venezuelaredcrosssociety',
  'venezuela-red-cross-society',
  'venezuelanredcross',
  'venezuelan-red-cross',
  'venezuelanredcrosssociety',
  'venezuelan-red-cross-society',
  'vereinigungvomrotenkreuzvonbelgien',
  'vereinigung-vom-roten-kreuz-von-belgien',
  'xn--verenigingvanhetrodekruisvanbelgi-58c',
  'xn--vereniging-van-het-rode-kruis-van-belgi-osd',
  'vietnamredcross',
  'vietnam-red-cross',
  'vietnamredcrosssociety',
  'vietnam-red-cross-society',
  'vietnameseredcross',
  'vietnamese-red-cross',
  'vietnameseredcrosssociety',
  'vietnamese-red-cross-society',
  'xn--vrskeresztmagyarorszgon-d6b91cb',
  'xn--vrskereszt-magyarorszgon-58b93cb',
  'xn--vrskereszttrsasgmagyarorszgon-qncel97db',
  'xn--vrskereszt-trsasg-magyarorszgon-btcem91eb',
  'yemenredcrescent',
  'yemen-red-crescent',
  'yemenredcrescentsociety',
  'yemen-red-crescent-society',
  'yemeniredcrescent',
  'yemeni-red-crescent',
  'yemeniredcrescentsociety',
  'yemeni-red-crescent-society',
  'zambiaredcross',
  'zambia-red-cross',
  'zambiaredcrosssociety',
  'zambia-red-cross-society',
  'zambianredcross',
  'zambian-red-cross',
  'zambianredcrosssociety',
  'zambian-red-cross-society',
  'zimbabweredcross',
  'zimbabwe-red-cross',
  'zimbabweredcrosssociety',
  'zimbabwe-red-cross-society',
  'zimbabweanredcross',
  'zimbabwean-red-cross',
  'zimbabweanredcrosssociety',
  'zimbabwean-red-cross-society',
  'xn--mxadfbaleeedheiaca0aj6ab4cbbgfhecht5a1ag6spaggk',
  'xn-------3ldimdbsghffjglada6al3bb4dbcggifdiw9a4ah0vrahhl',
  'xn--mxadfbaleeedheiaca0aj6abhcb8bbchfxt5a1ag6slbogg',
  'xn-------3ldimdbsghffjglada6al3bbidc5cbdhg1aw9a4ah0vpbphh',
  'xn--mxahahgehfav1cbbiecosog0lgg',
  'xn-----d9bnbkjfjhaz0dbcifdquph2mhh',
  'xn--hxajblcbt0aa9cbblcggrng9kg',
  'xn-----x8bscrcdx8aa9dbcmdhhtoh2mh',
  'xn--hxajblcbng4aa4dbbmcbggtdmg0ng',
  'xn------5wcwducesh6ba9ebcodchhwenh0ph',
  'xn--mxaalmcfc9bjebbhecnrfig0lgg',
  'xn-----b9bcssdhc1dlfbchfdptfjh2mhh',
  'xn--mxahahgehfavr6abbjecptpg7lgg',
  'xn------qxcrbmkgkia1aw4bbckfdswrh6nhh',
  'xn--mxadgajhfieaca1awbhcb8abchrtzvg4roagg',
  'xn------qxcgnbolhlgada7a1abidc3bbdhuw2axh5tpahh',
  'xn--80aaaactefgfrieblctslfwvilugh7c8e1grf',
  'xn-----6kcaabdzghghtkecmdwulgzximvhi1dvf4gvf',
  'xn--80aaaaaccikfiebdhl1bvewvmhrbddba3ajqxgfjv5cwh7d0m',
  'xn------5cdaacbcdloglecfho7byag0aymjscdebb6ajsyhgkw0dyhse8m',
  'xn--80aapkfbdccnlf5ageggft8oxc',
  'xn-----6kcb0aohceddpng0bhfghgv5q2c',
  'xn--80aaacfhkbd7becrohjbddb0alghgfhoo6qwg',
  'xn------5cdcbdilocf9cgdurjkcdeb4anhhhgipq5snh',
  'xn--80aaaaaaaabnbboscz0dlf4cu8agchihxft1l5efj',
  'xn------5cdabaababbrbcrwc5a9dof2dxa2bhdiii0afv5mofgk',
  'xn--80abekhwbevjtbeh3a0e8cec4s',
  'xn-----6kcdhpl2ade0alwcfh7a9ewded4u',
  'xn--80abmbngbecce3ah2aeehagfvg8q',
  'xn-----7kccudricfdde8ai6affhahgxg5s',
  'xn--80aabafhkabd2cecf1bibbddb4ajgiagfhqoh9r',
  'xn------7cdbbbiloacf4dgdf9bjbcdeb8alhiahgirqh8t',
  'xn--80abeclbspec3a4adegjr5f5bp',
  'xn-----6kcdhdqbxtgc8a9aefglt4g9br',
  'xn--80abeclbspec3a4adegjji0g6bq4h',
  'xn-----6kcdhdqbxtgc8a9aefgljk9gqcs2i',
  'xn--80aaccdedifbc4azeh3akg8akdefjjgud6kndp',
  'xn-------53dbgeghfmibd4b7agj0bmh6bmeffllgyd5mvdra',
  'xn--80aaccdedifbc4azeh3akg8akdefjjgud6kndp5m',
  'xn-------53dbgeghfmibd4b7agj0bmh6bmeffllgyd5mvdra3o',
  'xn--80aaccbhcicbf7awpxel6aheeglmmj0jzdd9m',
  'xn-------53ddgcdncmebh9b3au2afn4bjffgonpk8k9de9o',
  'xn--80aaccbhcicbf4adyqyem8ahfegmmnj4j3dd2mma',
  'xn-------53ddgcdncmebh4bf5av3afo6bjgfgpnqk2lqee0ooa',
  'xn--80aaaaadebphgbd3bexecoxjdbmftqqeec3awlws1h4c21b',
  'xn---------3nfdbbaifdzniee0df6agdt4akedofzvrgfe7azozx6irdv5b',
  'xn--80aabdcfbco0axe7aegfexchggfleve1hyb69a',
  'xn------6cdccfehcdt8a3ag4bfhgf1adihhfneye2i1b33b',
  'xn--80adadbck1be2agbehmceggclese9fwb',
  'xn------8cdgcfcdp5cg0bhcfjodfhhcneve0hzb',
  'xn--80acbafdjd0ar5ajdwcgbqbhlj6e1b34a',
  'xn------7cdgbbifnf9aw3ble1adhcschnk6f5b28a',
  'xn--80aaauhhhbcdconlqpnngh3b4a4b1hye',
  'xn-----6kcab5ajjjcdddrpmtroohi7b6a8b3h3e',
  'xn--80aapgggcdlkulwi1ay2a5n',
  'xn----7sbavhhhddnlwmxj3az4a4o',
  'xn--80aaaazijibcecpohfsrpncghg4b8a0c1iof',
  'xn-----6kcaab0bklkcdedsqhgvtqochig8bxb4c2isf',
  'xn--80aaaaaehjobdp1beesrihnbddbazvqcgfjhk2c6b2d3e0m',
  'xn------5cdaacbfknscfs0cegwuijocdebb2axrchgkhl7c8b8d6e8m',
  'xn--80aaflcrdgcc2ah3aedifsu6m',
  'xn------5cddkte0adjde8ak9affigvz7o',
  'xn--80aefdtecjigpcqelfr0nma',
  'xn-----6kckje0agdmkgtcsfmgt7ooa',
  'xn--80aacasacwafjcemfu2bleblefcll5ak4s1b',
  'xn------5cddeb2abd4aagmdfpgx0cmfcmegdmm9al5u6b',
  'xn--80akjacecwmoebhfcan0n',
  'xn-----6kcvqacgd1aqqfchgdao7o',
  'xn--80aaasqdfcb0ag1aecifrs9l',
  'xn-----6kcca1axdhdc4ai5afdigtv3n',
  'xn--80aaoncecbxgyechfqr1lm',
  'xn-----6kccxucgdc1ai2afdhgsu5moa',
  'xn--80aaaaa0apnhdjmkjgpnthevn7ct0jye',
  'xn-----6kccaaa9aupjfkollhrovifxo2du9j7e',
  'xn--80aaakjnlgdiljigomjjhevn6e0fkc6h',
  'xn-----6kccaqmsnifjnkkhqnljifxo2f4fmc3i',
  'xn--80aaaacxashagkgbidnppfufnirhck3amh7es5n2f',
  'xn------5cddaae8abzjahnicjeprsgxgoksidl6ani4fta9olg',
  'xn--80aaaasejqaejhhomlgrpvhgqhn5fsg6e',
  'xn-----6kccaa0afmuaeljiqnnhtqxihsho1gvgof',
  'xn--80aaactbmkeighnlkgqouhfwnd8e5f2e',
  'xn-----6kccae0acqmekiipmmhspwigyoe3fwgpf',
  'xn--80afmalbcdgnvaq7cxgccb',
  'xn------7cdmybscdfhs1aat9d6gcdb',
  'xn--80aahmearbcln4bh9lccbed',
  'xn------7cdbnxfbzbdor4cj0pcdbfe',
  'xn--80aabhkldgmbfbadgddvka3aaguc1ag5aatibck1wcmbjb',
  'xn--------4veddqvugjucgcbfhee2ala2bahyd6ag1baxjbcn6zcnblb',
  'xn--80aabeddkbdn5aceqpnkbddbawhlqgfjo8b8fsdwcc6o',
  'xn------7cdbchffocfq4bcgusplcdebbzhnrhgkp3c4gwdzcc1q',
  'xn--80aahqbnbddhoftassr3a9kcgb',
  'xn------5cdco2acucefitfzavvu9a9lchb',
  'xn--80aahfncpbedipfbvauut7a3mcgcb',
  'xn------5cdcoiwdwcffjufb1aaxxw3b0mcgdb',
  'xn--80aahfncpbelsb7aqp3lcgcbd',
  'xn-----6kcclhtdubfnvb3bsr1ncgdbe',
  'xn--80aaajgmecvbenphb6blit9ocgcbed',
  'xn------5cdcbpjufd3abfqtib5cnjw6rcgdbfe',
  'xn--80aaajgmecvbeafgdsdfb5aaznmzfazbco0ucgjbe',
  'xn--------3vedbulzge7acfbiheyffb5ba4aqo4aga4abcs5xcglbf',
  'xn--80afhkfbcbcmljongfmgh1b7dwecj2i',
  'xn-----7kcknohcdbdpnkrpggnhi5bye4eck1j',
  'xn--80aaa0abnm0kbd48fdaa',
  'xn-----6kcac2bcsp2nbe24hdab',
  'xn--80aaaaa2bcrqnfq6ao1lbg70k6ddeg',
  'xn------5cdaacb0ddyvsfw2br9nbi32msedfi',
  'xn--80aaaa6acpoq2bl4ibd00jdae',
  'xn-----6kcaac8bdurt0cn3kbe85kdaf',
  'xn--80aaaaaae5apfjpjmetfgxjttegn6a6tbabg51uzgdsg20a',
  'xn-------43daabcbf1cwhmvlqfzfh4akxwfgq1b8ubbbi30xshdui13a',
  'xn--80aaaaae1anfioilewg2aqqegk3a2rbabd60udas9w',
  'xn------5cdaabcf3bshktjof0ah8atsfgm7a6tbbbe34wdau2z',
  'xn--80aaaaawsdtsofbs0bq0nbbg32msedhg',
  'xn------5cdaacb5a1ae0axtfby6bt7pcbi93oefdii',
  'xn--80aaaatpdrqrb8bn3kbbd73ldah',
  'xn-----6kcaaczvewtub6cp1mcbe59mdai',
  'xn--80aafbhcdcbc0afrcdbcdujpegehmd6bekeiihjslh2kua',
  'xn--------3vednenffddd1biycfccfzmrfifipd4cfmfikilumj8lxa',
  'xn--80aaalcbedcdzlnccthnchlegb9akgwhrbmg2z',
  'xn------5cdcbtdbheed6anrddwipcjmfhb3bmhyisbog61a',
  'xn--80aafgiqhbfcccmxf4beigfv6q',
  'xn-----6kcbikmujcgddco0ag9bfihgx3s',
  'xn--80aaaaand4awmagdkefkc0h',
  'xn-----7kcabaarf2b0aobhdmeglc1i',
  'xn--80aaaaandca8aplbsagfckfefiic5kwmc',
  'xn------6cdabaaufda9btncvbhgcmgegkic1mtnc',
  'xn--80aaaaand4awmagdkecdkc6h0h',
  'xn-----7kcabaarf2b0aobhdmecelc7i8h',
  'xn--80aaaaandca8aplbsagfckfecdiic1lpjwcc',
  'xn------6cdabaaufda9btncvbhgcmgecekic7m3j5cc',
  'xn--80aaaadagwbcugi1ah2acckfebd4ehsiv5e6e1d',
  'xn-----6kcacaebh1acdygk5ah6accmfecd0fitjx9e9e3d',
  'xn--80aabeceidd1ccomjjddbec3agmfhmo2p7c',
  'xn------6cdbchffndf5ddspkldebgc7ahnginq1rod',
  'xn--80aaabcadjpdbc1bawcfqf1apddb3aqgclefhdlfo6bk3r0f',
  'xn-------53dbcbfbdowfcd2ca2adgug6asdeb9ashdmegiemfq1cl3tqg',
  'xn--80aaabcddfledb4bawcfqdtkqddbkcstgcsfhdso7b4p',
  'xn-------53dbcbfehgsefc6ca2adguexltdebncvvhdtgietq2cxq',
  'xn--80aaaabgind6aqcc1bhcddb9agdifhmo3a8m',
  'xn------6cdbbabjmsf6budd8bjcdeb5bheiginq7a3o',
  'xn--80aaabfhld3aocc8ahbddb7agdhfhlo2a2m9c',
  'xn------6cdbbbilqf3bsdd5bjbdeb3bhehgimq6a7nud',
  'xn--80aaaaaabdflpedcsl1bivusocddba1axomfjs2c7g1dum',
  'xn------6cdbbaaabgfpuefdvo7bjzxuqcdebb4azqmgkt7cwh5d2m',
  'xn--80aaaaaabikrd0a7aemqokcddbaxtwfjfiww8e2a8f1l',
  'xn------6cdbbaaablowf7a3bgotqmcdebb0avygkgizy5f4a5g2m',
  'xn--80aaaabgibndy4aelpnjcddbawsmjfjfiww6hvdvec5l',
  'xn------6cdbbabjmdqf5a1bgnsplcdebbzuojgkgizy5izd0ec6m',
  'xn--80aaaaabcfkrdb2amayhbleqtqbddba2agslrfjdkh5avi7g1a5irn',
  'xn--80aaabcadjpdbcynadwhejerurbddba3agtlsfjdlh6avif4j0exn',
  'xn--80aaaaabhjledp0aaviksqmcddbazvyfjhirhw5i1dum',
  'xn------6cdbbaabknqeft6aazkmvsocdebb2ax0agkiiuhy4j5d2m',
  'xn--80aaaabcejpdcwpuhkrplcddbayuxfjgixwe7h1d7l',
  'xn------6cdbbabfenufd2asyjmurncdebb1awzgkhi0ayf5i5dwm',
  'xn--80abgcewkjzqdfi1kod',
  'xn-----8kcdkcg4anm4ategj0msd',
  'xn--80akhaebbecc5aezheeacgfv6q',
  'xn-----7kcvlagbcfdd0bf3aifeachgx3s',
  'xn--80abemmbcmeye1agfbfjs1e5t',
  'xn-----6kcdhsrbdpg2af6ahgbflu8e3u',
  'xn--b1aghcgetlidfbcjp9c',
  'xn-----elcmmdjgyokegbclr6d',
  'xn--80aalfdmaibgacpomrqooghb5b1e0fye',
  'xn-----6kcbsjdqakchadsqnuspphib9b3e9f7e',
  'xn--80aaacfhdibdey0aaesrpmbddbayurgfjcn0c1gyd1l',
  'xn------5cdcbdilelcfe4a6aagwurncdebb1awshgkco5c4g2d2m',
  'xn--80aaaagbaiqc2a4a9afcecbb4aegdjmhgn4a8czc00b',
  'xn------5cdcbajbblwd9a2b4bhcedbb9afhejoigp8a0d2cz1b',
  'xn--80aaafbahoczhw9afcecbb4aegdjmhgn4a8czc00b',
  'xn------5cdcbibbkud6ak2a6bhcedbb9afhejoigp8a0d2cz1b',
  'xn--80abmbgihbfaconlqpnnghwr6e0fye',
  'xn-----7kccudikjcgadrpmtroohiyt2f4f3e',
  'xn--80aabafhkabdn2baerqolbddbaxtqgfjos0a6hvd6l',
  'xn------7cdbbbiloacfq1cagvtqmcdebb0avrhgkpu3a5izdwm',
  'xn--80aapkagbcccc3af7ahehgfub4q',
  'xn-----6kcb0aoaicdcdd7ag2bifhhgwb1s',
  'xn--80aaacfhkbdxucec0bhkbddb1amgigfhpoc7r',
  'xn------5cdcbdilocf3a0acgd6bjlcdeb5aohihgiqqc6t',
  'xn--80aaagbahldc2atb5bijecbbzheghhmjgnc3gtcwjwp',
  'xn------5cdbckbbkpfd9ayb4ckledbb3aifhhiokgpc2hwc0jpq',
  'xn--80aeibmcedcb6afmugfgmtb1dvb8h4l',
  'xn-----6kcjocqdfedb2bgoxhggovb7dxbyitm',
  'xn--80aaaaaqc1awmgdjcgjqc2f',
  'xn-----6kcabaawd8a0aphekdglsc0g',
  'xn--80aaaaaqcaa7aplbsgfcjfcgihcvc5islvbc',
  'xn------5cdabaazdab7btncwhgdkgdgkicyc7j8lzbc',
  'xn--80aawacaocamsgy3a89nga8e',
  'xn-----7kca1badbscapvi1a8a15oha7f',
  'xn--80aawacaapdanrdgd0awnf75tga8g',
  'xn-----7kca1badbatdaqudid3azpf18vha7h',
  'xn--80aaaacesdbd4aereclujbmulpecx1a7a7ex6a',
  'xn-------63dbbaff4adee5bfwgdozjdoyoqfez5a3b8e58a',
  'xn--80aaaaacfdudbcbc2bivegl2ajbkgzlueckskx4b8fu8a',
  'xn--------5vebbbafhh5adedbd5ck1agio9ajdmh4aowfelunz1c2gs1b',
  'xn--90aabmabbtccqdlx7ajfahlin0a3dw4a',
  'xn------cddbbvcbczedueo1a5bkgbimko4a0et4a',
  'xn--90abjbnbmwyfalan5c9t',
  'xn-----clcbpdrdp1a4agbmbo2dyv',
  'xn--80aaaaaaidqedcdc9aehxcgc6akjcgebghio1bgleursds2lwa40fua',
  'xn--80abcfhbymkr4aeejp5eycd4h',
  'xn-----8kcdcjjb6apnt0bffkr4f3ce4i',
  'xn--80aacchibyepntc0bfgkpd3g8cf9hpa',
  'xn------7cdbfcnlb7ahtswe7bgilre7hwdh1jsa',
  'xn--b1afblfwgofll6as',
  'xn----ctbicng0ahqgmm9at',
  'xn--80adakbrhkwgdwfdmqs4a4a23ata',
  'xn------8cdgcrczkq2ajd2agfntx8a9az5awa',
  'xn--80aaelbrhjxfewfcnqr5a4a05a',
  'xn-----8kcagrcwjn1ahe0agdosu8a7a48a',
  'xn--80adibocejxfewfcnqr5a4a05a',
  'xn-----8kcfocteen1ahe0agdosu8a7a48a',
  'xn--80aaa4aaaacaufaih0ag6a0al0m8ds8hga5fva',
  'xn------5cdca6cabadb0agakj5ai1b4ao1ozer7iha5gya',
  'xn--80aaa4aaaacaavgajhvhgd8a0aglf4p5es6jga5hva',
  'xn------5cdca6cabadba1ahaljziid3b4agof5rqfn4kha5iya',
  'xn--lodaapaecmcjcctd5b5bmci6etixc',
  'xn-----7hkacvbfeodlcdve0cxcoci4f0i2c',
  'xn--lodaaaaffjfaefcpjcqcb8ad6djd0dlmiia0iwkxd',
  'xn------jwnadaaiimfbgfetjdtcc2be4emd6dnnikb9ixl7d',
  'xn--lodaleiagaccoe5ek9a2a9jpc',
  'xn-----7hkcshkbhbcdqf6fm3b6a4luc',
  'xn--y9aaaaa2dbqpf4cgp3cwc6awc',
  'xn-----ccdaabb0fcvsg3dhr1d0cvbzac',
  'xn--y9aaaaa3dolbbf9cgs5cxgc',
  'xn-----ccdaabb2ftanbcg8dhu3d4gc',
  'xn--y9aaaaayujobutbbgd9elpiie7bcq1pci3dd',
  'xn------0ceaabb1b3amsc0axbcid2gnsile3ccs1rck9dd',
  'xn--4dbaaddagbabqdd1d5bmjt4ptai',
  'xn-------ktfdbhdbjdacvfe8ewcplx3txai',
  'xn--4dbaaddagbabddled9d9bolv2rva',
  'xn-------ktfdbhdbjdacedpge6f0crnz1vza',
  'xn--4dbddabjd8dbl',
  'xn-----wldfgacme4fbn',
  'xn--4dbaccfabnd5b8aifp8lpa',
  'xn------rpebfcjacse8c2bkgs8osa',
  'xn--igbakaaaaaabaaixd4aoje0ab6cf9fuerngbafbceabbrmk6euas2eeg',
  'xn--igbakaaablb1ar0axc5f3a9pecaabbehpqroy2dgt',
  'xn-------i5fbwbbbcpc2bya9a4ad6hqb5sfdaabcfhssuq1a9dhw',
  'xn--igbakaabaaeib9bgeu3aif9d9psafbbabceabbyk4dtn5df',
  'xn--------qchczbccabglc8djg0a2blh6ftsxagbcabdfabc1am3evaq2eg',
  'xn--igbhabacrwvq9ae8qbceo8fn',
  'xn-----zsdobcacx1a0at6be8tbdfp7gp',
  'xn--igbhabaclgzxs2bf3sbcep3gn',
  'xn-----zsdobcacpi4a2av9bf3vbdfq2hp',
  'xn--igbhabacd2byb9a6mbceamwb6do',
  'xn-----zsdobcacf1c4b3bznbdfanzb2eq',
  'xn--igbhabacc5aixs1ar3sbcgnvg9ae5a',
  'xn------7yesbdace7bk5aw9au8wbdiozh4bf8a',
  'xn------9yeqbcgfc3avt9a6cwoaebcabd0ag1dzcg',
  'xn--igbhaabadgbo5a1aw8bh4ao9sdcabcehbolg2d8cfn',
  'xn-------i5fubccaficv6bxb3a1dj1br7xedabdfhbrmh0eydgp',
  'xn--igbhaabadgbfj8a3ay1ci6ap1udcabceibomg4dvdfn',
  'xn-------i5fubccaficin9bzb5a4dk3bs9yedabdfibrnh2exdgp',
  'xn--mgba0gabp',
  'xn--mgbaaa4ay2kabbnq',
  'xn----ymcaba0b2a0mabcor',
  'xn--mgbaaaag7ac7ad4rabbenxd5eb',
  'xn-----btdababi6bd4be0vabcfo0ad3fb',
  'xn--mgbaaaaa4brqv4pabbenxd5a',
  'xn-----btdababa5cwat0a6sabcfo0ad9a',
  'xn--mgbaaaaa4brqv4pabbenxd5a7b',
  'xn-----btdababa5cwat0a6sabcfo0ad9azc',
  'xn--mgbaaaa9a2abr7nabbemy3a1b',
  'xn-----btdabab0cwbcv9qabcfn1a7a5b',
  'xn--mgbaaaaa4b6a0b9jabbeckex5a7b',
  'xn-----btdababa5c0b7b5kabcfclfz9azc',
  'xn--mgbaaaaag2bybbcx8evhabbeem8a2b4bf',
  'xn------nzeababbi6c7bcd3a5g0iabcffn3b7b8bg',
  'xn--mgbaaaa9a2ab7c8fpaabben0a2e',
  'xn-----btdabab0cwbc9d3graabcfo3a0f',
  'xn--mgbaaaaaa9bxbb9bk4lnbabbicki1a3b3b',
  'xn-------z5fabacba0e9bd6dn7pvbabclcll5a1c8b',
  'xn--mgbaaaaa4b6a0b8h1aabbfckfy7a2b',
  'xn------nzeabaca0d3bzc1j8aabchclh1a3b5b',
  'xn--mgbaaa4ayo6a7fa0aabbfmxd1ee',
  'xn------nzeaba2cwbw8b0hb6aabchn2ad2ff',
  'xn--igbakaab5bspc5rabbenxd6e',
  'xn-----zsdbqabc6cxasd1vabcfo0ad4f',
  'xn--igbhaabst6au6nabbemue4a4b',
  'xn-----zsdoabcyy4bza8qabcfnxe8a7b',
  'xn--igbgbaaba3bh4bej0uabbep4a0f',
  'xn-----zsdnbabca3ci2cfl4xabcfq7a8f',
  'xn--igbhaaba4b6ab3f3eoabbeo2a6e',
  'xn-----zsdoabca5c0bc9gtfqaabcfp5a4f',
  'xn--igbhaab9a2ar0b0g0aabbebmzd9eb',
  'xn-----zsdoabc0cwbw8b1h4aabcfbn2ad7fb',
  'xn--igbhaabg7a6a8mabbealy7db',
  'xn-----zsdoabci6b1b8nabcfam1a5eb',
  'xn--igbhaab9a2ab9a7kabbemdw2e',
  'xn-----zsdoabc0cwbc7b2labcfney0f',
  'xn--igbhaabg7a6ab3gxeabbendy6e',
  'xn-----zsdoabci6b1bc1iqfabcfoe0a4f',
  'xn--igbhaabaggcb1al6cbcd7a7htjabbdaefne9bn5al9cgg',
  'xn-------g5fwabdbjjdc1bo2ecde5b4iukabceafgof5cp9an5dhh',
  'xn--igbhaabauw0bb6rabbendvd6a7bd',
  'xn----rmckabbaxz4bb4tabceodwd8a8bd',
  'xn--mgbaaaarkk0bv5pbkfhcl71hnxio',
  'xn------nzebbba0anp2c2a4ubmhico58ic1app',
  'xn--mgbai3aaie3md42cob9h6rga73cd',
  'xn-----btdao8bbkg9pf62dvb1i6tia60dea',
  'xn--lgbbaan7bameaa7td3a74efa3e7jvyga74edib',
  'xn------mzebadt9dbqgba1zf0b15ega5ftlr1aia15feajb',
  'xn--igbhabacdc0ap8b4bxc4jbceael0ab7efo',
  'xn------8yesbcadfc9au0dyc4c4kbdfaen4ab6fgq',
  'xn--igbhabaccd0afk1aw5av5d6hxbbchfl1ag9be9afi',
  'xn--------pch0abeadfe5bjn4b3a8b1a4fzj9bbdkfn8ah8cf4bgk',
  'xn--mgbaaaagbxoc5bd7gqhabbefl1ah5des',
  'xn------ozeababjb6atd6ce8ioiabcffn5ai3eev',
  'xn--mgbaaaahuntsx7dj0mabbefl8a9a2at',
  'xn------ozeababk3as0ax4a2fm1pabcffn3b2b5aw',
  'xn--mgbaaaaaixovuz3euiabbefl1ah9a5a',
  'xn------ozeababal6at2az6a3ftjabcffn5ai4b8a',
  'xn--mgbaaaaaixovuz3euiabbefl1ah9a5au',
  'xn------ozeababal6at2az6a3ftjabcffn5ai4b8ax',
  'xn--mgbaaaaaigtpxw1a9exiabbegl2ai0b7au',
  'xn------ozeababaljzu4a1a8a9f4jabcfgn6aj5bybx',
  'xn--mgbaaaaaixo4b6b0bzkabbeccle5a9a5au',
  'xn------ozeababal6at6c2c6bzlabcfccnf9a3b8ax',
  'xn--mgbaaaahun0bb9dp9jvaabbefl8a3ds',
  'xn------ozeababk3as2cc7fsa9mzaabcffn3b8dva',
  'xn--mgbaaaahmjo4b3e7fgabbeel6a7a1arb',
  'xn------ozeababksmt6c2f9ghabcfen1b1b4aub',
  'xn--mgbaaaaaaj0ap8bb7cm9azkwbabbiccli9a6b9ao',
  'xn--------ddhabacbao5bw8dd8eq0c9l7babclccnl5b2c0br',
  'xn--mgbaaaaaaaaakckbketxk4b4add1poa4rxcabbfgfglfm3c9gon',
  'xn--mgbaaaaaixo4b6b0b1hpbabbfcclf6a1b6ao',
  'xn-------05fabacam9au0d7c9b8iwbabchccnh1b7bxbr',
  'xn--igbakaabjxowtc8gqhabbefl1ah9dt',
  'xn------8yebuabcm6at3ayd9ioiabcffn5ai7ewa',
  'xn--igbhaabhmjo4bya6d7habbeelyi8a3at',
  'xn------8yesabcksmt6c5a1f9iabcfen2aj3b6aw',
  'xn--igbgbaabak0agj2cel9gsiabbehl2b6dua',
  'xn------8yeqcabcan9ajl4dfo7ixjabcfhn7b2exa',
  'xn--igbhaabhun0bbv7d7habbeel6a7a1as',
  'xn------8yesabck3as2cc1a2f9iabcfen1b1b4av',
  'xn--igbhaabhfro4bbx3euiabbefl8a8a3as',
  'xn------8yesabckixt6cc3a8ftjabcffn3b1b6av',
  'xn--igbhaabaixo4bb1gi2kuaabbegl0b6dt',
  'xn------8yesabcal6at6cc2il9myaabcfgn5bzewa',
  'xn--igbhaabagc0ap8bbc1a2fi4pabbeeel6b5b6asf',
  'xn-------h5fwabcbjd2bv4dcd9a6gl6tabcffen3cycwbvg',
  'xn--igbhaabhun0bv8bo6i9aabbebel3ah2etab',
  'xn------8yesabck3as2c2a0dr3ltbabcfben7ai0fwab',
  'xn--igbhaabgbxo4b3eugabbeadl6a3dqb',
  'xn------8yesabcjb6at6c2fvhabcfadn1b8dtab',
  'xn--igbhaabhun0bb7b0bzkabbeeld4a6ds',
  'xn------8yesabck3as2cc9c7bzlabcfene8a0eva',
  'xn--igbhaabaahcfcb1agn8dccd3bzhm2pqcabbfefflf8co0bl8bohgg',
  'xn--igbhaabainlp8bb9gqhabbefldzh0b7asd',
  'xn-----0sdoabbakrnt6cb3i6habcefnd1ai3b9aud',
  'xn--mgbaaallk6a8dq0f4abjhvf60voa',
  'xn------ozebbarrp8byfwa1hrbbljzg40zra',
  'xn--mgbaaallk6ab7e6fbihtf37tka',
  'xn------ozebbarrp8bc8gqhbkjxg17xna',
  'xn--mgbaaaaappdj8bbdz5f7ibhghcf0afj0a566aoafb',
  'xn--------ddhbcabazzgn8dcf8a4hxkbkhjdg6agl5a223bragc',
  'xn--mgbaamazqpl9c5d0biliqo2a78vicf',
  'xn------nzeacvb1bxvp5e3eqckmltr5a97ztcg',
  'xn--mgbgthh4hn97h',
  'xn----zmcjzjj1joa31j',
  'xn--igbhbabptto6ad4pcem8en',
  'xn-----zsdpcabvyyr3bd4sdfn7fp',
  'xn--igbhbabkfwvq9ae9qcen3fn',
  'xn-----zsdpcaboh1a0at6be9tdfo2gp',
  'xn--mgba3aai1b3dbjjbnjbliamdees92u',
  'xn----------3pje2hbv4f7fbrpeuncroapgffz331a',
  'xn--mgbaaaa8a2a7a3jabbecjev4a5b',
  'xn-----btdbaba9bxb4b0kabcfckfx8a8b',
  'xn--mgbuf4egnaf13g',
  'xn------nze7cl5jjwah63j',
  'xn--mgba3aai1b3drkvajeel22n',
  'xn-------z5fe2ebp6d7ezao6aamffp32r',
  'xn--igbhaabh6a6abt0c8iabbedbkh0a8azc',
  'xn------8yesabck0c4bcz2dxkabcfdbmi4a3b3c',
  'xn--igbhaabagc4b1bbcz2dxa3oabbeedbkm5a8b0cf',
  'xn-------h5fwabcbjd2dyccd7a8e4a5sabcffdbmo0b3c5cg',
  'xn--igbhabacfbn2azu5bg2an0scabcefbojg8c6cfn',
  'xn-------i5fvccaehcu3b8a1a8ci9aq9wdabdffbrkh6dudgp',
  'xn--igbhabacfbfi5a1aw8bh4ao2tcabcegbokg0d6cfn',
  'xn-------i5fvccaehcim6bxb3a1dj1br1ydabdfgbrlh8dwdgp',
  'xn--mgbasq5gbjk',
  'xn----ymcbyu3ibll',
  'xn--mgbaaa2ayb0mbmpb15o',
  'xn-----btdbba3b6ac6pbpsb91r',
  'xn--mgbaaaaa5ah0bbdv7sbhkcfwbjw151afab',
  'xn-------z5fbcaba3ck6ccf2a2zbkmdg1abl0a976agac',
  'xn--mgbaaa3ay2kbbeidnb0dc',
  'xn-----btdbab4b6a7mbcfjepb8dc',
  'xn--lgbcaaai6a6a8mabbpqe7b',
  'xn-----7sdfabak6b1b8nabcrsf2c',
  'xn--lgbcaaaaaaamt5a6cd0zabbere1ae1ewa',
  'xn------jzegababaaapz3b2de54aabcftf4af9eza',
  'xn--lgbcaaaaj0btsx6e1gabbep0ae8azau',
  'xn------jzegababm4c0ax4a4g0habcfr4af3b2ax',
  'xn--lgbcaaaaaaaaaldkble8bk4b4add5qrhucabbfgfqfm5be3ipan',
  'xn---------hjijabacaaabbqepcpg2do6cybfe3vwi8cabchhgsho1cf0ksap',
  'xn--mgbaaaoq2ar1mbnr73e1s4k',
  'xn-----btdbbauv0bw3pbqu94f3umm',
  'xn--hqbecaoju9bgy6tc4cddb7dbfb',
  'xn-----vnekdbul0a1dh3a6xc2deeb4ecfb',
  'xn--nxdv1gvbc82ayk8ttah59e',
  'xn-----g9k3b7ipcd23bonlyyai73g',
  'xn--nxdvk6esb2dc2xogkglmh4a1al97h',
  'xn------txowcua0koc6ed96a6h2hroq0b8an29j',
  'xn--nxdv1gvbc82aykit3avi66f',
  'xn-----g9k3b7ipcd23bonjx9a0aj80h',
  'xn--nxdvk6esb2dc2xogkglm12aib3am05i',
  'xn------txowcua0koc6ed96a6h2hro38apbwbo36k',
  'xn--nxdnm9e6b8cc40a1erskonllb7ai05i',
  'xn------uxo6a0a4kodued31bkgmwvrjoub1bj36k',
  'xn--nxdnmn1fp7c0ec63arbxm9hro8t0owb1bm43l',
  'xn-------7lsvb4a0a2l0a8f8fd56b6b4o7j5rvyrs7b9bo81o',
  'xn--nxdv1gvbc82ayk0l4h6ayi73g',
  'xn-----g9k3b7ipcd23bonlopjjb3aj97h',
  'xn--nxdvk6esb2dc2xogkglmyrhlpb6am12j',
  'xn------txowcua0koc6ed96a6h2hroqvnnxbzbo43l',
  'xn--nxdnm9e6b8cc40a1ersko6a33apbxbi12j',
  'xn------uxo6a0a4kodued31bkgmwvrjb77axb4bj43l',
  'xn--nxdnmn1fp7c0ec63arbxm9hro8tmb17azb3bm50m',
  'xn-------7lsvb4a0a2l0a8f8fd56b6b4o7j5rvyxby0bgcuco98o',
  'xn--11bxui4ack6c7b6ee8e',
  'xn----uud9a0ak1bcl3dvcwff4f',
  'xn--n1b1a5af8am6bco8daa4ehb8a1he0j',
  'xn-----gmf8cuci1cq9ccr1fba4fjb2b6hf2k',
  'xn--54b2b4agxboac7azbncue4dc6b9fcecks1qjf8mkf',
  'xn-------qoj7h0cn4bcxae0cxcsdzf9ed6cxhcfcmw0tlag0pmaf',
  'xn--54b2b4agxbq1a2bcr3c4a9ecll2lcf6jf',
  'xn------ush1hscl0bcy1b1cdv5dwb5fcno4ndg4lf',
  'xn--54bk5b0bbi7abu9a9bcv1d8aca9gcleli1pcf5i1bf',
  'xn-------qoj7a4grdco5cc4a3c9cd1a7e0bda0jcoeoj0sdg2k7bf',
  'xn--54b2b4agymac3a7amcse0dc4b8fcec1a5ojf6lkf',
  'xn------tsh2hscl1btae2b5bqdvf1ed2czgcfc6a6qlag2nmaf',
  'xn--54b2b4agyox8acp9b2a3ecv6jcf4if',
  'xn-----xwf2f4bj7au3a7bdr7c8a9ecz0ldg6jf',
  'xn--54bk5b0bbi8as5a6bct7c6aca3gcloi5ncf7hybf',
  'xn------tsh2a6f6ccm2c0a5b5cdx9dxbda8hcoqj6pdg0j1bf',
  'xn--54bf1czbi8ak8aa7ac4a7ct9gch4lfk5if',
  'xn------wshn8g2cn6co1cb6bd2b3dxa9ici7ngm1kf',
  'xn--54b1b4ag2aj3a1acy9br5fch8jfi5hf',
  'xn-----zwf8e5bj4bm2b7ad3a7cu8gci3lgj6if',
  'xn--54bff9c8bbk0cm8ba3bc0bzdvaca8ifchi3pfq8g2bf',
  'xn-------tojqm4iydcq4er6db5cd1cze0ada2lhcij3sgt2i8bf',
  'xn--xkc2aaobfaaa1bznnbf6b5j7azf8ai3gfhcc',
  'xn-----85g0dcudgaab8b6orbg2c2kmb4fsbj2hghdc',
  'xn--uaxu-xvc782kea7adbb6jtjyeg2isbe',
  'xn--u-a--x---u---k2g1581aka1egbe8rmn7gj7n7bh',
  'xn--fzc2ca6a3c9cpbccq0c8aa9gydh4dzabb1373h',
  'xn-------0un6ic9d1e7exabedw5d3bb3jrek6e5acb8832j',
  'xn--12cs4am9c2eb9l',
  'xn--12cs4amaw5cf0cwerb0q6b',
  'xn--12cmi4bqa0a2dg5c2a7dtb0tka3d8c',
  'xn--12cs4am5bq4c9cbb3r',
  'xn--12cs4amaw5awg5co2fuabb2uqc',
  'xn--12cmi4bqa0a9azah0dpr7ewabb2xna2ezd',
  'xn--p6ccob6dufoa9b2bg2n',
  'xn--p6cm2b1dk6dd0j',
  'xn--p6cadrcb2gsb3hua0dh3dcg5kqe',
  'xn--p6caqc8d2a1gqa0cyccd1i1d',
  'xn--nidadja8gaf1bb3snath9a6eycge0amd',
  'xn--nidadjad0iaf6bb1evb0kqawk5b5f4byageh2amd',
  'xn----0qgaemae5jaf1cb7exbylraxl7b5f6bzagei3amd',
  'xn--i2eaa1eo0an2cymc42a',
  'xn--i2eaaa9a7cuatqbt6e2ocj2fyuf',
  'xn--i2eaaa9a7cpfwrcu2f6pck7f0g1lh',
  'xn--i2eaaag4ae3dua3azobhb3a8e3a12bdccjj7iy1afa',
  'xn--i2eaaag4ae3duavi2apbhc4a3f4a34bdcckj2jojzpha',
  'xn--3e0ba393bh3hc2c2zjn8dl3l92az09g',
  'xn----xj6eb918csmjqode4k4tei3mi6an39g',
  'xn--3e0bs9hfvieoht0fwjj0ay24f',
  'xn----xj6er1m4wk6vi3zgjla63ab39f',
  'xn--3e0bs9hfvieoht0fwjj0ay24fmmc',
  'xn----xj6er1m4wk6vi3zgjla63ab39fcrc',
  'xn--3e0bs9hfvig3eo8crxjtwaz24f',
  'xn--3e0bs9hfvig3eo8crxjtwaz24fmmc',
  'xn--vk1b82xpvbxxhdqa745d',
  'xn--vk1b151a0od4mn56chvb',
  'xn--pr3b89dsvbxxidqayu',
  'xn--pr3b89dsvbxxidqayui40f',
  'xn--ob0byio99aa296df1cdrllhyhv5bx8ac0ca2498b',
  'xn----2c6emm049ba800fmldnsmiianij8b2zbc2ca8532c',
  'xn--ob0byio99aa296df1cdrllhyhv5bx8ac0ca2498beya',
  'xn----2c6emm049ba800fmldnsmiianij8b2zbc2ca8532c0za',
  'xn--fiqr7j6sdmwfxs7c',
  'xn--fiq7vu3hivegvg2x5d',
  'xn--fiq7va463bbc762a3widn0f',
  'xn--fiq7vu3hvb79y9wh7m3e',
  'xn--fiqr7jeud0ufx54c',
  'xn--fiqr7jwb26tgzgjmuyk0b',
  'xn--fiqr7jeud0uf4sr094a',
  'xn--fiqr7jwb26tgzgimuba1816b',
  'xn--jhqz5icnp3jgvgf3r2gc549eda6905bk3o',
  'xn--jhq5yba524ch8aj5kxokijyk8c033gea957cso7g',
  'xn--6oqs9f2n55k53f9vsjg5b',
  'xn--kkr01qt5injbz69h',
  'xn--kkr01qt5injb6x3cfu1a',
  'xn--kkrqb622bsrm0y0a',
  'xn--kkr01qe5j28v',
  'xn--kkrqb622brrmba3194a',
  'xn--kkr01qe5ja082zusb',
  'xn--6oqz4hpzis71b',
  'xn--6oqa103awb171d2l7c',
  'xn--6oqa103auqk5kge84c',
  'xn--6oqz4hhb706cx49b',
  'xn--6oqz4h6xam1f4xekwc7y3hgsyb',
  'xn--kkr01qhx7b',
  'xn--kkr01qf5zo2r',
].map(b => b + '.avax')

const exports = {
  isBlocked: (name) => {
    return blocklist.indexOf(name) > -1
  }
}

export default exports
