import constants from './constants'
import services from 'services'

const actions = {
  setLoading: (isLoading) => {
    return {
      type: constants.SET_LOADING,
      isLoading,
    }
  },

  setData: (data) => {
    return {
      type: constants.SET_DATA,
      data
    }
  },

  loadHash: (hash) => {
    return async (dispatch, getState) => {
      dispatch(actions.setLoading(true))
      const api = await services.provider.buildAPI()
      const data = await api.loadHash(hash)
      dispatch(actions.setData(data))
      dispatch(actions.setLoading(false))
    }
  },
}

export default actions
