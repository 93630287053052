import React from 'react'

import components from 'components'
import services from 'services'

const ConnectWalletButton = (props) => {
  return (
    <components.buttons.Button sm={props.sm || false} text={props.text || 'Connect your wallet'} onClick={() => services.connect.toggle()} />
  )
}

export default ConnectWalletButton
