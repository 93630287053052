"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const collectDependencies = (...dependencies) => {
    const set = new Set();
    const array = new Array();
    dependencies === null || dependencies === void 0 ? void 0 : dependencies.forEach((dependency) => {
        if (!set.has(dependency)) {
            set.add(dependency);
            array.push(dependency);
        }
    });
    return array.join('\n');
};
exports.default = collectDependencies;
