"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [
    ...fragments_1.discordTargetFragmentDependencies,
    fragments_1.discordTargetFragment,
];
const MUTATION = `
mutation createDiscordTarget(
  $name: String!
  $value: String!
) {
  createDiscordTarget(
    createTargetInput: {
      name: $name
      value: $value
    }
  ) {
    ...discordTargetFragment
  }
}
`.trim();
const createDiscordTargetImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'createDiscordTarget');
exports.default = createDiscordTargetImpl;
