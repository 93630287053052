import React from 'react'
import { connect } from 'react-redux'
import { DuplicateIcon, CheckIcon } from '@heroicons/react/solid'

import actions from './actions'
import selectors from './selectors'

import components from 'components'
import services from 'services'

const INITIAL_STATE = {
  step: 1,
  loading: false,

  recordType: null,
  reverseContract: null
}

class ClearReverseRecord extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = Object.assign({}, INITIAL_STATE)
  }

  reset = (recordType) => {
    this.setState(Object.assign({}, INITIAL_STATE, { recordType }))
  }

  deleteRecord = async () => {
    this.setState({
      loading: true
    })
    const api = await services.provider.buildAPI()
    try {
      await api.deleteReverseRecord(this.state.recordType, this.props.domain)
      this.setState({
        loading: false,
        step: 2
      }, () => {
        this.props.reloadRecords()
      })
    } catch(err) {
      this.setState({
        loading: false
      })
    }
  }

  getName = () => {
    return {
      [3]: 'EVM Address',
      [4]: 'Validator Node ID',
    }[this.state.recordType]
  }

  renderStepOne = () => {
    return (
      <>
        <div className='font-bold mt-8'>
          {'Delete Reverse Record?'}
        </div>
        <div className='mb-2'>
          {'This will delete the '}{this.getName(this.state.recordType)}{' reverse record for '}{this.props.domain}{'.'}
        </div>
        <div className='my-4'>
          <components.buttons.Button sm={true} text={'Continue'} onClick={this.deleteRecord} loading={this.state.loading} />
        </div>
      </>
    )
  }

  renderStepTwo = () => {
    return (
      <>
        <div className='font-bold mt-8'>
          {'Record deleted ✅'}
        </div>
        <div className='mb-2'>
          {"The reverse record has been deleted."}
        </div>
        <div className='my-4'>
          <components.buttons.Button sm={true} text={'Complete'} onClick={() => this.props.onComplete()} />
        </div>
      </>
    )
  }

  render() {
    if (this.props.complete) return (
      <>
        <div className='max-w-md m-auto'>
          <components.labels.Success text={'Reverse Record for C-Chain / EVM has been set'} />
        </div>
        <div className='max-w-md m-auto mt-4'>
          <components.buttons.Button text={'Close'} onClick={() => {
            this.props.reset()
            this.props.onComplete()
          }} />
        </div>
      </>
    )

    const renderStep = {
      1: this.renderStepOne,
      2: this.renderStepTwo,
    }[this.state.step]

    return (
      <>
        <div className='max-w-md m-auto'>
          {renderStep()}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: selectors.isSettingEVMReverseRecord(state),
  complete: selectors.isSettingEVMReverseRecordComplete(state),
})

const mapDispatchToProps = (dispatch) => ({
  setEVMReverseRecord: (domain) => dispatch(actions.setEVMReverseRecord(domain)),
  reset: () => dispatch(actions.resetSetEVMReverseRecord()),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ClearReverseRecord)
