"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterFragmentDependencies = exports.filterFragment = void 0;
exports.filterFragment = `
fragment filterFragment on Filter {
  id
  name
  filterType
}
`.trim();
exports.filterFragmentDependencies = [];
