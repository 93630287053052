"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const DEPENDENCIES = [];
const QUERY = `
query findTenantConfig(
  $tenant: String!
  $type: TenantConfigType!
  $id: String!
) {
  findTenantConfig(findTenantConfigInput: {
    tenant: $tenant
    type: $type
    id: $id
  }) {
    id
    type
    dataJson
  }
}
`.trim();
const findTenantConfigImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'findTenantConfig');
exports.default = findTenantConfigImpl;
