"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const conversationMessageFragment_1 = require("../fragments/conversationMessageFragment");
const DEPENDENCIES = [
    ...conversationMessageFragment_1.conversationMessageFragmentDependencies,
    conversationMessageFragment_1.conversationMessageFragment,
];
const MUTATION = `
mutation sendConversationMessage($sendConversationMessageInput: SendConversationMessageInput!) {
  sendConversationMessage(sendConversationMessageInput: $sendConversationMessageInput) {
    ...ConversationMessage
  }
}
`.trim();
const sendConversationMessageImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'sendConversationMessage');
exports.default = sendConversationMessageImpl;
