"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NOTIFI_CONFIGS = void 0;
exports.NOTIFI_CONFIGS = {
    Production: {
        gqlUrl: 'https://api.notifi.network/gql',
        storagePrefix: 'notifi-jwt',
        wsUrl: 'wss://api.notifi.network/gql',
        dpapiUrl: 'https://dpapi.prd.notifi.network'
    },
    Staging: {
        gqlUrl: 'https://api.stg.notifi.network/gql',
        storagePrefix: 'notifi-jwt:stg',
        wsUrl: 'wss://api.stg.notifi.network/gql',
        dpapiUrl: 'https://dpapi.stg.notifi.network'
    },
    Development: {
        gqlUrl: 'https://api.dev.notifi.network/gql',
        storagePrefix: 'notifi-jwt:dev',
        wsUrl: 'wss://api.dev.notifi.network/gql',
        dpapiUrl: 'https://dpapi.dev.notifi.network'
    },
    Local: {
        gqlUrl: 'https://localhost:5001/gql',
        storagePrefix: 'notifi-jwt:local',
        wsUrl: 'wss://localhost:5001/gql',
        dpapiUrl: 'http://localhost:5005'
    },
};
const notifiConfigs = (environment) => {
    return exports.NOTIFI_CONFIGS[environment];
};
exports.default = notifiConfigs;
