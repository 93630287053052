"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.completeLogInByTransactionFragmentDependencies = exports.completeLogInByTransactionFragment = void 0;
const userFragment_1 = require("./userFragment");
exports.completeLogInByTransactionFragment = `
fragment completeLogInByTransactionFragment on CompleteLogInByTransactionResult {
  ...userFragment
}
`.trim();
exports.completeLogInByTransactionFragmentDependencies = [
    userFragment_1.userFragment,
    exports.completeLogInByTransactionFragment,
];
