"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userFragmentDependencies = exports.userFragment = void 0;
const authorizationFragment_1 = require("./authorizationFragment");
exports.userFragment = `
fragment userFragment on User {
  email
  emailConfirmed
  authorization {
    ...authorizationFragment
  }
  roles
}
`.trim();
exports.userFragmentDependencies = [
    ...authorizationFragment_1.authorizationFragmentDependencies,
    authorizationFragment_1.authorizationFragment,
];
