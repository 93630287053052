import React from 'react'
import fetch from 'node-fetch'
import { utils as ensAvatarUtils } from '@ensdomains/ens-avatar'

import services from 'services'


class ERC721Token extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contentUrl: null,
      contentType: null,
      tokenName: '',
    }
  }

  componentDidMount() {
    this.fetchImageUrl()
  }

  fetchImageUrl = async () => {
    let metadata, contentUrl
    try {
      if (this.props.metadata && this.props.metadata.indexStatus === 'INDEXED')  {
        metadata = this.props.metadata
      } else {
        let metadataUrl = ensAvatarUtils.resolveURI(this.props.tokenUri, {
          ipfs: services.environment.DEFAULT_IPFS_GATEWAY
        })
        const res = await fetch(metadataUrl.uri)
        metadata = await res.json()
      }
    } catch (err) {
      console.log('METADATA ERR', err)
      console.log(this.props.tokenUri)
    }

    if (metadata) {
      try {
        if (metadata.imageUri && !metadata.image_url) metadata.image_url = metadata.imageUri
        contentUrl = ensAvatarUtils.getImageURI({ 
          metadata,
          gateways: {
            ipfs: services.environment.DEFAULT_IPFS_GATEWAY
          }
        })
        let fileExtension = contentUrl.split('.').pop()
        let contentType = (['mp4', 'mov', 'wmv', 'webm', 'avi', 'flv', 'mkv'].indexOf(fileExtension) > -1) ? 'video' : 'image'
        this.setState({
          contentUrl,
          contentType,
          tokenName: metadata.name,
        })
      } catch (err) {
        console.log('IMAGE ERR', err)
        console.log(metadata)
      }
    }
  }

  render() {
    return (
      <div className='bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden p-2 w-full h-full'>
        <div className='bg-gray-200 dark:bg-gray-700 w-full aspect-square bg-center bg-contain bg-no-repeat rounded-lg overflow-hidden' style={{ backgroundImage: `url(${this.state.contentUrl})` }}>
          {this.state.contentType === 'video' ? (
            <video src={this.state.contentUrl} className='w-full aspect-square' autoPlay muted />
          ) : null}
        </div>
        <div className='p-2'>
          <div className='text-sm font-bold mt-2'>{this.props.collectionName}</div>
          <div className='text-sm'>{this.state.tokenName}</div>
        </div>
      </div>
    )
  }
}

export default ERC721Token
