"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.conversationMessageFragmentDependencies = exports.conversationMessageFragment = void 0;
const participantFragment_1 = require("./participantFragment");
exports.conversationMessageFragment = `
fragment ConversationMessage on ConversationMessage {
  id
  userId
  conversationId
  createdDate
  updatedDate
  message
  conversationParticipant {
    ...Participant
  }
}
`.trim();
exports.conversationMessageFragmentDependencies = [
    ...participantFragment_1.participantFragmentDependencies,
    participantFragment_1.participantFragment,
];
