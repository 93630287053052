"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.connectedWalletFragmentDependencies = exports.connectedWalletFragment = void 0;
exports.connectedWalletFragment = `
fragment connectedWalletFragment on ConnectedWallet {
  address
  walletBlockchain
}
`.trim();
exports.connectedWalletFragmentDependencies = [];
