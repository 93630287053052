import components from 'components'
import services from 'services'

import actions from './actions'
import constants from './constants'
import reducer from './reducer'
import selectors from './selectors'

import React from 'react'
import { connect } from 'react-redux'
import { CheckCircleIcon, ExternalLinkIcon } from '@heroicons/react/solid'

class Hash extends React.PureComponent {
  constructor(props) {
    super(props)
    const params = services.linking.getParams('Hash')
    this.state = {
      hash: params.hash
    }
    this.loadHash(params.hash)
    this.init()
  }

  init = async () => {
    const api = await services.provider.buildAPI()
    this.avvy = api.avvy
  }

  loadHash = (hash) => {
    this.props.loadHash(hash)
  }

  renderLoader() {
    return (
      <div className='m-auto max-w-sm text-center'>
        <components.Spinner className='w-6' size='md' dark={true} />
      </div>
    )
  }

  renderBody() {
    if (this.props.isLoading) return this.renderLoader()
    let account = services.provider.getAccount()
    const exists = this.props.data.owner !== null && this.props.data.owner !== undefined
    let isExpired, isGracePeriod, isRecyclePeriod
    if (exists) {
      isExpired = Date.now() >= this.props.data.expiresAt * 1000 
      isGracePeriod = isExpired && Date.now() <= (this.props.data.expiresAt + 2592000) * 1000
      isRecyclePeriod = isExpired 
        && Date.now() > ((this.props.data.expiresAt + 2592000) * 1000) 
        && Date.now() <= ((this.props.data.expiresAt + 2592000 * 2) * 1000)
    }
    console.log(this.props.data)
    return (
      <div className='bg-gray-100 rounded-xl w-full relative p-4 md:p-8 dark:bg-gray-800'>
        <div className='flex justify-between items-center'>
          <div className='font-bold'>{'Basic Information'}</div>
        </div>
        <div className='w-full bg-gray-300 dark:bg-gray-700 mt-4' style={{height: '1px'}}></div>
        <div className='mt-4 text-sm'>
          <div className='font-bold'>{'Domain'}</div>
          <div className='truncate flex items-center flex-wrap'>
            {this.props.data.domain ? (
              <>
                {this.props.data.domain}
                <components.buttons.Transparent onClick={(navigator) => {
                  services.linking.navigate(navigator, 'Domain', { domain: this.props.data.domain })
                }}><div className='sm:ml-2 inline-block cursor-pointer text-alert-blue underline'>View Domain</div></components.buttons.Transparent>
              </>
            ) : (
              <>{'Unknown'}</>
            )}
          </div>
        </div>
        {exists ? (
          <>
            <div className='mt-4 text-sm'>
              <div className='font-bold'>{'Registrant'}</div>
              <div className='truncate flex items-center flex-wrap'>
                <div className='flex items-center cursor-pointer w-full sm:w-auto' onClick={() => {
                  this.setState({
                    dataExplorer: {
                      title: 'View on Block Explorer',
                      data: this.props.data.owner,
                      dataType: this.avvy.RECORDS.EVM,
                    }
                  })
                  this.dataExplorerModal.toggle()
                }}>
                  <components.EVMAddress address={this.props.data.owner} />
                  <ExternalLinkIcon className='w-4 ml-2 flex-shrink-0' />
                </div>
              </div>
            </div>
            <div className='mt-4 text-sm flex items-center justify-between'>
              <div>
                <div className='font-bold'>{'Expiry'}</div>
                <div className='flex items-center'>
                  {isExpired && isGracePeriod ? (
                    <div>
                      Expired (Grace Period)
                    </div>
                  ) : isExpired && isRecyclePeriod ? (
                    <div className='flex items-center'>
                      Expired (Recycle Period)
                      <components.buttons.Transparent onClick={(navigator) => {
                        services.linking.navigate(navigator, 'ExpiredSearch', { search: this.state.domain })
                      }}><div className='ml-2 inline-block cursor-pointer text-alert-blue underline'>{'Register at auction'}</div></components.buttons.Transparent>
                    </div>
                  ) : isExpired ? (
                    <div className='flex items-center'>
                      Expired
                    </div>
                  ) : (
                    <div>
                      {new Intl.DateTimeFormat(
                        navigator.language,
                        { month: 'short', day: 'numeric', year: 'numeric' }
                      ).format(this.props.data.expiresAt * 1000)}
                      {' at '}
                      {new Intl.DateTimeFormat(
                        navigator.langauge,
                        { hour: 'numeric', minute: 'numeric' }
                      ).format(this.props.data.expiresAt * 1000)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    )
  }

  render() {
    return (
      <>
        <components.Modal ref={(ref) => this.dataExplorerModal = ref}>
          <components.DataExplorer data={this.state.dataExplorer} />
        </components.Modal>
        <div className='max-w-screen-md m-auto'>
          <div className='flex flex-col justify-center items-center mt-2 md:mt-8 mb-4'>
            <div className='text-lg text-center font-bold truncate'>Hash Information</div>
            <div className='text-sm truncate'>{this.state.hash}</div>
          </div>
          {this.renderBody()}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.data(state),
  isLoading: selectors.isLoading(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadHash: (hash) => dispatch(actions.loadHash(hash))
})

const component =  connect(mapStateToProps, mapDispatchToProps)(Hash)

component.redux = {
  actions,
  constants,
  reducer,
  selectors,
}

export default component
