

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store'
import '@notifi-network/notifi-react-card/dist/index.css'

import services from 'services'

store.afterRehydrate(() => {
  services.provider.init(store)
  services.referrals.init(store)
})

window.addEventListener('error', (e) => {
  const ignoreMessages = [
    'ResizeObserver loop completed with undelivered notifications.',
  ]
  if (ignoreMessages.indexOf(e.message) > -1) {
    e.stopPropagation()
    e.preventDefault()
    return
  }
  throw e
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store.store}>
      <div className=''>
        <App />
      </div>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
