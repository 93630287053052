"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockchainEnvironment = void 0;
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
// TODO: Deprecate
var BlockchainEnvironment;
(function (BlockchainEnvironment) {
    BlockchainEnvironment[BlockchainEnvironment["MainNetBeta"] = 0] = "MainNetBeta";
    BlockchainEnvironment[BlockchainEnvironment["TestNet"] = 1] = "TestNet";
    BlockchainEnvironment[BlockchainEnvironment["DevNet"] = 2] = "DevNet";
    BlockchainEnvironment[BlockchainEnvironment["LocalNet"] = 3] = "LocalNet";
})(BlockchainEnvironment = exports.BlockchainEnvironment || (exports.BlockchainEnvironment = {}));
const useNotifiConfig = (env = 'Production') => {
    let notifiEnv;
    switch (env) {
        case BlockchainEnvironment.MainNetBeta:
            notifiEnv = 'Production';
            break;
        case BlockchainEnvironment.TestNet:
            notifiEnv = 'Staging';
            break;
        case BlockchainEnvironment.DevNet:
            notifiEnv = 'Development';
            break;
        case BlockchainEnvironment.LocalNet:
            notifiEnv = 'Local';
            break;
        default:
            notifiEnv = env;
    }
    const { gqlUrl, storagePrefix } = (0, notifi_axios_utils_1.notifiConfigs)(notifiEnv);
    return {
        gqlUrl,
        storagePrefix,
    };
};
exports.default = useNotifiConfig;
