"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ensureMetaplexAuctionSource = exports.ensureBonfidaAuctionSource = void 0;
const ensureSource = async (service, existing, input) => {
    const found = existing.find((it) => input.name === it.name);
    if (found !== undefined) {
        return found;
    }
    const newSource = await service.createSource(input);
    existing.push(newSource);
    return newSource;
};
const ensureBonfidaAuctionSource = async (service, existing, input) => {
    const { auctionAddressBase58, auctionName } = input;
    const underlyingAddress = `${auctionName}:;:${auctionAddressBase58}`;
    return await ensureSource(service, existing, {
        name: auctionAddressBase58,
        blockchainAddress: underlyingAddress,
        type: 'SOLANA_BONFIDA_AUCTION',
    });
};
exports.ensureBonfidaAuctionSource = ensureBonfidaAuctionSource;
const ensureMetaplexAuctionSource = async (service, existing, input) => {
    const { auctionAddressBase58, auctionWebUrl } = input;
    const underlyingAddress = `${auctionWebUrl}:;:${auctionAddressBase58}`;
    return await ensureSource(service, existing, {
        name: auctionAddressBase58,
        blockchainAddress: underlyingAddress,
        type: 'SOLANA_METAPLEX_AUCTION',
    });
};
exports.ensureMetaplexAuctionSource = ensureMetaplexAuctionSource;
exports.default = ensureSource;
