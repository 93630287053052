import React from 'react'
import { connect } from 'react-redux'
import { FilterIcon, DotsVerticalIcon, SortAscendingIcon, SortDescendingIcon, RefreshIcon, SearchIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { EyeOffIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

import components from 'components'
import services from 'services'

import actions from './actions'
import constants from './constants'
import reducer from './reducer'
import selectors from './selectors'

import Actions from './Actions'


class MyDomains extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      paginationIndex: 0,
      filterExpiry: null,
      sortName: 'alphabetical',
      sortAscending: true,
      address: this.getAddress(), // address we are loading domains for
    }
  }

  getAddress = () => {
    return this.props.isOwned ? null : services.linking.getParams('AddressDomains').address
  }

  updateParams = () => {
    this.setState({
      address: this.getAddress()
    }, () => {
      this.loadDomains()
    })
  }

  componentDidMount() {
    services.provider.addEventListener(services.provider.EVENTS.CONNECTED, this.onConnect.bind(this))
    services.provider.addEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnect.bind(this))
    services.linking.addEventListener(services.linking.EVENTS.ROUTE_CHANGED, this.updateParams)
    if (!this.props.hasLoadedDomains || this.state.address) {
      this.loadDomains()
    }
  }

  componentWillUnmount() {
    services.provider.removeEventListener(services.provider.EVENTS.CONNECTED, this.onConnect.bind(this))
    services.provider.removeEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnect.bind(this))
    services.linking.removeEventListener(services.linking.EVENTS.ROUTE_CHANGED, this.updateParams)
  }

  onConnect() {
    if (this.connectModal) {
      this.connectModal.hide()
    }
    this.loadDomains()
  }

  onDisconnect() {
    this.forceUpdate()
  }

  loadDomains() {
    const address = this.state.address
    if (address || services.provider.isConnected()) {
      this.props.loadDomains(address)
    }
  }

  renewAll(years) {
    const domains = this.getDisplayedDomains()
    const renewal = domains.reduce((sum, curr) => {
      const domain = this.props.reverseLookups[curr.toString()] 
      if (domain) {
        sum[domain] = years
      }
      return sum
    }, {})
    this.props.renewDomains(renewal)
  }

  setSort = (sortName, sortAscending) => {
    this.setState({
      sortName,
      sortAscending
    })

    this.sortModal.hide()
  }

  getSortDisplayName = () => {
    const names = {
      alphabetical: 'Alphabetical',
      expiryDate: 'Expiry Date',
    }
    return names[this.state.sortName]
  }

  renderHiddenDomainsNotice(domainCount) {
    return (
      <div className='mb-4 mt-8 max-w-md m-auto'>
        {domainCount > 0 ? (
          <>
            <components.labels.Information text={`You have ${domainCount} unrevealed Enhanced Privacy ${domainCount === 1 ? 'domain' : 'domains'} in your wallet.`} />
            <div className='max-w-md m-auto'>
              <div className='mt-4 text-gray-700'>
                {"Enhanced Privacy Domains are hidden on-chain so that an observers have difficulty knowing which domains you have registered. To reveal your domains, search for them below. You can enter multiple domains separated by spaces, or copy-paste them from a text file or spreadsheet."}
              </div>
              <div className='mt-4 mb-4'>
                <components.DomainReveal />
              </div>
            </div>
          </>
        ) : (
          <div className='mb-4'>
            <components.labels.Success text={'All of your domains have been revealed.'} />
          </div>
        )}
        <components.buttons.Button text={'Return to domain list'} onClick={() => this.hiddenDomainsModal.toggle()} />
      </div>
    )
  }

  renderPagination(numPages) {
    numPages = Math.ceil(numPages)
    const currPage = this.state.paginationIndex
    let pagesDisplayed = []
    const maxPagesToDisplay = 5
    if (currPage === 0 || currPage === 1) {
      for (let i = 0; i < numPages; i += 1) {
        pagesDisplayed.push(i+1)
        if (pagesDisplayed.length >= maxPagesToDisplay) break
      }
    } else if (currPage === numPages - 1 || currPage === numPages - 2) {
      for (let i = numPages - 5; i < numPages; i += 1) {
        if (i + 1 > 0) {
          pagesDisplayed.push(i+1)
        }
        if (pagesDisplayed.length >= maxPagesToDisplay) break
      }
    } else {
      pagesDisplayed = [
        currPage - 1,
        currPage,
        currPage + 1,
        currPage + 2,
        currPage + 3,
      ]
    }
    return (
      <div className='flex items-center justify-center'>
        <div 
          onClick={() => {
            this.setState(currState => {
              const currPage = this.state.paginationIndex
              const nextPage = currPage === 0 ? currPage : currPage - 1
              return {
                paginationIndex: nextPage
              }
            })
          }}
          className='dark:bg-gray-800 bg-gray-100 rounded-lg select-none w-12 h-12 flex items-center justify-center mr-2 cursor-pointer'>
          <ChevronLeftIcon className='w-6' />
        </div>
        {pagesDisplayed.map((p, index) => (
          <div 
            onClick={() => {
              this.setState({
                paginationIndex: p - 1
              })
            }}
            className={`dark:bg-gray-800 cursor-pointer select-none bg-gray-100 rounded-lg w-12 h-12 flex items-center justify-center mr-2 ${currPage === p - 1 ? 'font-bold' : ''}`} key={index}>{p}
          </div>
        ))}
        <div 
          onClick={() => {
            this.setState(currState => {
              const currPage = this.state.paginationIndex
              const nextPage = currPage >= numPages - 1 ? currPage : currPage + 1
              return {
                paginationIndex: nextPage
              }
            })
          }}
          className='dark:bg-gray-800 bg-gray-100 rounded-lg select-none w-12 h-12 flex items-center justify-center mr-2 cursor-pointer'>
          <ChevronRightIcon className='w-6' />
        </div>
      </div>
    )
  }
  
  getDisplayedDomains() {
    const reverseLookups = this.props.reverseLookups
    const expiries = this.props.expiries
    let domains = this.props.domainIds.filter(domain => reverseLookups[domain])

    if (this.state.search) {
      domains = domains.filter(hash => {
        const name = reverseLookups[hash]
        return name.indexOf(this.state.search) > -1
      })
    }

    if (this.state.filterExpiry) {
      // filter by expiring in [this.state.filterExpiry] days
      let now = parseInt(Date.now() / 1000)
      let numSeconds = this.state.filterExpiry * 60 * 60 * 24
      domains = domains.filter(domain => {
        const expiry = expiries[domain.toString()]
        const diff = expiry - now
        return diff < numSeconds
      })
    }

    if (this.state.sortName === 'alphabetical') {
      if (this.state.sortAscending) {
        domains.sort((_a, _b) => {
          const a = reverseLookups[_a]
          const b = reverseLookups[_b]
          if (a > b) return 1
          if (a < b) return -1
          return 0
        })
      } else {
        domains.sort((_a, _b) => {
          const a = reverseLookups[_a]
          const b = reverseLookups[_b]
          if (a > b) return -1
          if (a < b) return 1
          return 0
        })
      }
    } else if (this.state.sortName === 'expiryDate') {
      if (this.state.sortAscending) {
        domains.sort((_a, _b) => {
          const a = expiries[_a]
          const b = expiries[_b]
          if (a > b) return 1
          if (a < b) return -1
          return 0
        })
      } else {
        domains.sort((_a, _b) => {
          const a = expiries[_a]
          const b = expiries[_b]
          if (a > b) return -1
          if (a < b) return 1
          return 0
        })
      }
    }
    return domains
  }

  renderDomains() {
    const reverseLookups = this.props.reverseLookups
    const expiries = this.props.expiries
    if (!this.props.domainIds) return
    let domains = this.getDisplayedDomains()

    const pageLength = 10
    const hasPagination = domains.length > pageLength
    const numPages = domains.length / pageLength
    domains = domains.slice(this.state.paginationIndex * pageLength, this.state.paginationIndex * pageLength + pageLength)

    return (
      <div>
        <div className='mt-4 dark:bg-gray-800 bg-gray-100 rounded-lg px-4'>
          <div className='w-full table border-collapse'>
            <div className='border-b border-gray-200 dark:border-gray-700 table-row'>
              <div className='w-full py-4 md:py-6 table-cell'>
                <div className='flex items-center'>
                  <SearchIcon className='w-5 mx-2' />
                  <input 
                    type="text" 
                    placeholder="Search" 
                    className='w-full p-2 bg-gray-100 dark:bg-gray-800' 
                    onChange={(e) => {
                      if (this.searchTimeout) clearTimeout(this.searchTimeout)
                      this.searchTimeout = setTimeout(() => {
                        this.setState({
                          search: e.target.value.toLowerCase(),
                          paginationIndex: 0
                        })
                      }, 300)
                    }}
                  />
                </div>
              </div>
              {domains.length > 0 ? (
                <>
                  <div className='hidden md:table-cell p-4 px-8 whitespace-nowrap text-sm'>
                    {'Expiry Date'}
                  </div>
                  <div className='hidden md:table-cell'>{/* arrow */}</div>
                </>
              ) : null}
            </div>
            {domains.length > 0 ? domains.map((hash, index) => {
              const domain = reverseLookups[hash] 
              const expiry = expiries[hash]
              const expiryDate = new Intl.DateTimeFormat(
                navigator.language,
                { month: 'short', day: 'numeric', year: 'numeric' }
              ).format(expiry * 1000)
              const expired = expiry * 1000 <= Date.now()
              return (
                <Link
                  to={services.linking.path('Domain', { domain })}
                  className={`table-row ${index < domains.length - 1 ? 'border-b border-gray-200 dark:border-gray-700' : ''}`}
                  key={index}>
                  <div className='py-6 table-cell'>
                    <div className='flex justify-between items-center'>
                      <div>
                        <div className='font-bold'>{domain}</div>
                        <div className='text-xs md:hidden'>{expired ? 'Expired' : `Expires: ${expiryDate}`}</div>
                      </div>
                      <ArrowRightIcon className="h-6 md:hidden" />
                    </div>
                  </div>
                  <div className='hidden md:table-cell text-sm p-4 px-8 whitespace-nowrap align-middle'>
                    {expired ? 'Expired' : expiryDate}
                  </div>
                  <div className='pr-4 align-middle table-cell hidden md:table-cell'>
                    <ArrowRightIcon className="h-6" />
                  </div>
                </Link>
              )
            }) : (
              <div className='w-full py-8 font-bold text-center'>
                No results
              </div>
            )}
          </div>
        </div>
        <div className='mt-4'>
          {hasPagination ? this.renderPagination(numPages) : null}
        </div>
      </div>
    )
  }

  renderEmpty() {
    return (
      <div className='max-w-md m-auto mt-8'>
        <div className='text-center text-lg font-bold mb-4'>{this.state.address || 'My Domains'}</div>
        <components.labels.Information text={this.state.address ? 'This address does not have any registered domains' : 'You do not have any registered domains'} />
        <div className='mt-8'>
          <components.DomainSearch />
        </div>
      </div>
    )
  }

  renderNotConnected() {
    return (
      <div className='max-w-md m-auto mt-8'>
        <div className='text-center text-lg font-bold mb-4'>{'My Domains'}</div>
        <components.labels.Information text={'You must be connected to a wallet to view your domains'} />
        <div className='mt-8'>
          <components.ConnectWalletButton />
        </div>
      </div>
    )
  }

  needsConnection = () => {

    // if address is provided, we don't need connected wallet
    if (this.state.address) return false

    // otherwise, check connection exists
    return !services.provider.isConnected()
  }

  renderDomainSection() {
    const domainCount = this.props.domainCount 
    const loadedDomainCount = this.props.loadedDomainCount
    const pct = parseInt((loadedDomainCount / domainCount) * 100)
    if (this.needsConnection()) return this.renderNotConnected()
    if (domainCount === null) return (
      <div className='mt-4 dark:bg-gray-800 bg-gray-100 rounded-lg px-4 text-center py-8'>
        <components.Spinner size='md' color={this.props.isDarkmode ? '#eee' : '#555'} />
      </div>
    )
    if (loadedDomainCount < domainCount) {
      return (
        <div className='mt-4 dark:bg-gray-800 bg-gray-100 rounded-lg px-4 text-center py-8'>
          <div className='max-w-sm m-auto text-center'>
            <components.ProgressBar progress={pct} className='dark:bg-gray-700' />
            <div className='text-sm mt-4 text-gray-900 dark:text-white'>{'Loading domains'}</div>
          </div>
        </div>
      )
    }
    return this.renderDomains()
  }

  render() {
    const reverseLookups = this.props.reverseLookups
    let domainCount, loadedDomainCount, hiddenDomainCount
    if (this.props.domainIds) {
      let domains = this.props.domainIds.filter(domain => reverseLookups[domain])
      domainCount = this.props.domainCount 
      loadedDomainCount = this.props.loadedDomainCount
      hiddenDomainCount = this.props.domainIds.length - domains.length
    } else {
      hiddenDomainCount = 0
    }
    if (domainCount === 0 || !this.props.domainIds) return this.renderEmpty()
    return ( 
      <div className='max-w-screen-xl m-auto md:px-4'>
        <components.Modal ref={(ref) => this.hiddenDomainsModal = ref} title={'Hidden Domains'}>
          {this.renderHiddenDomainsNotice(hiddenDomainCount)}
        </components.Modal>
        <components.Modal ref={(ref) => this.sortModal = ref} title={'Sort Domains'}>
          <div className='max-w-xs m-auto'>
            <div className='flex bg-gray-100 dark:bg-gray-700 text-sm py-2 px-4 items-center justify-between cursor-pointer rounded' onClick={() => this.setSort('alphabetical', true)}>
              <SortAscendingIcon className='w-4 md:mr-2' />
              <div className='w-full text-left'>
                {'Alphabetical (A-Z)'}
              </div>
            </div>
            <div className='flex dark:bg-gray-700 text-sm bg-gray-100 py-2 px-4 items-center justify-between mt-2 cursor-pointer rounded' onClick={() => this.setSort('alphabetical', false)}>
              <SortDescendingIcon className='w-4 md:mr-2' />
              <div className='w-full text-left'>
                {'Alphabetical (Z-A)'}
              </div>
            </div>
            <div className='flex dark:bg-gray-700 text-sm bg-gray-100 py-2 px-4 items-center justify-between mt-2 cursor-pointer rounded' onClick={() => this.setSort('expiryDate', true)}>
              <SortAscendingIcon className='w-4 md:mr-2' />
              <div className='w-full text-left'>
                {'Expiry Date (Expiring soon first)'}
              </div>
            </div>
            <div className='flex dark:bg-gray-700 text-sm bg-gray-100 py-2 px-4 items-center justify-between mt-2 cursor-pointer rounded' onClick={() => this.setSort('expiryDate', false)}>
              <SortDescendingIcon className='w-4 md:mr-2' />
              <div className='w-full text-left'>
                {'Expiry Date (Recently renewed)'}
              </div>
            </div>
          </div>
        </components.Modal>
        <components.Modal ref={(ref) => this.filtersModal = ref} title={'Filter'}>
          <div className='max-w-xs m-auto'>
            <div className='flex bg-gray-100 dark:bg-gray-700 text-sm py-2 px-4 items-center justify-between rounded'>
              <div className='flex-shrink-0 mr-4'>{'Expires in '}</div>
              <components.Input type="number" min="1" step="1" placeholder="" onChange={(e) => this.setState({ filterExpiry: parseInt(e.target.value)}) }/>
              <div className='flex-shrink-0 ml-4'>{' days'}</div>
            </div>
          </div>
        </components.Modal>
        <components.Modal ref={(ref) => this.actionsModal = ref} title={'Actions'}>
          <Actions 
            renewAll={(years) => this.renewAll(years)} 
            bulkRegistrationProgress={this.props.bulkRegistrationProgress}
          />
        </components.Modal>
        {this.needsConnection() ? this.renderNotConnected() : (
          <>
            <div className='flex justify-between mt-2 md:mt-8 mb-4 items-center'>
              <div>
                <div className='text-lg font-bold pl-2 flex-shrink-0'>{this.state.address || "My Domains"}</div>
              </div>
              {loadedDomainCount === domainCount ? (
                <div className='flex items-center flex-wrap justify-end'>
                  <div className='my-1 ml-2 cursor-pointer bg-gray-100 dark:bg-gray-700 px-2 md:px-4 py-2 rounded-lg flex items-center text-sm' onClick={() => this.loadDomains()}>
                    <RefreshIcon className='w-4 md:mr-2' />
                    <div className='hidden md:block'>
                      Refresh
                    </div>
                  </div>
                  <div className='my-1 ml-2 cursor-pointer bg-gray-100 dark:bg-gray-700 px-2 md:px-4 py-2 rounded-lg flex items-center text-sm' onClick={() => this.filtersModal.toggle()}>
                    <FilterIcon className='w-4 md:mr-2' />
                    <div className='hidden md:block'>
                      Filters
                    </div>
                  </div>
                  {this.state.address ? null : (
                    <div className='my-1 ml-2 cursor-pointer bg-gray-100 dark:bg-gray-700 px-2 md:px-4 py-2 rounded-lg flex items-center text-sm' onClick={() => this.actionsModal.toggle()}>
                      <DotsVerticalIcon className='w-4 md:mr-2' />
                      <div className='hidden md:block'>
                        Actions
                      </div>
                    </div>
                  )}
                  <div className='my-1 ml-2 cursor-pointer bg-gray-100 dark:bg-gray-700 px-2 md:px-4 py-2 rounded-lg flex items-center text-sm' onClick={() => this.sortModal.toggle()}>
                    {this.state.sortAscending ? (
                      <SortAscendingIcon className='w-4 md:mr-2' />
                    ) : (
                      <SortDescendingIcon className='w-4 md:mr-2' />
                    )}
                    <div className='hidden md:block'>
                      {this.getSortDisplayName()}
                    </div>
                  </div>
                  {hiddenDomainCount > 0 ? (
                    <div className='my-1 ml-2 cursor-pointer bg-gray-100 dark:bg-gray-700 px-2 md:px-4 py-2 rounded-lg flex items-center text-sm' onClick={() => this.hiddenDomainsModal.toggle()}>
                      <EyeOffIcon className='w-4 md:mr-2' />
                      <div className='hidden md:block'>
                        {hiddenDomainCount === 1 ? '1 Hidden Domain' : `${hiddenDomainCount} Hidden Domains`}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            {this.renderDomainSection()}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  domainIds: selectors.domainIds(state),
  domainCount: selectors.domainCount(state),
  loadedDomainCount: selectors.loadedDomainCount(state),
  hasLoadedDomains: selectors.hasLoadedDomains(state),
  expiries: selectors.expiries(state),

  reverseLookups: services.names.selectors.reverseLookups(state),
  isDarkmode: services.darkmode.selectors.isDarkmode(state),
  bulkRegistrationProgress: services.cart.selectors.bulkRegistrationProgress(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadDomains: (address) => dispatch(actions.loadDomains(address)),
  renewDomains: (registrations) => dispatch(services.cart.actions.addBulkRegistrations(registrations)),
})

const component = connect(mapStateToProps, mapDispatchToProps)(MyDomains)

component.redux = {
  actions,
  constants,
  reducer,
  selectors,
}

export default component
