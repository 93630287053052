"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.targetGroupFragmentDependencies, fragments_1.targetGroupFragment];
const QUERY = `
query getTargetGroups {
  targetGroup {
    ...targetGroupFragment
  }
}
`.trim();
const getTargetGroupsImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'targetGroup');
exports.default = getTargetGroupsImpl;
