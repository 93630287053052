"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const DEPENDENCIES = [];
const MUTATION = `
mutation deleteTargetGroup(
  $id: String!
) {
  deleteTargetGroup(targetGroupInput: {
    id: $id
  }) {
    id
  }
}
`.trim();
const deleteTargetGroupImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'deleteTargetGroup');
exports.default = deleteTargetGroupImpl;
