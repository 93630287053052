import constants from './constants'
import services from 'services'


const actions = {
  loading: (isLoading) => {
    return {
      type: constants.SET_LOADING,
      isLoading,
    }
  },
  complete: (isComplete) => {
    return {
      type: constants.SET_COMPLETE,
      isComplete,
    }
  },
  send: (address, amount) => {
    return async (dispatch, getState) => {
      const api = await services.provider.buildAPI()
      dispatch(actions.loading(true))
      try {
        await api.sendAVAX(address, amount)
        dispatch(actions.complete(true))
      } catch (err) {
        alert('Failed to send transfer')
      }
      dispatch(actions.loading(false))
    }
  },
  sendERC20: (address, amount, tokenAddress) => {
    return async (dispatch, getState) => {
      const api = await services.provider.buildAPI()
      dispatch(actions.loading(true))
      try {
        await api.sendERC20(address, amount, tokenAddress)
        dispatch(actions.complete(true))
      } catch (err) {
        alert('Failed to send transfer')
      }
      dispatch(actions.loading(false))
    }
  },
  sendERC721: (address, contract, tokenId) => {
    return async (dispatch, getState) => {
      const api = await services.provider.buildAPI()
      dispatch(actions.loading(true))
      try {
        await api.sendERC721(address, contract, tokenId)
        dispatch(actions.complete(true))
      } catch (err) {
        alert('Failed to send transfer')
      }
      dispatch(actions.loading(false))
    }
  },
  reset: () => {
    return (dispatch, getState) => {
      dispatch(actions.complete(false))
      dispatch(actions.loading(false))
    }
  },
  loadingTokens: (isLoading) => {
    return {
      type: constants.SET_LOADING_TOKENS,
      isLoading
    }
  },
  setTokens: (tokens) => {
    return {
      type: constants.SET_TOKENS,
      tokens
    }
  },
  setLoadTokenError: (error) => {
    return {
      type: constants.SET_LOAD_TOKEN_ERROR,
      error
    }
  },
  loadTokens: () => {
    return async (dispatch, getState) => {
      const api = await services.provider.buildAPI()
      dispatch(actions.setLoadTokenError(false))
      dispatch(actions.loadingTokens(true))
      let tokens
      try {
        tokens = await api.loadTokens()
        dispatch(actions.setTokens(tokens))
      } catch (err) {
        dispatch(actions.setLoadTokenError(true))
      }
      dispatch(actions.loadingTokens(false))
    }
  }
}

export default actions
