"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alertFragmentDependencies = exports.alertFragment = void 0;
const filterFragment_1 = require("./filterFragment");
const sourceGroupFragment_1 = require("./sourceGroupFragment");
const targetGroupFragment_1 = require("./targetGroupFragment");
exports.alertFragment = `
fragment alertFragment on Alert {
  id
  groupName
  name
  filterOptions
  filter {
    ...filterFragment
  }
  sourceGroup {
    ...sourceGroupFragment
  }
  targetGroup {
    ...targetGroupFragment
  }
}
`.trim();
exports.alertFragmentDependencies = [
    ...sourceGroupFragment_1.sourceGroupFragmentDependencies,
    ...filterFragment_1.filterFragmentDependencies,
    ...targetGroupFragment_1.targetGroupFragmentDependencies,
    sourceGroupFragment_1.sourceGroupFragment,
    filterFragment_1.filterFragment,
    targetGroupFragment_1.targetGroupFragment,
];
