import React from 'react'
import services from 'services'


class EVMAddress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      domain: null
    }
  }

  componentDidMount() {
    this.reverseAddress()
  }

  reverseAddress = async () => {
    const api = services.provider.buildAPI()
    const names = await api.avvy.batch([ this.props.address ]).reverseToNames(api.avvy.RECORDS.EVM)
    if (names[0]) this.setState({ 
      domain: names[0]
    }) 
  }

  render() {
    return (
      <div className='truncate'>
        {this.state.domain || this.props.address}
      </div>
    )
  }
}

export default EVMAddress
