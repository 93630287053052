import React from 'react'
import { renderIcon } from '@download/blockies'


/*
 * props
 * - width
 * - height
 * - borderRadius
 * - avatar (url)
 * - account (0x)
 */
class Avatar extends React.PureComponent {
  render() {
    return (
      <div>
        {this.props.avatar ? (
          <div style={{ width: this.props.width, height: this.props.height, borderRadius: this.props.borderRadius, backgroundImage: `url(${this.props.avatar})` }} className='px-2 bg-gray-100 bg-cover bg-center'></div>
        ) : (
          <canvas style={{ width: this.props.width, height: this.props.height, borderRadius: this.props.borderRadius }} ref={(ref) => {
            if (ref) renderIcon({ seed: this.props.account }, ref)
          }} />
        )}
      </div>
    )
  }
}

export default Avatar
