"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const DEPENDENCIES = [];
const MUTATION = `
mutation broadcastMessage(
  $idempotencyKey: String
  $topicName: String!
  $targetTemplates: [KeyValuePairOfTargetTypeAndStringInput!]
  $variables: [KeyValuePairOfStringAndStringInput!]
  $timestamp: Long!
  $walletBlockchain: WalletBlockchain!
  $signature: String!
) {
  broadcastMessage(broadcastMessageInput: {
    idempotencyKey: $idempotencyKey
    sourceAddress: $topicName
    targetTemplates: $targetTemplates
    variables: $variables
    timestamp: $timestamp
    walletBlockchain: $walletBlockchain
  }, signature: $signature) {
    id
  }
}
`.trim();
const broadcastMessageImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'broadcastMessage');
exports.default = broadcastMessageImpl;
