"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [
    ...fragments_1.webhookTargetFragmentDependencies,
    fragments_1.webhookTargetFragment,
];
const QUERY = `
query getWebhookTargets {
  webhookTarget {
    ...webhookTargetFragment
  }
}
`.trim();
const getWebhookTargetsImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'webhookTarget');
exports.default = getWebhookTargetsImpl;
