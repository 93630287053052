import React from 'react'
import { connect } from 'react-redux'
import { DuplicateIcon, CheckIcon } from '@heroicons/react/solid'

import actions from './actions'
import selectors from './selectors'

import components from 'components'
import services from 'services'

const INITIAL_STATE = {
  walletAddress: null,
  step: 1,

  // upload staking cert
  stakingCertProcessing: false,
  cert: null,
  nodeID: null,
  modulus: null,
  exponent: null,

  // sign challenge
  challengeGenerating: false,
  challengeMessage: null,
  challengeExpiry: null,
  challengeTimestamp: null,
  challengeMessageCopied: false,
  challengeResponse: null,
  challengeResponseSubmitting: false,
}

class SetResolver extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setWalletAddress()
    this.state = Object.assign({}, INITIAL_STATE)
  }

  reset = () => {
    this.setState(INITIAL_STATE)
  }

  setWalletAddress = async () => {
    const api = await services.provider.buildAPI()
    this.setState({
      walletAddress: api.account
    })
  }

  submit = async () => {
    this.props.setEVMReverseRecord(this.props.domain)
  }

  renderStepOne = () => {
    return (
      <>
        <div className='font-bold mt-8'>
          {'What is this?'}
        </div>
        <div className='mb-2'>
          {'This sets '} {this.props.domain} {'as the name associated with your Avalanche validator.'}
        </div>
        <div className='mb-2'>
          {'Applications can then display your .avax name instead of your validtor\'s NodeID, making it easier to identify.'}
        </div>
        <div className='my-4'>
          <components.buttons.Button sm={true} text={'Continue'} onClick={() => this.setState({ step: 2 })} />
        </div>
      </>
    )
  }

  _handleStakerCertChange = (e) => {
    this.setState({
      stakingCertProcessing: true
    })
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (e) => {
      const raw = e.target.result
      if (!raw.startsWith('-----BEGIN CERTIFICATE-----\n')) {
        // this would be invalid, not certificate, possibly wrong file. quit here so we don't accidentally expose staker keys.
        alert('This does not look like a staker certificate.. Are you sure it is the right file?')
        this.setState({
          stakingCertProcessing: false
        })
        return 
      }

      const pubkey = raw.split('-----')[2].replace(/\s/g, '')
      const cert = Buffer.from(pubkey, 'base64')
      
      const api = await services.provider.buildAPI()
      const { nodeID, modulus, exponent } = await api.getNodeCertificateDetails(cert)
      this.setState({
        nodeID,
        cert,
        modulus,
        exponent,
        stakingCertProcessing: false,
      })

    }
    if (file) {
      reader.readAsBinaryString(file)
    } else {
      this.setState({
        stakingCertProcessing: false
      })
    }
  }

  renderStepTwo = () => {
    return (
      <>
        <div className='font-bold mt-8'>
          {'Upload staker certificate'}
        </div>
        <div className='mb-2'>
          {'Your staker certificate can be found in your node\'s '}<a target="_blank" className='underline' href="https://docs.avax.network/nodes/configure/avalanchego-config-flags#--data-dir-string">{'data directory'}</a>{' in '}<code>{'staking/staker.crt'}</code>
        </div>
        <div className='mb-2'>
          {'Upload that your staker certificate. We will use it to find your NodeID & verify your ownership of the node.'}
        </div>
        <div onClick={() => this.stakerCertRef.click()} className='block my-4 p-8 border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100 cursor-pointer rounded text-center'>
          {this.state.stakingCertProcessing ? (
            <components.Spinner.Connected />
          ) : (
            <>
              {this.state.nodeID ? (
                <>{this.state.nodeID}</>
              ) : (
                <>{'Upload staker.crt'}</>
              )}
            </>
          )}
        </div>
        <input className='hidden' id="staker-cert-ref" type="file" ref={(ref) => this.stakerCertRef = ref} onChange={this._handleStakerCertChange} />
        {this.state.nodeID ? (
          <div className='my-4'>
            <components.buttons.Button sm={true} text={'Continue'} onClick={() => this.setState({ step: 3 })} />
          </div>
        ) : null}
      </>
    )
  }

  generateChallenge = async () => {
    this.setState({
      challengeGenerating: true,
    }, async () => {
      const api = await services.provider.buildAPI()
      const { expiry, message, timestamp } = await api.getNodeAuthenticationChallenge(this.props.domain)
      this.setState({
        challengeMessage: message,
        challengeExpiry: expiry,
        challengeTimestamp: timestamp,
        challengeGenerating: false,
      })
    })
  }

  copyChallengeMessage = async () => {
    await navigator.clipboard.writeText(this.state.challengeMessage)
    this.setState({
      challengeMessageCopied: true
    })
    if (this.challengeMessageCopiedClearTimeout) clearTimeout(this.challengeMessageCopiedClearTimeout)
    setTimeout(() => {
      this.setState({
        challengeMessageCopied: false
      })
    }, 2000)
  }

  setRecord = async () => {
    this.setState({
      challengeResponseSubmitting: true,
    }, async () => {
      const api = await services.provider.buildAPI()
      const sig = '0x' + this.state.challengeResponse.replace(/\s/g, '')
      try {
        const result = await api.setNodeReverse(
          this.props.domain,
          this.state.nodeID,
          this.state.cert,
          this.state.challengeTimestamp,
          sig
        )
        this.props.reloadRecords()
        this.setState({
          step: 4,
        })
      } catch (err) {
        alert(`Failed to set reverse record: ${err}`)
      }
      this.setState({
        challengeResponseSubmitting: false
      })
    })
  }

  renderStepThree = () => {
    return (
      <>
        <div className='font-bold mt-8'>
          {'Sign proof of ownership'}
        </div>
        {this.state.challengeMessage ? (
          <>
            <div className='mb-2'>
              {'Copy the challenge below and '}<a className='underline' href="https://avvy.domains/blog/proving-ownership-of-a-validator-node/" target="_blank">{'sign the challenge using your staking keys'}</a>{'.'}
            </div>
            <div className='relative block my-4 p-8 border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100 cursor-pointer rounded text-center' onClick={this.copyChallengeMessage}>
              <div className='absolute top-0 right-0 p-2'>
                {this.state.challengeMessageCopied ? (
                  <CheckIcon className='w-4' />
                ) : (
                  <DuplicateIcon className='w-4' />
                )}
              </div>
              {this.state.challengeMessage}
            </div>
            <div className='my-4'>{'Enter your signature below:'}</div>
            <textarea className='w-full relative block my-4 p-8 border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-100 rounded text-center' onChange={(e) => this.setState({ challengeResponse: e.target.value })}>

            </textarea>
            <div className='my-4'>
              <components.buttons.Button sm={true} text={'Set Record'} onClick={() => this.setRecord()} loading={this.state.challengeResponseSubmitting} />
            </div>
          </>
        ) : (
          <>
            <div className='mb-2'>
              {'You need to prove ownership of your node using your staking key.'}
            </div>
            <div className='mb-2'>
              {'The staking key is private and cannot be shared - we will ask you to sign a challenge to prove that you own the keys corresponding to your staking certificate.'}
            </div>
            <div className='my-4'>
              <components.buttons.Button sm={true} text={this.state.challengeMessage ? 'Restart' : 'Start'} onClick={() => this.generateChallenge()} loading={this.state.challengeGenerating} />
            </div>
          </>
        )}
      </>
    )
  }

  renderStepFour = () => {
    return (
      <>
        <div className='font-bold mt-8'>
          {'Record set 🎉'}
        </div>
        <div className='mb-2'>
          {"You're all set! Your Node ID has been associated with your .avax domain."}
        </div>
        <div className='my-4'>
          <components.buttons.Button sm={true} text={'Complete'} onClick={() => this.props.onComplete()} />
        </div>
      </>
    )
  }

  render() {
    if (this.props.complete) return (
      <>
        <div className='max-w-md m-auto'>
          <components.labels.Success text={'Reverse Record for C-Chain / EVM has been set'} />
        </div>
        <div className='max-w-md m-auto mt-4'>
          <components.buttons.Button text={'Close'} onClick={() => {
            this.props.reset()
            this.props.onComplete()
          }} />
        </div>
      </>
    )

    const renderStep = {
      1: this.renderStepOne,
      2: this.renderStepTwo,
      3: this.renderStepThree,
      4: this.renderStepFour,
    }[this.state.step]

    return (
      <>
        <div className='max-w-md m-auto'>
          {renderStep()}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: selectors.isSettingEVMReverseRecord(state),
  complete: selectors.isSettingEVMReverseRecordComplete(state),
})

const mapDispatchToProps = (dispatch) => ({
  setEVMReverseRecord: (domain) => dispatch(actions.setEVMReverseRecord(domain)),
  reset: () => dispatch(actions.resetSetEVMReverseRecord()),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SetResolver)
