"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [
    ...fragments_1.authorizationFragmentDependencies,
    fragments_1.authorizationFragment,
];
const MUTATION = `
mutation refreshAuthorization {
  refreshAuthorization {
    ...authorizationFragment
  }
}
`.trim();
const refreshAuthorizationImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'refreshAuthorization');
exports.default = refreshAuthorizationImpl;
