"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emailTargetFragmentDependencies = exports.emailTargetFragment = void 0;
exports.emailTargetFragment = `
fragment emailTargetFragment on EmailTarget {
  emailAddress
  id
  isConfirmed
  name
}
`.trim();
exports.emailTargetFragmentDependencies = [];
