"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [
    ...fragments_1.connectedWalletFragmentDependencies,
    fragments_1.connectedWalletFragment,
];
const QUERY = `
query getConnectedWallets {
  connectedWallet {
    ...connectedWalletFragment
  }
}
`.trim();
const getConnectedWalletsImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'connectedWallet');
exports.default = getConnectedWalletsImpl;
