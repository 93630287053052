import reduxService from 'services/redux'

const constants = reduxService.prepareConstants(
  'views/Hash',
  [
    'SET_LOADING', 
    'SET_DATA', 
  ]
)

export default constants
