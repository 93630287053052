import { reducerName } from './reducer'

const root = (state) => state[reducerName]

const selectors = {
  isLoading: (state) => root(state).isLoading,
  isComplete: (state) => root(state).isComplete,
  isLoadingTokens: (state) => root(state).isLoadingTokens,
  tokens: (state) => root(state).tokens,
  loadTokenError: (state) => root(state).loadTokenError,
}

export default selectors
