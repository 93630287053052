import constants from './constants'
import services from 'services'

import selectors from './selectors'

const actions = {
  addRecord: (name, hash) => {
    return {
      type: constants.ADD_RECORD,
      name,
      hash
    }
  },

  isRevealed: (hash, isRevealed) => {
    return {
      type: constants.IS_REVEALED,
      hash,
      isRevealed
    }
  },

  addRecordWithoutHash: (name) => {
    return async (dispatch, getState) => {
      const api = await services.provider.buildAPI()
      const hash = await api.nameHash(name)
      dispatch(actions.addRecord(name, hash))
    }
  },

  bulkAddRecords: (names, hashes) => {
    return {
      type: constants.BULK_ADD_RECORDS,
      names,
      hashes,
    }
  },

  checkIsRevealed: (hash) => {
    return async (dispatch, getState) => {
      const api = services.provider.buildAPI()
      try {
        const isRevealed = await api.isPreimageRevealed(hash)
        dispatch(actions.isRevealed(hash, isRevealed))
      } catch (err) {
      }
    }
  },

  lookupMultiple: (hashes) => {
    return async (dispatch, getState) => {
      hashes = hashes.map(h => h.toString())
      const api = services.provider.buildAPI()
      const lookups = selectors.reverseLookups(getState())
      const batchSize = 100
      let batch = []
      for (let i = 0; i < hashes.length; i += 1) {
        if (!lookups[hashes[i]]) {
          batch.push(hashes[i])
        }
        if (batch.length > batchSize || i === hashes.length - 1) {
          let preimages = await api.lookupPreimages(batch)
          for (let j = 0; j < batch.length; j += 1) {
            if (preimages[j]) {
              dispatch(actions.addRecord(preimages[j], batch[j]))
            }
          }
          batch = []
        }
      }
      try {
      } catch (err) {
      }
    }
  },

  lookup: (hash) => {
    return async (dispatch, getState) => {
      const api = services.provider.buildAPI()
      try {
        const name = await api.lookupPreimage(hash)
        dispatch(actions.addRecord(name, hash))
      } catch (err) {
      }
    }
  },

  discoverSubdomains: (domain) => {
    return async (dispatch, getState) => {
      const api = services.provider.buildAPI()
      const results = await api.indexerRequest(`
        {
          domains(search: ".${domain}") {
            name,
            hash
          }
        }
      `)
      const names = []
      const hashes = []
      for (let i = 0; i < results.data.domains.length; i += 1) {
        names.push(results.data.domains[i].name)
        hashes.push(results.data.domains[i].hash)
      }
      dispatch(actions.bulkAddRecords(names, hashes))
    }
  }
}

export default actions
