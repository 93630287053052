"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const DEPENDENCIES = [];
const MUTATION = `
mutation deleteAlert(
  $id: String!
) {
  deleteAlert(alertId: $id) {
    id
  }
}
`.trim();
const deleteAlertImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'deleteAlert');
exports.default = deleteAlertImpl;
