"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const notificationHistoryEntryFragment_1 = require("../fragments/notificationHistoryEntryFragment");
const DEPENDENCIES = [
    ...notificationHistoryEntryFragment_1.notificationHistoryEntryFragmentDependencies,
    notificationHistoryEntryFragment_1.notificationHistoryEntryFragment,
];
const QUERY = `query getNotificationHistory($after: String, $first: Int) {
  notificationHistory(after: $after, first: $first) {
    nodes {
      ...NotificationHistoryEntry
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}`.trim();
const getNotificationHistoryImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'notificationHistory');
exports.default = getNotificationHistoryImpl;
