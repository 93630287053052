"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IHeartBeat = void 0;
const events_1 = require("@walletconnect/events");
class IHeartBeat extends events_1.IEvents {
    constructor(opts) {
        super();
    }
}
exports.IHeartBeat = IHeartBeat;
