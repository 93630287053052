"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ensureTarget_1 = require("./ensureTarget");
const ensureTargetIds = async (service, existing, input) => {
    const { emailAddress, phoneNumber, telegramId, webhook, includeDiscord } = input;
    const [emailTargetId, smsTargetId, telegramTargetId, webhookTargetId, discordTargetId,] = await Promise.all([
        (0, ensureTarget_1.ensureEmail)(service, existing.emailTargets, emailAddress),
        (0, ensureTarget_1.ensureSms)(service, existing.smsTargets, phoneNumber),
        (0, ensureTarget_1.ensureTelegram)(service, existing.telegramTargets, telegramId),
        (0, ensureTarget_1.ensureWebhook)(service, existing.webhookTargets, webhook),
        includeDiscord
            ? (0, ensureTarget_1.ensureDiscord)(service, existing.discordTargets, 'Default')
            : Promise.resolve(null),
    ]);
    const emailTargetIds = [];
    if (emailTargetId !== null) {
        emailTargetIds.push(emailTargetId);
    }
    const smsTargetIds = [];
    if (smsTargetId !== null) {
        smsTargetIds.push(smsTargetId);
    }
    const telegramTargetIds = [];
    if (telegramTargetId !== null) {
        telegramTargetIds.push(telegramTargetId);
    }
    const webhookTargetIds = [];
    if (webhookTargetId !== null) {
        webhookTargetIds.push(webhookTargetId);
    }
    const discordTargetIds = [];
    if (discordTargetId !== null) {
        discordTargetIds.push(discordTargetId);
    }
    return {
        emailTargetIds,
        smsTargetIds,
        telegramTargetIds,
        webhookTargetIds,
        discordTargetIds,
    };
};
exports.default = ensureTargetIds;
