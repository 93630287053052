import services from 'services'

let _modal = null
let _component = null

const connect = {

  // modal is our overlay which includes terms of service
  // and privacy policy
  setModal: (modal) => {
    _modal = modal
  },

  // component is the component that container terms of
  // service and privacy policy
  setComponent: (component) => {
    _component = component
  },

  connectWallet: () => {
    const modal = services.provider.web3Modal()
    if (services.provider.isWrongChain()) {
      modal.open({ view: 'Networks' })
    } else {
      modal.open()
    }
  },

  toggle: () => {
    if (_component.hasAcceptedDisclaimers()) {
      connect.connectWallet()
    } else {
      _modal.open()
    }
  },

  closeDisclaimers: () => {
    _modal.hide()
  },

  disconnect: () => {
    services.provider.web3Modal().disconnect()
  },
}
export default connect
