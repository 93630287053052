import constants from './constants'

export const reducerName = 'hashView'

export const initialState = {
  isLoading: false,
  data: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }

    case constants.SET_DATA:
      return {
        ...state,
        data: action.data
      }

    default:
      return state
  }
}

const exports = {
  reducer, 
  reducerName,
  initialState,
}

export default exports
