import services from 'services'

import actions from './actions'
import constants from './constants'
import selectors from './selectors'
import reducer from './reducer'

let _store

const exports = {
  actions,
  constants,
  selectors,
  reducer,
  init: async (store) => {
    _store = store
    const api = services.provider.buildAPI()
    const params = new URLSearchParams(document.location.search)
    const code = params.get('ref')
    let shouldSet = true

    if (!code) return

    // check for valid existing code
    const existingCode = selectors.code(store.store.getState())
    if (existingCode) {

      // need to fix this part
      const isValid = await api.isReferralCodeValid(existingCode)
      if (isValid) {
        shouldSet = false
      }
    }

    // if we do not have an existing code, or the current one is invalid, we set the code
    if (shouldSet) {
      store.store.dispatch(actions.setCode(code))
    }

    // remove and update url
    params.delete('ref')
    const updatedParamString = params.toString() === '' ? '' : '?' + params.toString()
    window.history.replaceState(null, '', window.location.pathname + updatedParamString)
  },
  getCode: () => {
    return selectors.code(_store.store.getState())
  }
}

export default exports
