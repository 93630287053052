import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from 'react-router-dom'
import './App.css';

import views from 'views'
import services from 'services'


function Inner() {
  const location = useLocation()
  React.useEffect(() => {
    services.linking.routeChanged()
  }, [location])
  return (
    <views.Wrapper>
      <Routes>
	<Route path={services.linking.path('AddressDomains')} element={<views.MyDomains isOwned={false} />} />
        <Route path={services.linking.path('Domain')} element={<views.Domain />} />
        <Route path={services.linking.path('Expired')} element={<views.Expired />} />
        <Route path={services.linking.path('Explore')} element={<views.Explore />} />
        <Route path={services.linking.path('ExploreDomain')} element={<views.Explore domainProvided={true} />} />
        <Route path={services.linking.path('Hash')} element={<views.Hash />} />
        <Route path={services.linking.path('Landing')} element={<views.Landing />} />
        <Route path={services.linking.path('MyDomains')} element={<views.MyDomains isOwned={true} />} />
        <Route path={services.linking.path('Register')} element={<views.Register />} />
        <Route path={services.linking.path('Renew')} element={<views.Renew/>} />
        <Route path={services.linking.path('Send')} element={<views.Send />} />
        <Route path={services.linking.path('SendDomain')} element={<views.Send domainProvided={true} />} />
        <Route path={services.linking.path('SetPassword')} element={<views.SetPassword />} />
        <Route path={services.linking.path('Settings')} element={<views.Settings />} />
      </Routes>
    </views.Wrapper>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Inner />
    </BrowserRouter>
  )
}

export default App;
