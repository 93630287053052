"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientConfigurationFragmentDependencies = exports.clientConfigurationFragment = void 0;
exports.clientConfigurationFragment = `
fragment clientConfigurationFragment on ClientConfiguration {
  supportedSmsCountryCodes
  supportedTargetTypes
}
`.trim();
exports.clientConfigurationFragmentDependencies = [];
