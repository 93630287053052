"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const DEPENDENCIES = [];
const MUTATION = `
mutation markFusionNotificationHistoryAsRead(
  $ids: [String!]!
  $beforeId: String
  $readState: NotificationHistoryReadState
) {
  markFusionNotificationHistoryAsRead(
    input: { ids: $ids, beforeId: $beforeId, readState: $readState }
  )
}
`.trim();
const markFusionNotificationHistoryAsReadImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'markFusionNotificationHistoryAsRead');
exports.default = markFusionNotificationHistoryAsReadImpl;
