//
// This service handles the connection
// with the web3 provider
//

import API from 'services/api'
import { ethers } from 'ethers'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5'
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import detectEthereumProvider from '@metamask/detect-provider'
import SafeAppsSDK from '@gnosis.pm/safe-apps-sdk'
import { SafeAppProvider } from '@gnosis.pm/safe-apps-provider'

import services from 'services'
import AvvyClient from '@avvy/client'

let store

let _isConnected = false
let _wrongChain = false
let _changeChain
let _chainId;
let _account;
let _provider;
let _signer;
let _providerType; // METAMASK or WALLETCONNECT

let web3Modal;

const CONNECTION_TYPES = {
  METAMASK: 1,
  WALLETCONNECT: 2,
  CORE: 3,
}

const PROVIDER_TYPES = {
  METAMASK: 1,
  WALLETCONNECT: 2,
}

const events = new EventTarget()

const EVENTS = {
  INITIALIZED: 0,
  CONNECTED: 1,
  DISCONNECTED: 2,
  WRONG_CHAIN: 3,
  CORRECT_CHAIN: 4,
}

const setWalletAutoconnect = async (wallet) => {
  store.store.dispatch(services.user.actions.setWalletAutoconnect(wallet))
}

const getDefaultProvider = () => {
  return new ethers.providers.JsonRpcProvider(services.environment.DEFAULT_PROVIDER_URL)
}

const getWeb3Provider = () => {
  if (web3Modal.getIsConnected()) {
    const walletProvider = web3Modal.getWalletProvider()
    return new ethers.providers.Web3Provider(walletProvider)
  }
  return getDefaultProvider()
}

const getAvvy = () => {
  return new AvvyClient(getWeb3Provider())
}

const wcIds = {
  bitget: '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
  coinbase: '',
  core: 'f323633c1f67055a45aac84e321af6ffe46322da677ffdd32f9bc1e33bafe29c',
  metamask: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  onto: '0f5eca2c7f2c9d11c992fdc707575c484ffb751e58e43eaeeea24510bfe8b8dd',
  walletconnect: 'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',
}

const initWeb3Modal = () => {
  if (!web3Modal) {
    let ethersConfig = defaultConfig({
      name: 'Avvy Domains',
      description: '.avax Domains on Avalanche',
      url: 'https://app.avvy.domains',
      icons: [],
    })
    const avalanche = {
      chainId: parseInt(services.environment.DEFAULT_CHAIN_ID),
      name: 'Avalanche',
      currency: 'AVAX',
      explorerUrl: services.environment.DEFAULT_BLOCK_EXPLORER_URL,
      rpcUrl: services.environment.DEFAULT_PROVIDER_URL,
    }
    let modalConfig = {
      ethersConfig,
      defaultChain: avalanche,
      chains: [
        avalanche
      ],
      projectId: services.environment.WALLETCONNECT_PROJECT_ID,
      enableAnalytics: false,
      featuredWalletIds: [
        wcIds.core,
        wcIds.bitget,
      ],
      includeWalletIds: Object.values(wcIds)
    }
    web3Modal = createWeb3Modal(modalConfig)
    web3Modal.syncProfile = async (address) => {
      const avvy = getAvvy()
      await avvy.contracts
      const hash = await avvy.reverse(avvy.RECORDS.EVM, address)
      if (!hash) {
        web3Modal.setProfileName(null)
        web3Modal.setProfileImage(null)
        return
      }
      const name = (await hash.lookup() || {})
      if (name) {
        web3Modal.setProfileName(name.name)
        const image = await name.resolve(avvy.RECORDS.AVATAR)
        if (image) {
          web3Modal.setProfileImage(image)
        } else {
          web3Modal.setProfileImage(null)
        }
      } else {
        web3Modal.setProfileName(null)
        web3Modal.setProfileImage(null)
      }
    }

    web3Modal.subscribeProvider(() => {
      if (web3Modal.getIsConnected()) {
        setTimeout(async () => {
          let chainId = (await getWeb3Provider().getNetwork()).chainId
          if (chainId === parseInt(services.environment.DEFAULT_CHAIN_ID)) {
            _isConnected = true
            _wrongChain = false
            _provider = getWeb3Provider().getSigner()
            _account = await _provider.getAddress()
            events.dispatchEvent(
              new Event(EVENTS.CONNECTED)
            )
          } else {
            _wrongChain = true
            services.logger.info('Wrong chain ID')
          }
        }, 1)
      } else {
        _isConnected = false
        _wrongChain = false
        _provider = null
        _account = null
        _chainId = null
        events.dispatchEvent(
          new Event(EVENTS.DISCONNECTED)
        )
      }
    })
  }
}

const provider = {
  
  // whether we have a web3 connection or not
  isConnected: () => {
    return _isConnected
  },

  // whether we have tried to connect and are on the wrong chain
  isWrongChain: () => _wrongChain,
  switchChain: () => _changeChain(),

  EVENTS,
  PROVIDER_TYPES,

  providerType: () => {
    return _providerType
  },

  web3Modal: () => {
    initWeb3Modal()
    return web3Modal
  },

  initGnosisSafe: async () => {
    const opts = {
      allowedDomains: [/gnosis-safe.io/],
    }
    const sdk = new SafeAppsSDK(opts)
    const safe = await sdk.safe.getInfo()
    services.logger.info('Gnosis Safe is connected')
    const chainId = safe.chainId
    if (chainId === parseInt(services.environment.DEFAULT_CHAIN_ID)) {
      services.logger.info('Chain ID is correct')
      const safeProvider = new SafeAppProvider(safe, sdk)
      _provider = new ethers.providers.Web3Provider(safeProvider)
      _signer = _provider.getSigner()
      _chainId = chainId
      _account = safe.safeAddress 
      _isConnected = true
      
      // we put a timeout here to let react
      // components digest the connection first
      setTimeout(() => {
        events.dispatchEvent(
          new Event(EVENTS.CONNECTED)
        )
      }, 1)
    } else {
      services.logger.info('Incorrect Chain ID')
    }
  },

  autoconnect: async () => {
    const state = store.store.getState()
    const walletAutoconnect = services.user.selectors.walletAutoconnect(state)

    switch (walletAutoconnect) {
      case CONNECTION_TYPES.METAMASK:
        provider.connectMetamask()
        break

      case CONNECTION_TYPES.WALLETCONNECT:
        provider.connectWalletConnect()
        break

      default:
        return
    }
  },

  init: (_store) => {
    store = _store
    initWeb3Modal()

    // this performs some checks when the application starts
    provider.initGnosisSafe()
    provider.autoconnect()
    events.dispatchEvent(
      new Event(EVENTS.INITIALIZED)
    )
  },

  connectWalletConnect: () => {
    return new Promise(async (resolve, reject) => {
      _provider =  await EthereumProvider.init({
        projectId: services.environment.WALLETCONNECT_PROJECT_ID,
        chains: [
          services.environment.DEFAULT_CHAIN_ID,
        ],
        rpcMap: {
          31337: 'http://localhost:8545',
          43113: 'https://api.avax-test.network/ext/bc/C/rpc',
          43114: 'https://api.avax.network/ext/bc/C/rpc',
        },
        showQrModal: true,
      })
      await _provider.enable().catch(reject)
      const web3Provider = new ethers.providers.Web3Provider(_provider)
      _signer = web3Provider.getSigner()
      _providerType = PROVIDER_TYPES.WALLETCONNECT

      const _getChainId = async () => {
        const id = await _provider.request({
          method: 'eth_chainId'
        })
        return id
      }

      const chainId = await _getChainId()
      const expectedChainId = parseInt(services.environment.DEFAULT_CHAIN_ID)
      if (chainId !== expectedChainId) {
        return reject('WRONG_CHAIN')
      } else {
        continueInitialization()
      }

      async function continueInitialization() {
        _chainId = await _getChainId()
        services.logger.info('Chain has changed')
        services.logger.info('Initializing accounts')

        _account = await _signer.getAddress()
        _isConnected = true
        
        // we put a timeout here to let react
        // components digest the connection first
        setTimeout(() => {
          events.dispatchEvent(
            new Event(EVENTS.CONNECTED)
          )
        }, 1)

        _provider.on('accountsChanged', () => {
          services.logger.info('Metamask accounts changed; reloading page')
          window.location.reload()
        })

        _provider.on('chainChanged', () => {
          services.logger.info('Metamask chain changed; reloading page')
          window.location.reload()
        })

        await setWalletAutoconnect(CONNECTION_TYPES.WALLETCONNECT)
        resolve()
      }
    })
  },

  disconnectWallet: async () => {

    if (_provider.disconnect) _provider.disconnect()
    web3Modal.disconnect()

    _isConnected = false
    _chainId = undefined
    _account = undefined
    _provider = undefined
    _signer = undefined
    _providerType = undefined

    events.dispatchEvent(
      new Event(EVENTS.DISCONNECTED)
    )

    await setWalletAutoconnect(null)
  },

  // connect to a metamask-like API
  _connectMetamask: (ethereum, provider, connectionType) => {
    return new Promise(async (resolve, reject) => {
      _wrongChain = false
      if (!provider) {
        services.logger.error('No window.ethereum provider')
        return reject('NO_PROVIDER')
      }

      const _getChainId = async () => {
        const raw = await provider.request({ method: 'eth_chainId' })
        return parseInt(raw, 16)
      }

      // verify they connected to the right chain
      const chainId = await _getChainId()
      const expectedChainId = parseInt(services.environment.DEFAULT_CHAIN_ID)
      if (chainId !== expectedChainId) {
        _wrongChain = true
        events.dispatchEvent(
          new Event(EVENTS.WRONG_CHAIN)
        )
        _changeChain = async () => {
          try {
            services.logger.info('Attempting to switch chains')
            await provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{
                chainId: '0x' + expectedChainId.toString(16)
              }]
            })
            events.dispatchEvent(
              new Event(EVENTS.CORRECT_CHAIN)
            )
            window.location.reload()
          } catch (err) {
            services.logger.info('Chain not found')
            try {
              services.logger.info('Attempting to add chain')
              await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0x' + expectedChainId.toString(16),
                    chainName: services.environment.DEFAULT_CHAIN_NAME,
                    nativeCurrency: {
                      name: 'AVAX',
                      symbol: 'AVAX',
                      decimals: 18,
                    },
                    rpcUrls: [services.environment.DEFAULT_PROVIDER_URL],
                    blockExplorerUrls: [services.environment.DEFAULT_BLOCK_EXPLORER_URL],
                  }
                ]
              })
              events.dispatchEvent(
                new Event(EVENTS.CORRECT_CHAIN)
              )
            } catch (err) {
              services.logger.error(err)
              return reject('WRONG_CHAIN')
            }
          }
        }
      } else {
        continueInitialization()
      }

      async function continueInitialization() {
        _chainId = await _getChainId()
        services.logger.info('Chain has changed')
        services.logger.info('Initializing accounts')

        const accounts = await provider.request({
          method: 'eth_requestAccounts'
        })

        _account = accounts[0]
        _isConnected = true
        _provider = new ethers.providers.Web3Provider(provider)
        _signer = _provider.getSigner()
        _providerType = PROVIDER_TYPES.METAMASK
        
        // we put a timeout here to let react
        // components digest the connection first
        setTimeout(() => {
          events.dispatchEvent(
            new Event(EVENTS.CONNECTED)
          )
        }, 1)

        await setWalletAutoconnect(connectionType)
        resolve(provider)
      }
    })
  },

  // connect to web3 via metamask
  connectMetamask: (providerFunc, connectionType) => {
    return new Promise(async (resolve, reject) => {
      let _provider = await detectEthereumProvider()

      if (_provider.providers) {
        _provider = _provider.providers.find(providerFunc)
      }

      if (!connectionType) connectionType = CONNECTION_TYPES.METAMASK

      try {
        const _finalProvider = await provider._connectMetamask(window.ethereum, _provider, connectionType)

        window.ethereum.on('accountsChanged', async () => {
          const accounts = await _finalProvider.request({
            method: 'eth_requestAccounts'
          })

          if (accounts[0] !== _account) {
            services.logger.info('Metamask accounts changed; reloading page')
            window.location.reload()
          }
        })

        window.ethereum.on('chainChanged', () => {
          services.logger.info('Metamask chain changed; reloading page')
          window.location.reload()
        })
        
        resolve()
      } catch (err) {
        reject(err)
      }
    })
  },

  connectOnto: () => {
    return new Promise(async (resolve, reject) => {
      const handleChanged = () => {
        window.onto.off('accountsChanged', handleChanged)
        provider._connectMetamask(window.onto, window.onto, CONNECTION_TYPES.METAMASK).then(resolve).catch(reject)
        resolve()
      }
      window.onto.on('accountsChanged', handleChanged)
      await window.onto.request({ method: 'eth_requestAccounts' })
      provider._connectMetamask(window.onto, window.onto, CONNECTION_TYPES.METAMASK).then(resolve).catch(reject)
    })
  },

  connectCore: () => {
    return new Promise(async (resolve, reject) => {
      const handleChanged = () => {
        window.ethereum.off('accountsChanged', handleChanged)
        provider.connectMetamask((provider) => provider.isAvalanche)
        resolve()
      }
      window.ethereum.on('accountsChanged', handleChanged)
      provider.connectMetamask((provider) => provider.isAvalanche, CONNECTION_TYPES.CORE)
    })
  },

  // get the account
  getAccount: () => {
    initWeb3Modal()
    return web3Modal.getAddress() 
  },

  getSigner: () => {
    return _provider
  },

  // get api client
  buildAPI: () => {
    initWeb3Modal()
    _chainId = parseInt(services.environment.DEFAULT_CHAIN_ID)

    let provider = _provider || getDefaultProvider()
    if (_isConnected) {
      return new API(_chainId, _account, provider);
    } else {
      let account = null
      return new API(_chainId, account, provider)
    }
  },

  // listen for changes 
  addEventListener: (eventName, callback) => {
    events.addEventListener(eventName, callback)
  },

  // stop listening for changes
  removeEventListener: (eventName, callback) => {
    events.removeEventListener(eventName, callback)
  },

  signMessage: async (message) => {
    let sig
    if (_providerType === PROVIDER_TYPES.METAMASK) {
      sig = await window.ethereum.request({
        method: 'personal_sign',
        params: [
          message,
          _account
        ]
      })
    } else if (_providerType === PROVIDER_TYPES.WALLETCONNECT) {
      //sig = await _signer.signMessage(message)
			sig = await new ethers.providers.Web3Provider(_provider).send(
        'personal_sign',
        [ ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)), _account.toLowerCase() ]
    	)
    } else {
      throw new Error("Unknown provider type for signMessage")
    }
    return sig
  }
}

export default provider
