"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.webhookTargetFragmentDependencies = exports.webhookTargetFragment = void 0;
exports.webhookTargetFragment = `
fragment webhookTargetFragment on WebhookTarget {
  id
  url
  status
  format
  headers {
    key
    value
  }
  id
  name
}
`.trim();
exports.webhookTargetFragmentDependencies = [];
