"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAlertPaused = exports.isAlertObsolete = void 0;
const hasKey = (obj, key) => {
    return Object.prototype.hasOwnProperty.call(obj, key);
};
const parseFilterOptions = (alert) => {
    const filterOptions = {};
    if (alert.filterOptions === null) {
        return filterOptions;
    }
    let jsonObject = {};
    try {
        jsonObject = JSON.parse(alert.filterOptions);
    }
    catch (e) {
        jsonObject = {};
    }
    if (hasKey(jsonObject, 'alertFrequency') &&
        (jsonObject.alertFrequency === 'ALWAYS' ||
            jsonObject.alertFrequency === 'SINGLE')) {
        filterOptions.alertFrequency = jsonObject.alertFrequency;
    }
    if (hasKey(jsonObject, 'threshold') &&
        typeof jsonObject.threshold === 'string') {
        try {
            filterOptions.threshold = parseInt(jsonObject.threshold, 10);
        }
        catch (e) {
            filterOptions.threshold = NaN;
        }
    }
    if (hasKey(jsonObject, 'delayProcessingUntil') &&
        typeof jsonObject.delayProcessingUntil === 'string') {
        filterOptions.delayProcessingUntil = jsonObject.delayProcessingUntil;
    }
    return filterOptions;
};
const isAlertPaused = (alert) => {
    if (alert.filterOptions === null) {
        return false;
    }
    const filterOptions = parseFilterOptions(alert);
    if (filterOptions.delayProcessingUntil === undefined) {
        return false;
    }
    const delayProcessingUntil = Date.parse(filterOptions.delayProcessingUntil);
    return !isNaN(delayProcessingUntil) && delayProcessingUntil > Date.now();
};
exports.isAlertPaused = isAlertPaused;
const isAlertObsolete = (alert) => {
    if (alert.filterOptions === null) {
        return false;
    }
    const filterOptions = parseFilterOptions(alert);
    return filterOptions.alertFrequency === 'SINGLE' && isAlertPaused(alert);
};
exports.isAlertObsolete = isAlertObsolete;
