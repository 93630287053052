"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const doFetchData = async (service) => {
    const [alerts, connectedWallets, sources, sourceGroups, targetGroups, emailTargets, smsTargets, telegramTargets, webhookTargets, discordTargets,] = await Promise.all([
        service.getAlerts(),
        service.getConnectedWallets(),
        service.getSources(),
        service.getSourceGroups(),
        service.getTargetGroups(),
        service.getEmailTargets(),
        service.getSmsTargets(),
        service.getTelegramTargets(),
        service.getWebhookTargets(),
        service.getDiscordTargets(),
    ]);
    const filterIds = new Set();
    const filters = [];
    sources.forEach((source) => {
        var _a;
        (_a = source.applicableFilters) === null || _a === void 0 ? void 0 : _a.forEach((filter) => {
            var _a;
            if (filter && !filterIds.has((_a = filter === null || filter === void 0 ? void 0 : filter.id) !== null && _a !== void 0 ? _a : '')) {
                filters.push(filter);
                filterIds.add(filter.id);
            }
        });
    });
    return {
        alerts: [...alerts],
        connectedWallets: [...connectedWallets],
        filters,
        sources: [...sources],
        discordTargets: [...discordTargets],
        sourceGroups: [...sourceGroups],
        targetGroups: [...targetGroups],
        emailTargets: [...emailTargets],
        smsTargets: [...smsTargets],
        telegramTargets: [...telegramTargets],
        webhookTargets: [...webhookTargets],
    };
};
const DataTtlMs = 1000;
const fetchDataImpl = async (service, timeProvider, state) => {
    if (state.pendingPromise !== undefined) {
        return await state.pendingPromise;
    }
    if (state.lastSuccessTime !== undefined &&
        state.lastSuccessData !== undefined) {
        const currentTime = timeProvider.now();
        if (currentTime <= state.lastSuccessTime + DataTtlMs) {
            return state.lastSuccessData;
        }
    }
    const promise = doFetchData(service);
    state.pendingPromise = promise;
    const results = await promise;
    state.pendingPromise = undefined;
    state.lastSuccessTime = timeProvider.now();
    state.lastSuccessData = results;
    return results;
};
exports.default = fetchDataImpl;
