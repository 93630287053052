import reduxService from 'services/redux'

const constants = reduxService.prepareConstants(
  'views/MyDomains',
  [
    'HAS_LOADED_DOMAINS', 

    'SET_DOMAIN_IDS',
    'SET_DOMAIN_COUNT',
    'SET_LOADED_DOMAIN_COUNT',
    'SET_DOMAIN_EXPIRY',
    'SET_SELECT_FROM',
  ]
)

export default constants
