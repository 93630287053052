"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const DEPENDENCIES = [];
const MUTATION = `
mutation deleteSourceGroup(
  $id: String!
) {
  deleteSourceGroup(sourceGroupInput: {
    id: $id
  }) {
    id
  }
}
`.trim();
const deleteSourceGroupImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'deleteSourceGroup');
exports.default = deleteSourceGroupImpl;
