import constants from './constants'
import services from 'services'

const actions = {
  hasLoadedDomains: (loaded) => {
    return {
      type: constants.HAS_LOADED_DOMAINS,
      loaded
    }
  },

  setDomainIds: (domainIds) => {
    return {
      type: constants.SET_DOMAIN_IDS,
      domainIds
    }
  },

  setDomainCount: (domainCount) => {
    return {
      type: constants.SET_DOMAIN_COUNT,
      domainCount
    }
  },

  setDomainExpiry: (hash, expiry) => {
    return {
      type: constants.SET_DOMAIN_EXPIRY,
      hash,
      expiry
    }
  },

  setLoadedDomainCount: (loadedDomainCount) => {
    return {
      type: constants.SET_LOADED_DOMAIN_COUNT,
      loadedDomainCount
    }
  },

  loadDomainsForAddress: (address) => {
    return async (dispatch, getState) => {
      const api = services.provider.buildAPI()
      dispatch(actions.setDomainCount(null))
      const domainCount = await api.getDomainCountForOwner(address)
      let domainIds = []
      let loadedDomainCount = 0
      dispatch(actions.setLoadedDomainCount(0))
      dispatch(actions.setDomainCount(domainCount))
      let batchSize = 50
      let batches = []
      let batch = []

      for (let i = 0; i < domainCount; i += batchSize) {
        let count = batchSize
        if (i + batchSize > domainCount - 1) count = domainCount - i
        let tokenIds = await api.getTokensOfOwnerByOffsetAndCount(address, i, count)
        let expiries = await api.getNameExpiries(tokenIds)

        for (let j = 0; j < tokenIds.length; j += 1) {
          let id = tokenIds[j]
          let expiry = expiries[j]
          dispatch(actions.setDomainExpiry(id, expiry))
          domainIds.push(id)
          loadedDomainCount += 1
        }
        if (loadedDomainCount % 10 === 0) dispatch(actions.setLoadedDomainCount(loadedDomainCount))
      }
      dispatch(actions.setLoadedDomainCount(domainIds.length))
      dispatch(services.names.actions.lookupMultiple(domainIds))
      dispatch(actions.setDomainIds(domainIds))
    }
  },
  
  selectFrom: (where) => {
    return {
      type: constants.SET_SELECT_FROM,
      where,
    }
  },

  loadDomains: (address) => {
    return async (dispatch, getState) => {
      if (address) {
        dispatch(actions.selectFrom('view'))
        dispatch(actions.loadDomainsForAddress(address))
      } else {
        dispatch(actions.selectFrom('userService'))
        dispatch(services.user.actions.loadDomains())
        dispatch(actions.hasLoadedDomains(true))
      }
    }
  },
}

export default actions
