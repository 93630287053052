import account from './account'
import abi from './abi'
import analytics from './analytics'
import api from './api'
import blocklist from './blocklist'
import cart from './cart'
import circuits from './circuits'
import connect from './connect'
import darkmode from './darkmode'
import data from './data'
import device from './device'
import environment from './environment'
import files from './files'
import linking from './linking'
import logger from './logger'
import money from './money'
import names from './names'
import notifications from './notifications'
import proofs from './proofs'
import provider from './provider'
import random from './random'
import records from './records'
import redux from './redux'
import referrals from './referrals'
import sunrise from './sunrise'
import time from './time'
import user from './user'

const services = {
  account,
  abi,
  analytics,
  api,
  blocklist,
  cart,
  circuits,
  connect,
  darkmode,
  data,
  device,
  environment,
  files,
  linking,
  logger,
  money,
  names,
  notifications,
  proofs,
  provider,
  random,
  records,
  redux,
  referrals,
  sunrise,
  time,
  user,
}

export default services
