"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.participantFragmentDependencies = exports.participantFragment = void 0;
exports.participantFragment = `
fragment Participant on ConversationParticipant {
  conversationId
  conversationParticipantType
  profile {
    avatarData
    avatarDataType
    id
    preferredAddress
    preferredBlockchain
    preferredName
  }
  resolvedName
  userId
  walletAddress
  walletBlockchain
}
`.trim();
exports.participantFragmentDependencies = [];
