"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const updateTargetGroup = async (service, existing, input) => {
    const targetGroupId = existing.id;
    if (targetGroupId === null) {
        throw new Error('Invalid target group');
    }
    return await service.updateTargetGroup(Object.assign({ id: targetGroupId }, input));
};
const ensureTargetGroup = async (service, existing, input) => {
    const existingIndex = existing.findIndex((it) => it.name === input.name);
    if (existingIndex >= 0) {
        const result = await updateTargetGroup(service, existing[existingIndex], input);
        existing[existingIndex] = result;
        return result;
    }
    else {
        const result = await service.createTargetGroup(input);
        existing.push(result);
        return result;
    }
};
exports.default = ensureTargetGroup;
