"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.smsTargetFragmentDependencies = exports.smsTargetFragment = void 0;
exports.smsTargetFragment = `
fragment smsTargetFragment on SmsTarget {
  id
  isConfirmed
  name
  phoneNumber
}
`.trim();
exports.smsTargetFragmentDependencies = [];
