"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const QUERY = `
query getUnreadNotificationHistoryCount {
  unreadNotificationHistoryCount {
    count
  }
}
`.trim();
const getUnreadNotificationHistoryCountImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(QUERY), 'unreadNotificationHistoryCount');
exports.default = getUnreadNotificationHistoryCountImpl;
