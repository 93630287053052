"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userTopicFragmentDependencies = exports.userTopicFragment = void 0;
exports.userTopicFragment = `
fragment userTopicFragment on UserTopic {
  name
  topicName
  targetCollections
  targetTemplate
}
`.trim();
exports.userTopicFragmentDependencies = [];
