import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { CheckIcon, ArrowLeftIcon } from '@heroicons/react/solid'
import { renderIcon } from '@download/blockies'
import { utils as ensAvatarUtils } from '@ensdomains/ens-avatar'

import components from 'components'
import services from 'services'

function Step(props) {
  const isLoading = props.isCurrentStep && props.isLoading
  return (
    <div 
      className={`flex items-center justify-between ${props.isCurrentStep ? 'cursor-pointer' : 'pointer-events-none'} bg-gray-100 dark:bg-gray-800 rounded-xl p-4 mt-4`} 
      onClick={() => {
        if (props.isCurrentStep) props.onClick()
      }}
    >
      <div className={props.isCurrentStep && !props.isFaded ? 'font-bold text-gray-700 dark:text-gray-300' : 'text-gray-500'}>
        {props.text}
      </div>
      {isLoading && props.isCurrentStep && !props.isFaded ? (
        <components.Spinner color={props.isDarkmode ? '#eee' : 'black'} />
      ) : props.isComplete ? (
        <div className=''>
          <CheckIcon className='w-6 text-gray-500' />
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}


function Setup(props) {
  const [ step, setStep ] = useState(null)
  const [ hasResolver, setHasResolver ] = useState(false)
  const [ hasForwardResolution, setHasForwardResolution ] = useState(false)
  const [ hasReverseResolution, setHasReverseResolution ] = useState(false)
  const [ hasAvatar, setHasAvatar ] = useState(false)
  const [ avatarURL, setAvatarURL ] = useState(null)
  const [ account, setAccount ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(true)
  const [ isLoadingStep, setIsLoadingStep ] = useState(false)
  const [ uploadURL, setUploadURL ] = useState(null)
  const [ hasUploadedAvatar, setHasUploadedAvatar ] = useState(false)
  const [ hasSetAvatar, setHasSetAvatar ] = useState(false)
  const [ hasSelectedAvatar, setHasSelectedAvatar ] = useState(false)
  const uploadRef = useRef(null)

  useEffect(() => {
    let running = false

    const run = async () => {
      if (!running) {
        const api = await services.provider.buildAPI()
        setAccount(api.account)
        const records = await api.getStandardRecords(props.domain)

        let _hasResolver = false
        let _hasForwardResolution = false
        let _hasReverseResolution = false
        let _hasAvatar = false

        await Promise.all([

          // get resolver details
          new Promise(async (resolve, reject) => {
            try {
              const resolver = await api.getResolver(props.domain)
              const defaultResolver = await api.getDefaultResolverAddress()
              _hasResolver = resolver.resolver === defaultResolver
            } catch (err) {}
            setHasResolver(_hasResolver)
            resolve()
          }), 

          // get forward resolution details
          new Promise(async (resolve, reject) => {
            for (let i = 0; i < records.length; i += 1) {
              // looking for EVM record
              if (records[i].type === api.avvy.RECORDS.EVM && records[i].value.toLowerCase() === api.account.toLowerCase()) {
                _hasForwardResolution = true
              }
            }
            setHasForwardResolution(_hasForwardResolution)
            resolve()
          }),

          // get reverse resolution details
          new Promise(async (resolve, reject) => {
            const records = await api.getReverseRecords(props.domain)
            _hasReverseResolution = records[api.avvy.RECORDS.EVM] && records[api.avvy.RECORDS.EVM].toLowerCase() === api.account.toLowerCase()
            setHasReverseResolution(_hasReverseResolution)
            resolve()
          }),

          // get avatar details
          new Promise(async (resolve, reject) => {
            const api = await services.provider.buildAPI()
            for (let i = 0; i < records.length; i += 1) {
              // looking for EVM record
              if (records[i].type === api.avvy.RECORDS.AVATAR && records[i].value) {
                _hasAvatar = true
                let url = ensAvatarUtils.resolveURI(records[i].value, {
                  ipfs: 'https://w3s.link/',
                }).uri
                setAvatarURL(url)
                setHasSelectedAvatar(true)
                setHasUploadedAvatar(true)
                setHasSetAvatar(true)
                setUploadURL(records[i].value)
              }
            }
            setHasAvatar(_hasAvatar)
            resolve()
          }),
        ])

        if (!_hasResolver) {
          setStep(1)
        } else if (!_hasForwardResolution) {
          setStep(2)
        } else if (!_hasReverseResolution) {
          setStep(3)
        } else if (!_hasAvatar) {
          setStep(4)
        } else {
          setStep(6)
        }
        setIsLoading(false)
      }
    }

    run()

    return () => {
      running = true
    }
  }, [])

  const nextStep = () => {
    let _step = 1
    while (true) {
      if (_step <= step) {
        _step += 1
      } else {
        break
      }
    }
    if (!hasResolver && step < 1) {
      setStep(1)
    } else if (!hasForwardResolution && step < 2) {
      setStep(2)
    } else if (!hasReverseResolution && step < 3) {
      setStep(3)
    } else if (!hasAvatar && step < 4) {
      setStep(4)
    } else if (!hasSetAvatar && step < 5) {
      setStep(5)
    } else {
      setStep(6)
    }
  }

  // call the resolver contract and set the
  // resolver to the default resolver address
  async function setResolver() {
    const api = await services.provider.buildAPI()
    const defaultAddress = await api.getDefaultResolverAddress()
    setIsLoadingStep(true)
    try {
      await api.setResolver(props.domain, defaultAddress)
      setHasResolver(true)
      nextStep()
    } catch (err) {
    }
    setIsLoadingStep(false)
  }

  // set standard record for EVM on the 
  // default resolver for this address
  async function setForwardResolution() {
    const api = await services.provider.buildAPI()
    setIsLoadingStep(true)
    try {
      await api.setStandardRecord(props.domain, api.avvy.RECORDS.EVM, api.account)
      setHasForwardResolution(true)
      nextStep()
    } catch (err) {
    }
    setIsLoadingStep(false)
  }

  // set the EVM reverse record for this domain
  // to the current address
  async function setReverseResolution() {
    const api = await services.provider.buildAPI()
    setIsLoadingStep(true)
    try {
      await api.setEVMReverseRecord(props.domain)
      setHasReverseResolution(true)
      nextStep()
    } catch (err) {
    }
    setIsLoadingStep(false)
  }

  async function selectAvatar() {
    setIsLoadingStep(true)
    const onChange = (e) => {
      uploadRef.current.removeEventListener('change', onChange)
      const file = e.target.files[0]
      if (!file) return setIsLoadingStep(false)
      if (file.size / 1024 / 1024 > 2) {
        setIsLoadingStep(false)
        return alert('Image must be smaller than 2 megabytes')
      }
      const allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/gif', 
      ]
      if (allowedTypes.indexOf(file.type) === -1) {
        setIsLoadingStep(false)
        return alert('Invalid file. JPG, PNG or GIF only!')
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        setAvatarURL(e.target.result)
        setHasSelectedAvatar(true)
        setIsLoadingStep(false)
      }
      reader.onerror = () => {
        setIsLoadingStep(false)
      }

      reader.readAsDataURL(file)
    }
    uploadRef.current.addEventListener('change', onChange)
    uploadRef.current.addEventListener('cancel', () => {
      setIsLoadingStep(false)
    })
    uploadRef.current.click()
  }

  async function uploadAvatar() {
    setIsLoadingStep(true)
    const api = await services.provider.buildAPI()
    const message = 'I am proving ownership of ' + props.domain + ' to update my .avax domain avatar!\n\nRequested by: app.avvy.domains\nNonce: ' + parseInt(Date.now() / 1000).toString()
    try {
      const signature = await api.signMessage(message)
      
      // first lets upload the avatar
      const url = services.environment.BACKEND_BASE_URL + '/api/' + props.domain + '/avatar/'
      const res = await fetch(url, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ 
          avatar: avatarURL, 
          signature, 
          address: account, 
          message 
        })
      })
      const data = await res.json()
      if (data.success) {
        nextStep()
        setUploadURL(data.url)
      } else {
        alert("Failed to set image")
      }
    } catch (err) {
    }
    setIsLoadingStep(false)
  }

  async function setAvatar() {
    setIsLoadingStep(true)
    const api = await services.provider.buildAPI()
    try {
      await api.setStandardRecord(props.domain, api.avvy.RECORDS.AVATAR, uploadURL)
      setHasSetAvatar(true)
      nextStep()
    } catch (err) {

    }
    setIsLoadingStep(false)
  }

  async function resetAvatar() {
    setUploadURL(false)
    setHasUploadedAvatar(false)
    setHasSetAvatar(false)
    setHasSelectedAvatar(false)
    setStep(4)
  }


  return (
    <div className='max-w-sm m-auto text-center'>
      {isLoading ? (
        <components.Spinner color={props.isDarkmode ? '#eee' : 'black'} />
      ) : (
        <>
          <div className='w-full flex items-center justify-center'>
            {avatarURL ? (
              <div style={{ width: '100px', height: '100px', borderRadius: '100px', backgroundImage: `url(${avatarURL})` }} className='bg-center bg-cover'></div>
            ) : (
              <canvas style={{ width: '100px', height: '100px', borderRadius: '100px' }} ref={(ref) => {
                if (ref) renderIcon({ seed: account }, ref)
              }} />
            )}
          </div>
          <div className='mt-4 font-bold'>{props.domain}</div>
          {step === 6 ? (
            <div className='text-left mt-8 text-sm'>
              <div className='font-bold'>{"🥳 You're all set!"}</div>
              <div>
                {"Your domain has been properly configured. You can still change the image if you would like!"}
              </div>

              <components.buttons.Button text="Change Image" onClick={resetAvatar} className='mt-4' />
            </div>
          ) : (
            <>
              <div className='text-sm'>Set up your domain!</div>
              <input type="file" className='hidden' ref={uploadRef} />
              <Step
                className='mt-4' 
                sm={true} 
                text={'1. Set your Resolver'} 
                isComplete={hasResolver}
                isFaded={hasResolver}
                isCurrentStep={step === 1}
                onClick={setResolver}
                isLoading={isLoadingStep}
                isDarkmode={props.isDarkmode}
              />
              <Step
                className='mt-4' 
                sm={true} 
                text={'2. Configure Forward Resolution'} 
                isComplete={hasForwardResolution}
                isFaded={hasForwardResolution}
                isCurrentStep={step === 2}
                onClick={setForwardResolution}
                isLoading={isLoadingStep}
                isDarkmode={props.isDarkmode}
              />
              <Step
                className='mt-4' 
                sm={true} 
                text={'3. Configure Reverse Resolution'} 
                isComplete={hasReverseResolution}
                isFaded={hasReverseResolution}
                isCurrentStep={step === 3}
                onClick={setReverseResolution}
                isLoading={isLoadingStep}
                isDarkmode={props.isDarkmode}
              />
              <Step
                className='mt-4' 
                sm={true} 
                text={'4. Select an Avatar'} 
                isComplete={hasSelectedAvatar}
                isFaded={step > 4 || hasSelectedAvatar}
                isCurrentStep={step === 4}
                onClick={selectAvatar}
                isLoading={isLoadingStep}
                isDarkmode={props.isDarkmode}
              />
              <Step
                className='mt-4' 
                sm={true} 
                text={'5. Save your Avatar'} 
                isComplete={uploadURL}
                isCurrentStep={step === 4 && hasSelectedAvatar}
                onClick={uploadAvatar}
                isLoading={isLoadingStep}
                isDarkmode={props.isDarkmode}
              />
              <Step
                className='mt-4' 
                sm={true} 
                text={'6. Set your Avatar'} 
                isComplete={hasSetAvatar}
                isCurrentStep={step === 5}
                onClick={setAvatar}
                isLoading={isLoadingStep}
                isDarkmode={props.isDarkmode}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}


class DomainSetup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      domain: null,
      domains: [],
    }
    this.init()
  }

  init = async () => {
    const api = await services.provider.buildAPI()
    if (!this.state.domain) {
      const domains = await api.getDomains(api.account)
      this.setState({
        domains
      })
    }
  }


  selectDomain = async (name) => {
    this.setState({ domain: name })
  }

  renderDomains() {
    if (!this.state.domains || this.state.domains.length === 0) return this.renderLoading()
    const names = Object.values(this.state.domains).filter(name => !!name)
    names.sort()
    return (
      <div className='max-w-sm m-auto text-center'>
        Select the domain you want to configure
        {names.map((name, index) => (
          <components.buttons.Button className='mt-4' sm={true} text={name} onClick={() => this.selectDomain(name)} />
        ))}
      </div>
    )
  }

  renderLoading() {
    return (
      <div className='mt-4 m-auto text-center'>
        <components.Spinner color={this.props.isDarkmode ? '#eee' : 'black'} />
      </div>
    )
  }

  render() {
    
    // user first has to pick a domain.
    if (!this.state.domain) return this.renderDomains()
    return (
      <div>
        <div className='text-left opacity-50 cursor-pointer text-sm' onClick={() => this.selectDomain(null)}>
          <ArrowLeftIcon className='w-4 mr-2 inline-block' /> Choose another domain
        </div>
        <Setup isDarkmode={this.props.isDarkmode} domain={this.state.domain} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isDarkmode: services.darkmode.selectors.isDarkmode(state),
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DomainSetup)
