import Avatar from './Avatar'
import ConnectWallet from './ConnectWallet'
import ConnectWalletButton from './ConnectWalletButton'
import ContinueRegistration from './ContinueRegistration'
import DataExplorer from './DataExplorer'
import DomainPrivacy from './DomainPrivacy'
import DomainSearch from './DomainSearch'
import DomainSetup from './DomainSetup'
import DomainReveal from './DomainReveal'
import ERC721Token from './ERC721Token'
import EVMAddress from './EVMAddress'
import Input from './Input'
import Modal from './Modal'
import Onramp from './Onramp'
import ProgressBar from './ProgressBar'
import Select from './Select'
import Spinner from './Spinner'
import Transfer from './Transfer/index.js'

import account from './account'
import buttons from './buttons'
import checkbox from './checkbox'
import labels from './labels'

const components = {
  account,
  buttons,
  checkbox,
  labels,

  Avatar,
  ConnectWallet,
  ConnectWalletButton,
  ContinueRegistration,
  DataExplorer,
  DomainPrivacy,
  DomainSearch,
  DomainSetup,
  DomainReveal,
  ERC721Token,
  EVMAddress,
  Input,
  Modal,
  Onramp,
  ProgressBar,
  Select,
  Spinner,
  Transfer,
}

export default components
