import services from 'services'

let _modal = null
let _component = null

const notifi = {

  // modal is our overlay which includes the whole notification setup
  setModal: (modal) => {
    _modal = modal
  },

  toggle: () => {
    _modal.open()
  },
}
export default notifi
