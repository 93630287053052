"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const hasKey = (obj, prop) => {
    return (typeof obj === 'object' &&
        obj !== null &&
        Object.prototype.hasOwnProperty.call(obj, prop));
};
const packageErrors = (errors) => {
    const messages = [];
    errors.forEach((error) => {
        if (hasKey(error, 'message') && typeof error.message === 'string') {
            messages.push(error.message);
        }
    });
    return messages;
};
class GqlError extends Error {
    constructor(operationName, errors) {
        super(`GQL Errors occurred during ${operationName}`);
        this.operationName = operationName;
        this.errors = errors;
    }
    getErrorMessages() {
        return packageErrors(this.errors);
    }
}
exports.default = GqlError;
