"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const updateSourceGroup = async (service, existing, input) => {
    const sourceGroupId = existing.id;
    if (sourceGroupId === null) {
        throw new Error('Invalid source group');
    }
    return await service.updateSourceGroup(Object.assign({ id: sourceGroupId }, input));
};
const ensureSourceGroup = async (service, existing, input) => {
    const existingIndex = existing.findIndex((it) => it.name === input.name);
    if (existingIndex >= 0) {
        const result = await updateSourceGroup(service, existing[existingIndex], input);
        existing[existingIndex] = result;
        return result;
    }
    else {
        const result = await service.createSourceGroup(input);
        existing.push(result);
        return result;
    }
};
exports.default = ensureSourceGroup;
