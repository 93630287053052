"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [
    ...fragments_1.clientConfigurationFragmentDependencies,
    fragments_1.clientConfigurationFragment,
];
const QUERY = `
query getConfigurationForDapp($dappAddress: String!) {
  configurationForDapp(getConfigurationForDappInput: {
    dappAddress: $dappAddress
  }) {
    ...clientConfigurationFragment
  }
}
`.trim();
const getConfigurationForDappImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'configurationForDapp');
exports.default = getConfigurationForDappImpl;
