import constants from './constants'
import services from 'services'

const actions = {
  setDomainIds: (domainIds) => {
    return {
      type: constants.SET_DOMAIN_IDS,
      domainIds
    }
  },

  setDomainCount: (domainCount) => {
    return {
      type: constants.SET_DOMAIN_COUNT,
      domainCount
    }
  },

  setDomainExpiry: (hash, expiry) => {
    return {
      type: constants.SET_DOMAIN_EXPIRY,
      hash,
      expiry
    }
  },

  setLoadedDomainCount: (loadedDomainCount) => {
    return {
      type: constants.SET_LOADED_DOMAIN_COUNT,
      loadedDomainCount
    }
  },

  loadDomains: () => {
    return async (dispatch, getState) => {
      const api = services.provider.buildAPI()
      dispatch(actions.setDomainCount(null))
      const domainCount = await api.getDomainCountForOwner(api.account)
      let domainIds = []
      let loadedDomainCount = 0
      dispatch(actions.setLoadedDomainCount(0))
      dispatch(actions.setDomainCount(domainCount))
      let batchSize = 50
      let batches = []
      let batch = []

      for (let i = 0; i < domainCount; i += batchSize) {
        let count = batchSize
        if (i + batchSize > domainCount - 1) count = domainCount - i
        let tokenIds = await api.getTokensOfOwnerByOffsetAndCount(api.account, i, count)
        let expiries = await api.getNameExpiries(tokenIds)

        for (let j = 0; j < tokenIds.length; j += 1) {
          let id = tokenIds[j]
          let expiry = expiries[j]
          dispatch(actions.setDomainExpiry(id, expiry))
          domainIds.push(id)
          loadedDomainCount += 1
        }
        if (loadedDomainCount % 10 === 0) dispatch(actions.setLoadedDomainCount(loadedDomainCount))
      }
      dispatch(actions.setLoadedDomainCount(domainIds.length))
      dispatch(services.names.actions.lookupMultiple(domainIds))
      dispatch(actions.setDomainIds(domainIds))
    }
  },
  
  acceptDisclaimers: () => {
    return {
      type: constants.ACCEPT_DISCLAIMERS,
    }
  },

  acceptNotifiDisclaimer: () => {
    return {
      type: constants.ACCEPT_NOTIFI_DISCLAIMERS,
    }
  },

  setExperimentalFeature: (feature, enabled) => {
    console.log(feature, enabled)
    return {
      type: constants.SET_EXPERIMENTAL_FEATURE,
      feature,
      enabled
    }
  },

  setToken: (token) => {
    return {
      type: constants.SET_TOKEN,
      token,
    }
  },

  setWalletAutoconnect: (wallet) => {
    return {
      type: constants.SET_WALLET_AUTOCONNECT,
      wallet
    }
  },

  setLoadingProfile: (loading) => {
    return {
      type: constants.SET_LOADING_PROFILE,
      loading
    }
  },

  setProfile: (profile) => {
    return {
      type: constants.SET_PROFILE,
      profile
    }
  },

  loadProfile: () => {
    return async (dispatch, getState) => {
      dispatch(actions.setLoadingProfile(true))
      const account = services.provider.getAccount()
      try {
        const api = services.provider.buildAPI()
        const profile = await api.getProfile(account)
        dispatch(actions.setProfile(profile))
      } catch (err) {
        dispatch(actions.setProfile({
          address: account
        }))
      }
      dispatch(actions.setLoadingProfile(false))
    }
  }
}

export default actions
