"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [
    ...fragments_1.connectedWalletFragmentDependencies,
    fragments_1.connectedWalletFragment,
];
const MUTATION = `
mutation connectWallet(
  $walletPublicKey: String!
  $timestamp: Long!
  $signature: String!
  $walletBlockchain: WalletBlockchain!
  $accountId: String
  $connectWalletConflictResolutionTechnique: ConnectWalletConflictResolutionTechnique
) {
  connectWallet(connectWalletInput: {
    walletPublicKey: $walletPublicKey
    timestamp: $timestamp
    walletBlockchain: $walletBlockchain
    accountId: $accountId
    connectWalletConflictResolutionTechnique: $connectWalletConflictResolutionTechnique
  }, signature: $signature) {
    ...connectedWalletFragment
  }
}
`.trim();
const connectWalletImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'connectWallet');
exports.default = connectWalletImpl;
