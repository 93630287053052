"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const sourceFragment_1 = require("../fragments/sourceFragment");
const DEPENDENCIES = [...sourceFragment_1.sourceFragmentDependencies, sourceFragment_1.sourceFragment];
const QUERY = `
query getSources {
  source {
    ...sourceFragment
  }
}
`.trim();
const getSourcesImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'source');
exports.default = getSourcesImpl;
