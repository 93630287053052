"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [
    ...fragments_1.beginLogInByTransactionFragmentDependencies,
    fragments_1.beginLogInByTransactionFragment,
];
const MUTATION = `
mutation beginLogInByTransaction(
  $walletAddress: String!
  $walletBlockchain: WalletBlockchain!
  $dappAddress: String!
) {
  beginLogInByTransaction(beginLogInByTransactionInput: {
    walletAddress: $walletAddress
    walletBlockchain: $walletBlockchain
    dappAddress: $dappAddress
  }) {
    ...beginLogInByTransactionFragment
  }
}
`.trim();
const beginLogInByTransactionImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'beginLogInByTransaction');
exports.default = beginLogInByTransactionImpl;
