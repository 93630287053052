import { arrayify } from '@ethersproject/bytes';
import {
  NotifiContext,
  NotifiInputFieldsText,
  NotifiInputSeparators,
  NotifiSubscriptionCard,
} from '@notifi-network/notifi-react-card';
import { providers } from 'ethers';
import React, { useMemo } from 'react';
import { connect } from 'react-redux'

import components from 'components'
import services from 'services'

class Notifi extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notifiDisclaimer: false,
      avvyDisclaimer: false,
    }
  }

  renderWrapped(inner) {
    const darkMode = this.props.darkMode
    return (
      <div className={`m-4 border-gray-200 dark:border-gray-700 border rounded overflow-hidden w-full max-w-md z-20 ${darkMode ? 'bg-gray-900': 'bg-white'}`}>
        {inner}
      </div>
    )
  }

  toggleDisclaimer(key) {
    this.setState({
      [key]: !this.state[key]
    })
  }

  renderDisclaimers() {
    return this.renderWrapped(
      <div className='p-4'>
        <div className=''>
          <p className='font-bold'>Notification Services</p>
          <p>Notifications are delivered by <a className='text-alert-blue' href="https://notifi.network" target="_blank">Notifi.Network</a>. Any information submitted will be collected directly by Notifi Network and will be subject to their <a className='text-alert-blue' href="https://notifi.network/terms" target="_blank">Terms of Service</a> and <a className='text-alert-blue' href="https://notifi.network/privacy" target="_blank">Privacy Policy</a>.</p>
        </div>
        <div className='mt-4'>
          <components.checkbox.Checkbox text={'I understand that notification services are provided by Notifi.Network'} singleLine={true} checked={this.state.acceptedDisclaimers} onCheck={() => this.toggleDisclaimer('acceptedDisclaimers')} />
        </div>
        <div className='mt-4 max-w-sm m-auto'>
          <components.buttons.Button disabled={!this.state.acceptedDisclaimers} text={'Continue'} onClick={() => this.props.acceptDisclaimers()} />
        </div>
      </div>
    )
  }

  renderConnect() {
    return this.renderWrapped(
      <div className='p-4'>
        <p className='font-bold text-center mb-4'>Connect your wallet to manage notifications</p>
        <components.ConnectWalletButton />
      </div>
    )
  }

  render() {
    const props = this.props
    const account = props.account 
    const signer = props.signer

    if (!account || !signer) {
      // account is required
      return this.renderConnect()
    }

    if (!this.props.hasAcceptedNotifiDisclaimers) {
      return this.renderDisclaimers()
    }

    return this.renderWrapped(
      <NotifiContext
        dappAddress={services.environment.NOTIFI_DAPP_ADDRESS}
        env={services.environment.NOTIFI_ENV}
        signMessage={async (message) => {
          const result = await signer.signMessage(message)
          return arrayify(result)
        }}
        walletPublicKey={account}
        walletBlockchain="AVALANCHE"
      >
        <NotifiSubscriptionCard
          cardId={services.environment.NOTIFI_CARD_ID}
          darkMode={this.props.darkMode} 
        />
      </NotifiContext>
    )
  }
}

const mapStateToProps = (state) => ({
  hasAcceptedNotifiDisclaimers: services.user.selectors.hasAcceptedNotifiDisclaimers(state),
})

const mapDispatchToProps = (dispatch) => ({
  acceptDisclaimers: () => dispatch(services.user.actions.acceptNotifiDisclaimer()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifi)
