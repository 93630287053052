import React from 'react'
import { ShoppingBagIcon } from '@heroicons/react/solid'

import components from 'components'
import services from 'services'


class Renew extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      years: 1,
      renewing: false
    }
  }

  renew = () => {
    this.setState({
      renewing: true
    })
    this.props.submit(this.state.years)
  }


  render() {
    return (
      <div className='max-w-xs m-auto'>
        <div className='font-bold'>
          {'Renew all selected domains'}
        </div>
        {this.state.renewing ? (
          <div className='max-w-sm m-auto'> 
            <div className='mb-4'>Preparing renewal</div>
            <div className='mb-4'>
              <components.ProgressBar progress={this.props.bulkRegistrationProgress} />
            </div>
            <components.buttons.Button text={'Continue Renewal'} disabled={this.props.bulkRegistrationProgress < 100} onClick={(navigator) => services.linking.navigate(navigator, 'Register')} />
          </div>
        ) : (
          <>
            <div>
              {'How many years would you like to renew each domain for?'}
            </div>
            <div className='my-2'>
              <components.Input value={this.state.years} onChange={(e) => this.setState({ years: e.target.value })} type="number" step="1" min={1} max={services.environment.MAX_REGISTRATION_QUANTITY} />
            </div>
            <components.buttons.Button text="Submit" onClick={this.renew} />
            <components.buttons.Transparent onClick={this.props.cancel}>
              <div className='cursor-pointer mt-2 text-sm text-center'>Go back</div>
            </components.buttons.Transparent>
          </>
        )}
      </div>
    )
  }
}


class Actions extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      view: 'menu'
    }
  }

  renderRenew() {
    return (
      <Renew 
        bulkRegistrationProgress={this.props.bulkRegistrationProgress}
        submit={(num) => {
          if (this.props.renewAll) {
            this.props.renewAll(parseInt(num))
          }
        }}
        cancel={() => this.setState({ view: 'menu' })} 
      />
    )
  }

  renderMenu() {
    return (
      <div className='max-w-xs m-auto'>
        <div className='mb-4'>
          {'These actions will apply to all of the domains that you have applied filters for, excluding any hidden domains.'}
        </div>
        <div className='flex dark:bg-gray-700 bg-gray-100 py-2 px-4 items-center justify-between mt-2 cursor-pointer rounded' onClick={() => this.setState({
          view: 'renew'
        })}>
          <ShoppingBagIcon className='w-4 md:mr-2' />
          <div className='w-full text-left'>
            {'Renew all'}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.view === 'menu') return this.renderMenu()
    if (this.state.view === 'renew') return this.renderRenew()
    return null
  }
}

export default Actions
