"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeRequest = exports.makeParameterLessRequest = exports.makeAuthenticatedRequest = void 0;
const types_1 = require("../types");
const makeRequestInternal = async (query, resultKey, axiosInstance, variables, config) => {
    var _a;
    const { data } = await axiosInstance.post(`/${encodeURIComponent(resultKey)}`, {
        query,
        variables,
    }, config);
    const result = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a[resultKey];
    const errors = data === null || data === void 0 ? void 0 : data.errors;
    if (result != null) {
        return result;
    }
    if (errors != null) {
        throw new types_1.GqlError(resultKey, errors);
    }
    throw new Error('Unknown error in makeRequest');
};
const makeAuthenticatedRequest = (query, resultKey) => {
    return (axiosInstance, jwt, variables) => makeRequestInternal(query, resultKey, axiosInstance, variables, {
        headers: { Authorization: `Bearer ${jwt}` },
    });
};
exports.makeAuthenticatedRequest = makeAuthenticatedRequest;
const makeRequest = (query, resultKey) => {
    return (axiosInstance, variables) => makeRequestInternal(query, resultKey, axiosInstance, variables);
};
exports.makeRequest = makeRequest;
const makeParameterLessRequest = (query, resultKey) => {
    return (axiosInstance) => makeRequestInternal(query, resultKey, axiosInstance, undefined);
};
exports.makeParameterLessRequest = makeParameterLessRequest;
