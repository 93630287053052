"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.beginLogInByTransactionFragmentDependencies = exports.beginLogInByTransactionFragment = void 0;
exports.beginLogInByTransactionFragment = `
fragment beginLogInByTransactionFragment on BeginLogInByTransactionResult {
  nonce
}
`.trim();
exports.beginLogInByTransactionFragmentDependencies = [
    exports.beginLogInByTransactionFragment,
];
