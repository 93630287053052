import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { SearchIcon } from '@heroicons/react/solid'
import { ArrowLeftIcon } from '@heroicons/react/outline' 
import { useNavigate } from 'react-router-dom'

import components from 'components'
import services from 'services'

function DomainSearch(props) {
  let navigator = useNavigate()
  let onBeforeSubmit = props.onBeforeSubmit
  let textInput = React.createRef()

  if (props.ctrl) {
    props.ctrl({
      focus: () => { if (textInput.current) textInput.current.focus() },
      blur: () => { if (textInput.current) textInput.current.blur() },
    })
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()
    if (onBeforeSubmit) onBeforeSubmit()
    let domain = textInput.current.value.toLowerCase()
    textInput.current.value = ''
    const domainSplit = domain.split('.')
    if (domainSplit.length === 1) {
      domain = domain + '.avax'
    }
    props.handleSubmit(navigator, domain)
  }
  
  return (
    <div className='bg-gray-100 rounded-xl w-full text-center relative dark:bg-gray-800'>
      <form onSubmit={handleSubmit}>
        <input autoComplete="off" ref={textInput} autoCapitalize="off" placeholder={props.placeholder || 'Search domain names'} className='bg-transparent w-full placeholder:text-gray-400 text-black dark:text-gray-300 text-center p-4' />
      </form>
      <div className='absolute right-0 top-0 h-full flex items-center justify-center mr-4 cursor-pointer' onClick={handleSubmit}>
        <SearchIcon className='w-6 text-gray-300' />
      </div>
    </div>
  )
}

class Send extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      domain: null,
      evmAddress: null,
      loading: props.domainProvided || false,
      connected: services.provider.isConnected(),
    }

    if (props.domainProvided) this.getDomainFromPath()
  }

  componentDidMount() {
    services.provider.addEventListener(services.provider.EVENTS.CONNECTED, this.onConnected)
    services.provider.addEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnected)
  }

  componentWillUnmount() {
    services.provider.removeEventListener(services.provider.EVENTS.CONNECTED, this.onConnected)
    services.provider.removeEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnected)
  }

  onConnected = () => {
    this.setState({
      connected: true
    })
    if (this.connectWalletModal) {
      this.connectWalletModal.hide()
    }
  }

  onDisconnected = () => {
    this.setState({
      connected: false
    })
  }

  getDomainFromPath = () => {
    const params = services.linking.getParams('SendDomain')
    this.loadDomain(params.domain)
  }

  loadDomain = async (domain) => {
    const api = await services.provider.buildAPI()
    const evmAddress = await api.getStandardRecord(domain, 3)
    this.setState({
      domain,
      evmAddress,
      loading: false,
    })
  }

  handleDomain = async (navigator, domain) => {
    services.linking.navigate(navigator, 'SendDomain', { domain })
    this.setState({
      loading: true
    }, () => this.loadDomain(domain))
  }

  handleBack = async (navigator) => {
    services.linking.navigate(navigator, 'Send')
    this.setState({
      domain: null,
      evmAddress: null
    })
  }

  render() {
    return (
      <div className='max-w-xl m-auto'>
        <div className='flex justify-between m-auto items-center'>
          <components.buttons.Transparent onClick={this.handleBack}>
            <ArrowLeftIcon className={`w-6 ${this.state.domain && this.state.evmAddress ? 'cursor-pointer' : 'invisible pointer-events-none'}`} />
          </components.buttons.Transparent>
          <div className='mb-4 font-bold text-center mt-4 text-lg'>{'Send Transfer'}</div>

          {/* placeholder for spacing */}
          <components.buttons.Transparent onClick={() => null}>
            <ArrowLeftIcon className={`w-6 cursor-pointer invisible`} />
          </components.buttons.Transparent>
          {/* placeholder for spacing */}
        </div>
        <div className='pb-4'>
          {this.state.loading ? (
            <div className='text-center'>
              <components.Spinner.Connected />
            </div>
          ) : this.state.evmAddress ? (
            <>
              <div className='text-center mb-4'>
                <div className='mb-2'>{`Send tokens and collectibles to ${this.state.domain}`}</div>
                <div className='text-xs'>({this.state.evmAddress})</div>
              </div>
            </>
          ) : (
            <>
              <div className='text-center mb-4'>
                {'Send tokens or collectibles to a .avax domain.'}
                {this.state.domain ? (
                  <div className='mb-4 text-red-500 text-sm text-center'>We could not find an address for {this.state.domain}</div>
                ) : null}
              </div>
              <DomainSearch handleSubmit={(navigator, domain) => this.handleDomain(navigator, domain)} />
            </>
          )}
        </div>
        {this.state.evmAddress ? (
          <div className='rounded border-gray-100 dark:border-gray-700 border-2 p-4'>
	          {this.state.connected ? (
              <components.Transfer domain={this.state.domain} address={this.state.evmAddress} /* onClose={() => null} */ />
            ) : (
              <components.ConnectWalletButton text="Connect Wallet to Transfer" />
            )}
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  injectSentry: services.analytics.selectors.injectSentry(state),
})

const mapDispatchToProps = (dispatch) => ({
  setInjectSentry: (val) => dispatch(services.analytics.actions.injectSentry(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Send)
