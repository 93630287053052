"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sourceFragmentDependencies = exports.sourceFragment = void 0;
const filterFragment_1 = require("./filterFragment");
exports.sourceFragment = `
fragment sourceFragment on Source {
  id
  name
  type
  blockchainAddress
  fusionEventTypeId
  applicableFilters {
    ...filterFragment
  }
}
`.trim();
exports.sourceFragmentDependencies = [
    ...filterFragment_1.filterFragmentDependencies,
    filterFragment_1.filterFragment,
];
