"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const convMessagePageInfoFragment_1 = require("../fragments/convMessagePageInfoFragment");
const conversationMessageFragment_1 = require("../fragments/conversationMessageFragment");
const DEPENDENCIES = [
    ...conversationMessageFragment_1.conversationMessageFragmentDependencies,
    convMessagePageInfoFragment_1.ConvMessagePageInfoFragment,
    conversationMessageFragment_1.conversationMessageFragment,
];
const QUERY = `query conversationMessages(
  $getConversationMessagesInput: GetConversationMessagesInput!
  $after: String
  $first: Int
) {
  conversationMessages(
    first: $first
    after: $after
    getConversationMessagesInput: $getConversationMessagesInput
  ) {
    nodes {
      ...ConversationMessage
    }
    pageInfo {
      ...ConvMessagePageInfo
    }
  }
}`.trim();
const getConversationMessagesImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'conversationMessages');
exports.default = getConversationMessagesImpl;
