import reduxService from 'services/redux'

const constants = reduxService.prepareConstants(
  'services/referrals',
  [
    'SET_REFERRAL_CODE', 
  ]
)

export default constants
