"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.emailTargetFragmentDependencies, fragments_1.emailTargetFragment];
const MUTATION = `
mutation sendEmailTargetVerificationRequest(
  $targetId: String!
) {
  sendEmailTargetVerificationRequest(sendTargetConfirmationRequestInput:{
    targetId: $targetId
  }) {
    ...emailTargetFragment
  }
}
`.trim();
const sendEmailTargetVerificationRequestImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'sendEmailTargetVerificationRequest');
exports.default = sendEmailTargetVerificationRequestImpl;
