"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.telegramTargetFragmentDependencies = exports.telegramTargetFragment = void 0;
exports.telegramTargetFragment = `
fragment telegramTargetFragment on TelegramTarget {
  id
  isConfirmed
  name
  telegramId
  confirmationUrl
}
`.trim();
exports.telegramTargetFragmentDependencies = [];
