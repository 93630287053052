"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.userTopicFragmentDependencies, fragments_1.userTopicFragment];
const QUERY = `
query getTopics {
  topics {
    nodes {
      ...userTopicFragment
    }
  }
}
`.trim();
const getTopicsConnection = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'topics');
const getTopicsImpl = (axiosInstance) => getTopicsConnection(axiosInstance).then((result) => result.nodes);
exports.default = getTopicsImpl;
