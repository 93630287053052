"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.alertFragmentDependencies, fragments_1.alertFragment];
const MUTATION = `
mutation createAlert(
  $name: String!
  $sourceGroupId: String!
  $filterId: String!
  $targetGroupId: String!
  $filterOptions: String!
  $groupName: String!
) {
  createAlert(
    alertInput: {
      name: $name
      sourceGroupId: $sourceGroupId
      filterId: $filterId
      targetGroupId: $targetGroupId
      filterOptions: $filterOptions
      groupName: $groupName
    }
  ) {
    ...alertFragment
  }
}
`.trim();
const createAlertImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'createAlert');
exports.default = createAlertImpl;
