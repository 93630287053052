import services from 'services'
import { reducerName } from './reducer'

const root = (state) => state[reducerName]

const selectFrom = (state, key) => {
  const _fr = root(state).selectFrom
  if (_fr === 'view') return root(state)[key]
  else return services.user.selectors[key](state)
}

const selectors = {
  domainIds: (state) => selectFrom(state, 'domainIds'),
  domainCount: (state) => selectFrom(state, 'domainCount'),
  loadedDomainCount: (state) => selectFrom(state, 'loadedDomainCount'),
  expiries: (state) => selectFrom(state, 'expiries'),

  hasLoadedDomains: (state) => root(state).hasLoadedDomains,
}

export default selectors
