"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const DEPENDENCIES = [];
const MUTATION = `
mutation createSupportConversation {
  createSupportConversation {
    id
    conversationType
    conversationGates {
       id
    }
    name
    createdDate
    participants {
      conversationParticipantType
      profile {
        id
        preferredAddress
        preferredName
        avatarData
        avatarDataType
      }
      resolvedName
    }
    backgroundImageUrl
  }
}
`.trim();
const createSupportConversationImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'createSupportConversation');
exports.default = createSupportConversationImpl;
