"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.userFragmentDependencies, fragments_1.userFragment];
const MUTATION = `
mutation completeLogInByTransaction(
  $walletAddress: String!,
  $walletBlockchain: WalletBlockchain!,
  $dappAddress: String!,
  $randomUuid: String!,
  $transactionSignature: String!
) {
  completeLogInByTransaction(completeLogInByTransactionInput: {
    walletAddress: $walletAddress
    walletBlockchain: $walletBlockchain
    dappAddress: $dappAddress
    randomUuid: $randomUuid
    transactionSignature: $transactionSignature
  }) {
    ...userFragment
  }
}
`.trim();
const completeLogInByTransactionImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'completeLogInByTransaction');
exports.default = completeLogInByTransactionImpl;
