import React from 'react'
import { connect } from 'react-redux'
import { RefreshIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
import { CashIcon, PhotographIcon } from '@heroicons/react/outline'
import { ethers } from 'ethers'

import components from 'components'
import actions from './actions'
import constants from './constants'
import reducer from './reducer'
import selectors from './selectors'


class Transfer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      amount: '',
      transferType: null,
      tokenIndex: null,
      showUnknownTokens: false,
      nftIndex: null,
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  send = () => {
    const token = this.erc20Tokens()[this.state.tokenIndex]
    const factor = ethers.FixedNumber.from(ethers.BigNumber.from('10').pow(ethers.BigNumber.from(token.decimals)))
    const fixed = ethers.FixedNumber.from(this.state.amount).mulUnsafe(factor).round()
    const amount = ethers.BigNumber.from(parseInt(fixed.toString()).toString())
    if (token.nativeToken) {
      this.props.send(this.props.address, this.state.amount)
    } else {
      this.props.sendERC20(this.props.address, amount, token.address)
    }
  }

  sendNFT = () => {
    const token = this.erc721Tokens()[this.state.nftIndex]
    this.props.sendERC721(this.props.address, token.token_address, token.token_id)
  }

  close = () => {
    this.setState({
      transferType: null,
      tokenIndex: null,
      nftIndex: null,
      amount: null,
    })
    this.props.reset()
    this.props.onClose()
  }

  erc20Tokens = () => {
    if (!this.props.tokens) return []
    let tokens = this.props.tokens.erc20
      .map(t => {
        t.value = Math.round(parseInt(ethers.BigNumber.from(t.balance).div(ethers.BigNumber.from('10').pow(ethers.BigNumber.from(t.decimals)))) * t.quote * 100) / 100
        return t
      })
      .filter(t => t.balance !== '0' && t.decimals && t.name && t.symbol && t.token_address)
      .filter(t => t.known_token || this.state.showUnknownTokens)
      .sort((a, b) => {
        if (a.value > b.value) return -1
        if (b.value > a.value) return 1
        return 0
      }).map((t, i) => {
        let balanceDisplay
        try {
          balanceDisplay = (parseInt(t.balance) / Math.pow(10, t.decimals)).toString()
        } catch (err) {
          balanceDisplay = ethers.BigNumber.from(t.balance).div(ethers.BigNumber.from('10').pow(ethers.BigNumber.from(t.decimals))).toString()
        }
        return {
          value: t.value,
          symbol: t.symbol,
          address: t.token_address,
          balance: t.balance,
          balanceDisplay,
          decimals: t.decimals,
          name: t.name,
          nativeToken: t.native_token,
          index: i
        }
      })
    return tokens
  }

  erc721Tokens = () => {
    if (!this.props.tokens) return []
    return this.props.tokens.erc721.filter(t => t.contract_type === 'ERC721')
  }

  renderTokenTransferAmount = () => {
    const token = this.erc20Tokens()[this.state.tokenIndex]
    return (
      <div className='max-w-md m-auto'>
        <div className='text-center mb-4'>
          {`Send ${token.name} (${token.symbol}) to ${this.props.domain}`}
        </div>
        <div className='text-center mb-4 text-sm flex sm:flex-row flex-col items-center justify-center'>
          <div>Balance: {token.balanceDisplay} {token.symbol}</div>
          <div className='hidden sm:block text-gray-500'>・</div>
          <div>Value: ${token.value}</div>
        </div>
        <components.Input type="number" placeholder="Amount to send" onChange={(e) => this.setState({ amount: e.target.value })} />
        <components.buttons.Button className='mt-4' sm text='Send' loading={this.props.isLoading} onClick={() => this.send()} />
        <components.buttons.Transparent onClick={() => this.setState({ tokenIndex: null })}>
          <div className='text-center cursor-pointer text-sm text-gray-500 mt-4'>{'Go back'}</div>
        </components.buttons.Transparent>
      </div>
    )
  }

  renderTokenTransfer = () => {
    return (
      <div className='max-w-md m-auto'>
        {this.props.loadTokenError ? (
          <div className='m-auto flex flex-col items-center justify-center'>
            <components.labels.Error text={'Failed to load token list'} />
          </div>
        ) : this.props.isLoadingTokens ? (
          <div className='m-auto flex flex-col items-center justify-center'>
            <div className='mb-4'>{'Loading token list'}</div>
            <components.Spinner.Connected />
          </div>
        ) : (
          <>
            <div className='m-auto w-full sm:hidden'>
              {this.erc20Tokens().map((token, index) => {
                return (
                  <div className='cursor-pointer bg-gray-100 dark:bg-gray-800 rounded p-2 mb-2' onClick={() => this.setState({ tokenIndex: index })} key={index}>
                    <div className='font-bold'>{token.symbol}</div>
                    <div className='text-sm'>Balance: {token.balanceDisplay}</div>
                    <div className='text-sm'>Value: ${token.value}</div>
                  </div>
                )
              })}
            </div>
            <div className='m-auto max-w-md sm:block hidden'>
              <table className='w-full'>
                <tr className=''>
                  <td className='p-2 underline'>Token</td>
                  <td className='p-2 underline'>Balance</td>
                  <td className='p-2 underline'>Estimated Value</td>
                  <td></td>
                </tr>
              {this.erc20Tokens().map((token, index) => {
                return (
                  <tr className='rounded hover:bg-gray-100 dark:hover:bg-gray-800 border-solid border-t-1 border-gray-800 p-2 cursor-pointer' onClick={() => this.setState({ tokenIndex: index })} key={index}>
                    <td className='p-2'>{token.symbol}</td>
                    <td className='p-2'>{token.balanceDisplay}</td>
                    <td className='p-2'>${token.value}</td>
                    <td className='p-2'>
                      <ArrowRightIcon className='w-4' />
                    </td>
                  </tr>
                )
              })}
              </table>
            </div>
          </>
        )}
        <div className='flex items-center w-full justify-center mt-4'>
          <components.buttons.Transparent onClick={() => this.setState({ transferType: null })}>
            <div className='text-center cursor-pointer text-sm text-gray-500'>{'Go back'}</div>
          </components.buttons.Transparent>
          {this.props.loadTokenError ? (
            <>
              <div className='text-gray-500'>・</div>
              <components.buttons.Transparent onClick={() => this.props.loadTokens()}>
                <div className='text-center cursor-pointer text-sm text-gray-500'>{'Retry'}</div>
              </components.buttons.Transparent>
            </>
          ) : this.props.isLoadingTokens ? null : (
            <>
              <div className='text-gray-500'>・</div>
              <components.buttons.Transparent onClick={() => this.props.loadTokens()}>
                <div className='text-center cursor-pointer text-sm text-gray-500'>{'Refresh'}</div>
              </components.buttons.Transparent>
              <div className='text-gray-500'>・</div>
              <components.buttons.Transparent onClick={() => this.setState((currState) => { return { showUnknownTokens: !currState.showUnknownTokens } })}>
                <div className='text-center cursor-pointer text-sm text-gray-500'>{this.state.showUnknownTokens ? 'Hide unknown tokens' : 'Show unknown tokens'}</div>
              </components.buttons.Transparent>
            </>
          )}
        </div>
      </div>
    )
  }

  renderNFTTransferFinal = () => {
    const token = this.erc721Tokens()[this.state.nftIndex]
    return (
      <>
        <div className='flex items-center text-sm font-bold'>
          <div className='flex items-center mb-4 cursor-pointer' onClick={() => this.setState({ nftIndex: null })}>
            <div className='w-4 mr-2'>
              <ArrowLeftIcon />
            </div>
            <div className='text-xs'>Go Back</div>
          </div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-3'>
          <components.ERC721Token tokenUri={token.token_uri} collectionName={token.name} metadata={token.metadata || null} imageOnly={true} />
          <div className='col-span-1 sm:col-span-2 pt-4 sm:pt-0 sm:pl-4 h-full w-full flex items-stretch justify-center flex-col'>
            <div className='p-4 border border-gray-200 dark:border-gray-800 rounded-xl h-full flex flex-col justify-between'>
              <div>
                <div className='text-2xl font-bold'>
                  Send Collectible
                </div>
                <div className='text-gray-500'>
                  Verify the details to complete your transfer.
                </div>
                <div className='font-bold mt-4'>
                  Recipient
                </div>
                <div className='text-gray-500'>
                  {this.props.domain}
                </div>
                <div className='font-bold mt-4'>
                  Collection
                </div>
                <div className='text-gray-500'>
                  {token.name} 
                </div>
              </div>
              <components.buttons.Button className='mt-4' text='Send' loading={this.props.isLoading} onClick={() => this.sendNFT()} />
            </div>
          </div>
        </div>
      </>
    )
  }

  renderNFTTransfer = () => {
    return (
      <>
        {this.props.loadTokenError ? (
          <div className='max-w-md m-auto'>
            <div className='m-auto flex flex-col items-center justify-center'>
              <components.labels.Error text={'Failed to load tokens'} />
            </div>
          </div>
        ) : this.props.isLoadingTokens ? (
          <div className='max-w-md m-auto'>
            <div className='m-auto flex flex-col items-center justify-center'>
              <div className='mb-4'>{'Loading NFTs'}</div>
              <components.Spinner.Connected />
            </div>
          </div>
        ) : (
          <>
            <div className='w-full flex justify-between items-center'>
              <div className='flex items-center text-sm font-bold'>
                <div className='flex items-center mb-4 cursor-pointer' onClick={() => this.setState({ transferType: null })}>
                  <div className='w-4 mr-2'>
                    <ArrowLeftIcon />
                  </div>
                  <div className='text-xs'>Go Back</div>
                </div>
              </div>
              <div className='flex items-center text-sm font-bold'>
                <div className='flex items-center mb-4 cursor-pointer' onClick={() => this.props.loadTokens()}>
                  <div className='w-4 mr-2'>
                    <RefreshIcon />
                  </div>
                  <div className='text-xs'>Refresh</div>
                </div>
              </div>
            </div>
            <div className='m-auto w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
              {this.erc721Tokens().map((token, index) => {
                return (
                  <div className='cursor-pointer' onClick={() => this.setState({
                    nftIndex: index,
                  })}>
                    <components.ERC721Token key={index} tokenUri={token.token_uri} collectionName={token.name} metadata={token.metadata || null} />
                  </div>
                )
              })}
            </div>
          </>
        )}
      </>
    )
  }

  setTransferType = (transferType) => {
    if (!this.props.tokens) {
      this.props.loadTokens()
    }
    this.setState({
      transferType
    })
  }

  renderSelectTransferType = () => {
    return (
      <>
        <div className='grid gap-2 grid-cols-1 md:grid-cols-2 w-full'>
          <div onClick={() => this.setTransferType('token')} className={`cursor-pointer bg-gray-100 dark:bg-gray-800 p-4 rounded-xl flex flex-col items-center justify-center py-12`}>
            <div className='w-16 text-gray-500'>
              <CashIcon />
            </div>
            <div className='font-bold flex items-center justify-center text-center mt-4'><div className=''>{'Send Tokens'}</div></div>
          </div>
          <div onClick={() => this.setTransferType('erc721')} className={`py-12 cursor-pointer bg-gray-100 dark:bg-gray-800 p-4 rounded-xl flex flex-col items-center justify-center`}>
            <div className='w-16 text-gray-500'>
              <PhotographIcon />
            </div>
            <div className='font-bold flex items-center justify-center text-center mt-4'><div className=''>{'Send Collectibles'}</div></div>
          </div>
        </div>
      </>
    )
  }

  render() {
    if (this.props.isComplete) return (
      <div className='max-w-md m-auto'>
        <components.labels.Success text='Transfer complete' />
        <components.buttons.Button className='mt-4' sm text='Close' onClick={() => this.close()} />
      </div>
    )
    if (this.state.transferType === null) return this.renderSelectTransferType()
    if (this.state.transferType === 'token' && this.state.tokenIndex !== null) return this.renderTokenTransferAmount()
    if (this.state.transferType === 'token') return this.renderTokenTransfer()
    if (this.state.transferType === 'erc721' && this.state.nftIndex !== null) return this.renderNFTTransferFinal()
    if (this.state.transferType === 'erc721') return this.renderNFTTransfer()
  }
}

const mapStateToProps = (state) => ({
  isLoading: selectors.isLoading(state),
  isComplete: selectors.isComplete(state),
  isLoadingTokens: selectors.isLoadingTokens(state),
  tokens: selectors.tokens(state),
  loadTokenError: selectors.loadTokenError(state),
})

const mapDispatchToProps = (dispatch) => ({
  send: (address, amount) => dispatch(actions.send(address, amount)),
  sendERC20: (address, amount, tokenAddress) => dispatch(actions.sendERC20(address, amount, tokenAddress)),
  sendERC721: (address, contract, tokenId) => dispatch(actions.sendERC721(address, contract, tokenId)),
  reset: () => dispatch(actions.reset()),
  loadTokens: () => dispatch(actions.loadTokens()),
})

const exports = connect(mapStateToProps, mapDispatchToProps)(Transfer)
exports.redux = {
  actions,
  constants,
  reducer,
  selectors
}

export default exports
