"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.userFragmentDependencies, fragments_1.userFragment];
const MUTATION = `
mutation logInFromDapp(
  $walletPublicKey: String!
  $dappAddress: String!
  $timestamp: Long!
  $signature: String!
  $walletBlockchain: WalletBlockchain
  $accountId: String
) {
  logInFromDapp(dappLogInInput: {
    walletPublicKey: $walletPublicKey
    dappAddress: $dappAddress
    timestamp: $timestamp
    walletBlockchain: $walletBlockchain
    accountId: $accountId
  }, signature: $signature) {
    ...userFragment
  }
}
`.trim();
const logInFromDaoImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'logInFromDapp');
exports.default = logInFromDaoImpl;
