"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [
    ...fragments_1.telegramTargetFragmentDependencies,
    fragments_1.telegramTargetFragment,
];
const QUERY = `
query getTelegramTargets {
  telegramTarget {
    ...telegramTargetFragment
  }
}
`.trim();
const getTelegramTargetsImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'telegramTarget');
exports.default = getTelegramTargetsImpl;
