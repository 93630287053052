import reduxService from 'services/redux'

const constants = reduxService.prepareConstants(
  'components/Transfer',
  [
    // sending transfers
    'SET_LOADING',
    'SET_COMPLETE',

    // loading tokens
    'SET_LOADING_TOKENS',
    'SET_TOKENS',
    'SET_LOAD_TOKEN_ERROR',
  ]
)

export default constants
