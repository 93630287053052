import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import selectors from './selectors'

import components from 'components'
import services from 'services'


class SetResolver extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      type: null,
      customAddress: null,
      customDatasetId: null,
      datasetId: null,
      resolverOptions: []
    }
    this.nullAddress = '0x0000000000000000000000000000000000000000'
  }

  componentDidMount() {
    this.setResolverOptions()
  }

  componentDidUpdate(prevProps) {
    if (this.props.resolver !== prevProps.resolver) {
      this.consumeProps()
    }
  }

  _getResolverType = async () => {
    const resolver = this._getResolver()
    const api = await services.provider.buildAPI()
    const defaultResolverAddress = await api.getDefaultResolverAddress()
    if (resolver === 'NONE') return 'NONE'
    if (resolver.toLowerCase() === defaultResolverAddress.toLowerCase()) return defaultResolverAddress.toLowerCase()
    return 'CUSTOM'
  }

  consumeProps = async () => {
    const resolver = this._getResolver()
    const type = await this._getResolverType()
    this.setState({
      type,
      customAddress: type === 'CUSTOM' ? resolver.toLowerCase() : null,
      customDatasetId: type === 'CUSTOM' ? this.props.resolver.datasetId : null,
    })
  }

  setResolverOptions = async () => {
    const api = await services.provider.buildAPI()
    const defaultResolverAddress = await api.getDefaultResolverAddress()
    this.setState({
      resolverOptions: [
        { name: 'None', value: 'NONE'},
        { name: 'Default Resolver', value: defaultResolverAddress.toLowerCase() },
        { name: 'Custom Resolver', value: 'CUSTOM' },
      ]
    })
  }

  submit = async () => {
    let address = this.state.type
    let datasetId = undefined
    if (address === 'NONE') address = this.nullAddress
    if (address === 'CUSTOM') {
      address = this.state.customAddress
      datasetId = this.state.customDatasetId
    }
    this.props.setResolver(this.props.domain, address, datasetId)
  }

  _getResolver = () => {
    return this.props.resolver ? this.props.resolver.resolver.toLowerCase() : 'NONE'
  }

  render() {
    if (this.props.complete) return (
      <>
        <div className='max-w-md m-auto'>
          <components.labels.Success text={'Resolver has been updated'} />
        </div>
        <div className='max-w-md m-auto mt-4'>
          <components.buttons.Button text={'Close'} onClick={() => this.props.onComplete()} />
        </div>
      </>
    )

    const initial = this._getResolver()
    const type = this.state.type

    return (
      <>
        <div className='max-w-md m-auto'>
          <div className='font-bold mb-2 mt-4'>
            Type
          </div>
          <components.Select initial={type} options={this.state.resolverOptions} ref={(ref) => {
            if (ref) {
              ref.addEventListener('change', (e) => {
                if (ref) {
                  this.setState({
                    type: ref.getValue(),
                  })
                }
              })
            }
          }} />
          {this.state.type === 'CUSTOM' ? (
            <div>
              <div>
                <div className='font-bold mt-4 mb-2'>
                  Address
                </div>
                <input type='text' value={this.state.customAddress} className='bg-gray-100 dark:bg-gray-800 w-full rounded-xl px-4 py-2' onChange={(e) => this.setState({ customAddress: e.target.value })} />
              </div>
              <div>
                <div className='font-bold mt-4 mb-2'>
                  Dataset ID
                </div>
                <input type='text' value={this.state.customDatasetId} className='bg-gray-100 dark:bg-gray-800 w-full rounded-xl px-4 py-2' onChange={(e) => this.setState({ customDatasetId: e.target.value })} />
              </div>
            </div>
          ) : null}
          <div className='mt-8'>
            <components.buttons.Button sm={true} text={'Submit'} onClick={() => this.submit()} loading={this.props.loading} />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: selectors.setResolverLoading(state),
  complete: selectors.setResolverComplete(state),
  resolver: selectors.resolver(state),
})

const mapDispatchToProps = (dispatch) => ({
  setResolver: (domain, address, datasetId) => dispatch(actions.setResolver(domain, address, datasetId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SetResolver)
