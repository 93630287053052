import constants from './constants'

export const reducerName = 'transferComponent'

export const initialState = {
  
  // sending transfer
  isLoading: false,
  isComplete: false,

  // erc20 / erc721
  isLoadingTokens: false,
  tokens: null,
  loadTokenError: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }

    case constants.SET_COMPLETE:
      return {
        ...state,
        isComplete: action.isComplete
      }

    case constants.SET_LOADING_TOKENS:
      return {
        ...state,
        isLoadingTokens: action.isLoading,
      }

    case constants.SET_TOKENS:
      return {
        ...state,
        tokens: action.tokens,
      }

    case constants.SET_LOAD_TOKEN_ERROR:
      return {
        ...state,
        loadTokenError: action.error
      }

    default:
      return state
  }
}

const exports = {
  reducer, 
  reducerName,
  initialState,
}

export default exports
