import React from 'react'
import components from 'components'
import services from 'services'
import { DuplicateIcon, CheckIcon } from '@heroicons/react/solid'


class DataExplorer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      valueCopied: false,
      avvy: null
    }
    this.getAvvy()

    this.vendors = {
      
      // explorers
      arkham: {
        name: 'Arkham',
        logo: services.linking.static('images/vendor/arkham.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      avascan: { 
        name: 'Avascan',
        logo: services.linking.static('images/vendor/avascan.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      lore: {
        name: 'Lore',
        logo: services.linking.static('images/vendor/lore.png'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      metasleuth: {
        name: 'MetaSleuth',
        logo: services.linking.static('images/vendor/metasleuth.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      'avalanche-subnet-explorer': {
        name: 'Subnet Explorer',
        logo: services.linking.static('images/vendor/avalanche-subnet-explorer.png'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      snowtrace: {
        name: 'Snowtrace', 
        logo: services.linking.static('images/vendor/snowtrace.png'),
        class: 'h-12 w-12',
      },
      vscout: {
        name: 'VScout', 
        logo: services.linking.static('images/vendor/vscout.svg'),
        class: 'h-12 w-12',
      },
      core: {
        name: 'Core', 
        logo: services.linking.static('images/vendor/coreapp.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      explcom: {
        name: '3xpl', 
        logo: services.linking.static('images/vendor/3xpl.png'),
        class: 'h-12 w-12',
      },
      nftscan: {
        name: 'NFTScan', 
        logo: services.linking.static('images/vendor/nftscan.png'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      oklink: {
        name: 'OKLink', 
        logo: services.linking.static('images/vendor/oklink.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      snowpeer: {
        name: 'snowpeer', 
        logo: services.linking.static('images/vendor/snowpeer.png'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      revokecash: {
        name: 'Revoke.cash', 
        logo: services.linking.static('images/vendor/revokecash.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      debank: {
        name: 'DeBank', 
        logo: services.linking.static('images/vendor/debank.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      watchers: {
        name: 'Watchers AI',
        logo: services.linking.static('images/vendor/watchers.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden border border-gray-300',
      },

      // dns & ip
      dnslookup: {
        name: 'DNS-Lookup',
        logo: services.linking.static('images/vendor/dns-lookup.png'),
        class: 'h-12 w-12',
      },
      ipinfo: {
        name: 'ipinfo.io',
        logo: services.linking.static('images/vendor/ipinfo-io.png'),
        class: 'h-12 w-12',
      },

      // ipfs
      ipfs: {
        name: 'ipfs.io',
        logo: services.linking.static('images/vendor/ipfs.png'),
        class: 'h-12 w-12',
      },
      cloudflare: {
        name: 'Cloudflare',
        logo: services.linking.static('images/vendor/cloudflare.svg'),
        class: 'h-12 w-12',
      },

      // nft marketplaces
      joepegs: {
        name: 'Joepegs',
        logo: services.linking.static('images/vendor/joepegs.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      opensea: {
        name: 'OpenSea',
        logo: services.linking.static('images/vendor/opensea.png'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      campfire: {
        name: 'Campfire',
        logo: services.linking.static('images/vendor/campfire.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      salvor: {
        name: 'Salvor',
        logo: services.linking.static('images/vendor/salvor.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      hyperspace: {
        name: 'Hyperspace',
        logo: services.linking.static('images/vendor/hyperspace.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
      nftrade: {
        name: 'NFTrade',
        logo: services.linking.static('images/vendor/nftrade.jpg'),
        class: 'h-12 w-12 rounded overflow-hidden',
      },
    }
  }

  async getAvvy() {
    const api = await services.provider.buildAPI()
    this.avvy = api.avvy
    this.setState({
      avvy: this.avvy
    })
  }

  renderInternalLink(routeName, getParams) {
    const vendor = {
      name: 'Avvy',
      logo: services.linking.static('images/vendor/avvy.png'),
      class: 'h-12 w-12 rounded overflow-hidden',
    }

    const routeParams = getParams(this.props.data.data)

    return (
      <components.buttons.Transparent onClick={(navigate) => services.linking.navigate(navigate, routeName, routeParams)}>
        <div className={`cursor-pointer flex flex-col items-center justify-center rounded-xl m-auto bg-gray-100 dark:bg-gray-800 w-full h-32`}>
          <div className={`${vendor.class} flex items-center justify-center`}>
            <img src={vendor.logo} alt={vendor.name} className='w-full' />
          </div>
          <div className='mt-2'>
            {vendor.name}
          </div>
        </div>
      </components.buttons.Transparent>
    )
  }

  renderVendor(key, getLink, params) {
    const vendor = this.vendors[key]
    const link = getLink(this.props.data.data)
    const target = params?.target || '_blank'
    return (
      <a target={target} rel="noreferrer" href={link} className={`cursor-pointer flex flex-col items-center justify-center rounded-xl m-auto bg-gray-100 dark:bg-gray-800 w-full h-32`}>
        <div className={`${vendor.class} flex items-center justify-center`}>
          <img src={vendor.logo} alt={vendor.name} className='w-full' />
        </div>
        <div className='mt-2'>
          {vendor.name}
        </div>
      </a>
    )
  }

  renderXChain() {
    return (
      <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-4'>
        {this.renderVendor('avascan', (d) => `https://avascan.info/blockchain/x/address/${d}`)}
      </div>
    )
  }

  renderPChain() {
    return (
      <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-4'>
        {this.renderVendor('avascan', (d) => `https://avascan.info/blockchain/p/address/${d}`)}
        {this.renderVendor('vscout', (d) => `https://vscout.io/address/${d}`)}
      </div>
    )
  }

  renderEVM() {
    return (
      <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-4'>
        {this.renderVendor('arkham', (d) => `https://platform.arkhamintelligence.com/explorer/address/${d}`)}
        {this.renderVendor('avalanche-subnet-explorer', (d) => `https://subnets.avax.network/c-chain/address/${d}`)}
        {this.renderVendor('avascan', (d) => `https://avascan.info/blockchain/c/address/${d}`)}
        {this.renderVendor('campfire', (d) => `https://campfire.exchange/accounts/${d}`)}
        {this.renderVendor('core', (d) => `https://core.app/account/${d}`)}
        {this.renderVendor('debank', (d) => `https://debank.com/profile/${d}`)}
        {this.renderVendor('explcom', (d) => `https://3xpl.com/search?q=${d}`)}
        {this.renderVendor('hyperspace', (d) => `https://avax.hyperspace.xyz/wallet/avax/${d}`)}
        {this.renderVendor('joepegs', (d) => `https://joepegs.com/profile/${d}`)}
        {this.renderVendor('lore', (d) => `https://skilift.io/v1/${d}`)}
        {this.renderVendor('metasleuth', (d) => `https://metasleuth.io/result/avalanche/${d}`)}
        {this.renderVendor('nftscan', (d) => `https://portfolio.nftscan.com/account/${d}`)}
        {this.renderVendor('nftrade', (d) => `https://nftrade.com/users/avalanche/${d}`)}
        {this.renderVendor('oklink', (d) => `https://www.oklink.com/middle/multi-search#key=${d}`)}
        {this.renderVendor('opensea', (d) => `https://opensea.io/${d}`)}
        {this.renderVendor('revokecash', (d) => `https://revoke.cash/address/${d}?chainId=43114`)}
        {this.renderVendor('salvor', (d) => `https://salvor.io/${d}`)}
        {this.renderVendor('snowtrace', (d) => `https://snowtrace.io/address/${d}`)}
        {this.renderVendor('watchers', (d) => `https://www.watchers.ai/address/${d}`)}
      </div>
    )
  }

  renderValidator() {
    return (
      <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-4'>
        {this.renderVendor('avascan', (d) => `https://avascan.info/staking/validator/${d}`)}
        {this.renderVendor('vscout', (d) => `https://vscout.io/validator/${d}`)}
        {this.renderVendor('avalanche-subnet-explorer', (d) => `https://subnets.avax.network/validators/${d}`)}
        {this.renderVendor('snowpeer', (d) => `https://alpha.snowpeer.io/validators?mode=PERFORMANCE&nodeID=${d}&network=mainnet`)}
      </div>
    )
  }

  renderDNS_A() {
    return (
      <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-4'>
        {this.renderVendor('ipinfo', (d) => `https://ipinfo.io/${d}`)}
      </div>
    )
  }

  renderDNS_CNAME() {
    return (
      <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-4'>
        {this.renderVendor('dnslookup', (d) => `https://dns-lookup.com/${d}`)}
      </div>
    )
  }

  renderAvatar() {
    return (
      <div>
        <a rel="noreferrer" href={this.props.data.data} target="_blank" className='text-center block'>
          <img src={this.props.data.data} className='w-40 m-auto' alt="Avatar" />
          <div className='mt-4'>
            View image
          </div>
        </a>
      </div>
    )
  }

  renderContent() {
    const data = this.props.data.data
    const proto = data.split('://')[0]
    const getIPFSHash = (d) => d.split('://')[1]

    if (proto === 'ipfs') return (
      <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-4'>
        {this.renderVendor('ipfs', (d) => `https://ipfs.io/ipfs/${getIPFSHash(d)}`)}
        {this.renderVendor('cloudflare', (d) => `https://cloudflare-ipfs.com/ipfs/${getIPFSHash(d)}`)}
      </div>
    )

    return (
      <div className='text-center py-4'>
        Unknown content protocol
      </div>
    )
  }

  // This is for an avvy domains hash that *has an ERC721 allocated.*
  renderTokenHash() {
    return (
      <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-4'>
        {this.renderInternalLink('Hash', (d) => ({ hash: d }))}
        {this.renderVendor('snowtrace', (d) => `https://snowtrace.io/nft/0x797ac669a1908ca68cd9854994345f570495541a/${d}`)}
        {this.renderVendor('avascan', (d) => `https://avascan.info/blockchain/c/erc721/0x797AC669A1908ca68CD9854994345f570495541A/nft/${d}`)}
        {this.renderVendor('opensea', (d) => `https://opensea.io/assets/avalanche/0x797ac669a1908ca68cd9854994345f570495541a/${d}`)}
        {this.renderVendor('joepegs', (d) => `https://joepegs.com/item/avalanche/0x797ac669a1908ca68cd9854994345f570495541a/${d}`)}
      </div>
    )
  }

  // This is for an avvy domains hash that *does not have an ERC721*. For example, subdomains.
  renderHash() {
    return (
      <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-4'>
        {this.renderInternalLink('Hash', (d) => ({ hash: d }))}
      </div>
    )
  }

  renderLinks() {
    const records = this.avvy.RECORDS
    switch (this.props.data.dataType) {
      case 'TOKEN_HASH':
        return this.renderTokenHash()

      case 'HASH':
        return this.renderHash()

      case records.X_CHAIN:
        return this.renderXChain()

      case records.P_CHAIN:
        return this.renderPChain()

      case records.EVM:
        return this.renderEVM()

      case records.VALIDATOR:
        return this.renderValidator()

      case records.DNS_CNAME:
        return this.renderDNS_CNAME()

      case records.DNS_A:
        return this.renderDNS_A()

      case records.AVATAR:
        return this.renderAvatar()

      case records.CONTENT:
        return this.renderContent()

      default:
        return null
    }
  }

  getTitle() {
    const records = this.avvy.RECORDS
    let title = {
      'HASH': 'Avvy Domains Hash',
      'TOKEN_HASH': 'Avvy Domains Token',
      [records.X_CHAIN]: 'X-Chain Address',
      [records.P_CHAIN]: 'P-Chain Address',
      [records.EVM]: 'EVM / C-Chain Address',
      [records.VALIDATOR]: 'Validator Node',
      [records.DNS_CNAME]: 'DNS CNAME',
      [records.DNS_A]: 'DNS A Record',
      [records.AVATAR]: 'Avatar',
      [records.CONTENT]: 'Content Record',
    }[this.props.data.dataType]
    if (!title) title = 'External Link'
    return title
  }

  handleCopyValue = async () => {
    await navigator.clipboard.writeText(this.props.data.data)
    this.setState({
      valueCopied: true
    })
    if (this.valueCopiedClearTimeout) clearTimeout(this.valueCopiedClearTimeout)
    setTimeout(() => {
      this.setState({
        valueCopied: false
      })
    }, 2000)
  }

  render() {
    if (!this.props.data) return null
    if (!this.avvy) return null
    const title = this.getTitle()

    return (
      <div>
        <div className='flex items-center border-b border-gray-400 pb-4 mb-4 flex-col w-full justify-center sm:flex-row sm:justify-between'>
          <div className='cursor-pointer text-center w-full sm:text-left' onClick={this.handleCopyValue}>
            <div className='font-bold'>{title}</div>
            <div className='max-w-xs truncate text-xs cursor-pointer'>{this.props.data.data}</div>
          </div>
          <div className='text-sm flex items-center cursor-pointer mt-2 sm:mt-0' onClick={this.handleCopyValue}>
            <div className='mr-2 text-right'>
              <div className='text-right'>Copy</div>
            </div>
            {this.state.valueCopied ? (
              <CheckIcon className='w-4' />
            ) : (
              <DuplicateIcon className='w-4' />
            )}
          </div>
        </div>
        {this.renderLinks()}
      </div>
    )
  }
}

export default DataExplorer
