import { connect } from 'react-redux'
import services from 'services'

//
// loading spinner..
//

function Spinner(props) {
  const style = {}
  if (props.color) style.borderColor = `${props.color} transparent transparent transparent`
  return (
		<div className={`lds-ring ${props.size ? `lds-ring-${props.size}` : ''} ${props.dark ? 'lds-ring-dark' : ''}`}><div style={style}></div><div style={style}></div><div style={style}></div><div style={style}></div></div>
  )
}

function ConnectedSpinner(props) {
  return <Spinner color={props.isDarkmode ? '#eee' : '#555'} {...props} />
}

const mapStateToProps = (state) => ({
  isDarkmode: services.darkmode.selectors.isDarkmode(state),
})

const mapDispatchToProps = (dispatch) => ({
})

Spinner.Connected = connect(mapStateToProps, mapDispatchToProps)(ConnectedSpinner)

export default Spinner
