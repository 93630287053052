"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fusionNotificationHistoryEntryFragment_1 = require("../fragments/fusionNotificationHistoryEntryFragment");
const DEPENDENCIES = [fusionNotificationHistoryEntryFragment_1.fusionNotificationHistoryEntryFragment];
const QUERY = `query getFusionNotificationHistory($after: String, $first: Int, $includeHidden: Boolean) {
  fusionNotificationHistory(after: $after, first: $first, includeHidden: $includeHidden) {
    nodes {
      ...FusionNotificationHistoryEntry
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}`.trim();
const getFusionNotificationHistoryImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'fusionNotificationHistory');
exports.default = getFusionNotificationHistoryImpl;
