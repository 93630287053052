"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.filterFragmentDependencies, fragments_1.filterFragment];
const QUERY = `
query getFilters {
  filter {
    ...filterFragment
  }
}
`.trim();
const getFiltersImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'filter');
exports.default = getFiltersImpl;
