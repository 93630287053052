//'
// This service is responsible for
// interacting with the chain.
// Portions of this service will be 
// extracted later for the public
// API clients.
//
import { ethers } from 'ethers'
import client from '@avvy/client'
import { utils as ensAvatarUtils } from '@ensdomains/ens-avatar'

import services from 'services'

class AvvyClient {
  constructor(chainId, account, signerOrProvider) {
    this.chainId = parseInt(chainId)
    this.avvy = new client(signerOrProvider, {
      chainId
    })
    this.getContracts = async () => {
      return await this.avvy.contracts
    }

    this.account = account
    this.signer = signerOrProvider
    this.DOMAIN_STATUSES = [
      'AVAILABLE',
      'AUCTION_AVAILABLE',
      'AUCTION_BIDDING_CLOSED',
      'REGISTERED_OTHER',
      'REGISTERED_SELF',
    ].reduce((sum, curr) => {
      sum[curr] = curr
      return sum
    }, {})
    this.client = client
  }

  async tokenExists(hash) {
    const contracts = await this.getContracts()
    const exists = await contracts.Domain.exists(hash)
    return exists
  }
  
  async ownerOf(hash) {
    const contracts = await this.getContracts()
    const owner = await contracts.Domain.ownerOf(hash)
    return owner
  }

  async getDomains(account) {
    const domains = await this.avvy.wallet(account).domains()
    const domainNames = {}
    const plaintexts = await this.avvy.batch(domains).lookup()
    for (let i = 0; i < domains.length; i += 1) {
      if (plaintexts[i]) {
        domainNames[domains[i].toString()] = plaintexts[i]
      }
    }
    return domainNames
  }

  async getDomainCountForOwner(account) {
    const contracts = await this.getContracts()
    const count = await contracts.Domain.balanceOf(account)
    return parseInt(count.toString())
  }

  async getDomainIDsByOwner(account) {
    const domainCount = await this.getDomainCountForOwner(account)
    const contracts = await this.getContracts()
    let domains = []
    for (let i = 0; i < domainCount; i += 1) {
      let id = await contracts.Domain.tokenOfOwnerByIndex(account, i.toString())
      domains.push(id)
    }
    return domains
  }

  async getTokenOfOwnerByIndex(account, index) {
    const contracts = await this.getContracts()
    let id = await contracts.Domain.tokenOfOwnerByIndex(account, index.toString())
    return id
  }

  async getTokensOfOwnerByOffsetAndCount(account, offset, count) {
    const contracts = await this.getContracts()
    const payload = []
    const target = contracts.Domain.address
    for (let i = offset; i < offset + count; i += 1) {
      let data = await contracts.Domain.populateTransaction.tokenOfOwnerByIndex(account, i.toString())
      let callData = data.data
      payload.push({
        target,
        callData
      })
    }
    const res = await contracts.Multicall2.callStatic.tryAggregate(false, payload)
    return res.map((r) => {
      if (r.success) {
        return contracts.Domain.interface.decodeFunctionResult('tokenOfOwnerByIndex', r.returnData)[0]
      }
      return null
    })
  }

  async isAuctionPeriod(auctionPhases) {
    const biddingStartsAt = parseInt(auctionPhases[0]) * 1000
    const claimEndsAt = parseInt(auctionPhases[3]) * 1000
    const now = parseInt(Date.now())
    return now >= biddingStartsAt && now < claimEndsAt
  }

  async isBiddingOpen(auctionPhases) {
    const biddingStartsAt = parseInt(auctionPhases[0]) * 1000
    const revealStartsAt = parseInt(auctionPhases[1]) * 1000
    const now = parseInt(Date.now())
    return now >= biddingStartsAt && now < revealStartsAt 
  }

  async isRegistrationPeriod() {
    return true
  }

  // ESTIMATE
  async getNamePrice(domain) {
    const name = domain.split('.')[0]
    let priceUSDCents = '500'
    if (name.length === 3) {
      priceUSDCents = '64000'
    } else if (name.length === 4) {
      priceUSDCents = '16000'
    }
    return priceUSDCents
  }

  async getNameExpiry(hash) {
    const contracts = await this.getContracts()
    const expiresAt = await contracts.Domain.getDomainExpiry(hash)
    return parseInt(expiresAt.toString())
  }

  async getNameExpiries(hashes) {
    const contracts = await this.getContracts()
    const payload = []
    const target = contracts.Domain.address
    for (let i = 0; i < hashes.length; i += 1) {
      let data = await contracts.Domain.populateTransaction.getDomainExpiry(hashes[i])
      let callData = data.data
      payload.push({
        target,
        callData
      })
    }
    const res = await contracts.Multicall2.callStatic.tryAggregate(false, payload)
    return res.map((r) => {
      if (r.success) {
        return parseInt(contracts.Domain.interface.decodeFunctionResult('getDomainExpiry', r.returnData)[0].toString())
      }
      return null
    })
  }

  async getNamePriceAVAX(domain, conversionRate) {
    const _priceUSD = await this.getNamePrice(domain)
    const priceUSD = ethers.BigNumber.from(_priceUSD)
    const priceAVAX = priceUSD.mul(conversionRate)
    return priceAVAX
  }

  async nameHash(name) {
    const hash = await this.avvy.utils.nameHash(name)
    return hash
  }

  async isSupported(name) {
    // checks whether a given name is supported by the system
    if (!name) return false
    if (services.blocklist.isBlocked(name)) return false
    const split = name.split('.')
    if (split.length !== 2) return false
    if (split[1] !== 'avax') return false
    if (split[0].length < 3) return false
    if (split[0].length > 62) return false
    if (!split[0].match(/^[a-z0-9][a-z0-9-]+[a-z0-9]$/)) return false
    if (split[0].length >= 4 && split[0][2] === '-' && split[0][3] === '-') return false
    return true
  }

  async getAVAXConversionRateFromChainlink(address) {
    let oracle = new ethers.Contract(address, services.abi.chainlink, this.signer)
    let roundData = await oracle.latestRoundData()
    let rate = roundData[1].toString()

    // add a buffer to the rate, so that we can have less chance of getting a revert due to not enough AVAX
    rate = ethers.BigNumber.from(rate).div('10').mul('9').toString()

    return rate
  }

  async getAVAXConversionRate() {
    // this is just fixed price for now based on latestRound from oracle
    let rate
    if (this.chainId === 31337) {
      rate = ethers.BigNumber.from('10000000000')
    } else if (this.chainId === 43113) {
      rate = await this.getAVAXConversionRateFromChainlink('0x5498BB86BC934c8D34FDA08E81D444153d0D06aD')
    } else if (this.chainId === 43114) {
      rate = await this.getAVAXConversionRateFromChainlink('0x0A77230d17318075983913bC2145DB16C7366156')
    }
    return ethers.BigNumber.from('10').pow('24').div(rate)
  }

  async revealDomain(domain) {
    const contracts = await this.getContracts()
    const preimage = await this.avvy.utils.encodeNameHashInputSignals(domain)
    const hash = await this.avvy.utils.nameHash(domain)
    const tx = await contracts.RainbowTableV1.reveal(preimage, hash)
    await tx.wait()
  }

  async loadDomain(name) {

    // subdomain
    const domain = this.getRootDomain(name)
    const isSubdomain = name !== domain

    const subdomain = isSubdomain ? {
      hash: await this.avvy.utils.nameHash(name),
      name
    } : null
    
    // hash the name
    const hash = await this.avvy.utils.nameHash(domain)
    const tokenExists = await this.tokenExists(hash)
    let domainStatus
    let owner = null

    if (tokenExists) {
      owner = await this.ownerOf(hash)
      if (owner && this.account && owner.toLowerCase() === this.account.toLowerCase()) domainStatus = this.DOMAIN_STATUSES.REGISTERED_SELF
      else domainStatus = this.DOMAIN_STATUSES.REGISTERED_OTHER
    } else {
      domainStatus = this.DOMAIN_STATUSES.AVAILABLE
    }

    let priceUSDCents = await this.getNamePrice(domain)
    let avaxConversionRate = await this.getAVAXConversionRate()
    let priceAVAXEstimate = avaxConversionRate.mul(ethers.BigNumber.from(priceUSDCents)).toString()
    let expiresAt = await this.getNameExpiry(hash)


    return {
      constants: {
        DOMAIN_STATUSES: this.DOMAIN_STATUSES,
      },
      subdomain,
      supported: await this.isSupported(domain, hash),
      domain,
      hash: hash.toString(),
      owner,
      expiresAt,
      status: domainStatus,
      priceUSDCents,
      priceAVAXEstimate,
      timestamp: parseInt(Date.now() / 1000),
    }
  }

  async generateDomainPriceProof(domain) {
    const domainSplit = domain.split('.')
    const name = domainSplit[0]
    const nameArr = await this.avvy.utils.string2AsciiArray(name, 62)
    const namespace = domainSplit[domainSplit.length - 1]
    const namespaceHash = await this.avvy.utils.nameHash(namespace)
    const hash = await this.avvy.utils.nameHash(domain)
    let minLength = name.length
    if (name.length >= 6) minLength = 6
    const inputs = {
      namespaceId: namespaceHash.toString(),
      name: nameArr,
      hash: hash.toString(),
      minLength
    }
    const proveRes = await services.circuits.prove('PriceCheck', inputs)
    const verify = await services.circuits.verify('PriceCheck', proveRes)
    if (!verify) throw new Error('Failed to verify')
    const calldata = await services.circuits.calldata(proveRes)
    return {
      proveRes,
      calldata
    }
  }

  async generateConstraintsProof(domain) {
    const split = domain.split('.')
    const _name = split[0]
    const _namespace = split[1]
    const namespace = await this.avvy.utils.string2AsciiArray(_namespace, 62)
    const name = await this.avvy.utils.string2AsciiArray(_name, 62)
    const hash = await this.avvy.utils.nameHash(domain)
    const inputs = {
      namespace,
      name,
      hash: hash.toString(),
    }
    const proveRes = await services.circuits.prove('Constraints', inputs)
    const verify = await services.circuits.verify('Constraints', proveRes)
    if (!verify) throw new Error('Failed to verify')
    const calldata = await services.circuits.calldata(proveRes)
    return {
      proveRes,
      calldata
    }
  }

  async commit(domains, quantities, constraintsProofs, pricingProofs, salt) {
    const contracts = await this.getContracts()
    let hashes = []
    for (let i = 0; i < domains.length; i += 1) {
      let hash = await this.avvy.utils.nameHash(domains[i])
      hashes.push(hash.toString())
    }
    const hash = await this.avvy.utils.registrationCommitHash(hashes, quantities, constraintsProofs, pricingProofs, salt)
    const tx = await contracts.LeasingAgentV2.commit(hash)
    await tx.wait()
    return hash
  }

  async _getRegistrationArgs(domains, quantities) {
    let hashes = []
    let total = ethers.BigNumber.from('0')
    const conversionRate = await this.getAVAXConversionRate()

    for (let i = 0; i < domains.length; i += 1) {
      let hash = await this.avvy.utils.nameHash(domains[i])
      hashes.push(hash.toString())
      let namePrice = await this.getNamePriceAVAX(domains[i], conversionRate)
      total = total.add(
        ethers.BigNumber.from(quantities[i].toString()).mul(
          namePrice
        )
      )
    }
    return {
      total, 
      hashes
    }
  }

  _getTreasuryGasSurplus() {
    return ethers.BigNumber.from('20000')
  }

  async register(domains, quantities, constraintsProofs, pricingProofs, referralCode) {
    if (!referralCode) referralCode = ''
    const contracts = await this.getContracts()
    const { total, hashes } = await this._getRegistrationArgs(domains, quantities)
    const premium = await this.getRegistrationPremium()
    const value = total.add(premium.mul(hashes.length))
    const gasEstimate = await contracts.LeasingAgentV2.estimateGas.register(hashes, quantities, constraintsProofs, pricingProofs, referralCode, {
      value
    })
    const gasLimit = gasEstimate.add(this._getTreasuryGasSurplus().mul(hashes.length))
    const registerTx = await contracts.LeasingAgentV2.register(hashes, quantities, constraintsProofs, pricingProofs, referralCode, {
      gasLimit,
      value
    })
    await registerTx.wait()
  }

  async registerWithPreimage(domains, quantities, referralCode) {
    if (!referralCode) referralCode = ''

    const preimages = await this.buildPreimages(domains)

    const getProof = (name) => {
      const abi = ethers.utils.defaultAbiCoder
      return abi.encode(
        ['int', 'string'],
        ['-15072972309624396715675110978837837858524265124245905331735422503227339309056', name]
      )
    }
    const proofs = domains.map(n => getProof(n))

    const contracts = await this.getContracts()
    const { total, hashes } = await this._getRegistrationArgs(domains, quantities)
    const premium = await this.getRegistrationPremium()
    const value = total.add(premium.mul(hashes.length))
    const gasEstimate = await contracts.LeasingAgentV2.estimateGas.registerWithPreimage(hashes, quantities, proofs, proofs, preimages, referralCode, {
      value,
    })

    const gasLimit = gasEstimate.add(this._getTreasuryGasSurplus().mul(hashes.length))
    const registerTx = await contracts.LeasingAgentV2.registerWithPreimage(hashes, quantities, proofs, proofs, preimages, referralCode, {
      gasLimit,
      value
    })
    await registerTx.wait()
  }

  async getAuctionPhases() {
    const contracts = await this.getContracts()
    const now = parseInt(Date.now() / 1000)
    if (this._auctionPhasesCache && now - this._auctionPhasesCachedAt < 60 * 5) return this._auctionPhasesCache
    const params = await contracts.SunriseAuctionV1.getAuctionParams()
    const phases = params.map(p => parseInt(p.toString()))
    this._auctionPhasesCache = phases
    this._auctionPhasesCachedAt = now
    return phases
  }

  async bid(hashes) {
    const contracts = await this.getContracts()
    const tx = await contracts.SunriseAuctionV1.bid(hashes)
    await tx.wait()
  }

  async reveal(names, amounts, salt) {
    const contracts = await this.getContracts()
    const tx = await contracts.SunriseAuctionV1.reveal(names, amounts, salt)
    await tx.wait()
  }

  async revealWithPreimage(names, amounts, salt, preimages) {
    const contracts = await this.getContracts()
    const tx = await contracts.SunriseAuctionV1.revealWithPreimage(names, amounts, salt, preimages)
    await tx.wait()
  }

  async getWinningBid(name) {
    const contracts = await this.getContracts()
    const hash = await this.avvy.utils.nameHash(name)
    let result
    try {
      const output = await contracts.SunriseAuctionV1.getWinningBid(hash.toString())
      try {
        const owner = await this.ownerOf(hash.toString())
        result = {
          type: 'IS_CLAIMED',
          owner,
          winner: output.winner,
          auctionPrice: output.auctionPrice.toString(),
          isWinner: output.winner.toLowerCase() === this.account
        }
      } catch (err) {
        result = {
          type: 'HAS_WINNER',
          winner: output.winner,
          auctionPrice: output.auctionPrice.toString(),
          isWinner: output.winner.toLowerCase() === this.account
        }
      }
    } catch (err) {
      result = {
        type: 'NO_WINNER'
      }
      console.log(err)
    }
    return result
  }

  async getWavaxContract() {
    const contracts = await this.getContracts()
    let contract
    if (this.chainId === 31337) {
      contract = contracts.MockWavax
    } else if (this.chainId === 43113) {
      contract = new ethers.Contract('0xd00ae08403B9bbb9124bB305C09058E32C39A48c', services.abi.wavax, this.signer)
    } else if (this.chainId === 43114) {
      contract = new ethers.Contract('0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', services.abi.wavax, this.signer)
    }
    return contract
  }

  async getWavaxBalance() {
    const contract = await this.getWavaxContract()
    const balance = await contract.balanceOf(this.account)
    return balance.toString()
  }

  async getAuctionWavax() {
    const contract = await this.getWavaxContract()
    const contracts = await this.getContracts()
    const allowance = await contract.allowance(this.account, contracts.SunriseAuctionV1.address)
    return allowance.toString()
  }

  async wrapAvax(amount) {
    const contract = await this.getWavaxContract()
    const tx = await contract.deposit({
      value: amount
    })
    await tx.wait()
  }

  async approveWavaxForAuction(amount) {
    const contract = await this.getWavaxContract()
    const contracts = await this.getContracts()
    const tx = await contract.approve(contracts.SunriseAuctionV1.address, amount) 
    await tx.wait()
  }

  async getRevealedBidForSenderCount() {
    const contracts = await this.getContracts()
    const count = await contracts.SunriseAuctionV1.getRevealedBidForSenderCount()
    return count
  }

  async getRevealedBidForSenderAtIndex(index) {
    const contracts = await this.getContracts()
    const bid = await contracts.SunriseAuctionV1.getRevealedBidForSenderAtIndex(index)
    let nameSignal, preimage
    try {
      nameSignal = await contracts.RainbowTableV1.lookup(bid.name)
      preimage = await this.avvy.utils.decodeNameHashInputSignals(nameSignal)
    } catch (err) {
      preimage = null
    }
    return {
      name: bid.name,
      amount: bid.amount,
      timestamp: bid.timestamp,
      preimage: preimage
    }
  }

  async sunriseClaim(names, constraintsData) {
    const hashes = []
    const contracts = await this.getContracts()
    for (let i = 0; i < names.length; i += 1) {
      let hash = await this.avvy.utils.nameHash(names[i])
      hashes.push(hash.toString())
    }
    const tx = await contracts.SunriseAuctionV1.claim(hashes, constraintsData)
    await tx.wait()
  }

  async checkHasAccount() {
    // check if there is an account on-chain
    const contracts = await this.getContracts()
    const hasAccount = await contracts.AccountGuardV1.addressHasAccount(this.account)
    return hasAccount
  }

  async submitAccountVerification(signature) {
    const contracts = await this.getContracts()
    const tx = await contracts.AccountGuardV1.verify(ethers.utils.getAddress(this.account), signature)
    await tx.wait()
  }

  async getRegistrationPremium() {
    const contracts = await this.getContracts()
    const now = parseInt(Date.now() / 1000)
    const registrationPremium = await contracts.LeasingAgentV2.getRegistrationPremium(now)
    return registrationPremium
  }

  async buildPreimages(names) {
    let signal = []
    for (let i = 0; i < names.length; i += 1) {
      let _sig = await this.avvy.utils.encodeNameHashInputSignals(names[i])
      signal = signal.concat(_sig)
    }
    return signal
  }

  async lookupPreimage(hash) {
    const contracts = await this.getContracts()
    const output = await contracts.RainbowTableV1.lookup(hash)
    const name = await this.avvy.utils.decodeNameHashInputSignals(output)
    return name
  }

  async lookupPreimages(hashes) {
    const contracts = await this.getContracts()
    const payload = []
    const target = contracts.RainbowTableV1.address
    for (let i = 0; i < hashes.length; i += 1) {
      let data = await contracts.RainbowTableV1.populateTransaction.lookup(hashes[i])
      let callData = data.data
      payload.push({
        target,
        callData
      })
    }
    const res = await contracts.Multicall2.callStatic.tryAggregate(false, payload)
    const ret = []
    for (let i = 0; i < res.length; i += 1) {
      let r = res[i]
      if (r.success) {
        let data = contracts.RainbowTableV1.interface.decodeFunctionResult('lookup', r.returnData)
        if (data) {
          ret.push(await this.avvy.utils.decodeNameHashInputSignals(data.preimage))
        } else {
          ret.push(null)
        }
      } else {
        ret.push(null)
      }
    }
    return ret
  }

  async isPreimageRevealed(hash) {
    const contracts = await this.getContracts()
    const output = await contracts.RainbowTableV1.isRevealed(hash)
    return output
  }

  async getDefaultResolverAddress() {
    const contracts = await this.getContracts()
    return contracts.PublicResolverV1.address
  }

  async getResolver(name) {
    const domain = this.getRootDomain(name)
    const hash = await this.avvy.utils.nameHash(name)
    const rootHash = await this.avvy.utils.nameHash(domain)
    const contracts = await this.getContracts()
    const resolver = await contracts.ResolverRegistryV1.get(rootHash, hash)
    return resolver
  }

  async setResolver(name, address, datasetId) {
    if (!datasetId) datasetId = 0 // default value
    const domain = this.getRootDomain(name)
    const hash = await this.avvy.utils.nameHash(domain)
    const path = await this.avvy.utils.encodeNameHashInputSignals(name)
    const defaultResolverAddress = await this.getDefaultResolverAddress()
    if (address.toLowerCase() === defaultResolverAddress.toLowerCase()) {
      datasetId = hash
    }
    const contracts = await this.getContracts()
    const contract = await contracts.ResolverRegistryV1
    const tx = await contract.set(hash, path.slice(4), address, datasetId)
    await tx.wait()
  }

  async setStandardRecord(name, type, value) {
    const domain = this.getRootDomain(name)
    const hash = await this.avvy.utils.nameHash(domain)
    const path = await this.avvy.utils.encodeNameHashInputSignals(name)
    const contracts = await this.getContracts()
    const tx = await contracts.PublicResolverV1.setStandard(hash, path.slice(4), type, value)
    await tx.wait()
  }

  async getStandardRecord(name, key) {
    const contracts = await this.getContracts()
    return await contracts.ResolutionUtilsV3.resolveStandard(name, key)
    /*
    const domain = this.getRootDomain(name)
    const hash = await this.avvy.utils.nameHash(name)
    const rootHash = await this.avvy.utils.nameHash(domain)
    const promises = this.avvy.RECORDS._LIST.map(r => contracts.ResolutionUtilsV3.resolveStandard(rootHash, hash, r.key))
    const results = await Promise.all(promises)
    */
  }

  async getStandardRecords(name) {
    const domain = this.getRootDomain(name)
    const hash = await this.avvy.utils.nameHash(name)
    const rootHash = await this.avvy.utils.nameHash(domain)
    const contracts = await this.getContracts()
    const promises = this.avvy.RECORDS._LIST.map(r => contracts.PublicResolverV1.resolveStandard(rootHash, hash, r.key))
    const results = await Promise.all(promises)
    return results.map((res, index) => {
      return {
        type: index + 1,
        value: res
      }
    }).filter(res => res.value !== '')
  }

  getRootDomain(domain) {
    let split = domain.split('.')
    return split.reverse().slice(0, 2).reverse().join('.')
  }

  async getReverseRecords(domain) {
    const rootDomain = this.getRootDomain(domain)
    const domainHash = await this.avvy.utils.nameHash(rootDomain)
    const hash = await this.avvy.utils.nameHash(domain)
    const contracts = await this.getContracts()
    const promises = [
      contracts.EVMReverseResolverV1.getEntry(domainHash, hash),
      contracts.ValidatorNodeIDReverseResolverV1.getEntry(domainHash, hash),
    ]
    const results = await Promise.all(promises)
    return {
      [this.avvy.RECORDS.EVM]: results[0] === '0x0000000000000000000000000000000000000000' ? null : results[0],
      [this.avvy.RECORDS.VALIDATOR]: results[1] === '' ? null : results[1],
    }
  }

  async getProfile(address) {
    const hash = await this.avvy.reverse(this.avvy.RECORDS.EVM, address)
    let _name, name, avatar
    try {
      _name = await hash.lookup()
    } catch (err) {
    }
    if (_name) {
      name = _name.name
      try {
        const _avatar = await _name.resolve(this.avvy.RECORDS.AVATAR)
        if (_avatar) avatar = ensAvatarUtils.resolveURI(_avatar, {
          ipfs: 'https://w3s.link/',
        }).uri
      } catch (err) {
      }
    }
    return {
      name,
      avatar,
      address
    }
  }

  async setEVMReverseRecord(name) {
    const domain = this.getRootDomain(name)
    const hash = await this.avvy.utils.nameHash(domain)
    const path = await this.avvy.utils.encodeNameHashInputSignals(name)
    const contracts = await this.getContracts()
    const tx = await contracts.EVMReverseResolverV1.set(hash, path.slice(4))
    await tx.wait()
  }

  async getBalance() {
    const balance = await this.signer.getBalance()
    return balance
  }

  async transferDomain(domain, address) {
    const tokenId = await this.avvy.utils.nameHash(domain)
    const contracts = await this.getContracts()
    const tx = await contracts.Domain['safeTransferFrom(address,address,uint256)'](this.account, address, tokenId)
    await tx.wait()
  }

  async sendAVAX(address, amountAVAX) {
    const tx = await this.signer.sendTransaction({
      to: address,
      value: ethers.utils.parseEther(amountAVAX)
    })
    await tx.wait()
  }

  async sendERC20(address, amount, tokenAddress) {
    const contract = new ethers.Contract(tokenAddress, services.abi.erc20, this.signer)
    const tx = await contract.transfer(address, amount)
    await tx.wait()
  }

  async sendERC721(address, contractAddress, tokenId) {
    const contract = new ethers.Contract(contractAddress, services.abi.erc721, this.signer)
    const tx = await contract.transferFrom(this.account, address, tokenId)
    await tx.wait()
  }

  async loadTokens() {
    const address = this.account
    const res = await fetch(`${services.environment.BACKEND_BASE_URL}/api/tokens/`, {
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        address,
      })
    })
    const data = await res.json()
    return data
  }

  async signMessage(message) {
    return await this.signer.signMessage(message)
  }

  async indexerRequest(payload) {
    const res = await fetch('https://api.avvy.domains/graphql', {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'accept': 'application/json',
      },
      body: JSON.stringify({
        query: payload
      })
    })
    return await res.json()
  }

  async getDomainsByExpiry(greaterThanMS, lessThanMS, options) {
    if (!options) options = {}
    if (!options.order) options.order = 'expiry'
    const expiryLessThan = new Date(lessThanMS).toISOString()
    const expiryGreaterThan = new Date(greaterThanMS).toISOString()
    const payload = `
      {
        domains(
          expiryLessThan: "${expiryLessThan}",
          expiryGreaterThan: "${expiryGreaterThan}",
          order: "${options.order}",
          limit: 200,
          ${options.search ? `search: "${options.search}",` : 'nameIsNull: false'},
          offset: ${options.offset},
        ) {
          hash,
          name,
          expiry
        }
      }
    `
    const domains = await this.indexerRequest(payload)
    return domains.data
  }

  async purchaseRecycleDomain(domain, hash, expiry, gracePeriodLength, recyclePeriodLength, referralCode) {
    const timestamp = parseInt(Date.now() / 1000)
    const conversionRate = await this.getAVAXConversionRate()
    const price = await this.getNamePriceAVAX(domain, conversionRate)
    const contracts = await this.getContracts()
    const premium = await contracts.RecyclingAgentV2.getRegistrationPremium(
      timestamp, 
      expiry, 
      gracePeriodLength, 
      recyclePeriodLength
    ) 
    let value = ethers.BigNumber.from(price).add(premium) // actual value
		value = value.mul('101').div('100') // add 1% buffer to handle AVAX price fluctuations
    const balance = await this.getBalance()
    const hashes = [hash]
    const quantities = [1]
    const proof = await this.generateDomainPriceProof(domain)
    const pricingProofs = [proof.calldata]
    if (!referralCode) referralCode = ''
    const gasEstimate = await contracts.RecyclingAgentV2.estimateGas.register(
      hashes,
      quantities,
      pricingProofs,
      referralCode,
      { value }
    )
    const gasLimit = gasEstimate.add(this._getTreasuryGasSurplus().mul(hashes.length))
    const registerTx = await contracts.RecyclingAgentV2.register(
      hashes,
      quantities,
      pricingProofs,
      referralCode,
      { gasLimit, value }
    )
    await registerTx.wait()
  }
  
  async getGracePeriodLengthMS() {
    const contracts = await this.getContracts()
    const namespaceId = await this.avvy.utils.nameHash('avax')
    const length = await contracts.NamespaceV1.getGracePeriodLength(namespaceId)
    return parseInt(length.toString()) * 1000
  }
  
  async getRecyclePeriodLengthMS() {
    const contracts = await this.getContracts()
    const namespaceId = await this.avvy.utils.nameHash('avax')
    const length = await contracts.NamespaceV1.getRecyclePeriodLength(namespaceId)
    return parseInt(length.toString()) * 1000
  }

  async getRecycleAuctionPricePoints() {
    const contracts = await this.getContracts()
    const output = []
    let point
    let i = 0
    while (true) {
      try {
        point = await contracts.RecyclingAgentV2._premiumPricePoints(i)
        output.push(point.toString())
        i += 1
      } catch (err) {
        break
      }
    }
    return output
  }

  async getDomainExpiries(domains) {
    // gets the actual expiries for domains from the RPC
    const contracts = await this.getContracts()
    const output = []
    const BATCH_SIZE = 5
    const batches = []
    let batch = []

    for (let i = 0; i < domains.length; i += 1) {
      batch.push(domains[i])
      if (batch.length >= BATCH_SIZE) {
        batches.push(batch)
        batch = []
      }
    }
    batches.push(batch)

    const promises = []
    for (let i = 0; i < batches.length; i += 1) {
      promises.push(new Promise(async (resolve, reject) => {
        const dd = batches[i]
        const txs = []
        for (let i = 0; i < dd.length; i += 1) {
          let id = await this.avvy.utils.nameHash(dd[i])
          let payload = await contracts.Domain.populateTransaction.getDomainExpiry(id)
          txs.push({
            target: payload.to,
            callData: payload.data
          })
        }
        const output = []
        const results = await contracts.Multicall2.callStatic.tryAggregate(false, txs)
        for (let i = 0; i < results.length; i += 1) {
          let res = results[i]
          if (res === null) output.push(null)
          else output.push(contracts.Domain.interface.decodeFunctionResult('getDomainExpiry', res[1])[0])
        }
        resolve(output)
      }))
    }

    const batchOutput = await Promise.all(promises)
    batchOutput.map((bb) => {
      bb.map((oo) => output.push(oo))
    })
    return output
  }

  async getNodeCertificateDetails(cert) {
    const contracts = await this.getContracts()
    const promises = [
      contracts.ValidatorNodeIDTargetAuthV1.certificateToNodeID(cert),
      contracts.ValidatorNodeIDTargetAuthV1.certificateToModulusAndExponent(cert),
    ]
    const results = await Promise.all(promises)
    const nodeID = results[0]
    const {modulus, exponent} = results[1]
    return {
      nodeID,
      modulus,
      exponent,
    }
  }

  async getNodeAuthenticationChallenge(domain) {
    const MESSAGE_MAX_AGE = 60 * 15 
    const timestamp = parseInt(Date.now() / 1000)
    const contracts = await this.getContracts()
    const message = await contracts.ValidatorNodeIDTargetAuthV1.getMessageString(
      domain,
      timestamp
    )
    const expiry = timestamp + MESSAGE_MAX_AGE
    return {
      message,
      timestamp,
      expiry
    }
  }

  async setNodeReverse(domain, nodeID, certificate, timestamp, signature) {
    const contracts = await this.getContracts()
    let auth = ''
    try {
      auth = ethers.utils.defaultAbiCoder.encode(
        ['bytes', 'string', 'uint256', 'bytes'],
        [certificate, domain, timestamp, signature]
      )
    } catch (err) {
      services.logger.error(err)
      throw "Failed to encode signature."
    }
    const valid = await contracts.ValidatorNodeIDTargetAuthV1.verify(
      nodeID,
      domain,
      auth
    )
    if (!valid) {
      // preflight check failed
      throw "Signature does not pass preflight check."
    }
    const sld = domain.split('.').reverse().slice(0, 2).reverse().join('.')
    const name = await this.avvy.utils.nameHash(sld)
    const path = await this.avvy.utils.encodeNameHashInputSignals(domain)
    try {
      const tx = await contracts.ValidatorNodeIDReverseResolverV1.set(
        name,
        path.slice(4),
        nodeID, // target
        auth
      )
      await tx.wait()
    } catch (err) {
      services.logger.error(err)
      throw "Transaction failed."
    }
    return true
  }

  async deleteReverseRecord(recordType, domain) {
    const contracts = await this.getContracts()
    const contract = {
      [this.avvy.RECORDS.EVM]: contracts.EVMReverseResolverV1,
      [this.avvy.RECORDS.VALIDATOR]: contracts.ValidatorNodeIDReverseResolverV1,
    }[recordType]
    const sld = domain.split('.').reverse().slice(0, 2).reverse().join('.')
    const name = await this.avvy.utils.nameHash(sld)
    const path = await this.avvy.utils.encodeNameHashInputSignals(domain)
    const tx = await contract.clear(name, path.slice(4))
    await tx.wait()
  }

  async isReferralCodeValid(code) {
    const contracts = await this.getContracts()
    return await contracts.ReferralPartnerV1.isValidCode(code)
  }

  // pull in as much data as we can about an unrevealed hash
  async loadHash(hash) {

    // hash the name
    const tokenExists = await this.tokenExists(hash)
    let domainStatus
    let owner = null

    // try to get the domain
    const contracts = await this.getContracts()
    let domain, nameSignal
    try {
      nameSignal = await contracts.RainbowTableV1.lookup(hash)
      domain = await this.avvy.utils.decodeNameHashInputSignals(nameSignal)
    } catch (err) {
      domain = null
    }

    if (tokenExists) {
      owner = await this.ownerOf(hash)
      if (owner && this.account && owner.toLowerCase() === this.account.toLowerCase()) domainStatus = this.DOMAIN_STATUSES.REGISTERED_SELF
      else domainStatus = this.DOMAIN_STATUSES.REGISTERED_OTHER
    } else {
      domainStatus = this.DOMAIN_STATUSES.AVAILABLE
    }

    let expiresAt = await this.getNameExpiry(hash)

    return {
      constants: {
        DOMAIN_STATUSES: this.DOMAIN_STATUSES,
      },
      hash: hash.toString(),
      owner,
      domain,
      expiresAt,
      status: domainStatus,
      timestamp: parseInt(Date.now() / 1000),
    }
  }

  // run a query on the marketplace api
  async marketplaceQuery(names) {
    const url = 'https://avvy.domains/api/marketplace-data/graphql/'
    const payload = `
      {
        domains(names: ${JSON.stringify([...new Set(names)])}) {
          name
          listingSet {
            marketplace
            price
            seller
            currency
            decimals
            lastUpdate
          }
        }
      }
    `

    const res = await fetch(url, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'accept': 'application/json',
      },
      body: JSON.stringify({query: payload})
    })
    const data = await res.json()
    const domains = data.data.domains
    const results = {}
    for (let i = 0; i < domains.length; i += 1) {
      results[domains[i].name] = domains[i]
    }
    return results
  }

  async getStripeOnrampToken(account, amount) {
    const url = services.environment.BACKEND_BASE_URL + '/onramp/start/'
    const res = await fetch(url, { 
      method: 'post',
      body: JSON.stringify({ account, amount })
    })
    const data = await res.json()
    const secret = data.result?.client_secret
    const redirectUrl = data.result?.redirect_url
    return {
      secret,
      redirectUrl
    }
  }
}

export default AvvyClient
