"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sourceGroupFragmentDependencies = exports.sourceGroupFragment = void 0;
const sourceFragment_1 = require("./sourceFragment");
exports.sourceGroupFragment = `
fragment sourceGroupFragment on SourceGroup {
  id
  name
  sources {
    ...sourceFragment
  }
}
`.trim();
exports.sourceGroupFragmentDependencies = [
    ...sourceFragment_1.sourceFragmentDependencies,
    sourceFragment_1.sourceFragment,
];
