"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.sourceFragmentDependencies, fragments_1.sourceFragment];
const MUTATION = `
mutation createSource(
  $name: String!
  $blockchainAddress: String!
  $type: SourceType!
  $fusionEventTypeId: String
) {
  createSource(
    createSourceInput: {
      name: $name
      blockchainAddress: $blockchainAddress
      type: $type
      fusionEventTypeId: $fusionEventTypeId
    }
  ) {
    ...sourceFragment
  }
}
`.trim();
const createSourceImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'createSource');
exports.default = createSourceImpl;
