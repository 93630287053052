"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [
    ...fragments_1.webhookTargetFragmentDependencies,
    fragments_1.webhookTargetFragment,
];
const MUTATION = `
mutation createWebhookTarget(
  $name: String!
  $url: String!
  $format: WebhookPayloadFormat!
  $headers: [KeyValuePairOfStringAndStringInput!]!
) {
  createWebhookTarget(
    createTargetInput: {
      name: $name
      url: $url
      format: $format
      headers: $headers
    }
  ) {
    ...webhookTargetFragment
  }
}
`.trim();
const createWebhookTargetImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'createWebhookTarget');
exports.default = createWebhookTargetImpl;
