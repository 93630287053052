"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.discordTargetFragmentDependencies = exports.discordTargetFragment = void 0;
exports.discordTargetFragment = `
fragment discordTargetFragment on DiscordTarget {
  id
  discordAccountId
  discriminator
  isConfirmed
  username
  name
  userStatus
  verificationLink
  discordServerInviteLink
}
`.trim();
exports.discordTargetFragmentDependencies = [];
