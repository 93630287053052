import React from 'react'
import { connect } from 'react-redux'
import { CogIcon, ArrowRightIcon, ExternalLinkIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

import components from 'components'
import services from 'services'


class Landing extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      connected: services.provider.isConnected(),
    }
  }

  componentDidMount() {
    services.provider.addEventListener(services.provider.EVENTS.CONNECTED, this.onConnected)
    services.provider.addEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnected)
  }

  componentWillUnmount() {
    services.provider.removeEventListener(services.provider.EVENTS.CONNECTED, this.onConnected)
    services.provider.removeEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnected)
  }

  onConnected = async () => {
    const api = await services.provider.buildAPI()
    this.setState({
      connected: true,
    })
  }

  onDisconnected = () => {
    this.setState({
      connected: false,
    })
  }

  render() {
    return (
      <div className='max-w-md m-auto'>
        <components.Modal title={this.state.connected ? 'Configure your domain' : 'Connect your wallet'} ref={(ref) => this.domainSetupModal = ref}>
          {this.state.connected ? (
            <components.DomainSetup onClose={() => this.domainSetupModal.toggle()} />
          ) : (
            <div className='m-auto max-w-sm'>
              <components.ConnectWalletButton />
            </div>
          )}
        </components.Modal>
        <div className='font-bold text-center mt-4 text-lg'>{'Avvy Domains'}</div>
        <div className='text-center max-w-sm m-auto mt-4 mb-8'>{'A naming service designed to support the Avalanche ecosystem and its various subnets.'}</div>
        <div className='mb-8'>
          <components.DomainSearch />
        </div>
        <Link
          to={services.linking.path('MyDomains')}
          className="flex justify-between bg-gray-100 font-bold p-4 rounded mb-2 dark:bg-gray-700">
          <div>{'My Domains'}</div>
          <ArrowRightIcon className="h-6" />
        </Link>
        <Link
          to={services.linking.path('Register')}
          className="flex justify-between bg-gray-100 font-bold p-4 rounded mb-2 dark:bg-gray-700">
          <div>{'Register a Domain'}</div>
          <ArrowRightIcon className="h-6" />
        </Link>
        <div className="cursor-pointer flex justify-between bg-gray-100 font-bold p-4 rounded mb-2 dark:bg-gray-700" onClick={() => this.domainSetupModal.toggle()}>
          <div>{'Configure your Primary Domain'}</div>
          <CogIcon className="h-6" />
        </div>
        <a href="https://avvy.domains/docs/"
          target="_blank"
          rel="noreferrer"
          className="flex justify-between bg-gray-100 font-bold p-4 rounded mb-2 dark:bg-gray-700">
          <div>{'Documentation'}</div>
          <ExternalLinkIcon className="h-6" />
        </a>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Landing)
