"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.targetGroupFragmentDependencies = exports.targetGroupFragment = void 0;
const discordTargetFragment_1 = require("./discordTargetFragment");
const emailTargetFragment_1 = require("./emailTargetFragment");
const smsTargetFragment_1 = require("./smsTargetFragment");
const telegramTargetFragment_1 = require("./telegramTargetFragment");
exports.targetGroupFragment = `
fragment targetGroupFragment on TargetGroup {
  id
  name
  emailTargets {
    ...emailTargetFragment
  }
  smsTargets {
    ...smsTargetFragment
  }
  telegramTargets {
    ...telegramTargetFragment
  }
  discordTargets {
    ...discordTargetFragment
  }
}
`.trim();
exports.targetGroupFragmentDependencies = [
    ...emailTargetFragment_1.emailTargetFragmentDependencies,
    ...smsTargetFragment_1.smsTargetFragmentDependencies,
    ...telegramTargetFragment_1.telegramTargetFragmentDependencies,
    ...discordTargetFragment_1.discordTargetFragmentDependencies,
    emailTargetFragment_1.emailTargetFragment,
    smsTargetFragment_1.smsTargetFragment,
    telegramTargetFragment_1.telegramTargetFragment,
    discordTargetFragment_1.discordTargetFragment,
];
