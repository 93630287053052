import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ArrowRightIcon, SearchIcon } from '@heroicons/react/solid'
import { MoonIcon, SunIcon, CogIcon, XIcon, BellIcon } from '@heroicons/react/outline'

import components from 'components'
import services from 'services'

import Notifi from './Notifi'


class Wrapper extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      account: null,
      signer: null,
      name: null,
      avatar: null,
      wrongChain: false,
    }
  }

  componentDidMount() {
    services.provider.addEventListener(services.provider.EVENTS.INITIALIZED, this.onInitialized)
    services.provider.addEventListener(services.provider.EVENTS.CONNECTED, this.onConnected)
    services.provider.addEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnected)
    services.provider.addEventListener(services.provider.EVENTS.WRONG_CHAIN, this.onWrongChain)
    services.provider.addEventListener(services.provider.EVENTS.CORRECT_CHAIN, this.onCorrectChain)
    document.body.addEventListener('keydown', this.handleKeyDown)
    document.body.addEventListener('keyup', this.handleKeyUp)
  }

  componentWillUnmount() {
    services.provider.removeEventListener(services.provider.EVENTS.INITIALIZED, this.onInitialized)
    services.provider.removeEventListener(services.provider.EVENTS.CONNECTED, this.onConnected)
    services.provider.removeEventListener(services.provider.EVENTS.DISCONNECTED, this.onDisconnected)
    services.provider.removeEventListener(services.provider.EVENTS.WRONG_CHAIN, this.onWrongChain)
    services.provider.removeEventListener(services.provider.EVENTS.CORRECT_CHAIN, this.onCorrectChain)
    document.body.removeEventListener('keydown', this.handleKeyDown)
    document.body.removeEventListener('keyup', this.handleKeyUp)
  }

  openSearch = () => {
    this.searchBox.blur()
    this.searchModal.open()
    setTimeout(() => {
      this.searchBox.focus()
    }, 1)
  }

  closeSearch = () => {
    this.searchBox.blur()
    this.searchModal.hide()
  }

  handleKeyUp = (e) => {
    switch (e.keyCode) {
      case 17: // ctrl
        this.ctrlPressed = false
        break
    }
  }

  handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 191: // slash
        this.openSearch()
        break

      case 27: // escape
        this.closeSearch()
        break

      case 17: // ctrl
        this.ctrlPressed = true
        break

      case 75: // K
        if (this.ctrlPressed) {
          this.openSearch()
          e.preventDefault()
        }
        break
    }
  }

  getAccount = () => {
    return this.state.account.substr(0, 6) + '..' + this.state.account.substr(this.state.account.length - 4)
  }

  onWrongChain = () => {
    this.setState({
      wrongChain: true
    })
  }

  onCorrectChain = () => {
    this.setState({
      correctChain: true
    })
  }

  onDisconnected = async () => {
    this.setState({
      signer: null,
      account: null,
      name: null,
      avatar: null,
    })

    if (this.userModal) {
      this.userModal.hide()
    }
  }

  onConnected = async () => {
    const account = services.provider.getAccount()
    const signer = services.provider.getSigner()
    if (this.connectModal) {
      this.connectModal.hide()
    }
    this.props.loadProfile()
    this.setState({
      account,
      signer,
    })
  }

  toggleMenu = () => {
    this.setState(state => ({
      menuOpen: !state.menuOpen
    }))
  }

  toggleDarkmode = () => {
    this.props.setDarkmode(!this.props.isDarkmode)
  }

  renderAccountSection() {
    if (this.state.wrongChain) {
      return (
        <div className='text-sm px-2 py-1 border border-gray-300 dark:border-gray-600 rounded cursor-pointer' onClick={() => services.provider.switchChain()}>
          Switch chain
        </div>
      )
    }

    if (this.props.loadingProfile) {
      return (
        <div className='text-sm px-2 pb-1 pt-2 border border-gray-300 dark:border-gray-600 rounded'>
          <components.Spinner color={this.props.isDarkmode ? '#eee' : 'black'} />
        </div>
      )
    }

    if (this.state.account) {
      return (
        <>
          <div className='cursor-pointer flex items-center justify-center border border-gray-300 rounded py-1 px-2 dark:border-gray-600' onClick={() => this.userModal.toggle()}>
            <div className='text-sm mr-2'>{this.props.profile?.name || this.getAccount()}</div>
            <components.Avatar
              width='20px'
              height='20px'
              borderRadius='20px'
              avatar={this.props.profile?.avatar}
              account={this.state.account}
            />
          </div>
        </>
      )
    }

    return (
      <div className='text-sm px-2 py-1 border border-gray-300 dark:border-gray-600 rounded cursor-pointer' onClick={() => services.connect.toggle()}>
        Connect
      </div>
    )
  }
 
  render() {
    return (
      <div className={`font-poppins`}>
        <components.Modal zIndex={'20'} ref={(ref) => this.userModal = ref}>
          <div className='max-w-md m-auto'>
            {this.state.account ? (
              <div className='mb-2 cursor-pointer flex flex-col items-center justify-center rounded py-1 px-2'>
                <div>
                  <components.Avatar
                    width='50px'
                    height='50px'
                    borderRadius='50px'
                    avatar={this.props.profile?.avatar}
                    account={this.state.account}
                  />
                </div>
                <div className='mt-2'>{this.props.profile?.name || this.getAccount()}</div>
              </div>
            ) : null}
            <div>
              <Link
                to={services.linking.path('MyDomains')}
                onClick={() => this.userModal.hide()}
                className="flex justify-between bg-gray-200 font-bold p-4 rounded mb-2 dark:bg-gray-700">
                <div>{'My Domains'}</div>
                <ArrowRightIcon className="h-6" />
              </Link>
            </div>
            <div>
              <div
                onClick={() => {
                  services.provider.disconnectWallet()
                }}
                className="cursor-pointer flex justify-between bg-gray-100 font-bold p-4 rounded mb-2 dark:bg-gray-800">
                <div>{'Disconnect Wallet'}</div>
                <XIcon className="h-6" />
              </div>
            </div>
          </div>
        </components.Modal>

        <components.Modal zIndex={'20'} ref={(ref) => services.connect.setModal(ref)} title={'Connect your wallet'}> 
          <components.ConnectWallet ref={(ref) => services.connect.setComponent(ref)} />
        </components.Modal>

        <components.Modal zIndex={'20'} ref={(ref) => services.notifications.setModal(ref)} borderless={true}>
          <Notifi account={this.state.account} signer={this.state.signer} darkMode={this.props.isDarkmode} />
        </components.Modal>
        
        <components.Modal ref={(ref) => this.searchModal = ref}>
          <div className='font-bold'>
            
          </div>
          <components.DomainSearch ctrl={(searchBox) => this.searchBox = searchBox} onBeforeSubmit={() => this.searchModal.toggle()} />
        </components.Modal>

        {/* Mobile menu */}
        <div className="fixed top-0 bg-white dark:bg-gray-900 h-full left-0 w-screen z-10 transition-all" style={{left: '100%', transform: this.state.menuOpen ? 'translateX(-100%)' : 'translateX(0)'}}>
          <div className="absolute top-0 right-0 p-4 cursor-pointer" onClick={this.toggleMenu.bind(this)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <div className="font-poppins flex-col flex items-center h-full p-4">
            <Link 
              to={services.linking.path('Landing')}
              onClick={this.toggleMenu.bind(this)}>
              <div className='dark:bg-white mb-4' style={{borderRadius: '500px'}}>
                <img src={services.linking.static('images/logo.png')} className="w-12 md:w-14 m-auto dark:w-12 dark:md:w-12" alt="Avvy Domains" />
              </div>
            </Link>
            <div className='mb-2 w-full'>
              <components.DomainSearch onBeforeSubmit={this.toggleMenu.bind(this)} />
            </div>
            <Link 
              className='block text-lg p-2 w-full h-16 flex items-center justify-between' 
              to={services.linking.path('MyDomains')}
              onClick={this.toggleMenu.bind(this)}>
              <div>My Domains</div>
              <ArrowRightIcon className='w-6' />
            </Link>
            <div className='w-full h-1 bg-gray-100 dark:bg-gray-800'></div>
            <Link 
              className='block text-lg p-2 w-full h-16 flex items-center justify-between' 
              to={services.linking.path('Settings')}
              onClick={this.toggleMenu.bind(this)}>
              <div>Settings</div>
              <ArrowRightIcon className='w-6' />
            </Link>
            <div className='w-full h-1 bg-gray-100 dark:bg-gray-800'></div>
            <div className='flex items-center'>
              <div className="font-poppins mr-4 text-md">
                <div className='py-8 px-4 cursor-pointer' onClick={() => this.props.setDarkmode(!this.props.isDarkmode)}>
                  {this.props.isDarkmode ? (
                    <SunIcon className='w-6' />
                  ) : (
                    <MoonIcon className='w-6' />
                  )}
                </div>
              </div>
              <div className="font-poppins text-md">
                <div onClick={() => {
                  services.notifications.toggle()
                }}>
                  <div className='py-8 px-4 cursor-pointer'>
                    <BellIcon className='w-6' />
                  </div>
                </div>
              </div>
            </div>
            <div>{this.renderAccountSection()}</div>
            <div className="h-24"></div>
          </div>
          <div className="absolute bottom-0 mb-8 text-center w-full">
            <div className="w-32 m-auto">
              <a href="https://avax.network">
                <img src={services.linking.static('images/avax.png')} alt="Powered by Avalanche." />
              </a>
            </div>
          </div>
        </div>

        {/* Page header */}
        <div className="fixed top-0 w-full h-16 md:h-24 border-b-2 border-gray-100 bg-white dark:bg-gray-900 dark:border-gray-700 z-10">
          <div className="text-center flex items-center justify-between w-full h-full max-w-screen-xl m-auto">
            <Link to={services.linking.path('Landing')}>
              <div className="h-full ml-1 md:ml-6 items-center justify-center flex">
                <div className='dark:bg-white dark:mx-2' style={{borderRadius: '500px'}}>
                  <img src={services.linking.static('images/logo.png')} className="w-12 md:w-14 m-auto dark:w-8 dark:md:w-12" alt="Avvy Domains" />
                </div>
                <div className="text-left ml-1 md:ml-3 dark:text-white">
                  <div className="font-zen uppercase text-md md:text-xl">Avvy</div>
                  <div className="font-poppins text-xs md:text-sm" style={{marginTop: '-4px'}}>The Avalanche Name Service</div>
                </div>
              </div>
            </Link>
            <div className="p-3 md:hidden cursor-pointer" onClick={this.toggleMenu.bind(this)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </div>
            <div className="pr-8 hidden md:flex items-center dark:text-white">
              <div className="font-poppins text-md">
                <div className="py-8 px-4 cursor-pointer" onClick={() => this.openSearch()}>
                  <SearchIcon className='w-6' />
                </div>
              </div>
              <div className="font-poppins text-md">
                <div className='py-8 px-4 cursor-pointer' onClick={() => this.props.setDarkmode(!this.props.isDarkmode)}>
                  {this.props.isDarkmode ? (
                    <SunIcon className='w-6' />
                  ) : (
                    <MoonIcon className='w-6' />
                  )}
                </div>
              </div>
              <div className="font-poppins text-md">
                <Link to={services.linking.path('Settings')}>
                  <div className='py-8 px-4 cursor-pointer'>
                    <CogIcon className='w-6' />
                  </div>
                </Link>
              </div>
              <div className="font-poppins text-md">
                <div onClick={() => services.notifications.toggle()}>
                  <div className='py-8 px-4 cursor-pointer'>
                    <BellIcon className='w-6' />
                  </div>
                </div>
              </div>
              <div className='pl-4'>
                {this.renderAccountSection()}
              </div>
            </div>
          </div>
        </div>
        <div className='min-h-screen flex flex-col justify-between'>

          <div>
            {/* Content */}
            <div className="h-16 md:h-24"></div>
            <components.ContinueRegistration />
            <div className='max-w-screen-xl m-auto p-4'>
              {this.props.children}
            </div>

            {/* Cart */}
            <div className='absolute bottom-0 w-full'>
            </div>
          </div>

          {/* Footer */}
          <div className='w-full grow-0'>
            <div className='border-t border-gray-100 dark:border-gray-800 p-4 text-gray-500'>
              <div className='w-full max-w-screen-xl m-auto px-2 md:px-8 flex flex-col flex-wrap sm:flex-row justify-between items-center text-sm'>
                <div>
                  <a href="https://avvy.domains/docs">User Docs</a>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <a href="https://docs.avvy.domains">Technical Docs</a>
                </div>
                <div>
                  {services.environment.VERSION_NUMBER}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isDarkmode: services.darkmode.selectors.isDarkmode(state),
  profile: services.user.selectors.profile(state),
  loadingProfile: services.user.selectors.loadingProfile(state),
  experimentalFeatures: services.user.selectors.experimentalFeatures(state),
})

const mapDispatchToProps = (dispatch) => ({
  setDarkmode: (value) => dispatch(services.darkmode.actions.setDarkmode(value)),
  loadProfile: () => dispatch(services.user.actions.loadProfile()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
