"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.emailTargetFragmentDependencies, fragments_1.emailTargetFragment];
const QUERY = `
query getEmailTargets {
  emailTarget {
    ...emailTargetFragment
  }
}
`.trim();
const getEmailTargetsImpl = (0, notifi_axios_utils_1.makeParameterLessRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, QUERY), 'emailTarget');
exports.default = getEmailTargetsImpl;
