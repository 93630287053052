"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ensureDiscord = exports.ensureWebhook = exports.ensureTelegram = exports.ensureSms = exports.ensureEmail = void 0;
const ensureTarget = (create, identify, valueTransform) => {
    return async (service, existing, value) => {
        if (value === undefined) {
            return undefined;
        }
        const transformedValue = valueTransform !== undefined ? valueTransform(value) : value;
        const found = existing === null || existing === void 0 ? void 0 : existing.find((it) => identify(it) === transformedValue);
        if (found !== undefined) {
            return found.id;
        }
        const created = await create(service, transformedValue);
        existing === null || existing === void 0 ? void 0 : existing.push(created);
        return created.id;
    };
};
const ensureEmail = ensureTarget(async (service, value) => await service.createEmailTarget({
    name: value.toLowerCase(),
    value: value.toLowerCase(),
}), (arg) => { var _a, _b; return (_b = (_a = arg.emailAddress) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : undefined; }, (value) => value.toLowerCase());
exports.ensureEmail = ensureEmail;
const ensureSms = ensureTarget(async (service, value) => await service.createSmsTarget({
    name: value,
    value,
}), (arg) => arg.phoneNumber);
exports.ensureSms = ensureSms;
const ensureTelegram = ensureTarget(async (service, value) => await service.createTelegramTarget({
    name: value.toLowerCase(),
    value: value.toLowerCase(),
}), (arg) => { var _a, _b; return (_b = (_a = arg.telegramId) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : null; }, (value) => value.toLowerCase());
exports.ensureTelegram = ensureTelegram;
// Webhook cannot use ensureTarget due to requiring more than one parameter in its creation
const ensureWebhook = async (service, existing, params) => {
    if (params === undefined) {
        return null;
    }
    const found = existing === null || existing === void 0 ? void 0 : existing.find((it) => it.url.toLowerCase() === params.url.toLowerCase() &&
        it.format === params.format);
    if (found !== undefined) {
        return found.id;
    }
    const created = await service.createWebhookTarget(Object.assign(Object.assign({}, params), { name: params.url.toLowerCase(), url: params.url.toLowerCase() }));
    existing === null || existing === void 0 ? void 0 : existing.push(created);
    return created.id;
};
exports.ensureWebhook = ensureWebhook;
const ensureDiscord = ensureTarget(async (service, value) => await service.createDiscordTarget({
    name: value,
    value,
}), (arg) => arg.name, () => 'Default');
exports.ensureDiscord = ensureDiscord;
exports.default = ensureTarget;
