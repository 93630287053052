"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const notifi_axios_utils_1 = require("@notifi-network/notifi-axios-utils");
const fragments_1 = require("../fragments");
const DEPENDENCIES = [...fragments_1.sourceGroupFragmentDependencies, fragments_1.sourceGroupFragment];
const MUTATION = `
mutation updateSourceGroup(
  $id: String!
  $name: String!
  $sourceIds: [String!]!
) {
  updateSourceGroup: createSourceGroup(
    sourceGroupInput: {
      id: $id
      name: $name
      sourceIds: $sourceIds
    }
  ) {
    ...sourceGroupFragment
  }
}
`.trim();
const updateSourceGroupImpl = (0, notifi_axios_utils_1.makeRequest)((0, notifi_axios_utils_1.collectDependencies)(...DEPENDENCIES, MUTATION), 'updateSourceGroup');
exports.default = updateSourceGroupImpl;
