import constants from './constants'

const actions = {
  setCode: (value) => {
    return {
      type: constants.SET_REFERRAL_CODE,
      value,
    }
  },
}

export default actions
