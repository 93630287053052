//
// This service provides variables that
// may change dependent on deployment
// environment.
//
const environment = {
  DEFAULT_PROVIDER_URL: process.env.REACT_APP_DEFAULT_PROVIDER_URL,
  DEFAULT_BLOCK_EXPLORER_URL: process.env.REACT_APP_DEFAULT_BLOCK_EXPLORER_URL,
  DEFAULT_CHAIN_ID: process.env.REACT_APP_DEFAULT_CHAIN_ID,
  DEFAULT_CHAIN_NAME: process.env.REACT_APP_DEFAULT_CHAIN_NAME,
  BACKEND_BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,
  MAX_REGISTRATION_QUANTITY: 5, // years
  MAX_REGISTRATION_NAMES: 8, // number of names per registration chunk
  REGISTRATIONS_ENABLED: true,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  AUCTION_KEY: process.env.REACT_APP_AUCTION_KEY,
  PROOF_KEY: process.env.REACT_APP_PROOF_KEY,
  VERSION_NUMBER: process.env.REACT_APP_VERSION_NUMBER,
  WALLETCONNECT_PROJECT_ID: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
  DEFAULT_IPFS_GATEWAY: 'https://cloudflare-ipfs.com',

  // NOTIFI
  NOTIFI_CARD_ID: process.env.REACT_APP_NOTIFI_CARD_ID,
  NOTIFI_DAPP_ADDRESS: process.env.REACT_APP_NOTIFI_DAPP_ADDRESS, 
  NOTIFI_ENV: 'Production',

  // STRIPE ONRAMP
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,

  // AUCTION
  SILENCE_DOMAINS: process.env.REACT_APP_SILENCE_DOMAINS.split(','),
}

export default environment

